import { useMemo, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../../../../../components/breadcrumb';
import BasePreMatricula from '../../basePreMatricula';
import { getHistorico } from '../../../../../services/processos/preMatricula';
import { useQuery } from 'react-query';
import FormInput from '../../../../../components/formInput';
import Table from '../../../../../components/table';
import TableComponent from '../../../../../components/novaTabela';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PreMatriculaInscricoesHistorico = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return 'PRÉ-MATRÍCULA';
      case 2:
        return 'CONVOCADO';
      case 3:
        return 'MATRICULADO';
      case 4:
        return 'NÃO COMPARECIDO';
      case 5:
        return 'INDEFERIDO';
      case 6:
        return 'CANCELADO';
      case 7:
        return 'DESISTÊNCIA';
      case 8:
        return 'MATRICULADO POR OUTRA OPÇÃO';
      case 9:
        return 'CONVOCADO POR OUTRA OPÇÃO';
      default:
        return '';
    }
  };

  const { isError, isLoading, refetch } = useQuery(
    'getHistorico',
    () => getHistorico(id, state.unidade),
    {
      retry: 0,
      enabled: state.unidade > 0,
      onSuccess: (data) => {
        setData(data);
      }
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Data', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          renderStatus(row.original.status)
        )
      },
      { Header: 'Por', accessor: 'usuario' },
      { Header: 'Observação', accessor: 'observacao' },
    ],
    []
  );

  const { register, setValue } = useForm({ mode: "onSubmit" });

  useEffect(() => {
    setValue('id', id);
    setValue('nome', state.nome);
    setValue('cpf', state.cpf);
    setValue('unidade', state.unidade);
  }, [setValue, id, state]);

  return (
    <BasePreMatricula isEvasaoAlunos>
      <Breadcrumb title="ATUALIZAR STATUS" itens={["PRÉ-MATRICULA 2025", "INSCRIÇÕES", "ATUALIZAR STATUS"]} />
      <form id="form">
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormInput
                    register={register}
                    errors={{}}
                    group=""
                    atribute="cpf"
                    label="CPF"
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInput
                    register={register}
                    errors={{}}
                    group=""
                    atribute="nome"
                    label="Nome"
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInput
                    register={register}
                    errors={{}}
                    group=""
                    atribute="unidade"
                    label="Unidade Opção"
                    readOnly
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
         
                <TableComponent
                  columns={columns}
                  data={data.data || []}
                  hasPagination
                  limit={limit}
                  setLimit={setLimit}
                  skip={skip}
                  setSkip={setSkip}
                  totalItems={data?.total}
                  hiddenColluns={["id", "passo2CpfAluno"]}
                />
          
          </Box>
        )}

        {isError && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            Ocorreu um erro ao carregar os dados.
          </Typography>
        )}
      </form>
    </BasePreMatricula>
  );
};

export default PreMatriculaInscricoesHistorico;