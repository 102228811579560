import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { deficiencias } from '../data/deficiencias';

const Step2 = ({ formik }) => {
  const deficienteOptions = [
    { value: 1, text: 'SIM' },
    { value: 2, text: 'NÃO' },
  ];

  return (
    <Grid container spacing={2}>
      {/* Nome Completo */}
      <Grid item xs={12}>
        <TextField
          label="Nome Completo"
          name="responsavelNomeCompleto"
          value={formik.values.responsavelNomeCompleto}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.responsavelNomeCompleto && Boolean(formik.errors.responsavelNomeCompleto)}
          helperText={formik.touched.responsavelNomeCompleto && formik.errors.responsavelNomeCompleto}
          fullWidth
          required
        />
      </Grid>

      {/* Data de Nascimento */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Data de Nascimento"
          type="date"
          name="responsavelDataNascimento"
          value={formik.values.responsavelDataNascimento}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.responsavelDataNascimento && Boolean(formik.errors.responsavelDataNascimento)}
          helperText={formik.touched.responsavelDataNascimento && formik.errors.responsavelDataNascimento}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
        />
      </Grid>

      {/* CPF */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="CPF"
          name="responsavelCpf"
          value={formik.values.responsavelCpf}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, '');
            const maskedValue = value
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            formik.setFieldValue('responsavelCpf', maskedValue);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.responsavelCpf && Boolean(formik.errors.responsavelCpf)}
          helperText={formik.touched.responsavelCpf && formik.errors.responsavelCpf}
          fullWidth
          required
        />
      </Grid>

      {/* RG */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="RG"
          name="responsavelRg"
          value={formik.values.responsavelRg}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.responsavelRg && Boolean(formik.errors.responsavelRg)}
          helperText={formik.touched.responsavelRg && formik.errors.responsavelRg}
          fullWidth
        />
      </Grid>


        <Grid item xs={12}>
          <TextField
            label="Email"
            type="email"
            name="responsavelEmail"
            value={formik.values.responsavelEmail}
            onChange={(e) => {
          const value = e.target.value.replace(/\s/g, ''); // Remove any spaces
          formik.setFieldValue('responsavelEmail', value);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.responsavelEmail && Boolean(formik.errors.responsavelEmail)}
            helperText={formik.touched.responsavelEmail && formik.errors.responsavelEmail}
            fullWidth
            required
          />
        </Grid>

          <Grid item xs={12}>
            <TextField
          label="Celular"
          name="responsavelTelefone"
          value={formik.values.responsavelTelefone}
          onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '');
          const maskedValue = value
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4,5})(\d{4})$/, '$1-$2');
          formik.setFieldValue('responsavelTelefone', maskedValue);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.responsavelTelefone && Boolean(formik.errors.responsavelTelefone)}
            helperText={formik.touched.responsavelTelefone && formik.errors.responsavelTelefone}
            fullWidth
            required
          />
        </Grid>

        {/* Parentesco */}
      <Grid item xs={12}>
        <TextField
          label="Parentesco"
          name="reponsavelParentesco"
          value={formik.values.reponsavelParentesco}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.reponsavelParentesco && Boolean(formik.errors.reponsavelParentesco)}
          helperText={formik.touched.reponsavelParentesco && formik.errors.reponsavelParentesco}
          fullWidth
          required
        />
      </Grid>

      {/* Deficiente */}
       <Grid item xs={12}>
              <Autocomplete
                options={deficienteOptions}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) => option.value === value}
                value={deficienteOptions.find((opt) => opt.value === formik.values.responsavelDeficiente) || null}
                onChange={(event, value) => formik.setFieldValue('responsavelDeficiente', value ? value.value : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="PcD?"
                    name="responsavelDeficiente"
                    onBlur={formik.handleBlur}
                    error={formik.touched.responsavelDeficiente && Boolean(formik.errors.responsavelDeficiente)}
                    helperText={formik.touched.responsavelDeficiente && formik.errors.responsavelDeficiente}
                    required
                  />
                )}
                fullWidth
              />
            </Grid>

      {/* Tipo de Deficiência */}
      {formik.values.responsavelDeficiente === 1 && (
        <Grid item xs={12}>
                    <Autocomplete
                      options={deficiencias}
                      getOptionLabel={(option) => option.text}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      value={deficiencias.find((opt) => opt.value === formik.values.responsavelDeficiencia) || null} // Bind to formik value
                      onChange={(event, value) =>
                        formik.setFieldValue('responsavelDeficiencia', value ? value.value : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Deficiência"
                          name="responsavelDeficiencia"
                          onBlur={formik.handleBlur}
                          error={formik.touched.responsavelDeficiencia && Boolean(formik.errors.responsavelDeficiencia)}
                          helperText={formik.touched.responsavelDeficiencia && formik.errors.responsavelDeficiencia}
                        />
                      )}
                      fullWidth
                    />
                  </Grid>
      )}
    </Grid>
  );
};

export default Step2;
