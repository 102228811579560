import { useEffect, useState } from 'react';
import './style.css';



const ImpressaoFaltas = () => {
    const [data, setData] = useState();
    const [alunosUnicos, setAlunosUnicos] = useState([]);

    useEffect(() => {
        var dados = JSON.parse(localStorage.getItem("faltasImpressao"));
        setData(dados);
        setAlunosUnicos([...new Map(data?.data.map(item => [item.alunoId, item])).values()]);
        setTimeout(() => {
            window.print();
        }, 2000);
    }, []);

    const filtrarHoraAulaPorData = (dataFiltrar, i) => {
        debugger;
        var diaAula = [...new Set(data?.data[0].faltas.filter(item => item.data === dataFiltrar).map(item => item.hora))];
        return diaAula[i] == undefined ? '-' : diaAula[i];
    };

    const filtrarPresencaPorData = (dataFiltrar, alunoId, i) => {
        const filtrado = data?.data.find(item => item.aluno.alunoId == alunoId);
        var response = filtrado.faltas.filter(x => x.data == dataFiltrar)[i];
        return response == undefined ? '-' : response.presenca == true ? "P" : "F";
    };

    const calculaTotalFaltas = (aluno) =>{
        var response = data?.data.filter(x => x.aluno.alunoId == aluno.aluno.alunoId);
        return response[0].faltas.filter(x=> x.presenca == false).length;
    }

    return (
        <div className='class-print' style={{ marginLeft: '10px', marginRight: '13px' }}>
            <div className="row remove-border-bottom">
                <div className="logo-container">
                    <img
                        className='p-3 logo-impressao'
                        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
                        alt='Logo'
                    />
                </div>
                <div className='text-container'>
                    ESTADO DO RIO DE JANEIRO <br />
                    {process.env.REACT_APP_PREFEITURA !== 'PREFEITURA MUNICIPAL DE ARARUAMA' ? 'PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA' : process.env.REACT_APP_PREFEITURA} <br />
                    SECRETARIA MUNICIPAL DE EDUCAÇÃO
                </div>
            </div>
            <div className='row'>
            <h5 className='text-center'>{data?.nomeEscola}</h5>
            <p className='text-center'>DIÁRIO DE CLASSE</p>
            </div>
            <div className='row border-right border-bottom border-left bg-bege'>
                <h5 className='text-center dados-unidade mt-2'>ESCOLARIDADE: {data?.nomeEscolaridade} | TURNO: {data?.nomeTurno} | TURMA: {data?.nomeTurma} | DISCIPLINA: {data?.nomeDisciplina} | {data?.nomeTrimestre}</h5>
            </div>
            <table>
                <tr>
                    <th>Data Chamada</th>
                    {
                        data?.dias?.map((row) => {
                            return <th colspan="3"><i>{row}</i></th>
                        })
                    }
                    <th colspan="1"><i></i></th>
                </tr>
                <tr>
                    <th></th>
                    {
                        data?.dias?.map((dia) => {
                            const th = [];
                            for (let index = 0; index < 3; index++) {
                                th.push(<th class="font vertical ">{filtrarHoraAulaPorData(dia, index)}</th>);
                            }
                            return th;
                        })
                    }
                    <th class="font">TOTAL DE FALTAS</th>
                </tr>
                {

                    data?.data?.map((item) => {
                        return (
                            <tr>
                                <td>{item.aluno.nome}</td>
                                {
                                    data?.dias?.map((dia) => {
                                        const th = [];
                                        for (let index = 0; index < 3; index++) {
                                            th.push(<td class="font-aula td-class"><strong>{filtrarPresencaPorData(dia, item.aluno.alunoId, index)}</strong></td>);
                                        }
                                        return th;
                                    })
                                }

                                <td class="font-aula td-class"><strong>{calculaTotalFaltas(item)}</strong></td>
                            </tr>
                        );
                    })
                }

            </table>
        </div>
    );
};

export default ImpressaoFaltas;
