import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput'

export default function Memorando({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()


  return (
    <div
      className="tab-pane fade show active"
      id="memorando"
      role="tabpanel"
      aria-labelledby="memorando-tab"
    >
      <div className="card-body">
        <div className="row">
          {id && (
            <>
              <div className="col-md-1">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="id"
                  label="ID"
                  readOnly={true}
                  required={false}
                />
              </div>

              <div className="col-md-11">
                <label htmlFor="ativo">Status <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                <select className="form-select" id="ativo" {...register("ativo", { required: false })}>
                  <option value={false}>Inativo</option>
                  <option value={true}>Ativo</option>
                </select>
                {errors.ativo?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
              </div>
            </>
          )}

        </div>

        <div className="row" style={id ? { marginTop: '30px' } : {}}>

          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              atribute="descricao"
              label="Descrição"
            />
          </div>

          <div className="col-md-6">
            <FormInput
            required={id ? false : true}
              register={register}
              errors={errors}
              type="file"
              atribute="anexo"
              label="Anexo"
            />
          </div>

        </div>
      </div>
    </div>
  )
}
