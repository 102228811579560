import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import FormInput from "../../../components/formInput";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUnidades } from "../../../services/unidades";
import { useQuery } from "react-query";
import { postLivro } from "../../../services/biblioteca";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";

const LivroAdicionarPage = (gestao) => {
  const navigate = useNavigate();

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const { data: unidades, isLoading: loadingUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      isbn: "",
      titulo: "",
      editora: "",
      estado_do_livro: "",
      ano: "",
      quantidade: "",
      quantidade_de_paginas: "",
      foto_do_livro: "",
      autores: "",
      unidadeLocalizacao: 0,
    },
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const downloadImageAsBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return convertToBase64(blob);
  };

  const onSubmit = async (values) => {
    try {
      if (values.foto_do_livro && typeof values.foto_do_livro === 'string' && values.foto_do_livro.startsWith('http')) {
        // If foto_do_livro is a URL, download and convert to Base64
        values.foto_do_livro = await downloadImageAsBlob(values.foto_do_livro);
      } else if (values.foto_do_livro && values.foto_do_livro.length > 0 && values.foto_do_livro[0] instanceof Blob) {
        // If foto_do_livro is a file, convert to Base64
        values.foto_do_livro = await convertToBase64(values.foto_do_livro[0]);
      } else {
        values.foto_do_livro = "";
      }

      await postLivro(values);
      toast.success("Livro cadastrado com sucesso!");
      navigate(-1); // Navigate back after successful submission
    } catch (error) {
      toast.error("Erro ao cadastrar o livro. Tente novamente mais tarde!");
      console.error("Error posting livro:", error);
    }
  };

  const fetchBookData = async (isbn) => {
    const cleanedIsbn = isbn.replace(/-/g, '');

    // Only validate if the length of the cleaned ISBN is 13
    if (cleanedIsbn.length === 13) {
      if (!isValidISBN(cleanedIsbn)) {
        toast.error("ISBN inválido. Por favor, insira um ISBN válido.");
        return;
      }

      try {
        const response = await axios.get(`https://api2.isbndb.com/book/${cleanedIsbn}`, {
          headers: {
            accept: "application/json",
            Authorization: "53869_67aa18fc3d476aa07ed5c0acd32a556a",
          },
        });

        if (response.status === 200) {
          const bookData = response.data.book;
          setValue("titulo", bookData.title);
          setValue("editora", bookData.publisher);
          setValue("autores", bookData.authors.join(", "));
          setValue("ano", bookData.date_published.split("-")[0]);
          setValue("quantidade_de_paginas", bookData.pages);
          setValue("foto_do_livro", bookData.image);
        }
      } catch (error) {
        console.error("Error fetching book data:", error);
      }
    }
  };

  const isValidISBN = (isbn) => {
    // Remove dashes
    const cleanedIsbn = isbn.replace(/-/g, '');

    // Check for ISBN-13 format
    return /^(97(8|9))?\d{9}(\d|X)$/.test(cleanedIsbn);
  };

  const isbn = watch("isbn");

  useEffect(() => {
    if (isbn) {
      fetchBookData(isbn);
    }
  }, [isbn]);

  return (
    <BaseComponent>
      <Breadcrumb
        title={"CADASTRO LIVRO"}
        itens={[BasePath, "Biblioteca", "Cadastro"]}
      />
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="livrosTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dados-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dados"
                        type="button"
                        role="tab"
                        aria-controls="dados"
                        aria-selected="true"
                      >
                        DADOS GERAIS
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="dados"
                      role="tabpanel"
                      aria-labelledby="dados-tab"
                    >
                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS DO LIVRO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>ISBN</label>
                            <InputMask
                              mask="999-99-999-9999-9"
                              maskChar=""
                              {...register("isbn")}
                              onBlur={() => fetchBookData(watch("isbn"))}
                              className={`form-control ${
                                errors.isbn ? "is-invalid" : ""
                              }`}
                            />
                            {errors.isbn && (
                              <div className="invalid-feedback">
                                {errors.isbn.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="titulo"
                            label="Título"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="editora"
                            label="Editora"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-5">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="autores"
                            label="Autores"
                            required={true}
                          />
                        </div>

                        <div className="col-md-1">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="ano"
                            label="Ano"
                            type="number"
                            required={true}
                          />
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Estado do Livro</label>
                            <select
                              className="form-select"
                              id="estado_do_livro"
                              {...register("estado_do_livro", {
                                required: true,
                              })}
                            >
                              <option value="4">ÓTIMO</option>
                              <option value="3">BOM</option>
                              <option value="2">REGULAR</option>
                              <option value="1">RUIM</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="quantidade"
                            label="Quantidade"
                            type="number"
                            required={true}
                          />
                        </div>
                        <div className="col-md-2">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="quantidade_de_paginas"
                            label="Número de Páginas"
                            type="number"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            type="file"
                            atribute="foto_do_livro"
                            label="Foto do Livro"
                            required={false}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: 0,
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ marginRight: 20 }}
                          type="button"
                          className="btn btn-warning"
                          onClick={() => navigate(-1)}
                        >
                          Voltar
                        </button>
                        <button
                          type="submit"
                          className="btn btn-success"
                          // disabled={!isValid}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent>
  );
};

export default LivroAdicionarPage;
