import api from "..";

async function getBnccByDisciplinaAnoFaixa(disciplina, anoFaixa) {
  var url = "";
  if(anoFaixa.indexOf("CRECHE") > -1)
    url = `BnccInfantil/filtro?escolaridade=CRECHE&componente=${disciplina}`;
  else if(anoFaixa.indexOf("PRÉ") > -1)
  url = `BnccInfantil/filtro?escolaridade=PRÉ-ESCOLA&componente=${disciplina}`;
  else
  url = `BnccFundamental/getBnccByDisciplinaAnoFaixa?disciplina=${disciplina}&anoFaixa=${anoFaixa.substring(0,1)}`
  return await api
    .get(url)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

export {
    getBnccByDisciplinaAnoFaixa,
}