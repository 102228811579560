import React from 'react';
import {
  Grid,
  TextField,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { escolaridades } from '../data/escolaridades';
import { deficiencias } from '../data/deficiencias';
import { getCpfAlunoNaRede } from '../../../../../../services/processos/chamadaPublica2025';

const deficienteOptions = [
  { value: 1, text: 'SIM' },
  { value: 2, text: 'NÃO' },
];

const Step1 = ({ formik, tipoFormulario, estudanteNaoLocalizado, setEstudanteRede, estudanteRede }) => {
  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
      {tipoFormulario === 'nao-sou-aluno' && (
        <Grid item xs={12}>
        <Alert severity="info"> 
          Inscrição disponível apenas para alunos que não são da rede de São Pedro da Aldeia.
        </Alert>
        </Grid>
      )}

      {estudanteNaoLocalizado && (
          <Grid item xs={12}>
        <Alert severity="error" fullWidth>
          Estudante não localizado na rede de ensino, não será possível prosseguir com o cadastro.
        </Alert>
        </Grid>
      )}

      {estudanteRede && tipoFormulario === 'nao-sou-aluno' && (
          <Grid item xs={12}>
        <Alert severity="error" fullWidth>
          Aluno já cadastrado na rede, favor acessar o formulário de transferência.
        </Alert>
        </Grid>
      )}

      {/* Responsive Table */}
      <Grid item xs={12}>
        <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
            <thead>
              <tr>
                <th>Turma</th>
                <th>Nascidos A Partir De</th>
                <th>Nascidos Até</th>
                <th>Idade Base A Partir De</th>
                <th>Idade Base Até</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Creche I</td>
                <td>01/04/2024</td>
                <td>30/09/2024</td>
                <td>6 meses</td>
                <td>11 meses</td>
              </tr>
              <tr>
                <td>Creche II</td>
                <td>01/04/2023</td>
                <td>31/03/2024</td>
                <td>1 ano</td>
                <td>1 ano e 11 meses</td>
              </tr>
              <tr>
                <td>Creche III</td>
                <td>01/04/2022</td>
                <td>31/03/2023</td>
                <td>2 anos</td>
                <td>2 anos e 11 meses</td>
              </tr>
              <tr>
                <td>Creche IV</td>
                <td>01/04/2021</td>
                <td>31/03/2022</td>
                <td>3 anos</td>
                <td>3 anos e 11 meses</td>
              </tr>
              <tr>
                <td>Pré I</td>
                <td>01/04/2020</td>
                <td>31/03/2021</td>
                <td>4 anos</td>
                <td>4 anos e 11 meses</td>
              </tr>
              <tr>
                <td>Pré II</td>
                <td>01/04/2019</td>
                <td>31/03/2020</td>
                <td>5 anos</td>
                <td>5 anos e 11 meses</td>
              </tr>
              <tr>
                <td>1º Ano</td>
                <td>01/04/2007</td>
                <td>31/03/2019</td>
                <td>6 anos</td>
                <td>17 anos e 11 meses</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Grid>
      

      {/* CPF */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="CPF"
          name="cpf"
          value={formik.values.cpf}
          onChange={async (e) => {
            const value = e.target.value.replace(/\D/g, '');
            const maskedValue = value
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            formik.setFieldValue('cpf', maskedValue);
            if (value.length === 11) {
              const data = await getCpfAlunoNaRede(value);
              setEstudanteRede(data?.data);
            } else {
              setEstudanteRede(false);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cpf && Boolean(formik.errors.cpf)}
          helperText={formik.touched.cpf && formik.errors.cpf}
          required
          fullWidth
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Grid>

      {/* Data de Nascimento */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Data de Nascimento"
          type="date"
          name="dataNascimento"
          value={formik.values.dataNascimento}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.dataNascimento && Boolean(formik.errors.dataNascimento)}
          helperText={formik.touched.dataNascimento && formik.errors.dataNascimento}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Nome"
          name="nome"
          value={formik.values.nome}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.nome && Boolean(formik.errors.nome)}
          helperText={formik.touched.nome && formik.errors.nome}
          fullWidth
          required
        />
      </Grid>

      {/* Escolaridade */}
      <Grid item xs={12}>
        <Autocomplete
          options={escolaridades}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={escolaridades.find((opt) => opt.value === formik.values.escolaridade) || null}
          onChange={(event, value) => formik.setFieldValue('escolaridade', value ? value.value : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Escolaridade"
              name="escolaridade"
              onBlur={formik.handleBlur}
              error={formik.touched.escolaridade && Boolean(formik.errors.escolaridade)}
              helperText={formik.touched.escolaridade && formik.errors.escolaridade}
              required
            />
          )}
          fullWidth
        />
      </Grid>

      {/* Cartão do SUS */}
      <Grid item xs={12}>
        <TextField
          label="Cartão do SUS"
          name="cartaoDoSus"
          value={formik.values.cartaoDoSus}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.cartaoDoSus && Boolean(formik.errors.cartaoDoSus)}
          helperText={formik.touched.cartaoDoSus && formik.errors.cartaoDoSus}
          fullWidth
        />
      </Grid>

      

      {/* Deficiente */}
      <Grid item xs={12}>
        <Autocomplete
          options={deficienteOptions}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={deficienteOptions.find((opt) => opt.value === formik.values.deficiente) || null}
          onChange={(event, value) => formik.setFieldValue('deficiente', value ? value.value : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="PcD?"
              name="deficiente"
              onBlur={formik.handleBlur}
              error={formik.touched.deficiente && Boolean(formik.errors.deficiente)}
              helperText={formik.touched.deficiente && formik.errors.deficiente}
              required
            />
          )}
          fullWidth
        />
      </Grid>

      {/* Tipo da Deficiência (if Deficiente is 'Sim') */}
      {formik.values.deficiente === 1 && (
        <>
          <Grid item xs={12}>
            <Autocomplete
              options={deficiencias}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) => option.value === value}
              value={deficiencias.find((opt) => opt.value === formik.values.deficiencia) || null}
              onChange={(event, value) =>
                formik.setFieldValue('deficiencia', value ? value.value : '')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Deficiência"
                  name="deficiencia"
                  onBlur={formik.handleBlur}
                  error={formik.touched.deficiencia && Boolean(formik.errors.deficiencia)}
                  helperText={formik.touched.deficiencia && formik.errors.deficiencia}
                />
              )}
              fullWidth
            />
          </Grid>

          {/* Alert for Laudo Médico */}
          <Grid item xs={12}>
            <Alert severity="warning">
              Necessário comprovação de laudo médico atualizado que comprove deficiência (no ato
              de efetivação da matrícula).
            </Alert>
          </Grid>
        </>
      )}

      {/* Filiação 1 */}
      <Grid item xs={12}>
        <TextField
          label="Filiação 1"
          name="filiacao1"
          value={formik.values.filiacao1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.filiacao1 && Boolean(formik.errors.filiacao1)}
          helperText={formik.touched.filiacao1 && formik.errors.filiacao1}
          fullWidth
          required
        />
      </Grid>

      {/* Filiação 2 */}
      <Grid item xs={12}>
        <TextField
          label="Filiação 2"
          name="filiacao2"
          value={formik.values.filiacao2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.filiacao2 && Boolean(formik.errors.filiacao2)}
          helperText={formik.touched.filiacao2 && formik.errors.filiacao2}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default Step1;
