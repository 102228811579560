import React, { useState, useMemo, useEffect } from 'react';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../../gestao/baseGestao';
import urlsGestao from '../../gestao/urlsGestao';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import Breadcrumb from '../../../components/breadcrumb';
import Table from '../../../components/table';
import Select from 'react-select';
import { getUnidades } from '../../../services/unidades';
import { getEscolaridadesUnidade } from '../../../services/escolaridades';
import { getTurnosUnidadeEscolaridade } from '../../../services/turnos';
import { getTurmasUnidadeEscolaridadeTurno } from '../../../services/turmas';
import { useQuery } from 'react-query';
import { listarAvisos } from '../../../services/familia-conectada';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const FamiliaConectadaAvisos = (gestao) => {
  let BaseComponent;
  let BasePath;
  let adicionarUrl;
  let modulo = 'Família Conectada';
  let componente = 'Listar Avisos';
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [turmas, setTurmas] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const unidadeId = localStorage.getItem('unidadeID');
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeId);
  const [escolaridade, setEscolaridade] = useState(0);
  const [turno, setTurno] = useState(0);
  const [turma, setTurma] = useState(0);
  const [avisos, setAvisos] = useState([]);
 



  const { isLoading: avisosData, refetch: refetchAvisos } = useQuery(
    'getAvisos',
    () => listarAvisos(unidade, turma, 0, 0, 20),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        setAvisos(data?.data.data);
      },
    }
  );

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    'getUnidades',
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: 'TODOS' }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      },
    }
  );

  const { isError, isLoading: loadingTurmas, refetch } = useQuery(
    'getTurmas',
    () =>
      getTurmasUnidadeEscolaridadeTurno(
        {unidadeId: unidade,
        escolaridadeId: escolaridade,
        turnoId: turno,
        anoLetivo: 2024,
        skip,
        limit}
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setTurmas(data);
      },
    }
  );

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades,
  } = useQuery(
    'getEscolaridades',
    () => (unidade !== null ? getEscolaridadesUnidade(unidade, 0) : null),
    {
      retry: 0,
      enabled: false,
    }
  );

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos,
  } = useQuery(
    'getTurnos',
    () =>
      unidade !== null && escolaridade !== null
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade, 2024)
        : null,
    {
      retry: 0,
      enabled: false,
    }
  );

  const cleanFilters = async (name) => {
    switch (name) {
      case 'unidade':
        await setEscolaridade(0);
        await setTurno(0);
        break;
      case 'escolaridade':
        await setTurno(0);
        break;
      case 'turno':
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    refetchAvisos();
    refetchEscolaridades();
  }, [refetchAvisos, limit, skip]);

  console.log(avisos);

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão';
    adicionarUrl = urlsGestao.alunosAdicionar;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades';
    adicionarUrl = urlsGestaoUnidades.alunosAdicionar;
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Título', accessor: 'titulo' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: 'Notificado Em',
        accessor: 'dataComunicacao',
        Cell: ({ row }) => row.original.dataComunicacao.split('T')[0].split('-').reverse().join('/'),
      },
      { Header: 'Enviado Por', accessor: 'servidorNome' },
      { Header: 'Aluno', accessor: 'alunoNome' },
      {
        Header: 'Lido',
        accessor: 'lida',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.lida ? 'success' : 'danger'}`}>
            {row.original.lida ? 'SIM' : 'NÃO'}
          </span>
        ),
      },
    ],
    []
  );

  

  return (
    <BaseComponent>
      <Breadcrumb title={componente} itens={[BasePath, modulo, componente]} />

      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              isDisabled={gestao.gestao ? false : true}
              value={unidades.find((x) => x.value == unidade)}
              placeholder=""
              onChange={async (e) => {
                await setUnidade(e.value);
                cleanFilters('unidade');
                refetchEscolaridades();
                refetch();
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaridadeID" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeID"
              value={escolaridade}
              onChange={async (e) => {
                await setEscolaridade(e.target.value);
                cleanFilters('escolaridade');
                refetchTurnos();
                refetch();
              }}
            >
              {loadingEscolaridades ? (
                <option value={0}>TODOS</option>
              ) : (
                <>
                  <option value={0}>TODOS</option>
                  {escolaridades &&
                    escolaridades.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async (e) => {
                await setTurno(e.target.value);
                refetch();
              }}
            >
              {loadingTurnos ? (
                <option value={0}>TODOS</option>
              ) : (
                <>
                  <option value={0}>TODOS</option>
                  {turnos &&
                    turnos.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              value={turma}
              onChange={async (e) => {
                await setTurma(e.target.value);
                refetchAvisos();
              }}
            >
              {loadingTurmas ? (
                <option value=""></option>
              ) : (
                <>
                  <option value=""></option>
                  {turmas?.data &&
                    turmas?.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                        {unidade === 0
                          ? ' - ' + (unidades.find((unidade) => unidade.value === item.unidadeId)?.label || '')
                          : ''}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group" style={{ width: '100%' }}>
              <label htmlFor="titulo" className="form-label">
                TÍTULO
              </label>
              <textarea
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                value={titulo}
                className="form-control"
                id="titulo"
                rows="1"
                placeholder="REUNIÃO"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="form-group" style={{ width: '100%' }}>
              <label htmlFor="descricao" className="form-label">
                DESCRIÇÃO
              </label>
              <textarea
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                value={descricao}
                className="form-control"
                id="descricao"
                rows="1"
                placeholder="REUNIÃO DE PAIS DIA ..."
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={adicionarAviso}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal> */}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={avisos || []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={avisos?.length}
            />
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FamiliaConectadaAvisos;
