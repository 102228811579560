import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { postProcesso, putProcesso, postAnexo } from './../../../../../services/administrativo/financeiro-processos'
import { useMutation } from 'react-query'
import Base from './../../../../../pages/administrativo/base'
import Processo from './tab/processo'
import { toast } from 'react-toastify'
import removeEmpty from '../../../../../utils/removeEmpty'
import upperCaseSimple from '../../../../../utils/upperCaseSimple'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { store } from '../../../../../store'


const ProcessosCadastrarPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const currentUser = store.getState()["user"]["user"];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      nomeProcesso: '',
      numeroProcesso: '',
      dataVencimento: '',
      anexoId: '',
      anexo: null,
    }
  })

  const { mutate: cadastrarMutate } = useMutation(postProcesso, {
    onSuccess: () => {
      toast.success('Processo cadastrado com sucesso.');
      navigate(urlsAdministrativo.financeiroProcessos);
    },
    onError: () => {
      toast.error('Alguma coisa deu errada. Tente novamente mais tarde.');
    }
  })

  const { mutate: editarMutate } = useMutation(putProcesso, {
    onSuccess: () => {
      toast.success('Processo atualizado com sucesso.');
      navigate(urlsAdministrativo.financeiroProcessos);
    },
    onError: () => {
      toast.error('Alguma coisa deu errada. Tente novamente mais tarde.');
    }
  })

  const { mutate: anexoMutate } = useMutation(postAnexo, {
    onSuccess: (data) => {
      const requestParams = {
        nomeProcesso: watch('nomeProcesso'),
        numeroProcesso: watch('numeroProcesso'),
        dataVencimento: watch('dataVencimento'),
        usuarioId: currentUser?.servidorId,
        anexoId: data.id
      }
      if (id) {
        editarMutate({ id: id, data: upperCaseSimple(removeEmpty(requestParams)) })
      } else {
        cadastrarMutate(upperCaseSimple(removeEmpty(requestParams)))
      }
    },
    onError: (data) => {
      toast.error('Alguma coisa aconteceu ao salvar o anexo, tente novamente.')
    }
  })

  useMemo(() => {
    (async () => {
      if (id && state) {
        setValue('id', id)
        setValue('nomeProcesso', state?.nomeProcesso)
        setValue('numeroProcesso', state?.numeroProcesso)
        setValue('dataVencimento', state?.dataVencimento)
      }
    })()
  }, [id, state, setValue])

  const onSubmit = async (data) => {
    
    if (data?.anexo?.[0]) {
      const file = data.anexo[0];

      const formData = new FormData();
      formData.append('arquivo', file);

      await anexoMutate(formData)

      return;
    }

    if (id) {
      console.log('atualizacao sem anexo')
      const requestParams = {
        nomeProcesso: data.nomeProcesso,
        numeroProcesso: data.numeroProcesso,
        dataVencimento: data.dataVencimento,
      }
      editarMutate({ id: id, data: upperCaseSimple(removeEmpty(requestParams)) })
    } else {
      console.log('cadastro sem anexo')
      const requestParams = {
        nomeProcesso: data.nomeProcesso,
        numeroProcesso: data.numeroProcesso,
        dataVencimento: data.dataVencimento,
      }
      cadastrarMutate(upperCaseSimple(removeEmpty(requestParams)))
    }
  }


  return (
    <Base>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="processo-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#processo"
                        type="button"
                        role="tab"
                        aria-controls="processo"
                        aria-selected="true"
                      >
                        Processo
                      </button>
                    </li>
                  </ul>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <Processo
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(urlsAdministrativo.financeiroProcessos)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!isValid}
                      >
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  )

};

export default ProcessosCadastrarPage;