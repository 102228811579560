import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { store } from "../../../store";
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseDocente from "../../docente/baseDocente";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import urlsDocente from "../../docente/urlsDocente";
import { listarAlunosRiap, postQuantidadeAula, listarAulas } from "../../../services/ficha-avaliacao";
import { quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioServidorEscolaridade } from "../../../services/quadro-horario";
import { getDisciplinasByTurmaServidor } from "../../../services/disciplinas";
import TabelaFundamentalRiap from "../tabelas/tabelaFundamentalRiap";

const RiapFundamental = (gestao) => {
  const currentUser = store.getState()['user']['user'];
  const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
  const [escolaridade, setEscolaridade] = useState(0);
  const [turno, setTurno] = useState(0);
  const [turma, setTurma] = useState(0);
  const [disciplina, setDisciplina] = useState(0);
  const [ciclo, setCiclo] = useState(0);
  const [aluno, setAluno] = useState(0);
  const [nomeAluno, setNomeAluno] = useState('');
  const [totalAulasDadas, setTotalAulasDadas] = useState('');

  const ciclos = [
    { value: 1, label: '1º TRIMESTRE' },
    { value: 2, label: '2 TRIMESTRE' },
    { value: 3, label: '3 TRIMESTRE' },
  ];

  const { data: aulas, isLoading: loadingAulas, refetch: refetchAulas } = useQuery(
    ['getAulasRiap', disciplina],
    () => (disciplina > 0 ? listarAulas(turma, ciclo, disciplina) : null),
    {
      enabled: disciplina > 0,
      onSuccess: data => {
        console.log(data?.totalAulas);
        if (data !== null) {
          setTotalAulasDadas(data.totalAulas);
        } else {
          setTotalAulasDadas('');
        }
      }
    }
  );

  const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery(
    'getAlunos',
    () => turma !== 0 ? listarAlunosRiap(turma, ciclo, disciplina) : null,
    {
      retry: 3,
      enabled: turma !== 0 && ciclo !== 0 && disciplina !== 0,
    }
  );

  let BaseComponent;
  let BasePath;
  let servidorId;
  let pageTitle = '3 AO 9 ANO';
  let urlImprir = '';

  function clickImprimir() {
    if (ciclo === 0 || disciplina === 0){
      toast.warn('Selecione um trimestre e disciplina para imprimir!')
      return;
    }
    window.open(urlImprir, '_blank');
  }

  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades';
    servidorId = 0;
    urlImprir = urlsGestaoUnidades.riapFundamentalImpressao;
  } else if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente';
    servidorId = currentUser?.servidorId;
    urlImprir = urlsDocente.riapFundamentalImpressao;
  }

  const [turnos, setTurnos] = useState([]);
  const { refetch: refetchTurnos, loadingTurnos } = useQuery(
    'getTurnos',
    () => (unidade !== null ? quadroHorarioServidorTurno(servidorId, unidade, escolaridade) : null),
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null,
      onSuccess: (data) => {
        if (data === null) {
          return;
        }
        var dados = [];
        data.forEach((item) => {
          if (!dados.some((d) => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });
        setTurnos(dados);
      },
    }
  );

  const [disciplinas, setDisciplinas] = useState([]);

  const { refetch: refetchDisciplinas, loadingDisciplinas } = useQuery(
    'getDisciplinas',
    () => (turma !== null && turma !== 0 ? getDisciplinasByTurmaServidor(turma, servidorId) : null),
    {
      retry: 3,
      enabled: turma !== null && turma !== 0,
      onSuccess: (data) => {
        if (data != null) {
          var dados = [];
          data.forEach((item) => {
            if (!dados.some((d) => d.value === item.id)) {
              dados.push({ value: item.id, label: item.descricao });
            }
          });
          setDisciplinas(dados);
        }
      },
    }
  );

  useEffect(() => {
    if (unidade !== null && escolaridade !== null) {
      refetchTurnos();
    }
  }, [escolaridade, refetchTurnos]);

  const handleChangeEscolaridade = async (e) => {
    await setEscolaridade(e.target.value);
    await setTurno(0);
    await setCiclo(0);
    await setTurma(0);
    await setDisciplina(0);
    await localStorage.removeItem('turmaIdImpressaoRiapInfantil');
  };

  const handleChangeTurno = async (e) => {
    await setTurno(e.target.value);
    await setCiclo(0);
    await setTurma(0);
    await setDisciplina(0);
    await localStorage.removeItem('turmaIdImpressaoRiapInfantil');
  };

  const handleChangeTrimestre = async (e) => {
    await setCiclo(e.target.value);
    await refetchAulas();
    await setDisciplina(0);
  };

  const handleChangeDisciplina = async (e) => {
    await setDisciplina(e.target.value);
    await localStorage.setItem('disciplinaIdImpressaoTerceiroAnoRiap', e.target.value);
    await refetchAlunos();
  };

  const handleChangeAluno = async (e) => {
    await setAluno(e.target.value);
    await setNomeAluno(e.target.options[e.target.selectedIndex].label);
  };

  async function handleBlurAulasDadas(e) {
    let data = {
      disciplinaId: disciplina,
      trimestreId: ciclo,
      turmaId: turma,
      quantidadeAulasDadas: e.target.value
    };

    const response = await postQuantidadeAula(data);

    if (response?.statusCode === undefined) {
      return;
    }
    if (response.statusCode === 200) {
      toast.success('Aulas lançadas com sucesso!');
    } else {
      toast.error('Erro ao salvar aulas!');
    }
  }

  const handleChangeTurma = async (e) => {
    await setTurma(e.target.value);
    await setCiclo(0);
    await setDisciplina(0);
    await localStorage.setItem('turmaIdImpressaoTerceiroAnoRiap', e.target.value);
  };

  const [turmas, setTurmas] = useState([]);
  const { refetch: refetchTurmas, loadingTurmas } = useQuery(
    'getTurmas',
    () => (unidade !== null ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null),
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null && turno !== null,
      onSuccess: (data) => {
        if (data === null) {
          return;
        }
        var dados = [];
        data.forEach((item) => {
          if (!dados.some((d) => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });
        setTurmas(dados);
      },
    }
  );

  useEffect(() => {
    if (unidade !== null && escolaridade !== null && turno !== null) {
      refetchTurmas();
    }
  }, [turno, refetchTurmas]);

  useEffect(() => {
    refetchAlunos();
    refetchAulas();
  }, [disciplina, refetchAlunos, refetchAulas]);

  const [escolaridades, setEscolaridades] = useState([]);
  let tipo = 3;
  const tipo1 = [12, 13, 14, 15, 24, 25];
  const tipo2 = [1];
  const tipo3 = [3, 4, 5, 6, 8, 10, 11];
  const tipo4 = [16, 17, 18, 19, 20, 21, 22, 23];

  const { refetch: refetchEscolaridades, loadingEscolariades } = useQuery(
    'getEscolaridades',
    () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
    {
      retry: 3,
      enabled: unidade !== null,
      onSuccess: (data) => {
        if (data === null) {
          return;
        }
        var dados = [];
        data.forEach((item) => {
          if (
            (tipo === 1 && tipo1.includes(item.id)) ||
            (tipo === 2 && tipo2.includes(item.id)) ||
            (tipo === 3 && tipo3.includes(item.id)) ||
            (tipo === 4 && tipo4.includes(item.id))
          ) {
            if (!dados.some((d) => d.value === item.id)) {
              dados.push({ value: item.id, label: item.descricao });
            }
          }
        });
        setEscolaridades(dados);
      },
    }
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={pageTitle}
        itens={[BasePath]}
        imprimir={{ link: urlImprir, text: "Imprimir" }}
        onClickImprimir={clickImprimir}
      />
      <form id='form'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <h5>Filtros</h5>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="escolaridadeId" className="form-label">
                          Escolaridades
                        </label>
                        <select
                          className="form-select"
                          id="escolaridadeId"
                          value={escolaridade}
                          onChange={async e => {
                            await handleChangeEscolaridade(e);
                          }}
                        >
                          {loadingEscolariades ? (
                            <option value={0}></option>
                          ) : (
                            <>
                              <option value={0}></option>
                              {escolaridades &&
                                escolaridades.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="turnoID" className="form-label">
                          Turnos
                        </label>
                        <select
                          className="form-select"
                          id="turnoID"
                          value={turno}
                          onChange={async e => {
                            await handleChangeTurno(e);
                          }}
                        >
                          {loadingTurnos ? (
                            <option value={0}></option>
                          ) : (
                            <>
                              <option value={0}></option>
                              {turnos &&
                                turnos.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="form-group">
                        <label htmlFor="turmaID" className="form-label">
                          Turmas
                        </label>
                        <select
                          className="form-select"
                          id="turmaID"
                          value={turma}
                          onChange={async e => {
                            await handleChangeTurma(e);
                          }}
                        >
                          {loadingTurmas ? (
                            <option value={0}></option>
                          ) : (
                            <>
                              <option value={0}></option>
                              {turmas &&
                                turmas.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="trimestreId" className="form-label">
                          Trimestres
                        </label>
                        <select
                          className="form-select"
                          id="trimestreId"
                          value={ciclo}
                          onChange={async e => {
                            await handleChangeTrimestre(e);
                          }}
                        >
                          <>
                            <option value={0}></option>
                            {ciclos &&
                              ciclos.map(item => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                          </>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label htmlFor="disciplinaId" className="form-label">
                          Disciplinas
                        </label>
                        <select
                          className="form-select"
                          id="disciplinaId"
                          value={disciplina}
                          onChange={async e => {
                            await handleChangeDisciplina(e);
                          }}
                        >
                          {loadingDisciplinas ? (
                            <option value={0}></option>
                          ) : (
                            <>
                              <option value={0}></option>
                              {disciplinas &&
                                disciplinas.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* Add more form elements here */}
                  </div>
  
                    
                    
                   
                
                  <div className='row' style={{marginTop: 30}}>
                  <h5>Lançamento</h5>

                  <div className="col-12 col-md-2">
                    <label style={{ marginBottom: 10 }} htmlFor="aulasDadas" id="aria-label">Horas / Aulas</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        aria-labelledby="aria-label"
                        id="aulasDadas"
                        name="aulasDadas"
                        value={totalAulasDadas} // Bind input to state
                        onChange={(e) => setTotalAulasDadas(e.target.value)} // Handle input change
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => { handleBlurAulasDadas({ target: { value: totalAulasDadas } }) }}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className='row' style={{ marginTop: 20 }}>
        {disciplina > 0 && (
          <TabelaFundamentalRiap key={Math.floor(Math.random() * 100) + 1} alunos={alunos} disciplina={disciplina} ciclo={ciclo} turma={turma} terceiroAoNonoAno={true} />
        )}
      </div>
    </BaseComponent>
  );
};

export default RiapFundamental;
