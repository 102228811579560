import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { putTransferencia } from "../../../../../../services/administrativo/patrimonio-estoque";

export default function EstoqueTransferenciasModal({ open, onClose, data }) {
  const unidadeString = localStorage.getItem("unidadeID");
  const unidadeId = parseInt(unidadeString);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      observacao: null,
      unidadeId: unidadeId,
      produtos: [],
    },
  });

  const mutation = useMutation({
    mutationFn: (formData) => putTransferencia(formData),
    onSuccess: () => {
      console.log("Dados enviados com sucesso");
      onClose();
    },
    onError: (error) => {
      console.error("Erro ao enviar dados:", error);
    },
  });

  const handleQuantidadeChange = (id, value) => {
    const currentProdutos = getValues("produtos") || [];
    const updatedProdutos = currentProdutos.map((produto) =>
      produto.transferId === id
        ? { ...produto, quantidadeRecebida: parseInt(value) || 0 }
        : produto
    );

    if (!updatedProdutos.some((produto) => produto.transferId === id)) {
      updatedProdutos.push({
        transferId: id,
        quantidadeRecebida: parseInt(value) || 0,
      });
    }

    setValue("produtos", updatedProdutos);
  };

  const onSubmit = async (formData) => {
    try {
      const produtosValidos = formData.produtos.filter(
        (produto) =>
          produto &&
          produto.transferId &&
          produto.quantidadeRecebida !== undefined
      );

      const payload = {
        unidadeId,
        observacao: formData.observacao,
        produtos: produtosValidos,
      };

      mutation.mutate(payload);
      reset();
      onClose();
    } catch (error) {
      console.error("Erro ao processar os dados:", error);
    }
  };

  useEffect(() => {
    if (data?.produtos) {
      const produtos = data.produtos.map((produto) => ({
        transferId: produto.id,
        quantidadeRecebida: produto.quantidade,
      }));
      reset({ produtos, observacao: null, unidadeId });
    }
  }, [data, reset, unidadeId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Detalhes da Transferência</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Número de Série</TableCell>
                <TableCell>Quantidade Enviada</TableCell>
                <TableCell>Quantidade Recebida</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.produtos?.map((produto) => (
                <TableRow key={produto?.id}>
                  <TableCell>{produto?.nome}</TableCell>
                  <TableCell>{produto?.marca}</TableCell>
                  <TableCell>{produto?.numeroDeSerie}</TableCell>
                  <TableCell>{produto?.quantidade}</TableCell>
                  <TableCell>
                    <Controller
                      name={`produtos[${produto.id}].quantidadeRecebida`}
                      control={control}
                      defaultValue={produto.quantidade || 0}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          required
                          onChange={(e) => {
                            field.onChange(e);
                            handleQuantidadeChange(produto.id, e.target.value);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TextField
          fullWidth
          margin="normal"
          label="Transportador"
          value={data?.transportadoPor}
          disabled
        />
        <TextField
          fullWidth
          margin="normal"
          label="Observação"
          {...control.register("observacao")}
          error={!!errors.observacao}
          helperText={errors.observacao?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
          disabled={mutation.isLoading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
