import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, Autocomplete } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  turnosPreMatriculaAraruama,
  unidadesPreMatriculaAraruama,
} from "../../../../../services/formulariosService";
import { escolhasBinarias } from "../data/escolhasBinarias";
import { unidadesIrmao } from "../data/unidadesIrmao";

const Etapa6 = ({ data, onChange, setValidate }) => {
  const [turnos, setTurnos] = useState([]); // Default to an empty array
  const [unidades1, setUnidades1] = useState({ proximos: [], outros: [] });
  const [unidades2, setUnidades2] = useState({ proximos: [], outros: [] });

  // Fetch turnos when necessary
  useEffect(() => {
    const fetchTurnos = async () => {
      try {
        const response = await turnosPreMatriculaAraruama(
          data.passo2Escolaridade
        );
        setTurnos(Array.isArray(response?.data) ? response?.data : []);
      } catch (error) {
        console.error("Failed to fetch turnos:", error);
      }
    };

    if (data.passo2Escolaridade) {
      fetchTurnos();
    }
  }, [data.passo2Escolaridade]);

  // Fetch unidades for the 1st option
  useEffect(() => {
    const fetchUnidades1 = async () => {
      try {
        const response = await unidadesPreMatriculaAraruama(
          data?.passo5Bairro || "",
          data?.passo5Cidade || "",
          data?.passo2Escolaridade || "",
          data?.passo6TurnoEscolha1 || ""
        );
        const result = response?.data || { proximos: [], outros: [] };

        if (data.passo2Deficiente === true) {
          result.proximos = result.proximos.filter(
            (unidade) => !unidade.nome.includes("CASA CRECHE")
          );
          result.outros = result.outros.filter(
            (unidade) => !unidade.nome.includes("CASA CRECHE")
          );
        }

        setUnidades1(result);
      } catch (error) {
        console.error("Failed to fetch unidades:", error);
      }
    };

    if (data.passo6TurnoEscolha1) {
      fetchUnidades1();
    }
  }, [
    data.passo6TurnoEscolha1,
    data.passo5Bairro,
    data.passo5Cidade,
    data.passo2Escolaridade,
  ]);

  // Fetch unidades for the 2nd option
  useEffect(() => {
    const fetchAndFilterUnidades2 = async () => {
      try {
        if (!data?.passo6TurnoEscolha2) return;

        const response = await unidadesPreMatriculaAraruama(
          data?.passo5Bairro || "",
          data?.passo5Cidade || "",
          data?.passo2Escolaridade || "",
          data?.passo6TurnoEscolha2 || ""
        );

        const result = response?.data || { proximos: [], outros: [] };

        if (data.passo2Deficiente === true) {
          result.proximos = result.proximos.filter(
            (unidade) => !unidade.nome.includes("CASA CRECHE")
          );
          result.outros = result.outros.filter(
            (unidade) => !unidade.nome.includes("CASA CRECHE")
          );
        }

        setUnidades2(result);
      } catch (error) {
        console.error("Failed to fetch or filter unidades:", error);
      }
    };

    fetchAndFilterUnidades2();
  }, [
    data.passo6TurnoEscolha2,
    data.passo5Bairro,
    data.passo5Cidade,
    data.passo2Escolaridade,
    data.passo6UnidadeEscolha1,
    data.passo6TurnoEscolha1,
  ]);

  // Validate the step
  useEffect(() => {
    setValidate(() => () => {
      const isSiblingFieldsValid =
        !data.passo6ConcorreUnidadeIrmao ||
        (data.passo6NomeDoIrmao &&
          data.passo6DataNascimentoIrmao &&
          data.passo6UnidadeRedeIrmao);

      return (
        data?.passo6TurnoEscolha1 &&
        data?.passo6UnidadeEscolha1 &&
        data?.passo6TurnoEscolha2 &&
        data?.passo6UnidadeEscolha2 &&
        isSiblingFieldsValid
      );
    });
  }, [data, setValidate]);

  useEffect(() => {
    if (data.passo6ConcorreUnidadeIrmao === false) {
      onChange("passo6NomeDoIrmao", "");
      onChange("passo6DataNascimentoIrmao", null);
      onChange("passo6UnidadeRedeIrmao", 0);
    }
  }, [data.passo6ConcorreUnidadeIrmao]);

  const combinedOptionsUnidade1 = [
    ...unidades1.proximos.map((item) => ({ ...item, groupLabel: "Próximos" })),
    ...unidades1.outros.map((item) => ({ ...item, groupLabel: "Outros" })),
  ];

  const combinedOptionsUnidade2 = [
    ...unidades2.proximos
      .filter(
        (item) =>
          item.id !== data.passo6UnidadeEscolha1 ||
          data.passo6TurnoEscolha1 !== data.passo6TurnoEscolha2
      )
      .map((item) => ({ ...item, groupLabel: "Próximos" })),
    ...unidades2.outros
      .filter(
        (item) =>
          item.id !== data.passo6UnidadeEscolha1 ||
          data.passo6TurnoEscolha1 !== data.passo6TurnoEscolha2
      )
      .map((item) => ({ ...item, groupLabel: "Outros" })),
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Escolha a Unidade e Turno
      </Typography>

      <Grid container spacing={2}>
        {/* Turno/Período - 1º Opção */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={turnos}
            getOptionLabel={(option) => option.descricao}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Turno/Período - 1º Opção"
                required
              />
            )}
            value={
              turnos.find(
                (option) => option.id === data?.passo6TurnoEscolha1
              ) || null
            }
            onChange={(e, value) => {
              onChange("passo6TurnoEscolha1", value ? value.id : null);
              onChange("passo6UnidadeEscolha1", null);
            }}
          />
        </Grid>

        {/* Unidade - 1º Opção */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={combinedOptionsUnidade1}
            groupBy={(option) => option.groupLabel}
            getOptionLabel={(option) => option.nome || ""}
            fullWidth
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.nome}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Unidade - 1º Opção" required />
            )}
            value={
              [...unidades1.proximos, ...unidades1.outros].find(
                (option) => option.id === data?.passo6UnidadeEscolha1
              ) || null
            }
            onChange={(e, value) =>
              onChange("passo6UnidadeEscolha1", value ? value.id : null)
            }
          />
        </Grid>

        {/* Turno/Período - 2º Opção */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={turnos}
            required
            getOptionLabel={(option) => option.descricao}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Turno/Período - 2º Opção"
                required
              />
            )}
            value={
              turnos.find(
                (option) => option.id === data?.passo6TurnoEscolha2
              ) || null
            }
            onChange={(e, value) => {
              onChange("passo6TurnoEscolha2", value ? value.id : null);
              onChange("passo6UnidadeEscolha2", null);
            }}
          />
        </Grid>

        {/* Unidade - 2º Opção */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={combinedOptionsUnidade2}
            groupBy={(option) => option.groupLabel}
            getOptionLabel={(option) => option.nome || ""}
            fullWidth
            required
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.nome}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Unidade - 2º Opção" required />
            )}
            value={
              [...unidades2.proximos, ...unidades2.outros].find(
                (option) => option.id === data?.passo6UnidadeEscolha2
              ) || null
            }
            onChange={(e, value) =>
              onChange("passo6UnidadeEscolha2", value ? value.id : null)
            }
          />
        </Grid>

        {/* Concorre à mesma unidade do irmão */}
        <Grid item md={12} xs={12}>
          <Autocomplete
            options={escolhasBinarias}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Concorre à mesma unidade do irmão"
              />
            )}
            value={
              data.passo6TemIrmaoNaRede !== null
                ? escolhasBinarias.find(
                    (option) => option.id === data.passo6TemIrmaoNaRede
                  )
                : null
            }
            onChange={(e, value) =>
              onChange("passo6TemIrmaoNaRede", value ? value.id : null)
            }
          />
        </Grid>

        {/* Nome do Irmão */}
        {data.passo6TemIrmaoNaRede && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                label="Nome do Irmão"
                fullWidth
                required
                value={data.passo6NomeDoIrmao || ""}
                onChange={(e) => onChange("passo6NomeDoIrmao", e.target.value)}
              />
            </Grid>

            {/* Data de Nascimento do Irmão */}
            <Grid item md={6} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label="Data de Nascimento"
                  value={
                    data.passo6DataNascimentoIrmao
                      ? dayjs(data.passo6DataNascimentoIrmao)
                      : null
                  }
                  onChange={(newValue) =>
                    onChange(
                      "passo6DataNascimentoIrmao",
                      newValue && dayjs(newValue).isValid()
                        ? newValue.toISOString()
                        : null
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true, // Prevent typing
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* Unidade do Irmão */}
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={unidadesIrmao}
                getOptionLabel={(option) => option.label}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Unidade do Irmão" required />
                )}
                value={
                  unidadesIrmao.find(
                    (option) => option.id === data.passo6UnidadeRedeIrmao
                  ) || null
                }
                onChange={(e, value) =>
                  onChange("passo6UnidadeRedeIrmao", value ? value.id : null)
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Etapa6;
