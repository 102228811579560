export const escolaridades = [
    { value: 1, text: '1º ANO' },
    { value: 2, text: '2º ANO' },
    { value: 3, text: '3º ANO' },
    { value: 4, text: '4º ANO' },
    { value: 5, text: '5º ANO' },
    { value: 6, text: '6º ANO' },
    { value: 7, text: '7º ANO' },
    { value: 8, text: '8º ANO' },
    { value: 9, text: '9º ANO' },
    { value: 10, text: 'CRECHE I' },
    { value: 11, text: 'CRECHE II' },
    { value: 12, text: 'CRECHE III' },
    { value: 13, text: 'CRECHE IV' },
    { value: 24, text: 'PRÉ I' },
    { value: 26, text: 'PRÉ II' },
  ]