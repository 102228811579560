import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumb from "../../../../../../components/breadcrumb";
import BaseGestaoUnidades from "../../../../../gestao-unidades/baseGestaoUnidades";
import { getEscolaridadesUnidade } from "../../../../../../services/escolaridades";
import { getTurmasUnidadeEscolaridadeTurno } from "../../../../../../services/turmas";
import { getTurnosUnidadeEscolaridade } from "../../../../../../services/turnos";
import { postEnturmar } from "../../../../../../services/processos/preMatricula";
import { useQuery, useMutation } from "react-query";
import { store } from "../../../../../../store";

const PreMatriculaMatriculasEnturmar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [aluno, setAluno] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [unidade, setUnidade] = useState(null);
  const [escolaridade, setEscolaridade] = useState(null);
  const [turno, setTurno] = useState(null);
  console.log(state);

  const [turma, setTurma] = useState(0);

  const unidadeID = localStorage.getItem("unidadeID");

  const currentUser = store.getState()["user"]["user"];

  const { data: unidades, isLoading: loadingEscolaridades } = useQuery(
    "getEscolaridades",
    () => (unidade !== null ? getEscolaridadesUnidade(unidadeID, 2025) : null),
    {
      retry: 3,
      enabled: unidade !== null,
    }
  );

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos,
  } = useQuery(
    "getTurnos",
    () =>
      unidadeID !== null && escolaridade !== null
        ? getTurnosUnidadeEscolaridade(
            unidadeID,
            state.aluno.escolaridadeId,
            2025
          )
        : null,
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null,
    }
  );

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas,
  } = useQuery(
    "getTurmas",
    () =>
      unidade !== null && escolaridade !== null && turno !== null
        ? getTurmasUnidadeEscolaridadeTurno({
            unidadeId: unidade,
            escolaridadeId: state.aluno.escolaridadeId,
            turnoId: state.aluno.turnoId,
            anoLetivo: 2025,
          })
        : null,
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null && turno !== null,
    }
  );

  const { mutate, isLoading } = useMutation(postEnturmar, {
    onSuccess: (message) => {
      toast.success("Matricula efetuada com sucesso!");
      navigate(-1);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error("Erro ao tentar enturmar, tente novamente mais tarde.");
      } else {
        toast.error(error.message);
      }
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      inscricaoId: "",
      unidadeID: "",
      turmaID: "",
      usuarioId: "",
    },
  });

  const onSubmit = (data) => {
    const values = {
      unidadeId: parseInt(unidadeID),
      turmaId: parseInt(turma),
      usuarioId: parseInt(currentUser?.servidorId),
      inscricaoId: id,
    };

    if (turma == 0) {
      return toast.warn(
        "Selecione uma turma para dar continuidade ao processo."
      );
    } else {
      mutate(values);
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { aluno } = state;
        if (id && aluno) {
          const unidadeID = await localStorage.getItem("unidadeID");
          const { nome, cpf, escolaridadeId, turnoId } = aluno;
          setUnidade(unidadeID);
          setAluno(nome);
          setCpf(cpf);
          setEscolaridade(escolaridadeId);
          setTurno(turnoId);
        }
      }
    })();
  }, [
    id,
    state,
    setValue,
    setAluno,
    setUnidade.setCpf,
    setEscolaridade,
    setTurno,
  ]);

  return (
    <BaseGestaoUnidades>
      <Breadcrumb
        title={"Enturmação"}
        itens={["Gestão", "Gestão de Unidades", "Matrículas", "Enturmação"]}
      />
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="nome" className="form-label">
                        Nome
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="nome"
                        value={aluno}
                        readOnly
                      />
                    </div>
                  </div>

                  <br />

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="cpf" className="form-label">
                        CPF
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={cpf}
                        readOnly
                      />
                    </div>
                  </div>

                  <br />

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="escolaridadeID" className="form-label">
                        Escolaridades
                      </label>
                      <select
                        className="form-select"
                        id="escolaridadeID"
                        disabled={true}
                        value={escolaridade}
                        {...register("escolaridadeID", { required: true })}
                        onChange={async (e) => {
                          refetchTurmas();
                        }}
                      >
                        {loadingEscolaridades ? (
                          <option value="">Carregando...</option>
                        ) : (
                          <>
                            <option value="">Selecione</option>
                            {unidades &&
                              unidades.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.descricao}
                                </option>
                              ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <br />

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="turnoID" className="form-label">
                        Turnos
                      </label>
                      <select
                        className="form-select"
                        id="turnoID"
                        disabled={true}
                        value={turno}
                        {...register("turnoID", { required: true })}
                        onChange={async (e) => {}}
                      >
                        {loadingTurnos ? (
                          <option value="">Carregando...</option>
                        ) : (
                          <>
                            <option value="">Selecione</option>
                            {turnos &&
                              turnos.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.descricao}
                                </option>
                              ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <br />

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="turmaID" className="form-label">
                        Turmas
                      </label>
                      <select
                        className="form-select"
                        id="turmaID"
                        {...register("turmaID", { required: true })}
                        onChange={async (e) => {
                          await setValue("turmaID", e.target.value);
                          setTurma(e.target.value);
                        }}
                      >
                        {loadingTurmas ? (
                          <option value="">Carregando...</option>
                        ) : (
                          <>
                            <option value="">Selecione</option>
                            {turmas?.data &&
                              turmas?.data?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.descricao}
                                </option>
                              ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </>
              </div>
              <div className="card-footer text-end">
                <button
                  style={{ marginRight: 20 }}
                  type="button"
                  className="btn btn-warning"
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={turma == 0}
                  onClick={() => onSubmit()}
                >
                  Enturmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseGestaoUnidades>
  );
};

export default PreMatriculaMatriculasEnturmar;
