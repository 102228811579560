import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../components/breadcrumb";
import BaseAdministrativo from "../../../baseAdministrativo";
import urlsAdministrativo from "../../../urlsAdministrativo";
import { useMutation } from 'react-query';
import upperCaseSimple from "../../../../../utils/upperCaseSimple";
import removeEmpty from "../../../../../utils/removeEmpty";
import { createVeiculo, updateVeiculo } from "../../../../../services/administrativo/transporte";

const VeiculoForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(id ? updateVeiculo : createVeiculo, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsAdministrativo.transporteVeiculos);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar veículo, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      modelo: '',
      fabricante: '',
      placa: '',
      ano: '',
      cor: ''
    }
  });

  const onSubmit = (data) => {
    if (id) {
      mutate({ id, data: upperCaseSimple(removeEmpty(data)) });
    } else {
      mutate(upperCaseSimple(removeEmpty(data)));
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const veiculo  = state.veiculo;
        if (id && veiculo) {
          setValue('modelo', veiculo.modelo);
          setValue('fabricante', veiculo.fabricante);
          setValue('placa', veiculo.placa);
          setValue('ano', veiculo.ano);
          setValue('cor', veiculo.cor);
        }
      }
    })();
  }, [id, state, setValue]);

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Formulário Veículo'} itens={['Administrativo', 'Transportes', 'Veículos', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-2'>
                      <label htmlFor="modelo" className="form-label">Modelo</label>
                      <input type="text" className="form-control" id="modelo"
                        {...register("modelo", { required: true })} />
                      {errors.modelo?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-2'>
                      <label htmlFor="fabricante" className="form-label">Fabricante</label>
                      <input type="text" className="form-control" id="fabricante"
                        {...register("fabricante", { required: true })} />
                      {errors.fabricante?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-2'>
                      <label htmlFor="placa" className="form-label">Placa</label>
                      <input type="text" className="form-control" id="placa"
                        {...register("placa", { required: true })} />
                      {errors.placa?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-2'>
                      <label htmlFor="ano" className="form-label">Ano</label>
                      <input type="number" className="form-control" id="ano"
                        {...register("ano", { required: true })} />
                      {errors.ano?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-2'>
                      <label htmlFor="cor" className="form-label">Cor</label>
                      <input type="text" className="form-control" id="cor"
                        {...register("cor", { required: true })} />
                      {errors.cor?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>
                </>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                  {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseAdministrativo>
  )

};

export default VeiculoForm;