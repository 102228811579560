import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { resetarSenhaV2 } from '../../services/servidores';
import { Types as UserTypes } from '../../store/ducks/user';
import {
    Box,
    Button,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    Divider
} from '@mui/material';
import { color, styled } from '@mui/system';

const LoginContainer = styled('div')({
    display: 'flex',
    height: '100vh',
});

const LoginImage = styled('div')({
    flex: 1,
    backgroundImage: `url(/assets/images/fundo/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const LoginForm = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '2rem',
});

const Header = styled(Box)({
    textAlign: 'center',
    marginBottom: '2rem',
});

const LogoPrefeitura = styled('img')({
    width: '120px',
    marginBottom: '1rem',
});

const PrefeituraText = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 'bold',
});

const RedefinirSenha = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const param = useParams();

    useEffect(() => {
        if(param.id == undefined){
            alert("Acesso não permitido");
            navigate("/");
        }
        localStorage.clear();
    }, []);

    const { handleSubmit, control, formState: { errors }, watch } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        }
    });

    const { mutate, isLoading } = useMutation(resetarSenhaV2, {
        onSuccess: (data) => {
          if (data.statusCode != 200) {
            toast.error(data.message);
            return;
          }
          toast.success(data.message);
          setTimeout(() => {
            navigate('/'); // Redireciona para os módulos
          }, 500);
        },
        onError: (error) => {
          if (error.response) {
            const { message } = error.response.data;
            toast.error(message);
          } else if (error.request) {
            toast.error('Erro ao tentar fazer login, tente novamente mais tarde.');
          } else {
            toast.error(error.message);
          }
        }
      });


    const handleLogin = async (data) => {
        const password = watch('password');
        const confirmPassword = watch('confirmPassword');

        if(password != confirmPassword){
            toast.error("Senhas diferentes, favor verificar!");
            return;
        }

        var request = {
            senha: password,
            token: param.id
        };
        mutate(request);
        //  var response = await resetarSenhaV2({ request });
    };

    return (
        <LoginContainer>
            <LoginImage />
            <LoginForm>
                <Header>
                    <LogoPrefeitura
                        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
                        alt="Logo Prefeitura"
                    />
                    <PrefeituraText>{process.env.REACT_APP_PREFEITURA}</PrefeituraText>
                    <PrefeituraText>SECRETARIA DE EDUCAÇÃO</PrefeituraText>
                    <p className="auth-description">
                        Redefinição de senha.
                    </p>
                </Header>
                <Typography
                    variant="h4"
                    component="h2"
                    textAlign="center"
                    marginBottom="2rem"
                >
                    Cadastre uma nova senha
                </Typography>
                <form onSubmit={handleSubmit(handleLogin)}>

                    <Grid container spacing={2} style={{ marginBottom: 15 }}>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="Nova Senha"
                                        placeholder="******"
                                        {...field}
                                    />
                                )}
                            />
                            {errors.password && (
                                <p className="text-danger">Digite sua senha.</p>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="Confirmar nova senha"
                                        placeholder="******"
                                        {...field}
                                    />
                                )}
                            />
                            {errors.confirmPassword && (
                                <p className="text-danger">Confirme sua senha.</p>
                            )}
                        </Grid>
                    </Grid>

                    <Box mt={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? "Confirmando..." : "Cadastrar nova senha"}
                        </Button>
                    </Box>
                </form>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="body2" textAlign="left">
                    Sistema de Gestão do Ecossistema Educacional | Versão -
                    @#BuildVersion#@
                </Typography>
                <Typography variant="body2" textAlign="left">
                    Todos os dados são de propriedade do cliente. Não faça uso indevido e
                    nem mesmo divulgar/vender as informações no qual tem acesso.
                </Typography>
            </LoginForm>
        </LoginContainer>
    );
};

export default RedefinirSenha;
