import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Step, StepLabel, Stepper, useMediaQuery, ThemeProvider, createTheme, Typography } from "@mui/material";
import Etapa1 from "../components/Etapa1";
import Etapa2 from "../components/Etapa2";
import Etapa3 from "../components/Etapa3";
import Etapa4 from "../components/Etapa4";
import Etapa5 from "../components/Etapa5";
import Etapa6 from "../components/Etapa6";
import Etapa7 from "../components/Etapa7";
import { criarFormularioPreMatriculaAraruama } from "../../../../../services/formulariosService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import urlsPreMatricula from "../../../../processos/preMatricula/urlsPreMatricula";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const steps = [
  "Pré-Matrícula",
  "Informações Iniciais",
  "Dados do Aluno",
  "Dados do Responsável",
  "Informações e Endereço",
  "Escolha a Unidade/Escola",
  "Confirmação Pré-Matrícula",
];



const FormularioPreMatriculaAraruama = () => {
  const savedStep = parseInt(localStorage.getItem("activeStep"), 10) || 0;
  const [activeStep, setActiveStep] = useState(savedStep);
  const { state } = useLocation();
  const navigate = useNavigate();
  const initialFormData = JSON.parse(localStorage.getItem("formData")) || {
    passo1Curso: "",
    passo1TermosPortaria: false,
    passo2Deficiente: false,
    passo2Escolaridade: 0,
    passo2CpfAluno: "",
    passo2EstudanteRede: false,
    passo2UnidadeRede: 0,
    passo3NomeCompleto: "",
    passo3DataNascimento: null,
    passo3CarteirinhaSus: "",
    passo3ModeloCertidaoNascimento: 0,
    passo3DocumentoCertidaoNascimento: "",
    passo4GgResponsavel: "",
    passo3Filiacao1: "",
    passo3Filiacao2: "",
    passo3EstudanteRedeExterna: false,
    passo3UnidadeRedeExterna: "",
    passo3CidadeRedeExterna: "",
    passo3EstadoRedeExterna: "",
    passo4NomeResponsavel: "",
    passo4GrauParentescoResponsavel: 0,
    passo4DataNascimentoResponsavel: null,
    passo4CpfResponsavel: "",
    passo4EmailResponsavel: "",
    passo4Telefone1Responsaval: "",
    passo4TipoTelefone1Responsavel: 0,
    passo4Telefone2Responsavel: "",
    passo4TipoTelefone2Responsavel: 0,
    passo5Cep: "",
    passo5Logradouro: "",
    passo5Numero: "",
    passo5Complemento: "",
    passo5Bairro: "",
    passo5Cidade: "",
    passo5Estado: "",
    passo6TurnoEscolha1: 0,
    passo6UnidadeEscolha1: 0,
    passo6TurnoEscolha2: 0,
    passo6UnidadeEscolha2: 0,
    passo6TemIrmaoNaRede: false,
    passo6NomeDoIrmao: "",
    passo6DataNascimentoIrmao: null,
    passo6UnidadeRedeIrmao: 0
  };

  const [formData, setFormData] = useState(initialFormData);

  // Save formData to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);
  

  const [isStepValid, setIsStepValid] = useState(false);
  // Media query for responsiveness
  const isMobileOrTablet = useMediaQuery("(max-width:1024px)"); // Mobile and tablet

  // Handle state updates
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    // Validate the current step on activeStep change
    if (validateRef.current) {
      setIsStepValid(validateRef.current());
      localStorage.setItem("activeStep", activeStep);
    }
  }, [activeStep, formData]); 

  useEffect(() => {
    if (state?.isAluno !== undefined) {
      if(state.isAluno){
        setFormData((prev) => ({
          ...prev,
          passo2EstudanteRede: true,
          passo3EstudanteRedeExterna: false
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          passo2EstudanteRede: false,
          passo2UnidadeRede: 0,
          passo3EstudanteRedeExterna: true
        }));
      }
    }
  }, [state]);

  const validateRef = useRef(() => true);

  // Render content based on the current step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <Etapa1 
                data={formData}
                onChange={handleChange}
                setValidate={(validateFn) => (validateRef.current = validateFn)}
              />;
      case 1:
        return <Etapa2 
        data={formData}
        onChange={handleChange}
        setValidate={(validateFn) => (validateRef.current = validateFn)}
      />;
      case 2:
        return <Etapa3
        data={formData}
        onChange={handleChange}
        setValidate={(validateFn) => (validateRef.current = validateFn)}
      />;
      case 3:
        return <Etapa4
        data={formData}
        onChange={handleChange}
        setValidate={(validateFn) => (validateRef.current = validateFn)}
      />;
      case 4:
        return <Etapa5
        data={formData}
        onChange={handleChange}
        setValidate={(validateFn) => (validateRef.current = validateFn)}
      />;
      case 5:
        return <Etapa6
        data={formData}
        onChange={handleChange}
        setValidate={(validateFn) => (validateRef.current = validateFn)}
      />;
      case 6:
        return <Etapa7
        data={formData}
      />;
      default:
        return "Etapa desconhecida";
    }
  };

  const handleNext = async () => {
    if (validateRef.current()) {
      if (activeStep === steps.length - 1) {
        // If it's the last step, handle form submission
        try {
          formData.passo3DataNascimento = dayjs(formData.passo3DataNascimento, "DD/MM/YYYY").toISOString();
          const response = await criarFormularioPreMatriculaAraruama(formData);
          // setFormData((prev) => ({
          //   ...prev,
          //   passo3DataNascimento: new Date(prev.passo3DataNascimento).toISOString(),
          // }));
          
          if (response?.status === 201) {
            // Handle successful submission (e.g., show success message, redirect)
            // alert("Pré-matrícula enviada com sucesso!");
            localStorage.removeItem("formData"); // Clear form data after successful submission
            localStorage.removeItem("activeStep"); // Clear step tracking
            setActiveStep(0); // Reset the form
            setFormData(initialFormData); // Clear form data state
            navigate(urlsPreMatricula.visualizar + response.data.id); // Redirect to the newly created form
          } else {
            toast.error("Erro ao enviar a pré-matrícula. Tente novamente.");
          }
        } catch (error) {
          console.error("Error during submission:", error);
          alert("Erro ao enviar a pré-matrícula. Tente novamente.");
        }
      } else {
        // If it's not the last step, move to the next step
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };
  

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          height: "100vh",
        }}
      >
        {/* Image Section */}
        {/* <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "25%",
            height: isMobileOrTablet ? "200px" : "100%",
            backgroundImage: "/assets/images/fundo/seduc.png",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Box> */}

        {/* Form Section */}
        <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: isMobileOrTablet ? 2 : 4,
            height: "100%",
          }}
        >
          {/* Header */}
          <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
            <img
              src="/assets/images/prefeituras/seduc.png"
              alt="Logo Prefeitura de Araruama"
              style={{ maxWidth: "150px" }}
            />
            <Typography variant="h5">PREFEITURA MUNICIPAL DE ARARUAMA</Typography>
            <Typography variant="h6">SECRETARIA DE EDUCAÇÃO</Typography>
            <Typography variant="body1" gutterBottom>
              <b>PRÉ-MATRÍCULA 2025 - SEDUC</b>
            </Typography>
          </Box>

          {/* Stepper */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel={!isMobileOrTablet}
              orientation={isMobileOrTablet ? "vertical" : "horizontal"}
              sx={{ mb: 3 }}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  {/* On Mobile/Tablet: Show content inside the active step */}
                  {isMobileOrTablet && activeStep === index && (
                    <Box
                      sx={{
                        mt: 2,
                        padding: 1,
                      }}
                    >
                      {renderStepContent(activeStep)}
                    </Box>
                  )}
                </Step>
              ))}
            </Stepper>

            {/* On Desktop: Show step content below the Stepper */}
            {!isMobileOrTablet && (
              <Box
                sx={{
                  mt: 3,
                }}
              >
                {renderStepContent(activeStep)}
              </Box>
            )}
          </Box>

          {/* Navigation Buttons */}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: isMobileOrTablet ? "space-between" : "flex-end",
              gap: 2,
              flexShrink: 0,
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              fullWidth={isMobileOrTablet}
            >
              Voltar
            </Button>
            <Button
              onClick={handleNext}
              variant="contained"
              fullWidth={isMobileOrTablet}
              disabled={!isStepValid} // Disable if step is invalid
            >
              {activeStep === steps.length - 1 ? "Enviar" : "Próximo"}
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FormularioPreMatriculaAraruama;
