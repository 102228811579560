import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Produtos from "./tab/produtos";
import HistoricoProduto from "./tab/historico";

const EstoqueUnidadesForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [canEdit, setCanEdit] = useState(id ? false : true);
  const [isHistoricTab, setIsHistoricTab] = useState(false);

  return (
    <BaseGestaoUnidades>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${!isHistoricTab ? "active" : ""}`}
                        id="produtos-tab"
                        data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        aria-controls="produtos"
                        aria-selected={!isHistoricTab}
                        onClick={() => setIsHistoricTab(false)}
                      >
                        Produtos
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${isHistoricTab ? "active" : ""}`}
                        id="historico-tab"
                        data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        aria-controls="historico"
                        aria-selected={isHistoricTab}
                        onClick={() => setIsHistoricTab(true)}
                      >
                        Histórico
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    {/* IMPLEMENTAR HISTORICO POSTERIORMENTE */}
                    {isHistoricTab ? <HistoricoProduto /> : <Produtos />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  );
};

export default EstoqueUnidadesForm;
