import React from 'react';
import { Route } from 'react-router-dom';
import urlsGestaoUnidades from '../../../pages/gestao-unidades/urlsGestaoUnidades';
import HomeChamadaPublica from '../../../pages/processos/chamadaPublica/homeChamadaPublica';
import ChamadaPublicaInscricoesHistoricoPage from '../../../pages/processos/chamadaPublica/private/historico';
import ChamadaPublicInscritosListagemPage from '../../../pages/processos/chamadaPublica/private/inscricoes-creche';
import ChamadaPublicaMatriculas from '../../../pages/processos/chamadaPublica/private/matriculas';
import MatriculasEnturmar from '../../../pages/processos/chamadaPublica/private/matriculas/enturmar';
import MatricularIndeferir from '../../../pages/processos/chamadaPublica/private/matriculas/indeferir';
import ChamadaPublicaPesquisarPage from '../../../pages/processos/chamadaPublica/private/pesquisar';
import ChamadaPublicaHome from '../../../pages/processos/chamadaPublica/public';
import ChamadaPublicaFormularioPage from '../../../pages/processos/chamadaPublica/public/formulario';
import RelatorioInscritosPorUnidadePage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porUnidade'
import RelatorioInscritosPorEscolaridadePage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porEscolaridade'
import RelatorioInscritosPorPnePage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porPne'
import RelatorioInscritosPorNovosDaRedePage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porNovosDaRede'
import RelatorioInscritosPorGeralPage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porGeral'
import RelatorioConvocacoesPorEscolaridadePage from '../../../pages/processos/chamadaPublica/private/relatorios/convocacoes/porEscolaridade'
import RelatorioMatriculadosPorEscolaridadePage from '../../../pages/processos/chamadaPublica/private/relatorios/matriculados/porEscolaridade'
import RelatorioMatriculadosPorUnidadePage from '../../../pages/processos/chamadaPublica/private/relatorios/matriculados/porUnidade'
import RelatorioMatriculadosPorGeralPage from '../../../pages/processos/chamadaPublica/private/relatorios/matriculados/porGeral'
import ListaDeEsperaPage from '../../../pages/processos/chamadaPublica/public/listaEspera';
import VisualizacaoFormularioChamadaPublicaPage from '../../../pages/processos/chamadaPublica/public/visualizacao';
import urlsChamadaPublica from '../../../pages/processos/chamadaPublica/urlsChamadaPublica';
import ChamadaPublicInscritosListagemPagePre from '../../../pages/processos/chamadaPublica/private/inscricoes-pre';
import ChamadaPublicInscritosListagemPageCreche from '../../../pages/processos/chamadaPublica/private/inscricoes-creche';
import ChamadaPublicaInscricoesRemanejamentoPage from '../../../pages/processos/chamadaPublica/private/inscricoes-remanejamento';
import ChamadaPublicInscritosListagemPageCrecheRemanejamento from '../../../pages/processos/chamadaPublica/private/inscricoes-creche-remanejamento';
import ChamadaPublicInscritosListagemPagePreRemanejamento from '../../../pages/processos/chamadaPublica/private/inscricoes-pre-remanejamento';
import RelatorioInscritosPorBairroPage from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porBairro';
import RelatorioInscritosPorTransExterna from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porTransExterna';
import RelatorioInscritosPorTransInterna from '../../../pages/processos/chamadaPublica/private/relatorios/inscritos/porTransInterna';
import ChamadaPublicInscritosListagemPageCrecheConvocacoes from '../../../pages/processos/chamadaPublica/private/inscricoes-creche-convocacoes';
import ChamadaPublicInscritosListagemPagePreConvocacoes from '../../../pages/processos/chamadaPublica/private/inscricoes-pre-convocacoes';
import RelatorioMatriculadosPorPeriodoNominalPage from '../../../pages/processos/chamadaPublica/private/relatorios/matriculados/porPeriodoNominal';
import RelatorioMatriculadosPorPeriodoQuantitativoPage from '../../../pages/processos/chamadaPublica/private/relatorios/matriculados/porPeriodoQuantitativo';
import RelatorioConvocadosPorPeriodoNominalPage from '../../../pages/processos/chamadaPublica/private/relatorios/convocacoes/porPeriodoNominal';
import RelatorioConvocadosPorPeriodoQuantitativoPage from '../../../pages/processos/chamadaPublica/private/relatorios/convocacoes/porPeriodoQuantitativo';
import AlunosManuaisPage from '../../../pages/alunos/manuais';
import AlunosPreMatriculaPage from '../../../pages/alunos/preMatricula';
import AlunosRemanejamentoPage from '../../../pages/alunos/remanejamento';
import ChamadaPublicaFormularioPageTransferencia from '../../../pages/processos/chamadaPublica/public/formulario/transferencia';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEDUC';
const dataAbertura = new Date('2024-01-06T09:00:00')
const dataFechamento = new Date('2025-01-23:59:59')

const ChamadaPublicaRoutes = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsChamadaPublica.dashboard} element={<HomeChamadaPublica />} />
      <Route path={urlsChamadaPublica.creches} element={<ChamadaPublicInscritosListagemPageCreche parametros={1} bloquearFiltros={false} statusDefault={1}/>} />
      <Route path={urlsChamadaPublica.pre} element={<ChamadaPublicInscritosListagemPagePre  parametros={2} bloquearFiltros={false}/>} statusDefault={1} />
      <Route path={urlsChamadaPublica.crechesConvocados} element={<ChamadaPublicInscritosListagemPageCrecheConvocacoes parametros={1} bloquearFiltros={true}/>} statusDefault={1} />
      <Route path={urlsChamadaPublica.preConvocados} element={<ChamadaPublicInscritosListagemPagePreConvocacoes  parametros={2} bloquearFiltros={true}/>} statusDefault={1}/>
      <Route path={urlsChamadaPublica.crechesRemanejamento} element={<ChamadaPublicInscritosListagemPageCrecheRemanejamento parametros={1} bloquearFiltros={true}/>} />
      <Route path={urlsChamadaPublica.preRemanejamento} element={<ChamadaPublicInscritosListagemPagePreRemanejamento  parametros={2} bloquearFiltros={true}/>} />
      <Route path={urlsChamadaPublica.pesquisar} element={<ChamadaPublicaPesquisarPage />} />
      <Route path={urlsChamadaPublica.historicoId} element={<ChamadaPublicaInscricoesHistoricoPage />} />
      {/*   <Route path={urlsChamadaPublica.homeFormulario} element={<ChamadaPublicaHome dataAbertura={dataAbertura} dataFechamento={dataFechamento} />} /> */}
      <Route path={urlsChamadaPublica.visualizacaoId} element={<VisualizacaoFormularioChamadaPublicaPage />} />
      {/* <Route path={urlsChamadaPublica.formularioRede} element={<ChamadaPublicaFormularioPage />} />
      <Route path={urlsChamadaPublica.formularioTransferencia} element={<ChamadaPublicaFormularioPageTransferencia />} /> */}
    
      <Route path={urlsChamadaPublica.listaEspera} element={<ListaDeEsperaPage />} />

      <Route path={urlsChamadaPublica.relatorioInscritosPorUnidade} element={<RelatorioInscritosPorUnidadePage />} />
      <Route path={urlsChamadaPublica.relatorioInscritosPorEscolaridade} element={<RelatorioInscritosPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica.relatorioInscritorPorBairro} element={<RelatorioInscritosPorBairroPage />} />
      <Route path={urlsChamadaPublica.relatorioInscritosTransferenciaExterna} element={<RelatorioInscritosPorTransExterna />} />
      <Route path={urlsChamadaPublica.relatorioInscritosTransferenciaInterna} element={<RelatorioInscritosPorTransInterna />} />
      <Route path={urlsChamadaPublica.relatorioInscritosPorPne} element={<RelatorioInscritosPorPnePage />} />
      <Route path={urlsChamadaPublica.relatorioInscritosPorNovosDaRede} element={<RelatorioInscritosPorNovosDaRedePage />} />
      <Route path={urlsChamadaPublica.relatorioInscritosPorGeral} element={<RelatorioInscritosPorGeralPage />} />
      <Route path={urlsChamadaPublica.relatorioConvocacoesPorEscolaridade} element={<RelatorioConvocacoesPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica.relatorioConvocadosPorPeriodoNominal} element={<RelatorioConvocadosPorPeriodoNominalPage />} />
      <Route path={urlsChamadaPublica.relatorioConvocadosPorPeriodoQuantitativo} element={<RelatorioConvocadosPorPeriodoQuantitativoPage />} />
      <Route path={urlsChamadaPublica.relatorioMatriculadosPorEscolaridade} element={<RelatorioMatriculadosPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica.relatorioMatriculadosPorUnidade} element={<RelatorioMatriculadosPorUnidadePage />} />
      <Route path={urlsChamadaPublica.relatorioMatriculadosPorPeriodoNominal} element={<RelatorioMatriculadosPorPeriodoNominalPage />} />
      <Route path={urlsChamadaPublica.relatorioMatriculadosPorPeriodoQuantitativo} element={<RelatorioMatriculadosPorPeriodoQuantitativoPage />} />
      <Route path={urlsChamadaPublica.relatorioMatriculadosPorGeral} element={<RelatorioMatriculadosPorGeralPage />} />
      <Route path={urlsChamadaPublica.remanejamento} element={<ChamadaPublicaInscricoesRemanejamentoPage />} />
      <Route path={urlsChamadaPublica.remanejamentoId} element={<ChamadaPublicaInscricoesRemanejamentoPage />} />

      {/* <Route exact path={urlsGestaoUnidades.matriculas} element={ <ChamadaPublicaMatriculas gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculaEnturmarId} element={ <MatriculasEnturmar gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculasIndeferir} element={ <MatricularIndeferir gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculasIndeferir} element={ <MatricularIndeferir gestao={false} />}/> */}
      <Route exact path={urlsChamadaPublica.alunosManuais} element={ <AlunosManuaisPage />}/>
      <Route exact path={urlsChamadaPublica.alunosPreMatricula} element={ <AlunosPreMatriculaPage  />}/>
      <Route exact path={urlsChamadaPublica.alunosRemanejamento} element={ <AlunosRemanejamentoPage  />}/>
    </>
    )}
  </React.Fragment>
);

export default ChamadaPublicaRoutes;
