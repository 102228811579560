import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import api from '../../../../../../../services'
import { toast } from 'react-toastify'
import { cpf } from 'cpf-cnpj-validator'
import FormInput from '../../../../../../../components/formInput'


export default function InscricaoOnlineLayout({dataAbertura, dataFechamento}) {
  const navigate = useNavigate();
  const [cpfValido, setCpfValido] = useState(false)
  const [exibeFormulario, setExibeFormulario] = useState(2);
  const [contagemRegressivaEncerramento, setContagemRegressivaEncerramento] = useState('');
  const [contagemRegressivaAbertura, setContagemRegressivaAbertura] = useState('');

  setInterval(() => {

    var dataAtual = new Date();
    var time = Date.parse(dataFechamento) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" :  `${ days.toString()} Dias -`;
    setContagemRegressivaEncerramento(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

    var time = Date.parse(dataAbertura) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" :  `${ days.toString()} Dias -`;
    setContagemRegressivaAbertura(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

  }, 1000);

  const {
    register,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cpf: '',
    }
  })

  const handleSearch = async (cpf) => {
    const response = await api.get(`/processoSeletivo/inscricao/remanejamento/cpf?cpf=${cpf}`);
    const id = response?.data?.data.hash;

    if (id) {
      navigate('/remanejamento/visualizar/' + id)
    } else {
      toast.error('Inscrição não encontrada na base de dados.')
    }
  }

  const validaExibeFormulario = () => {
    var dataAtual = new Date();
    setExibeFormulario(2);
    if (dataAtual >= dataAbertura && dataAtual < dataFechamento)
      setExibeFormulario(1);
    if (dataAtual >= dataFechamento)
      setExibeFormulario(2);
  }

  useEffect(() => { //Checando se CPF do aluno é válido
    validaExibeFormulario();

    const cpfWithoutMask = watch('cpf').replaceAll('.', '').replaceAll('-', '');
    const isValidCpf = cpf.isValid(cpfWithoutMask);

    setCpfValido(isValidCpf)
  }, [watch('cpf')])

  return (
    <div
      className="tab-pane fade show active"
      id="listaEspera"
      role="tabpanel"
      aria-labelledby="listaEspera-tab"
    >
      <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h5>SISTEMA DE PRÉ-MATRÍCULA ONLINE REMANEJAMENTO DE ALUNOS ANO DE 2025 PREFEITURA DE ARARUAMA</h5>
        {/* <h6>ESCOLHA UMA DAS OPÇÕES ABAIXO:</h6> */}

        {exibeFormulario == 1 ?
          <>
           <div>
                <center>
                  <div style={{ color: '#d2d208', fontSize: '25px', fontWeight: 'normal' }} id="dday">
                  O formulário será disponibilizado em {contagemRegressivaAbertura}
                  </div>
                </center>
              </div>
            <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
              <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ABERTAS A PARTIR DO DIA<br></br> {new Date(dataAbertura).toLocaleDateString('pt-BR')} às {new Date(dataAbertura).toLocaleTimeString('pt-BR')}.</h2>
            </div>
          </>
          : exibeFormulario == 1 ?
            <>
              <div>
                <center>
                  <div style={{ color: '#d2d208', fontSize: '25px', fontWeight: 'normal' }} id="dday">
                  A rematricula se encerra em  {contagemRegressivaEncerramento}
                  </div>
                </center>
              </div>
              {/* <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                <h3 style={{ color: '#3377c0', fontSize: '35px', alignSelf: 'center' }} >PESQUISAR</h3>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '100%' }}>
                  <div style={{ width: '60%' }} >
                    <FormInput
                      register={register}
                      errors={errors}
                      mask="999.999.999-99"
                      atribute="cpf"
                      style={{ width: '100%' }}
                      required={false}
                      placeholder="CPF DO RESPONSÁVEL"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {!cpfValido && watch('cpf') !== '' && (
                        <span className="text-danger">CPF Inválido</span>
                      )}
                    </div>
                  </div>
                  <button
                    disabled={!cpfValido}
                    onClick={() => handleSearch(watch('cpf'))}
                    style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px', width: '30%', height: '43px', alignSelf: 'flex-end' }} >PESQUISAR</button>
                </div>
              </div> */}

              <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #dee2e6', padding: '12px', gap: '8px', borderRadius: '5px' }} >
                  <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >REMANEJAMENTO DE ALUNOS ANO DE 2025 PREFEITURA DE ARARUAMA</h3>
                  <button
                    type="button"
                    onClick={() => navigate('/remanejamento/formulario/dados')}
                    style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }}
                  >CLIQUE AQUI PARA COMEÇAR</button>
                </div>
               
              </div>
            </> :
            <>
              <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ENCERRADAS</h2>
              </div>
            </>
        }
      </div>
    </div>
  )
}
