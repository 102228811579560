import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  TextField,
  Autocomplete
} from '@mui/material';

const Step4 = ({ formik }) => {

  const beneficiarioProgramaSocial = [
    { value: 1, text: 'BOLSA FAMÍLIA' },
    { value: 2, text: 'CADÚNICO' },
    { value: 3, text: 'NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS' },
  ];

  const escolhaBinaria = [
    { value: 1, text: 'SIM' },
    { value: 2, text: 'NÃO' },
  ];

  return (
    <Grid container spacing={2}>

      {/* NIS */}
      <Grid item xs={6}>
        <TextField
          label="NIS"
          name="nis"
          value={formik.values.nis}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.nis && Boolean(formik.errors.nis)}
          helperText={formik.touched.nis && formik.errors.nis}
          fullWidth
        />
      </Grid>
      
      {/* <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Beneficiário do Bolsa Família ou cadastro no CadÚnico</InputLabel>
          <Select
            name="beneficiarioProgramaSocial"
            value={formik.values.beneficiarioProgramaSocial}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.beneficiarioProgramaSocial && Boolean(formik.errors.beneficiarioProgramaSocial)}
          >
            <MenuItem value={1}>BOLSA FAMÍLIA</MenuItem>
            <MenuItem value={2}>CADÚNICO</MenuItem>
            <MenuItem value={3}>NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS</MenuItem>
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item xs={12} sm={6}>
                          <Autocomplete
                            options={beneficiarioProgramaSocial}
                            getOptionLabel={(option) => option.text}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            value={beneficiarioProgramaSocial.find((opt) => opt.value === formik.values.beneficiarioProgramaSocial) || null} // Bind to formik value
                            onChange={(event, value) =>
                              formik.setFieldValue('beneficiarioProgramaSocial', value ? value.value : 0)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Beneficiário do Bolsa Família ou cadastro no CadÚnico"
                                name="beneficiarioProgramaSocial"
                                onBlur={formik.handleBlur}
                                error={formik.touched.beneficiarioProgramaSocial && Boolean(formik.errors.beneficiarioProgramaSocial)}
                                helperText={formik.touched.beneficiarioProgramaSocial && formik.errors.beneficiarioProgramaSocial}
                              />
                            )}
                            fullWidth
                          />
                        </Grid>

      

      {formik.values.escolaridade && [10, 11, 12, 13].includes(parseInt(formik.values.escolaridade, 10)) && (
        
        <Grid item xs={12} sm={6}>
        <Autocomplete
          options={escolhaBinaria}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={escolhaBinaria.find((opt) => opt.value === formik.values.tempoTrabalho) || null} // Bind to formik value
          onChange={(event, value) =>
            formik.setFieldValue('tempoTrabalho', value ? value.value : 0)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Responsável Legal Está Empregado?"
              name="tempoTrabalho"
              onBlur={formik.handleBlur}
              error={formik.touched.tempoTrabalho && Boolean(formik.errors.tempoTrabalho)}
              helperText={formik.touched.tempoTrabalho && formik.errors.tempoTrabalho}
            />
          )}
          fullWidth
        />
      </Grid>
      )}

      {formik.values.tempoTrabalho === 1 && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Alert severity="info">
              O Responsável Legal que Está Empregado, deverá apresentar um dos documentos listados abaixo Na Unidade Escolar (no ato da efetivação da mátricula)
              <ul>
                <li>CTPs</li>
                <li>Contracheque</li>
                <li>Relação Anual de Informações Sociais (RAIS)</li>
                <li>Holerites e Recibos (assinados)</li>
                <li>Declaração de Imposto de Renda</li>
                <li>Extrato Previdenciário (CNIS)</li>
                <li>Comprovante de Autônomo</li>
                <li>MEI (Extrato)</li>
                <li>Contrato de Prestação de Serviço</li>
                <li>Declaração Comprobatória de Percepção de Rendimentos (DECORE)</li>
                <li>Declaração de Associação, Sindicato ou Entidade de Classe</li>
                <li>Declaração de Serviço Prestado pelo empregador com firma reconhecida.</li>
              </ul>
              Todo o período de trabalho informado deverá ser devidamente comprovado, e não poderá ter data superior a 90 dias.
            </Alert>
          </FormControl>
        </Grid>
      )}

<Grid item xs={12} sm={6}>
        <Autocomplete
          options={escolhaBinaria}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={escolhaBinaria.find((opt) => opt.value === formik.values.monoparental) || null} // Bind to formik value
          onChange={(event, value) =>
            formik.setFieldValue('monoparental', value ? value.value : 0)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Possui Filiação MonoParental?"
              name="monoparental"
              onBlur={formik.handleBlur}
              error={formik.touched.monoparental && Boolean(formik.errors.monoparental)}
              helperText={formik.touched.monoparental && formik.errors.monoparental}
            />
          )}
          fullWidth
        />
      </Grid>

     

      {(formik.values.beneficiarioProgramaSocial === 1 || formik.values.beneficiarioProgramaSocial === 2) && (
        <Grid item xs={12} style={{ color: 'red' }}>
          <Alert severity="info">
            Necessário apresentar comprovante de CadÚnico ou do Bolsa Família (no ato de efetivação da matrícula).
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default Step4;
