import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { TextField, Button, Grid, Box, Alert } from "@mui/material";
import { postSolicitacao } from "../../../../services/administrativo/patrimonio-estoque";
import { store } from "../../../../store";
import { toast } from "react-toastify";

const SolicitacaoForm = ({ onClose, handlePrint }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState(null);

  const unidadeId = localStorage.getItem("unidadeID");
  const currentUser = store.getState()["user"]["user"];

  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
      unidadeId: parseInt(unidadeId),
      produtosSolicitados: [],
      observacoes: "",
      solicitadoPor: currentUser.usuarioLogin,
      solicitadoEm: new Date().toLocaleDateString(),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "produtosSolicitados",
  });

  const onSubmit = async (data) => {
    if (data.produtosSolicitados?.length === 0) {
      toast.error("Você precisa adicionar ao menos um produto.");
      return;
    }
    setError(null);
    setSuccess(null);
    try {
      const response = await postSolicitacao(data);
      if (response.statusCode === 200) {
        setSuccess("Solicitação registrada com sucesso!");
        setFormData({
          ...data,
          solicitadoPor: currentUser.usuarioLogin,
          solicitadoEm: new Date().toLocaleDateString(),
        });
        toast.success("Solicitação registrada com sucesso!");
        reset();
      }
    } catch (err) {
      toast.error("Falha ao registrar solicitação. Tente novamente.");
      setError("Falha ao registrar solicitação. Tente novamente.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={2} marginTop={4}>
        {/* Produtos */}
        {!formData && (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={`Nome do Produto ${index + 1}`}
                    {...register(`produtosSolicitados.${index}.nome`, {
                      required: "Nome é obrigatório",
                    })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label={`Quantidade`}
                    {...register(`produtosSolicitados.${index}.quantidade`, {
                      required: "Quantidade é obrigatória",
                      valueAsNumber: true,
                      min: { value: 1, message: "Quantidade mínima é 1" },
                    })}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => remove(index)}
                  >
                    Remover
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
            {/* Botão para adicionar mais produtos */}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => append({ nome: "", quantidade: 1 })}
              >
                Adicionar Produto
              </Button>
            </Grid>

            {/* Campo de observações */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Observações"
                {...register("observacoes")}
                placeholder="Adicione quaisquer detalhes ou comentários adicionais sobre esta solicitação."
              />
            </Grid>

            {/* Botão para registrar a solicitação */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Registrar Solicitação
              </Button>
            </Grid>
          </>
        )}

        {/* Botão para imprimir a solicitação */}
        {formData && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={async () => {
                await handlePrint(formData);
                onClose();
              }}
            >
              Imprimir Solicitação
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SolicitacaoForm;
