import { useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumbs, Card, CardContent, Grid, Typography, FormControl, Autocomplete, TextField, IconButton, makeStyles, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/breadcrumb';
import { store } from '../../store';
import { getUnidades } from '../../services/unidades';
import { Row } from 'react-bootstrap';
import { Add, AttachFile, PeopleSharp, Search, Send } from '@mui/icons-material';
import { getServidores } from '../../services/servidores';
import { getContatosComConversas, getMensagens, salvaMensagem, criaGrupo, statusMensagem, salvarImagem,pessoasGrupo } from '../../services/chat';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import './style.css'
import Dialog from './components/modal';
import DialogGrupoChat from './components/modal';
import DialogFotoChat from './components/modal-foto';
import DialogFotoPreviewChat from './components/modal-preview';
import BaseGlobal from '../global/BaseGlobal';
import DialogPessoasChat from './components/modal-pessoas';



const ChatPage = (gestao) => {

    const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
    const navigate = useNavigate();
    const [unidadeSelect, setUnidadeSelect] = useState(gestao.administrativo ? 0 : unidadeId);
    const [openModal, setOpenModal] = useState(false);
    const [openModalFoto, setOpenModalFoto] = useState(false);
    const [openModalPessoasGrupo, setOpenModalPessoasGrupo] = useState(false);
    const [openModalFotoPreview, setOpenModalFotoPreview] = useState(false);
    const [pesquisa, setPesquisa] = useState();
    const currentUser = store.getState()["user"]["user"];
    const [contatos, setContatos] = useState([]);
    const [pessoas, setPessoas] = useState([]);
    const [contatosFiltrado, setContatosFiltado] = useState([]);
    const [contatoSelecionado, setContatoSelecionado] = useState(null);

    let socket;
    const chatUrl = process.env.REACT_APP_CHAT_API_URL;
    
    if (currentUser) {
        socket = io(chatUrl);
    }

    let BaseComponent = BaseGlobal;
    let BaseTitle;

    // if (gestao.gestao){
    //     BaseComponent = BaseGestao;
    //     BaseTitle = "Gestão";
    // }
    // else{
    //     BaseComponent = BaseGestaoUnidades;
    //     BaseTitle = "Gestão Unidades";
    // }


    const myId = uuidv4();
    const [conversaId, setConversaId] = useState(0);
    const [mensagem, setMensagem] = useState();
    const [mensagens, setMensagens] = useState([]);
    const chatRef = useRef(null);
    const fileInputRef = useRef(null);
    const [foto, setFoto] = useState('');
    const [fotoPreview, setFotoPreview] = useState(null);

    const hanldeInputChange = event => setMensagem(event.target.value);

    useEffect(() => {
        const handleMessage = (msg) => {
            if (conversaId === msg.conversaId) {
                setMensagens((prevMensagens) => [...prevMensagens, msg]);
            }

            // Atualizar a lista de contatos ao receber uma nova mensagem
            RetornaContatosComConversa();
        };

        socket.on('chat.message', handleMessage);

        return () => {
            socket.off('chat.message', handleMessage);
        };
    }, [conversaId]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (mensagem.trim()) {
            const msg = {
                id: myId,
                usuarioId: currentUser.servidorId,
                message: mensagem,
                sendAt: new Date(),
                conversaId: conversaId,
            };

            setMensagem('');

            await saveMessage({
                conversaId: conversaId,
                remetenteId: currentUser.servidorId,
                usuariosId: [contatoSelecionado.usuarioId],
                tipoConteudo: 'texto',
                mensagem: mensagem,
            });

            // Emitir a mensagem para o servidor de socket e atualizar contatos
            socket.emit('chat.message', msg);
            RetornaContatosComConversa();
        }
    };

    const saveMessage = async (data) => {
        var response = await salvaMensagem(data);
        if (response.data.conversaId != conversaId) {
            setConversaId(response.data.conversaId);
            contatoSelecionado.conversaId = response.data.conversaId;
        }
    }

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [mensagens]);

    useEffect(() => {
        RetornaContatosComConversa();
    }, [])

    const RetornaContatosComConversa = () => {
        getContatosComConversas(currentUser.servidorId).then(async (response) => {
            var listaContatos = response.data.filter(x => x.usuarioId != currentUser.servidorId);
            if (contatoSelecionado != null && listaContatos.length > 0) {
                listaContatos.find(x => x.conversaId == contatoSelecionado.conversaId).selected = true;

                const fetchData = async () => {
                    try {
                        await statusMensagem(
                            {
                                usuarioId: currentUser.servidorId,
                                conversaId: contatoSelecionado.conversaId
                            }
                        );
                        await retornaMensagens(contatoSelecionado.conversaId, currentUser.servidorId);
                    } catch (error) {
                        console.error('Erro ao atualizar o status:', error);
                    }
                };

                // Chama a função assíncrona
                fetchData();
            }

            setContatos([...listaContatos]);
        });
    }

    const abrirModal = () => {
        setOpenModal(true);
    }

    const salvaGrupo = async (titulo, ids) => {
        ids.push(currentUser.servidorId);
        var response = await criaGrupo({
            nomeGrupo: titulo,
            ids: ids
        });
        RetornaContatosComConversa();
        setOpenModal(false);
    }

    const filtraContatos = async (nome) => {
        if (nome.length >= 3) {
            await getServidores(0, 0, nome, 10000, 0).then((response) => {
                var items = [];
                response.data.forEach(item => {
                    items.push({
                        servidorId: item.id,
                        conversaId: 0,
                        name: item.nomeCompleto,
                        lastMessage: '',
                        mensagensNaoLidas: 0,
                        selected: false
                    });
                });
                setContatosFiltado([...items]);
            });
        } else {
            setContatosFiltado([]);
        }
    }

    const retornaMensagens = async (conversaId, usuarioId) => {
        var response = await getMensagens(conversaId, usuarioId);
        setMensagens(response.data);
    }

    const selecionaContato = async (row) => {
        var listaContatos = contatos;
        listaContatos.forEach(contato => {
            contato.selected = false;
        });

        if (row.conversaId == 0) {
            listaContatos.unshift({
                usuarioId: row.servidorId,
                conversaId: conversaId,
                lastMessage: '',
                name: row.name,
                mensagensNaoLidas: 0,
                selected: true
            })

        } else {
            listaContatos.find(x => x.conversaId == row.conversaId).selected = true;
        }
        listaContatos.find(x => x.selected == true).mensagensNaoLidas = 0;
        await statusMensagem(
            {
                usuarioId: currentUser.servidorId,
                conversaId: row.conversaId
            }
        );
        setContatoSelecionado(listaContatos.find(x => x.selected == true));
        setContatosFiltado([]);
        setContatos([...listaContatos]);
        setConversaId(row.conversaId);
        setPesquisa('');
        await retornaMensagens(row.conversaId, row.usuarioId == undefined ? row.servidorId : row.usuarioId);
    }

    const formataData = (data) => {
        var date = new Date(data);
        var actualDate = new Date();

        // Formata a data atual
        const diaAtual = String(date.getDate()).padStart(2, '0');
        const mesAtual = String(date.getMonth() + 1).padStart(2, '0');
        const horaAtual = String(date.getHours()).padStart(2, '0');
        const minutoAtual = String(date.getMinutes()).padStart(2, '0');
        const segundoAtual = String(date.getSeconds()).padStart(2, '0');

        if (`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` == `${actualDate.getDate()}/${actualDate.getMonth()}/${actualDate.getFullYear()}`) {
            return `${horaAtual}:${minutoAtual}:${segundoAtual}`;
        } else {
            return `${diaAtual}/${mesAtual}/${date.getFullYear()} - ${horaAtual}:${minutoAtual}:${segundoAtual}`;
        }
    }

    const handleAttachClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith("image/") || fileType === "application/pdf") {
                setFoto(file);
                setOpenModalFoto(true);
            } else {
                toast.error('Selecione um arquivo PDF ou uma imagem');
            }
        }
        if (file) {
        }
    };

    const salvarFoto = async () => {
        const formData = new FormData();
        formData.append("conversaId", conversaId);
        formData.append("remetenteId", currentUser.servidorId);
        formData.append("usuariosId", contatoSelecionado.usuarioId);
        formData.append("tipoConteudo", foto.type === "application/pdf" ? 'arquivo' : 'imagem');
        formData.append("arquivo", foto);
        formData.append("extensao", foto.type);
        formData.append("nomeArquivo", foto.name);
        await salvarImagem(formData);

        setOpenModalFoto(false);
        setFoto('');
        socket.emit('chat.message', 'imagem anexada');
        RetornaContatosComConversa();
    }

    const retornaNome = (row) => {
        return currentUser.servidorId == row.usuarioId ? 'Eu' : row.nome;
    }

    const abrirPdf = (base64) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        window.open(url);

        setTimeout(() => URL.revokeObjectURL(url), 1000);
    }

    const validaTipoMensagem = (row) => {
        if (row.lastMessage != '')
            return row.lastMessage;
        else if (row.tipoMensagem == 'arquivo')
            return <i>{row.nomeArquivo}</i>;
        else if (row.tipoMensagem == 'imagem')
            return <i>Imagem</i>;
        else if (row.lastMessage == '')
            return <i>Rascunho</i>;
    }

    const modalPessoasGrupo = async (conversaId) =>{
        var response = await pessoasGrupo(conversaId);
        setPessoas(response.data);
        setOpenModalPessoasGrupo(true);
    }

    return (
        <BaseComponent>
        <DialogPessoasChat isOpen={openModalPessoasGrupo} setOpenModal={setOpenModalPessoasGrupo} pessoas={pessoas}>
        </DialogPessoasChat>
            <DialogGrupoChat
                isOpen={openModal} setOpenModal={setOpenModal} salvaGrupo={salvaGrupo}
            ></DialogGrupoChat>
            <DialogFotoChat
                isOpen={openModalFoto} setOpenModal={setOpenModalFoto} salvarFoto={salvarFoto} foto={foto}
            ></DialogFotoChat>
            <DialogFotoPreviewChat
                isOpen={openModalFotoPreview} setOpenModal={setOpenModalFotoPreview} foto={fotoPreview}
            ></DialogFotoPreviewChat>
            <Breadcrumb title={''} itens={['Chat']} />
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <Card style={{ border: '1px solid #C6C6C6', maxHeight: 600 }}>
                        <Row>
                            <Grid md={3} style={{ borderRight: '2px solid #C6C6C6', maxHeight: 480, overflow: 'auto', paddingTop:15 }}>
                                <Row style={{marginBottom: 3, paddingLeft: 8 }}>
                                    <div className='col-10'>
                                        <TextField
                                            onChange={
                                                async (e) => {

                                                    setPesquisa(e.target.value);
                                                    await filtraContatos(e.target.value);
                                                }
                                            }
                                            variant="outlined"
                                            placeholder="Buscar contato"
                                            value={pesquisa}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>
                                                ),
                                                sx: { height: '30px' },
                                                inputProps: { style: { height: '100%', paddingBottom:20 } }
                                            }}
                                            sx={{ height: '30px' }}
                                        />
                                    </div>
                                    <div className='col-2' style={{position: 'relative', bottom: 7}}>
                                    <button onClick={abrirModal} className='btn btn-primary' style={{ width:35, borderRadius: '50%', paddingRight: '0', paddingLeft: '0' }}><PeopleSharp></PeopleSharp></button>

                                    </div>
                                </Row>

                                <hr style={{ marginLeft: 10, marginTop: 0, marginRight: 0, marginBottom: 0 }} />
                                {
                                    contatosFiltrado.length > 0 ? contatosFiltrado?.map((row) => {
                                        return <>
                                            <Row className={row.selected ? 'cursor-pointer chat-selected' : 'chat-not-selected cursor-pointer'} style={{ padding: 10, backgroundColor: row.selected ? '#F3F3F3' : '#FFFFFF' }} onClick={() => { selecionaContato(row) }}>
                                                <div className='col-12'>
                                                    <p className='title'>{row.name}</p>
                                                    <p className='last-message'>{validaTipoMensagem(row)}</p>
                                                </div>

                                            </Row>
                                            <hr style={{ marginLeft: 10, marginTop: 0, marginRight: 0, marginBottom: 0 }} />
                                        </>
                                    }
                                    ) : contatos?.map((row) => {
                                        return <>
                                            <Row className={row.selected ? 'cursor-pointer chat-selected' : 'chat-not-selected cursor-pointer'} style={{ padding: 10, backgroundColor: row.selected ? '#F3F3F3' : '#FFFFFF' }} onClick={() => { selecionaContato(row) }}>
                                                <div className='col-10'>
                                                    <p className='title'>{row.name}</p>
                                                    <p className='last-message'>{validaTipoMensagem(row)}</p>
                                                </div>
                                                
                                                <div className='col-2' style={{paddingBottom: 0, marginBottom: 0, height:20}}>
                                                {row.mensagensNaoLidas > 0 && !row.selected ? <small className='nao-lidas'>{row.mensagensNaoLidas == 1 ? `${row.mensagensNaoLidas} não lida` : `${row.mensagensNaoLidas} não lidas`}</small> : ''}
                                                    {
                                                        row.grupo ?  <i onClick={() => modalPessoasGrupo(row.conversaId)} className="material-icons md-people" style={{color:row.selected ? '#FFF' : '#c6c6c6', fontSize:16, float:'right', position: 'relative', top: row.mensagensNaoLidas > 0 ? '19px' : '38px'}}></i> : <></>
                                                    }
                                               
                                                </div>

                                            </Row>
                                            <hr style={{ marginLeft: 10, marginTop: 0, marginRight: 0, marginBottom: 0 }} />
                                        </>
                                    }
                                    )
                                }

                            </Grid>
                            <Grid md={9} >
                                <Row style={{ marginTop: 10 }}>
                                    <div className='col-12'>
                                        <img height={40} style={{ float: 'left', marginRight: 20 }} src={contatoSelecionado?.image}></img>
                                        <p className='title mt-8'>{contatoSelecionado?.name}</p>
                                    </div>
                                </Row>
                                <hr style={{ marginRight: 10, marginTop: 4 }} />
                                <div className='chat-message' style={{ maxHeight: 300, minHeight: 300, overflow: 'auto', overflowX: 'hidden' }} ref={chatRef}>
                                    {

                                        contatoSelecionado != null && mensagens?.map((row) => {
                                            return <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Grid md={12} className={currentUser.servidorId == row.usuarioId ? 'mensagem-enviada' : 'mensagem-recebida'}>
                                                    {
                                                        row.tipoMensagem == 'texto' ?
                                                            <p>{row.message}</p> : row.tipoMensagem == 'imagem' ?
                                                                <img src={'data:' + row.extensao + ';base64,' + row.arquivo} height={50} style={{ marginLeft: 12, marginRight: 20, borderRadius: 5, cursor: 'pointer' }} onClick={() => { setOpenModalFotoPreview(true); setFotoPreview(row); }} /> :
                                                                <div className='arquivo'>
                                                                    <a onClick={() => abrirPdf(row.arquivo)} target='_blank' style={{ marginLeft: 12, marginRight: 20, color: 'white', cursor: 'pointer' }}>
                                                                    <i className="material-icons md-attach_file"></i>{row.nomeArquivo}</a>
                                                                </div>
                                                                
                                                    }
                                                    <br />
                                                    <small className='data-hora'>{retornaNome(row) + ': ' + formataData(row.sendAt)}</small>
                                                </Grid>
                                            </Row>
                                        })
                                    }
                                </div>

                                <hr style={{ marginRight: 10 }} />
                                <form onSubmit={handleFormSubmit}>
                                    <Row style={{ padding: 10, marginBottom: 10 }}>
                                        <div className='col-12 input-message'>
                                            <TextField
                                                onChange={hanldeInputChange}
                                                value={mensagem}
                                                style={{ padding: '0' }}
                                                variant="outlined"
                                                placeholder={contatoSelecionado == null ? "Selecione um contato para enviar mensagem" : "Digite aqui a mensagem"}
                                                fullWidth
                                                autoComplete="off"
                                                InputProps={{
                                                    readOnly: contatoSelecionado == null ? true : false,
                                                    disableUnderline: contatoSelecionado == null ? true : false,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AttachFile
                                                                style={{ cursor: 'pointer', marginRight: 10 }}
                                                                onClick={contatoSelecionado != null ? handleAttachClick : null} />
                                                            <Send
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={contatoSelecionado != null ? handleFormSubmit : null} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <input
                                                type="file"
                                                accept="image/*,application/pdf"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </Row>
                                </form>
                            </Grid>

                        </Row>
                    </Card>
                </Grid>
            </Grid>
        </BaseComponent>
    );
};

export default ChatPage;
