import BaseAdministrativo from "../../../baseAdministrativo";
import BaseGestaoUnidades from "../../../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../../../components/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  getRegistros,
  getSolicitacaoById,
  putSolicitacao,
} from "../../../../../services/administrativo/patrimonio-estoque";
import { store } from "../../../../../store";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import ImprimirSolicitacao from "../../../../almoxarifado-unidades/solicitacoes/imprimir";

function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

function ResponderSolicitacao({ gestaoUnidades }) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [solicitacao, setSolicitacao] = useState({});
  const [searchText, setSearchText] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [registro, setRegistro] = useState({});
  const location = useLocation();
  const { state } = location;
  const componentRef = useRef();
  const navigate = useNavigate();

  const debouncedSearch = React.useRef(
    debounce((nextValue) => setSearchText(nextValue), 300)
  ).current;

  const BaseComponent = gestaoUnidades
    ? BaseGestaoUnidades
    : BaseAdministrativo;

  const currentUser = store.getState()["user"]["user"];

  const { control, handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues: {
      produtos: [],
      observacoes: "",
    },
  });

  const { data: solicitacaoData } = useQuery(
    "getSolicitacaoById",
    () => getSolicitacaoById(state?.solicitacao?.id),
    {
      enabled: true,
      onSuccess: (data) => {
        setSolicitacao(data?.data);
        setFormData(data?.data);
      },
    }
  );

  const { data: registrosData, isFetching } = useQuery(
    ["getRegistros", searchText],
    () => getRegistros({ searchText }),
    {
      enabled: !!searchText,
    }
  );

  const handleInputChange = (event, value) => {
    debouncedSearch(value);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "produtos",
  });

  const onSubmit = async (data) => {
    if (actionType === "responder") {
      if (data.produtos.length === 0) {
        toast.error("Você precisa adicionar ao menos um produto.");
        return;
      }
    }

    const produtosFormatados = data.produtos.map((produto) => ({
      estoqueId: produto.id,
      quantidade: parseInt(produto.quantidadeFornecida),
    }));

    const payload = {
      id: state.solicitacao.id,
      observacao:
        actionType !== "responder" ? "Solicitação Recusada." : data.observacoes,
      transportadoPor: data.transportadoPor,
      status: actionType === "responder" ? 3 : 2,
      unidadeId: solicitacao?.unidadeId,
      produtosEnviados: produtosFormatados,
      atendidoEm: new Date().toLocaleDateString(),
      atendidoPor: currentUser.usuarioLogin,
    };

    try {
      await putSolicitacao(payload);
      const successMessage =
        actionType === "responder"
          ? "Solicitação respondida com sucesso!"
          : "Solicitação recusada com sucesso!";
      toast.success(successMessage);
      reset();
      navigate(-1);
    } catch {
      const errorMessage =
        actionType === "responder"
          ? "Erro ao responder a solicitação."
          : "Erro ao recusar a solicitação.";
      toast.error(errorMessage);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Solicitações"}
        itens={["Administrativo", "Estoque", "Solicitações", "Responder"]}
        hasExcelButton
      />
      <div className="card">
        <div className="m-4" ref={componentRef}>
          <ImprimirSolicitacao row={solicitacao} formData={formData} />
        </div>

        <Box m={4} component="form" onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}

          <Grid container spacing={2} marginTop={4} alignItems="center">
            {!gestaoUnidades && !solicitacao?.atendidoPor ? (
              <>
                {fields.map((field, index) => (
                  <React.Fragment key={field.id}>
                    <Grid item xs={5}>
                      <Autocomplete
                        id={`produtoId-${index}`}
                        disableClearable
                        options={
                          registrosData?.data?.filter(
                            (registro) =>
                              !watch("produtos").some(
                                (produto) => produto.id === registro.id
                              )
                          ) || []
                        }
                        filterOptions={(x) => x}
                        getOptionLabel={(option) =>
                          `${option.nomeProduto} - ${option.marca} - ${
                            option.numeroPatrimonio
                              ? option.numeroPatrimonio
                              : "N/A"
                          }` || ""
                        }
                        onInputChange={handleInputChange}
                        loading={isFetching}
                        onChange={(_, selectedOption) => {
                          setRegistro(selectedOption);
                          const {
                            id,
                            tipoProduto,
                            numeroDeSerie,
                            dataValidade,
                            quantidade,
                          } = selectedOption;
                          setValue(`produtos.${index}`, {
                            id,
                            tipoProduto,
                            numeroDeSerie,
                            dataValidade,
                            quantidadeDisponivel: quantidade,
                            quantidadeFornecida:
                              tipoProduto === "DURÁVEL" ? 1 : undefined,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nome do Produto"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    {watch(`produtos.${index}.tipoProduto`) === "DURÁVEL" && (
                      <>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label="Quantidade"
                            value={1}
                            disabled
                          />
                        </Grid>
                        {watch(`produtos.${index}.numeroDeSerie`) && (
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              label="Número de Série"
                              value={watch(`produtos.${index}.numeroDeSerie`)}
                              disabled
                            />
                          </Grid>
                        )}
                      </>
                    )}

                    {watch(`produtos.${index}.tipoProduto`) ===
                      "NÃO DURÁVEL" && (
                      <>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            type="number"
                            label="Quantidade"
                            {...register(
                              `produtos.${index}.quantidadeFornecida`,
                              {
                                required: "Quantidade é obrigatória",
                                validate: (value) =>
                                  (value > 0 &&
                                    value <=
                                      watch(
                                        `produtos.${index}.quantidadeDisponivel`
                                      )) ||
                                  `Quantidade deve ser menor ou igual a ${watch(
                                    `produtos.${index}.quantidadeDisponivel`
                                  )}`,
                              }
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            type="date"
                            label="Data de Validade"
                            value={
                              watch(`produtos.${index}.dataValidade`)
                                ? new Date(
                                    watch(`produtos.${index}.dataValidade`)
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            disabled
                          />
                        </Grid>
                      </>
                    )}

                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => remove(index)}
                      >
                        Remover
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      append({
                        nomeProduto: "",
                        tipoProduto: "",
                        quantidadeFornecida: 1,
                        conservacao: "",
                      })
                    }
                  >
                    Adicionar Produto
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Transportado Por"
                    {...register("transportadoPor")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Observações"
                    {...register("observacoes")}
                    placeholder="Adicione quaisquer detalhes ou comentários adicionais sobre esta solicitação."
                  />
                </Grid>

                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      setActionType("recusar");
                      handleSubmit(onSubmit)();
                    }}
                  >
                    Recusar Solicitação
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setActionType("responder")}
                  >
                    Responder Solicitação
                  </Button>
                </Grid>
              </>
            ) : null}

            {solicitacao?.atendidoPor && formData ? (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handlePrint}
                >
                  Imprimir Comprovante
                </Button>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="warning"
                fullWidth
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </BaseComponent>
  );
}

export default ResponderSolicitacao;
