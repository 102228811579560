import React, { forwardRef, useEffect, useState } from "react";
import { useTable } from "react-table";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import * as S from "./styles"; // Import the styled components
import { Dropdown } from "react-bootstrap";

const Table = forwardRef(
  (
    {
      columns,
      data,
      hasPagination,
      limit = 20,
      setLimit,
      skip = 0,
      setSkip,
      withSelectOption,
      setRow,
      selected,
      totalItems,
      isBoardTable = false,
      hiddenColluns = [],
      overflowUnset = false,
      validatePage = false, // Nova prop para controle de validação da página
      resetCurrentPage,
      paginationKey,
      customPagination = [],
    },
    ref
  ) => {
    // Verifica se deve restaurar a página do localStorage
    const savedPage = validatePage ? localStorage.getItem(paginationKey) : null;
    const [currentPage, setCurrentPage] = useState(savedPage ? parseInt(savedPage) : 1);
    const [isSelected, setIsSelected] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(limit === 0 ? 10 : limit);
    const [buttonsAmount, setButtonsAmount] = useState([]);
    const [newData, setNewData] = useState(data ? data : []);

    useEffect(() => {
      if (resetCurrentPage !== undefined) {
        setCurrentPage(1); // Atualiza o currentPage com o valor recebido via props
        setSkip((resetCurrentPage - 1) * limit); // Também ajusta o skip de acordo com o limite
      }
    }, [resetCurrentPage, limit, setSkip]);

    const checkPageAmount = () => {
      const pageCount = Math.ceil(totalItems / itemsPerPage);
      const buttons = [];

      for (let i = 1; i <= pageCount; i++) {
        buttons.push(i);
      }
      if (buttons.length === 0) {
        buttons.push(1);
        if(validatePage !== true){
          setCurrentPage(1);
        }
        
      }
      setButtonsAmount(buttons);
    };

    const parseData = async () => {
      setNewData(data);
    };

    useEffect(() => {
      parseData();
      checkPageAmount();
    }, [itemsPerPage, totalItems, data]);

    const handlePageNumber = (page) => {
      setCurrentPage(page);
      setSkip((page - 1) * limit);
      
      // Salva a página no localStorage apenas se validatePage for true
      if (validatePage) {
        localStorage.setItem(paginationKey, page);
      }
    };

    const handleAmountView = (amount) => {
      setItemsPerPage(amount);
      setLimit(amount);
    };

    const handleSelect = (row) => {
      setRow(row);
      check(row);
    };

    const check = (row) => {
      if (row === selected) {
        setIsSelected(true);
        return { backgroundColor: "#4bad48" };
      }
      return {};
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data: newData,
        initialState: {
          hiddenColumns: hiddenColluns,
        },
      });

    const paginationContent = customPagination.length === 0 ? (
      <>
      <S.SelectAmount>
        <S.SelectComponent
        onChange={(e) => handleAmountView(parseInt(e.target.value))}
        value={itemsPerPage}
        >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        </S.SelectComponent>{" "}
        itens de {totalItems} no total
      </S.SelectAmount>
      <S.SelectPage>
        Página:
        <S.NumberButton
        onClick={() => handlePageNumber(currentPage - 1)}
        disabled={currentPage === 1}
        >
        <NavigateBeforeIcon />
        </S.NumberButton>
        <S.SelectComponent
        onChange={(e) => handlePageNumber(parseInt(e.target.value))}
        value={currentPage}
        >
        {buttonsAmount.map((key) => (
          <option value={key}>{key}</option>
        ))}
        </S.SelectComponent>
        <S.NumberButton
        onClick={() => handlePageNumber(currentPage + 1)}
        disabled={currentPage === buttonsAmount.length}
        >
        <NavigateNextIcon />
        </S.NumberButton>
      </S.SelectPage>
      </>
    ) : (
      <>
      <S.SelectAmount>
        <S.SelectComponent
        onChange={(e) => handleAmountView(parseInt(e.target.value))}
        value={itemsPerPage}
        >
        {customPagination.map((key) => (
          <option value={key}>{key}</option>
        ))}
        </S.SelectComponent>{" "}
        itens de {totalItems} no total
      </S.SelectAmount>
      <S.SelectPage>
        Página:
        <S.NumberButton
        onClick={() => handlePageNumber(currentPage - 1)}
        disabled={currentPage === 1}
        >
        <NavigateBeforeIcon />
        </S.NumberButton>
        <S.SelectComponent
        onChange={(e) => handlePageNumber(parseInt(e.target.value))}
        value={currentPage}
        >
        {buttonsAmount.map((key) => (
          <option value={key}>{key}</option>
        ))}
        </S.SelectComponent>
        <S.NumberButton
        onClick={() => handlePageNumber(currentPage + 1)}
        disabled={currentPage === buttonsAmount.length}
        >
        <NavigateNextIcon />
        </S.NumberButton>
      </S.SelectPage>
      </>
    );

    return (
      <S.TableResponsive className="table-responsive" style={{overflow: overflowUnset ? 'unset' : 'auto'}}>
      <table
        ref={ref}
        className="table table-striped table-hover table-responsive"
        {...getTableProps()}
      >
        <thead className="align-middle">
        {headerGroups.map((headerGroup) => (
          <tr
          className="text-center"
          {...headerGroup.getHeaderGroupProps()}
          >
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>
            {column.render("Header")}
            </th>
          ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()} className="align-middle">
        {rows.map((row, i) => {
          prepareRow(row);
          return withSelectOption ? (
          <tr
            className="small text-center"
            {...row.getRowProps()}
            onClick={() => handleSelect(row.values)}
          >
            {row.cells.map((cell) => {
            return (
              <td
              className="small text-center"
              {...cell.getCellProps()}
              >
              {cell.render("Cell")}
              {cell.column.id === "actions" && (
                <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Ações
                </Dropdown.Toggle>
                <S.DropdownMenuShow>
                  <Dropdown.Menu>
                  {/* Your dropdown items */}
                  <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                  </Dropdown.Menu>
                </S.DropdownMenuShow>
                </Dropdown>
              )}
              </td>
            );
            })}
          </tr>
          ) : (
          <tr className="small text-center" {...row.getRowProps()}>
            {row.cells.map((cell) => {
            return (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            );
            })}
          </tr>
          );
        })}
        {rows.length === 0 && (
          <tr>
          <td colSpan={columns.length} className="text-center">
            {isBoardTable
            ? "Utilize os filtros para visualizar os quadros disponíveis"
            : "Nenhum registro encontrado"}
          </td>
          </tr>
        )}
        </tbody>
      </table>
      {hasPagination && totalItems > 0 && (
        <S.PaginationSection>
        {paginationContent}
        </S.PaginationSection>
      )}
      </S.TableResponsive>
    );
  }
);

Table.displayName = "Table";

export default Table;
