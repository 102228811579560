import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import FormInput from '../../../../../../components/formInput'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { postInscricaoServidorRecurso } from '../../../../../../services/processos/remanejamento'
import { cpf } from 'cpf-cnpj-validator'
import urlsServidores from '../../urlsServidores'
import api from '../../../../../../services'
import moment from "moment";

export default function ProcessoSeletivoServidoresRecurso() {
  const navigate = useNavigate();
  const [exibeDados, setExibeDados] = useState(0)
  const [desabilitaBota, setDesabilitaBota] = useState(false)
  const dataOcultarRecurso = new Date('2025-01-03T23:59:59');

  const { mutate: mutatePostForm } = useMutation(postInscricaoServidorRecurso, {
    onSuccess: async data => {
      if (data == undefined || data == false) {
        toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
      }
      if (data != false && data != undefined) {
        toast.success('Recurso enviado com sucesso.');
        navigate(urlsServidores.formulario)
      }


    },
    onError: data => {
      toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cpf: '',
      razoesDoRecurso: ''

    }
  })

  function findCargo(id) {
    switch (id) {
      case 3:
        return 'MERENDEIRA';
      case 4:
        return 'MONITOR DE TRANSPORTE ESCOLAR';
      case 5:
        return 'MOTORISTA D';
      case 12:
        return 'SECRETÁRIO ESCOLAR';
      case 14:
          return 'SUPERVISOR EDUCACIONAL';
      default:
        return '';
    }
  }
  

  const handleSearch = async (cpf) => {
    const response = await api.get(`/processoSeletivo/inscricao/filtroCpf?cpf=${cpf}`);
    const data = response?.data?.data;

    if (data != undefined) {

      if(data?.recurso != null){
        toast.error('Você já possui um recurso cadastrado, é permitido apenas um por CPF.')
      }
      if(data?.recurso == null){
        console.log(data)
      setValue('candidato', data?.nome)
      setValue('vagaPretendida', findCargo(data?.cargo))

      setExibeDados(1)
      }
      
    } else {
      toast.error('Inscrição não encontrada na base de dados.')
    }
  }

  useEffect(() => {
    console.log(watch('cpf'))

    const cpf = watch('cpf').replace(/\D/g, '')

    console.log(cpf)

    if (cpf?.length == 11) {
        handleSearch(cpf)
        setDesabilitaBota(false)
    }
    else{
      setExibeDados(0)
      setDesabilitaBota(true)
    }
  }, [watch('cpf')])

  const onSubmit = async data => {

    console.log(data)



    if (watch('razoesDoRecurso') === '') {
      return toast.error('O campo motivo do recurso é obrigatório.');
    }

    
    





  const request = {
    cpf: data?.cpf,
    razoesDoRecurso: data?.razoesDoRecurso
  }
  if (new Date() > dataOcultarRecurso) {
    return toast.error('Recurso encerrado.');
  }
  mutatePostForm(request)
}







return (
  <div
    className="tab-pane fade show active"
    id="listaEspera"
    role="tabpanel"
    aria-labelledby="listaEspera-tab"
  >
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row">
        <div className="col-md-12">
            <FormInput
              register={register}
              errors={errors}
              atribute="cpf"
              mask="999.999.999-99"
              label="CPF"
            />
          </div>

          
        </div>
        
        {
            exibeDados == 1 && (

              <>
               <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              atribute="candidato"
              label="Candidato"
              readOnly={true}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              required={false}
              atribute="vagaPretendida"
              label="Vaga Pretendida"
              readOnly={true}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px'}}>
  <div className="col-md-12">
    <label htmlFor="razoesDoRecurso">Razões do Recurso</label>
    <textarea
      rows={10}
      id="razoesDoRecurso"
      {...register('razoesDoRecurso')}  // Certifique-se de adicionar a propriedade 'register' corretamente
      className={`form-control ${errors.razoesDoRecurso ? 'is-invalid' : ''}`}
    />
    {errors.razoesDoRecurso && (
      <div className="invalid-feedback">{errors.razoesDoRecurso.message}</div>
    )}
  </div>
</div>
               </>
            )
          }

       

   


        

        

        

        









        




        

        

        








        

        {/* <div className="row" style={{ marginTop: 30 }}>
            <div className='col-md-12'>
              <label><h4>Observação importante!</h4>Senhor(a) responsável, informamos que caso o aluno possua um cadastro anterior, o mesmo será cancelado e subtituído pelo novo.</label>
            </div>
          </div> */}

        {/* <div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label><h6>*Uma cópia das suas respostas será enviada para o endereço de e-mail fornecido.</h6></label>
          </div>
        </div> */}


      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '50px' }}>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => navigate(urlsServidores.formulario)}
        >
          Voltar
        </button>
        <button
        disabled={desabilitaBota}
          type="submit"
          className="btn btn-success"
        >
          Enviar
        </button>
      </div>
    </form>
  </div>



)
}