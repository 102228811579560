import Breadcrumb from "../../../components/breadcrumb";
import TableComponent from "../../../components/novaTabela";
import BaseGestao from "../../gestao/baseGestao";
import { IconButton, Tooltip, Box, Grid, TextField, MenuItem } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { getAlunosManuaisPendenteAprovacao, reprovarCadastroManual, aprovarCadastroManual } from "../../../services/alunos";
import { useEffect, useState } from "react";
import { getUnidades } from "../../../services/unidades";
import { toast } from "react-toastify";

const AlunosTelaAprovacaoInclusao = () => {

  const refetchData = async () => {
    const response = await getAlunosManuaisPendenteAprovacao(nome, cpf, unidade);
    setData(response.data);
  };


  const handleAprovacao = async (row) => {
    const response = await aprovarCadastroManual(row.id);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success("Cadastro aprovado com sucesso");
      refetchData();
    }
    else{
      toast.error("Erro ao aprovar cadastro");
    }
  };

  const handleReprovacao = async (row) => {
    const response = await reprovarCadastroManual(row.id);
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success("Cadastro reprovado com sucesso");
      refetchData();
    }
    else{
      toast.error("Erro ao reprovar cadastro");
    }
  };

  const [data, setData] = useState([]);
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [unidade, setUnidade] = useState(0);
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAlunosManuaisPendenteAprovacao(nome, cpf, unidade);
      setData(response.data);
    };
    const fetchUnidades = async () => {
      const response = await getUnidades();
      setUnidades([{ id: 0, nome: "TODOS" }, ...response]);
    };
    fetchUnidades();
    fetchData();
  }, [unidade, nome, cpf]);



  const tableColumns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "CPF",
      accessor: "cpf",
    },
    {
      Header: "Data de Nascimento",
      accessor: "carteiraNascimento",
      Cell: ({ value }) => new Date(value).toLocaleDateString('pt-BR'),
    },
    {
      Header: "Unidade",
      accessor: "unidade",
    },
    {
      Header: "Escolaridade",
      accessor: "escolaridade",
    },
    {
      Header: "Servidor",
      accessor: "servidorLogin",
    },
    {
      Header: "Data e Hora",
      accessor: "dataEvento",
      Cell: ({ value }) => new Date(value).toLocaleString('pt-BR'),
    },
    {
      Header: "Ações",
      Cell: ({ row }) => (
        <Box>
          <Tooltip title="Aceitar">
            <IconButton color="success" onClick={() => handleAprovacao(row.original)}>
              <CheckCircle />
            </IconButton>
          </Tooltip>
          <Tooltip title="Recusar">
            <IconButton color="error" onClick={() => handleReprovacao(row.original)}>
              <Cancel />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <BaseGestao>
      <Breadcrumb
        title={"APROVAÇÃO DE ALUNO MANUAIS"}
        itens={["Configurações", "Alunos", "Aprovação de Cadastro"]}
      />

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            select
            fullWidth
            label="Unidade"
            value={unidade}
            onChange={(e) => setUnidade(e.target.value)}
          >
            {unidades.map((u) => (
              <MenuItem key={u.id} value={u.id}>
                {u.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <TextField
            label="Nome"
            fullWidth
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          >
            {() => <TextField label="CPF" fullWidth />}
          </InputMask>
        </Grid> */}
      </Grid>

      <TableComponent data={data} columns={tableColumns} hiddenColluns={["id"]} />
    </BaseGestao>
  );
};

export default AlunosTelaAprovacaoInclusao;