import { useMemo } from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import FormInput from '../../../components/formInput';
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query'
import { toast } from 'react-toastify';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import urlsGestao from '../../gestao/urlsGestao';
import { useMutation } from 'react-query';

import { getEscolaridades } from '../../../services/escolaridades';

import { getUnidadesEscolaridade2024 } from '../../../services/unidades';

import { getTurmasUnidadeEscolaridadeTurno } from '../../../services/turmas';

import { aprovarAlunoEja } from '../../../services/alunos';
import { store } from '../../../store';

  



const AlunosAprovacaoEJAPage = (gestao) => {

    const currentUser = store.getState()['user']['user'];

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';
  const alunosUrl = gestao.gestao ? urlsGestao.alunos : urlsGestaoUnidades.alunos;

   const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });


   const [status, setStatus] = useState(0)
   const [escolaridade, setEscolaridade] = useState(0)
   const [unidadeProxima, setUnidadeProxima] = useState(0)
   const [turma, setTurma] = useState(0)
   const [turmasData, setTurmasData] = useState([])

   const unidadeID = localStorage.getItem('unidadeID')

   const {  isError, isLoading, refetch: refetchTurmas } = useQuery(
    'getTurmasUnidadeEscolaridadeTurno', () => getTurmasUnidadeEscolaridadeTurno({unidadeId: state.unidadeId, escolaridadeId: escolaridade, turnoId: 0, anoLetivo: 2024, skip: 0, limit: 200}),
    {
      retry: 0,
      onSuccess: (data) => {
        setTurmasData(data);
      }
    });

   const navigate = useNavigate()

   const { state, pathname } = useLocation();



    const { data: escolaridades, isLoading: loadingEscolaridades } = useQuery(
        'getEscolaridades',
        () => getEscolaridades(),
        {
          retry: 3,
          enabled: true
        }
      )

      const { data: unidades, isLoading: loadingUnidades, refetch } = useQuery(
        'getUnidades2024',
        () => getUnidadesEscolaridade2024(escolaridade),
        {
          retry: 3,
          enabled: escolaridade != 0
        }
      )

   useEffect(() => {
    setValue('id', state.id)
    setValue('nome', state.nome)
    setValue('escolaridade', state.escolaridade)
    setValue('unidade', state.unidade)
  }, [state, pathname, setValue])

  const { mutate } = useMutation(aprovarAlunoEja, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(alunosUrl);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar enturmar, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {
    if(parseInt(escolaridade) == 0){
      return toast.error('Informe a próxima escolaridade para dar continuidade.');
    }

    if(parseInt(turma) == 0){
      return toast.error('Informe a próxima turma para dar continuidade.');
    }


    const requestData = {
      alunoId: state.id,
      usuarioId: parseInt(currentUser?.servidorId),
      escolaridadeId: parseInt(escolaridade),
      turmaId: parseInt(turma),
    }
    console.log(requestData)
    mutate(requestData);
  }

  return (
    <BaseComponent>
      <Breadcrumb title={'Alunos'} itens={['Gestão', 'Alunos', 'Aprovação']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <div className='row'>
          <div className='col-12'>
        <div className='card'>
            <div className="card-body">
            <div className='row'>
                    <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>
                    <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="nome"
                        label="Nome"
                        readOnly
                      />
                    </div>
                    
                  </div>

                  <div className='row' style={{marginTop: 20}}>
                  <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="unidade"
                        label="Unidade"
                        readOnly
                      />
                    </div>
                  <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="escolaridade"
                        label="Escolaridade"
                        readOnly
                      />
                    </div>

                  </div>

                

                <div className="row" style={{paddingTop: '20px'}}>
                <div className='col-md-6'>
                         <div className="form-group">
                           <label htmlFor="proximaEscolaridade">Escolaridade<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                           <select
                             className="form-control"
                             id="proximaEscolaridade"
                             {...register("proximaEscolaridade", { required: true })}
                             onChange={async (e) => {
                             //   await setValue('escolaridade2023', e.target.value);
                            await setEscolaridade(e.target.value)
                            refetch()
                            refetchTurmas()
                             }}>
                            {loadingEscolaridades ? (
                                 <option value="">Carregando...</option>
                               ) : (
                                 <>
                                   <option value=""></option>
                                   {escolaridades &&
  escolaridades
    .filter(item => item.descricao.includes('FASE'))
    .map(item => (
      <option key={item.id} value={item.id}>
        {item.descricao}
      </option>
    ))}
                                 </>
                               )}
                             
                           </select>
                           {errors.proximaEscolaridade && <span className="text-danger">Campo obrigatório</span>}
                         </div>
                       </div>

                       <div className='col-md-6'>
            <div className="form-group">
              <label htmlFor="proximaTurma">Turmas<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-control"
                id="proximaTurma"
                {...register("proximaTurma", { required: true })}
                onChange={async (e) => {
                  await setTurma(e.target.value)
                }}>
                 {loadingUnidades ? (
                    <option value="">Carregando...</option>
                  ) : (
                    <>
                      <option value=""></option>
                      {turmasData?.data &&
                        turmasData?.data.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.descricao}
                          </option>
                        ))}
                    </>
                  )}
                
              </select>
              {errors.proximaTurma && <span className="text-danger">Campo obrigatório</span>}
            </div>
          </div>

                       
                 </div>
<div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 30,
                marginTop: 20
              }}>
              <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                <button 
                type='submit' 
                className='btn btn-success' 
                // disabled={!isValid}
                >
                  Enviar
                </button>
              </div>
            </div>
        </div>
        </div>
    </div>
    </form>
    </BaseComponent>
  )

};

export default AlunosAprovacaoEJAPage;
