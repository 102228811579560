import { useMemo } from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import FormInput from '../../../components/formInput';
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query'
import { toast } from 'react-toastify';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import urlsGestao from '../../gestao/urlsGestao';
import { useMutation } from 'react-query';
import { escolaridadeArray } from './escolaridadeArray';

import { getEscolaridades } from '../../../services/escolaridades';

import { getEscolaridadesMultisseriada, getUnidadesEscolaridade2025 } from '../../../services/unidades';

import { getTurmasUnidadeEscolaridadeTurno } from '../../../services/turmas';

import { aprovarAluno } from '../../../services/alunos';

  



const AlunosAprovacaoPage = (gestao) => {

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';
  const alunosUrl = gestao.gestao ? urlsGestao.alunos : urlsGestaoUnidades.alunos;

  const limitarUnidades = gestao.gestao == false && process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC";
  const unidadeId = localStorage.getItem('unidadeID');


  

   const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });


   const [status, setStatus] = useState(0)
   const [escolaridade, setEscolaridade] = useState(0)
   const [unidadeProxima, setUnidadeProxima] = useState(0)
   const [turma, setTurma] = useState(0)
   const [turmasData, setTurmasData] = useState([])
   const [allEscolaridades, setAllEscolaridades] = useState(false);

   const unidadeID = parseInt(localStorage.getItem('unidadeID'))
   const [proximaEscolaridade, setProximaEscolaridade] = useState("");

  const currentEscolaridade = watch("escolaridade");

   const {  isError, isLoading, refetch: refetchTurmas } = useQuery(
    'getTurmasUnidadeEscolaridadeTurno', () => getTurmasUnidadeEscolaridadeTurno({
      unidadeId: unidadeProxima,
      escolaridadeId: escolaridade,
      turnoId: 0,
      anoLetivo: 2025,
      skip: 0,
      limit: 200
    }),
    {
      retry: 0,
      enabled: unidadeProxima == unidadeID,
      onSuccess: (data) => {
        setTurmasData(data);
      }
    });

   const navigate = useNavigate()

   const { state, pathname } = useLocation();



    const { data: escolaridades, isLoading: loadingEscolaridades } = useQuery(
        'getEscolaridades',
        () => getEscolaridades(),
        {
          retry: 3,
          enabled: true
        }
      )

      const { data: unidades, isLoading: loadingUnidades, refetch } = useQuery(
        'getUnidades2025',
        async () => {
          const allUnidades = await getUnidadesEscolaridade2025(escolaridade);
      
          if (limitarUnidades) {
            return allUnidades.filter((unidade) => unidade.id === parseInt(unidadeId));
          }
          return allUnidades;
        },
        {
          retry: 3,
          enabled: escolaridade !== 0, // Ensure escolaridade is valid before enabling the query
        }
      );
      

      const handleStatusChange = (e) => {
        const selectedStatus = parseInt(e.target.value);
        setStatus(selectedStatus);
      
        if (selectedStatus === 4) {
          // Approved: Show next escolaridade
          const currentIndex = escolaridadeArray.indexOf(currentEscolaridade);
          const nextEscolaridadeDescription =
            currentIndex !== -1 && currentIndex < escolaridadeArray.length - 1
              ? escolaridadeArray[currentIndex + 1]
              : currentEscolaridade; // Fallback to the current if no next exists
      
          // Get the corresponding ID from escolaridades based on the description
          const nextEscolaridade = escolaridades.find(
            (item) => item.descricao === nextEscolaridadeDescription
          );

          if (nextEscolaridade.descricao === "MULTISSERIADA") {
            setAllEscolaridades(true);
          }
      
          const nextEscolaridadeId = nextEscolaridade ? nextEscolaridade.id : null;
      
          setProximaEscolaridade(nextEscolaridadeDescription);
          setValue("proximaEscolaridade", nextEscolaridadeId);
          setEscolaridade(nextEscolaridadeId);
        } else {
          // Not approved: Keep the same escolaridade
          const currentEscolaridadeData = escolaridades.find(
            (item) => item.descricao === currentEscolaridade
          );
      
          const currentEscolaridadeId = currentEscolaridadeData
            ? currentEscolaridadeData.id
            : null;
      
          setProximaEscolaridade(currentEscolaridade);
          setValue("proximaEscolaridade", currentEscolaridadeId);
          setEscolaridade(currentEscolaridadeId);
        }
      };
      

   useEffect(() => {
    setValue('id', state.id)
    setValue('nome', state.nome)
    setValue('escolaridade', state.escolaridade)
    setValue('unidade', state.unidade)
  }, [state, pathname, setValue])

  useEffect(() => {
    if(escolaridade > 0){
      refetch()
    }
    
  }, [escolaridade])

  const { mutate } = useMutation(aprovarAluno, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(alunosUrl);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar enturmar, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {

    if(parseInt(escolaridade) == 0){
      return toast.error('Informe a próxima escolaridade para dar continuidade.');
    }

    if(parseInt(unidadeProxima) == 0){
      return toast.error('Informe a próxima unidade para dar continuidade.');
    }

    if(parseInt(unidadeProxima) == parseInt(state.unidade) && parseInt(turma) == 0){
      return toast.error('Informe a próxima turma para dar continuidade.');
    }

    const requestData = {
      alunoId: state.id,
      status: parseInt(status),
      unidadeAnterior: parseInt(unidadeID),
      proximaEscolaridade: parseInt(escolaridade),
      proximaUnidade: parseInt(unidadeProxima),
      proximaTurma: parseInt(turma)
    }
    mutate(requestData);
  }

  return (
    <BaseComponent>
      <Breadcrumb title={'Alunos'} itens={[BasePath, 'Alunos', 'Aprovação']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <div className='row'>
          <div className='col-12'>
        <div className='card'>
            <div className="card-body">
            <div className='row'>
                    <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>
                    <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="nome"
                        label="Nome"
                        readOnly
                      />
                    </div>
                    
                  </div>

                  <div className='row' style={{marginTop: 20}}>
                  <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="unidade"
                        label="Unidade"
                        readOnly
                      />
                    </div>
                  <div className='col-6'>
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="escolaridade"
                        label="Escolaridade"
                        readOnly
                      />
                    </div>

                  </div>

                  <div className="row">
   <div className='col-md-12' style={{marginTop: 20}}>
            <div className="form-group">
              <label htmlFor="status">Status?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-control"
                id="status"
                {...register("status", { required: true })}
                onChange={async (e) => {
                //   await setValue('aprovado', e.target.value);
                  await setStatus(e.target.value);
                  await handleStatusChange(e);
                }}>
                <option value=''></option>
                <option value={3}>REPROVADO</option>
                <option value={4}>APROVADO</option>
              </select>
              {errors.status && <span className="text-danger">Campo obrigatório</span>}
            </div>
          </div>
    </div>

    {
            status > 0 && (
                <div className="row" style={{paddingTop: '20px'}}>
                <div className='col-md-12'>
                         <div className="form-group">
                           <label htmlFor="proximaEscolaridade">Escolaridade 2025<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                           <select
                             className="form-control"
                             id="proximaEscolaridade"
                             disabled={!allEscolaridades}
                             {...register("proximaEscolaridade", { required: true })}
                             onChange={async (e) => {
                             //   await setValue('escolaridade2023', e.target.value);
                            await setUnidadeProxima(0)
                            await setEscolaridade(e.target.value)
                            refetch()
                             }}>
                            {loadingEscolaridades ? (
                                 <option value="">Carregando...</option>
                               ) : (
                                 <>
                                   <option value=""></option>
                                   {escolaridades &&
                                     escolaridades.map(item => (
                                       <option key={item.id} value={item.id}>
                                         {item.descricao}
                                       </option>
                                     ))}
                                 </>
                               )}
                             
                           </select>
                           {errors.proximaEscolaridade && <span className="text-danger">Campo obrigatório</span>}
                         </div>
                       </div>
                 </div>
                )
            }

{
            status > 0 && (

                <div className="row" style={{paddingTop: '20px'}}>
   <div className='col-md-12'>
            <div className="form-group">
              <label htmlFor="proximaUnidade">Unidades Disponíveis<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-control"
                id="proximaUnidade"
                {...register("proximaUnidade", { required: true })}
                onChange={async (e) => {
                    await setUnidadeProxima(e.target.value)
                    if (e.target.value == unidadeID) {
                        refetchTurmas()
                    }
                }}>
                 {loadingUnidades ? (
                    <option value="">Carregando...</option>
                  ) : (
                    <>
                      <option value=""></option>
                      {unidades &&
                        unidades.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                    </>
                  )}
                
              </select>
              {errors.proximaUnidade && <span className="text-danger">Campo obrigatório</span>}
            </div>
          </div>
    </div>

            )
}

{
             (unidadeProxima == unidadeID) && (

                <div className="row" style={{paddingTop: '20px'}}>
   <div className='col-md-12'>
            <div className="form-group">
              <label htmlFor="proximaTurma">Turmas 2025<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select
                className="form-control"
                id="proximaTurma"
                {...register("proximaTurma", { required: true })}
                onChange={async (e) => {
                  await setTurma(e.target.value)
                }}>
                 {loadingUnidades ? (
                    <option value="">Carregando...</option>
                  ) : (
                    <>
                      <option value=""></option>
                      {turmasData?.data &&
                        turmasData?.data.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.descricao}
                          </option>
                        ))}
                    </>
                  )}
                
              </select>
              {errors.proximaTurma && <span className="text-danger">Campo obrigatório</span>}
            </div>
          </div>
    </div>
    

            )
}

{
            (unidadeProxima == unidadeID) && (

                <div className="row" style={{paddingTop: '20px'}}>
  <label style={{color: 'green'}}>Unidade de origem é a mesma da unidade de destino, sendo assim após o preencimento do formulário o aluno será enturmado na nova turma.</label>
    </div>
    

            )
}
<div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 30,
                marginTop: 20
              }}>
              <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                <button 
                type='submit' 
                className='btn btn-success' 
                >
                  Enviar
                </button>
              </div>
            </div>
        </div>
        </div>
    </div>
    </form>
    </BaseComponent>
  )

};

export default AlunosAprovacaoPage;
