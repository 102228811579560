const baseUrl = '/servidores-2025';

const urlsServidores = {
    dashboard: baseUrl,
    inscricoes: `${baseUrl}/inscricoes`,
    indeferir: `${baseUrl}/indeferir`,
    indeferirId: `${baseUrl}/indeferir/:id`,
    anexo: `${baseUrl}/anexo`,
    anexoId: `${baseUrl}/anexo/:id`,
    visualizar: `${baseUrl}/visualizar/`,
    visualizarId: `${baseUrl}/visualizar/:id`,
    formulario: `${baseUrl}/formulario`,
    formularioDados: `${baseUrl}/formulario/dados`,
    formularioRecurso: `${baseUrl}/formulario/recurso`,
}

export default urlsServidores;