import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  FilterAltOutlined,
  PrintOutlined,
  InfoOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseAdministrativo from "../../administrativo/baseAdministrativo";
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import SolicitacaoForm from "./cadastrar";
import { useQuery } from "react-query";
import { getUnidades } from "../../../services/unidades";
import { useNavigate } from "react-router-dom";
import urlsAdministrativo from "../../administrativo/urlsAdministrativo";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import {
  getAllSolicitacoes,
  getRelatorioSolicitacoes,
  getSolicitacaoById,
} from "../../../services/administrativo/patrimonio-estoque";
import moment from "moment";
import ImprimirSolicitacao from "./imprimir";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";

export default function SolicitacoesUnidades({ gestaoUnidades }) {
  const solicitacoesSkip = localStorage.getItem("solicitacoesSkip");
  const solicitacoesLimit = localStorage.getItem("solicitacoesLimit");

  const [anchorEl, setAnchorEl] = useState(null);
  const [newData, setNewData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [skip, setSkip] = useState(solicitacoesSkip ? solicitacoesSkip : 0);
  const [limit, setLimit] = useState(solicitacoesLimit ? solicitacoesLimit : 20);
  const [solicitacaoInicio, setSolicitacaoInicio] = useState(null);
  const [solicitacaoFim, setSolicitacaoFim] = useState(null);
  const [unidadeFilter, setUnidadeFilter] = useState(
    gestaoUnidades ? localStorage.getItem("unidadeID") : null
  );
  const [statusFilter, setStatusFilter] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [produtoIdFilter, setProdutoIdFilter] = useState(null);
  const [tipoProdutoFilter, setTipoProdutoFilter] = useState(null);
  const [selectedChip, setSelectedChip] = useState(
    !gestaoUnidades ? "Recebidas" : "Enviadas"
  );
  const [openModalSolicitacoes, setOpenModalSolicitacoes] = useState(false);
  const navigate = useNavigate();
  const unidade = localStorage.getItem("unidadeID");
  const open = Boolean(anchorEl);

  const BaseComponent = gestaoUnidades
    ? BaseGestaoUnidades
    : BaseAdministrativo;

  const { data: solicitacoesData, refetch: refetchSolicitacoes } = useQuery(
    "getAllSolicitacoes",
    () =>
      getAllSolicitacoes({
        skip,
        limit,
        solicitacaoInicio: solicitacaoInicio,
        solicitacaoFim: solicitacaoFim,
        unidadeId: unidadeFilter,
        status: statusFilter,
      }),
    {
      enabled: true,
      onSuccess: (data) => {
        setNewData(data?.data);
      },
    }
  );

  const { data: unidades } = useQuery("getUnidades", getUnidades, {
    enabled: true,
  });

  const handlePrint = async (row) => {
    try {
      const response = await getSolicitacaoById(row);
      const printableContent = ReactDOMServer.renderToString(
        <ImprimirSolicitacao row={response?.data ? response?.data : row} />
      );

      const printWindow = window.open("", "_blank");

      const printStyles = `
        <style>
          @page {
            margin: auto;
          }
          body {
            margin: 20px;
            font-family: Arial, sans-serif;
          }
        </style>
      `;

      printWindow.document.write(`
        <html lang="pt-BR">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Impressão</title>
            ${printStyles}
          </head>
          <body>
            ${printableContent}
          </body>
        </html>
      `);

      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to fetch solicitacao:", error);
    }
  };

  const handleResponderSolicitacao = async (row) => {
    navigate(
      !gestaoUnidades
        ? urlsAdministrativo.patrimonioAlmoxarifadoResponderSolicitacoes +
            row.id
        : urlsGestaoUnidades.solicitacoesEstoqueResposta + row.id,
      {
        state: {
          solicitacao: row,
        },
      }
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const handleChipSelect = (chip) => {
    setSelectedChip(chip);
  };

  const handleClickOpenModalSolicitacoes = () => {
    setOpenModalSolicitacoes(true);
  };

  const handleCloseModalSolicitacoes = () => {
    setOpenModalSolicitacoes(false);
  };

  const handleDownloadExcel = async () => {
    await getRelatorioSolicitacoes({
      skip,
      limit,
      searchText: searchFilter,
      produtoId: produtoIdFilter,
      tipoProduto: tipoProdutoFilter,
      status: statusFilter,
    });
  };

  useEffect(() => {
    if (!unidades || !newData || !unidade) return;

    const filteredAdm = newData.filter((solicitacao) =>
      selectedChip === "Recebidas"
        ? solicitacao.unidade != null
        : solicitacao.unidade == null
    );

    const filteredGestao = newData.filter((solicitacao) => {
      const userUnidade = unidades.find((u) => u.id == unidade)?.nome;
      return (
        selectedChip === "Enviadas" &&
        userUnidade &&
        userUnidade == solicitacao.unidade
      );
    });

    setFilteredData(gestaoUnidades ? filteredGestao : filteredAdm);
  }, [selectedChip, newData, unidade, gestaoUnidades, unidades]);

  useEffect(() => {
    refetchSolicitacoes();
    localStorage.setItem("solicitacoesLimit", limit);
    localStorage.setItem("solicitacoesSkip", skip);
  }, [skip, limit, openModalSolicitacoes]);

  const columns = useMemo(
    () => [
      {
        Header: "Data da Solicitação",
        accessor: "dataSolicitacao",
        Cell: ({ row }) => {
          return moment(row.original.dataSolicitacao).format("DD/MM/YYYY");
        },
      },
      {
        Header: "Unidade",
        accessor: "unidade",
        Cell: ({ row }) => {
          return <Chip label={row.original.unidade} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const statusValue = row.original.status;
          let color = "default";
          switch (statusValue) {
            case "CONCLUÍDO":
              color = "success";
              break;
            case "REPROVADO":
              color = "error";
              break;
            case "EM TRANSPORTE":
              color = "info";
              break;
            case "EM ANÁLISE":
              color = "warning";
              break;
            default:
              color = "default";
          }

          return <Chip label={statusValue} color={color} />;
        },
      },
      { Header: "Usuário Solicitante", accessor: "usuario" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <Tooltip title="Imprimir Solicitação">
              <IconButton
                onClick={() => handlePrint(row.original.id)}
                size="large"
                color="inherit"
              >
                <PrintOutlined color="primary" />
              </IconButton>
            </Tooltip>
            {["EM TRANSPORTE", "REPROVADO", "CONCLUÍDO"].includes(
              row.original.status
            ) && (
              <Tooltip title="Ver Resposta">
                <IconButton
                  onClick={() => handleResponderSolicitacao(row.original)}
                  size="large"
                  color="inherit"
                >
                  <InfoOutlined style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            )}
            {!gestaoUnidades && row.original.status === "EM ANÁLISE" ? (
              <Tooltip title="Responder">
                <IconButton
                  onClick={() => {
                    handleResponderSolicitacao(row.original);
                  }}
                  size="large"
                  color="inherit"
                >
                  <FormatListBulletedOutlined />
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        ),
      },
    ],
    []
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Solicitações"}
        itens={[
          gestaoUnidades ? "Gestão Unidades" : "Administrativo",
          "Estoque",
          "Solicitações",
        ]}
        hasExcelButton
        hasModal={gestaoUnidades}
        onClickModal={handleClickOpenModalSolicitacoes}
        modalTitulo="Nova Solicitação"
        onClickDownload={() => handleDownloadExcel()}
      />
      <div className="card">
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          {/* <div className="d-flex gap-2 justify-content-end flex-grow-1">
            <Chip
              label="Enviadas"
              color={selectedChip === "Enviadas" ? "success" : "default"}
              variant="filled"
              onClick={() => handleChipSelect("Enviadas")}
            />
            <Chip
              label="Recebidas"
              color={selectedChip === "Recebidas" ? "success" : "default"}
              onClick={() => handleChipSelect("Recebidas")}
            />
          </div> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              {!gestaoUnidades && (
                <div className="mt-4">
                  <Autocomplete
                    options={unidades}
                    getOptionLabel={(option) => option?.nome || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      unidades?.find(
                        (unidade) => unidade.id === unidadeFilter
                      ) || null
                    }
                    onChange={async (_, selectedOption) => {
                      const unidadeId = selectedOption
                        ? selectedOption.id
                        : localStorage.getItem("unidadeID");
                      await setUnidadeFilter(unidadeId);
                      await refetchSolicitacoes();
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="UNIDADE" />
                    )}
                    isClearable
                  />
                </div>
              )}
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA SOLICITAÇÃO INÍCIO"
                    value={solicitacaoInicio}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setSolicitacaoInicio(e.target.value);
                      await refetchSolicitacoes();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA SOLICITAÇÃO FIM"
                    value={solicitacaoFim}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setSolicitacaoFim(e.target.value);
                      await refetchSolicitacoes();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="status">STATUS</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={statusFilter}
                    label="Status"
                    onChange={async (e) => {
                      await setStatusFilter(e.target.value);
                      await refetchSolicitacoes();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    <MenuItem value={1}>EM ANÁLISE</MenuItem>
                    <MenuItem value={2}>REPROVADO</MenuItem>
                    <MenuItem value={3}>CONCLUÍDO</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={async () => {
                    await setUnidadeFilter(
                      gestaoUnidades ? unidadeFilter : null
                    );
                    await setSolicitacaoInicio(null);
                    await setStatusFilter(null);
                    await setSolicitacaoFim(null);
                    await refetchSolicitacoes();
                  }}
                />
              </div>
            </div>
          </Menu>
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={solicitacoesData?.total}
          columns={columns}
          data={filteredData ? filteredData : []}
          paginationKey="solicitacoesPage"
          validatePage
        />
      </div>
      <Dialog
        open={openModalSolicitacoes}
        onClose={handleCloseModalSolicitacoes}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          REGISTRAR SOLICITAÇÃO DE PRODUTOS
        </DialogTitle>
        <DialogContent>
          <SolicitacaoForm
            handlePrint={handlePrint}
            onClose={handleCloseModalSolicitacoes}
          />
        </DialogContent>
      </Dialog>
    </BaseComponent>
  );
}
