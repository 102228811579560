const baseUrlPublic = '/chamada-publica-2025';
const baseUrlPrivate = '/chamadaPublica2025';

const urlsChamadaPublica2025 = {
    dashboard: baseUrlPrivate,
    homeFormulario: baseUrlPublic,
    creches: `${baseUrlPrivate}/creches`,
    crechesConvocados: `${baseUrlPrivate}/creches/convocacao`,
    preConvocados: `${baseUrlPrivate}/pre/convocacao`,
    crechesRemanejamento: `${baseUrlPrivate}/creches/remanejamento`,
    preRemanejamento: `${baseUrlPrivate}/pre/remanejamento`,
    pre: `${baseUrlPrivate}/pre`,
    pesquisar: `${baseUrlPrivate}/pesquisar`,
    // listaEspera: `${baseUrlPrivate}/lista-espera`,
    relatoriosTransferenciasExternas: `${baseUrlPrivate}/relatorios/transferencias-externas`,
    relatoriosInscritos: `${baseUrlPrivate}/relatorios/inscritos`,
    relatoriosMatriculados: `${baseUrlPrivate}/relatorios/matriculados`,
    relatoriosVagas: `${baseUrlPrivate}/relatorios/vagas`,
    relatorioSaldoVagas: `${baseUrlPrivate}/relatorios/saldo-vagas`,
    visualizacao: `${baseUrlPublic}/visualizacao/`,
    visualizacaoId: `${baseUrlPublic}/visualizacao/:id`,
    visualizacaoIdv2: `${baseUrlPublic}/visualizacaov2/:id`,
    formularioRede: `${baseUrlPublic}/formulario/nao-sou-aluno`,
    formularioTransferencia: `${baseUrlPublic}/formulario/sou-aluno`,
    formulariov2: `${baseUrlPublic}/formulariov2/`,
    // formularioAberto: `${baseUrlPublic}/formulario/aberto`,
    historico: `${baseUrlPrivate}/historico`,
    historicoId: `${baseUrlPrivate}/historico/:id`,
    remanejamento: `${baseUrlPrivate}/remanejamento`,
    remanejamentoId: `${baseUrlPrivate}/remanejamento/:id`,
    listaEspera: `${baseUrlPublic}/lista-espera`,
    relatorioInscritosPorUnidade : `${baseUrlPrivate}/relatorios/inscritos/porUnidade`,
    relatorioInscritosPorEscolaridade : `${baseUrlPrivate}/relatorios/inscritos/porEscolaridade`,
    relatorioInscritosPorPne : `${baseUrlPrivate}/relatorios/inscritos/porPne`,
    relatorioInscritosPorNovosDaRede : `${baseUrlPrivate}/relatorios/inscritos/porNovosDaRede`,
    relatorioInscritosPorGeral : `${baseUrlPrivate}/relatorios/inscritos/porGeral`,
    relatorioInscritorPorBairro: `${baseUrlPrivate}/relatorios/inscritos/porBairro`,
    relatorioInscritosTransferenciaInterna: `${baseUrlPrivate}/relatorios/inscritos/transferencia-interna`,
    relatorioInscritosTransferenciaExterna: `${baseUrlPrivate}/relatorios/inscritos/transferencia-externa`,
    relatorioConvocacoesPorEscolaridade : `${baseUrlPrivate}/relatorios/convocados/porEscolaridade`,
    relatorioConvocadosPorPeriodoNominal : `${baseUrlPrivate}/relatorios/convocados/porPeriodoNominal`,
    relatorioConvocadosPorPeriodoQuantitativo : `${baseUrlPrivate}/relatorios/convocados/porPeriodoQuantitativo`,
    relatorioMatriculadosPorEscolaridade : `${baseUrlPrivate}/relatorios/matriculados/porEscolaridade`,
    relatorioMatriculadosPorUnidade : `${baseUrlPrivate}/relatorios/matriculados/porUnidade`,
    relatorioMatriculadosPorGeral : `${baseUrlPrivate}/relatorios/matriculados/porGeral`,
    relatorioMatriculadosPorPeriodoNominal : `${baseUrlPrivate}/relatorios/matriculados/porPeriodoNominal`,
    relatorioMatriculadosPorPeriodoQuantitativo : `${baseUrlPrivate}/relatorios/matriculados/porPeriodoQuantitativo`,
    alunosManuais: `${baseUrlPrivate}/alunos/manuais`,
    alunosPreMatricula: `${baseUrlPrivate}/alunos/pre-matricula`,
    alunosRemanejamento: `${baseUrlPrivate}/alunos/remanejamento`,
    alunosPesquisa: `${baseUrlPrivate}/alunos/pesquisa`,

}

export default urlsChamadaPublica2025;