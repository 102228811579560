import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tab, Tabs, Box, Paper } from "@mui/material";
import DadosTab from "./tabs/dadosTab";
import EscolaridadesTab from "./tabs/escolaridadeTab";
import Breadcrumb from "../../../components/breadcrumb";
import { getUnidade } from "../../../services/unidades";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";

const UnidadesCadastrarPage = () => {
  const { id: unidadeId } = useParams();
  const [tabValue, setTabValue] = React.useState(0);
  const [unidadeData, setUnidadeData] = React.useState(null);

  useEffect(() => {
    const fetchUnidade = async () => {
      if (unidadeId) {
        try {
          const response = await getUnidade(unidadeId);
          setUnidadeData(response);
        } catch (error) {
          console.error("Erro ao buscar unidade", error);
        }
      }
    };
    fetchUnidade();
  }, [unidadeId]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <BaseConfiguracoes>
    <Box>
      <Breadcrumb title={"Formulário Unidades"} itens={["Gestão", "Secretaria", "Unidades", "Formulário"]} />
      <Paper>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Dados" />
        <Tab label="Escolaridades" />
      </Tabs>
      
      {tabValue === 0 && <DadosTab unidadeData={unidadeData} isEdit={!!unidadeId} />}
      {tabValue === 1 && <EscolaridadesTab unidadeId={unidadeId} />}
      </Paper>
    </Box>
    </BaseConfiguracoes>
  );
};

export default UnidadesCadastrarPage;
