import { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getAllBaixas,
  getProdutoByNome,
  postRetornoBaixa,
} from "../../../services/administrativo/patrimonio-estoque";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterAltOutlined, RecyclingOutlined } from "@mui/icons-material";
import {
  conservacao,
  tipoProduto,
} from "../../administrativo/patrimonios/almoxarifado/data";
import { getUnidades } from "../../../services/unidades";

const BaixasUnidade = () => {
  const estoqueSkip = localStorage.getItem("baixasUnidadeSkip");
  const estoqueLimit = localStorage.getItem("baixasUnidadeLimit");

  const [produtos, setProdutos] = useState([]);
  const [skip, setSkip] = useState(estoqueSkip ? estoqueSkip : 0);
  const [limit, setLimit] = useState(estoqueLimit ? estoqueLimit : 20);
  const [unidadeFilter, setUnidadeFilter] = useState(
    localStorage.getItem("unidadeID")
  );
  const [searchFilter, setSearchFilter] = useState("");
  const [produtoIdFilter, setProdutoIdFilter] = useState(0);
  const [marcaFilter, setMarcaFilter] = useState("");
  const [tipoProdutoFilter, setTipoProdutoFilter] = useState(0);
  const [conservacaoFilter, setConservacaoFilter] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openModalBaixa, setOpenModalBaixa] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const open = Boolean(anchorEl);
  const [produto, setProduto] = useState(null);

  const unidadeAtual = localStorage.getItem("unidadeID");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModalBaixa = () => {
    setOpenModalBaixa(true);
  };

  const handleCloseModalBaixa = () => {
    setOpenModalBaixa(false);
  };

  const handleRetornaBaixa = async (produto) => {
    try {
      await postRetornoBaixa({
        estoqueConsumoId: produto?.id,
        estoqueId: produto?.estoqueId,
      });
      handleCloseModalBaixa();
      await refetchBaixas();
      toast.success("Produto retornado ao estoque!");
    } catch (error) {
      toast.error(
        "Erro ao retornar produto ao estoque, tente novamente mais tarde!"
      );
    }
  };

  const { data: baixasData, refetch: refetchBaixas } = useQuery({
    queryKey: "getBaixas",
    queryFn: () =>
      getAllBaixas({
        skip,
        limit,
        unidadeId: unidadeAtual,
        searchText: searchFilter,
        produtoId: produtoIdFilter,
        marca: marcaFilter,
        tipoProduto: tipoProdutoFilter,
        conservacao: conservacaoFilter,
      }),
    enabled: true,
  });

  const { data } = useQuery("getProdutos", () => getProdutoByNome(), {
    enabled: true,
    onSuccess: (data) => {
      setProdutos(
        data?.data.map((produto) => ({
          label: produto.nome,
          id: produto.id,
        }))
      );
    },
  });

  const { data: unidades } = useQuery("getUnidades", getUnidades, {
    enabled: true,
  });

  const getTipoProdutoLabel = (value) => {
    const tipo = tipoProduto.find((item) => item.value === value);
    return tipo ? tipo.label : "-";
  };

  useEffect(() => {
    refetchBaixas();
    localStorage.setItem("baixasUnidadeLimit", limit);
    localStorage.setItem("baixasUnidadeSkip", skip);
  }, [skip, limit]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "produtoImagem",
        Cell: ({ value }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={`data:image/jpeg;base64,${value}`}
              sx={{
                width: 45,
                height: 45,
                border: "1px solid rgba(0, 0, 0, 0.38)",
                padding: 1,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
            />
          </div>
        ),
      },
      { Header: "Produto", accessor: "nomeProduto" },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        Cell: ({ value }) => getTipoProdutoLabel(value),
      },
      {
        Header: "Patrimônio",
        accessor: "numeroPatrimonio",
        Cell: ({ value }) => (value ? value : "-"),
      },

      { Header: "Quantidade", accessor: "quantidade" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => {
                handleClickOpenModalBaixa();
                setProduto(row.original);
              }}
              size="large"
              color="inherit"
            >
              <RecyclingOutlined color="success" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    []
  );

  return (
    <BaseGestaoUnidades>
      <Breadcrumb
        title={"Baixas"}
        itens={["Gestão Unidades", "Gestão Educacional", "Baixas"]}
      />
      <div className="card">
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="NOME"
                    value={searchFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setSearchFilter(e.target.value);
                      await refetchBaixas();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="MARCA"
                    value={marcaFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setMarcaFilter(e.target.value);
                      await refetchBaixas();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="tipoProdutoFilter">TIPO</InputLabel>
                  <Select
                    labelId="tipoProdutoFilter"
                    id="tipoProdutoFilter"
                    value={tipoProdutoFilter}
                    label="Tipo"
                    onChange={async (e) => {
                      await setTipoProdutoFilter(e.target.value);
                      await refetchBaixas();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {tipoProduto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="conservacao">CONSERVAÇÃO</InputLabel>
                  <Select
                    labelId="conservacao"
                    id="conservacao"
                    value={conservacaoFilter}
                    label="Conservação"
                    onChange={async (e) => {
                      await setConservacaoFilter(e.target.value);
                      await refetchBaixas();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {conservacao.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={produtos}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="PRODUTO" />
                    )}
                    onChange={async (_, newValue) => {
                      if (newValue) {
                        await setProdutoIdFilter(newValue.id);
                        await refetchBaixas();
                      }
                    }}
                  />
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={async () => {
                    await setUnidadeFilter(localStorage.getItem("unidadeID"));
                    await setSearchFilter("");
                    await setTipoProdutoFilter(0);
                    await setProdutoIdFilter(0);
                    await setMarcaFilter("");
                    await setConservacaoFilter(0);
                    await refetchBaixas();
                  }}
                />
              </div>
            </div>
          </Menu>

          <Chip
            color="info"
            label={`UNIDADE: ${
              unidades?.find((item) => item.id == unidadeFilter)?.nome || "-"
            }`}
            variant="filled"
          />
          {tipoProdutoFilter > 0 ? (
            <Chip
              color="info"
              label={`TIPO: ${
                tipoProduto.find((item) => item.value === tipoProdutoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setTipoProdutoFilter(0);
                await refetchBaixas();
              }}
            />
          ) : null}
          {conservacaoFilter > 0 ? (
            <Chip
              color="info"
              label={`CONSERVAÇÃO: ${
                conservacao.find((item) => item.value === conservacaoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setConservacaoFilter(0);
                await refetchBaixas();
              }}
            />
          ) : null}
          {marcaFilter != "" ? (
            <Chip
              color="info"
              label={`MARCA: ${marcaFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setMarcaFilter("");
                await refetchBaixas();
              }}
            />
          ) : null}
          {searchFilter != "" ? (
            <Chip
              color="info"
              label={`NOME: ${searchFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setSearchFilter("");
                await refetchBaixas();
              }}
            />
          ) : null}
          {produtoIdFilter > 0 ? (
            <Chip
              color="info"
              label={`PRODUTO: ${
                produtos.find((produto) => produto.id === produtoIdFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setProdutoIdFilter(0);
                await refetchBaixas();
              }}
            />
          ) : null}
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={baixasData?.total}
          columns={columns}
          data={baixasData?.data ? baixasData?.data : []}
          paginationKey="estoqueBaixasPage"
          validatePage
        />
      </div>
      <Dialog
        open={openModalBaixa}
        onClose={handleCloseModalBaixa}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          RETORNAR PRODUTO AO ESTOQUE
        </DialogTitle>
        <DialogContent>
          <TextField
            className="mt-4"
            label="Nome Produto"
            value={produto?.nomeProduto}
            variant="outlined"
            fullWidth
            disabled
          />
          <TextField
            className="mt-4"
            label="Marca"
            value={produto?.marca}
            variant="outlined"
            fullWidth
            disabled
          />
          <Button
            className="mt-4"
            variant="contained"
            onClick={() => handleRetornaBaixa(produto)}
          >
            Restaurar
          </Button>
        </DialogContent>
      </Dialog>
    </BaseGestaoUnidades>
  );
};
export default BaixasUnidade;
