import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  Kitchen,
  DirectionsBus,
  DriveEta,
  School,
  SupervisorAccount,
} from '@mui/icons-material';
import Breadcrumb from "../../../../components/breadcrumb";
import BaseServidores from "./baseServidores";
import { getDashboardProcessoSeletivoServidores } from "../../../../services/dashboard";
import { useQuery } from 'react-query';
import CargoBarChart from "./components/graficoCargos";

const HomeServidores = () => {
  const { data, isLoading } = useQuery(
    'getDashboardProcessoSeletivoServidores',
    getDashboardProcessoSeletivoServidores,
    { retry: 3 }
  );

  // Map of cargos with titles, corresponding data keys, and icons
  const cargos = [
    { title: "MERENDEIRA", key: "cargo3", icon: <Kitchen fontSize="large" />, iconColor: "#FFA726", bgColor: "#FFF3E0" },
    { title: "MONITOR DE TRANSPORTE ESCOLAR", key: "cargo4", icon: <DirectionsBus fontSize="large" />, iconColor: "#29B6F6", bgColor: "#E1F5FE" },
    { title: "MOTORISTA D", key: "cargo5", icon: <DriveEta fontSize="large" />, iconColor: "#66BB6A", bgColor: "#E8F5E9" },
    { title: "SECRETÁRIO ESCOLAR", key: "cargo12", icon: <School fontSize="large" />, iconColor: "#FFCA28", bgColor: "#FFF8E1" },
    { title: "SUPERVISOR EDUCACIONAL", key: "cargo14", icon: <SupervisorAccount fontSize="large" />, iconColor: "#EF5350", bgColor: "#FFEBEE" },
  ];

  const chartData = cargos.reduce((acc, cargo) => {
    acc[cargo.title] = data?.[cargo.key] || 0;
    return acc;
  }, {});

  const renderCard = (title, amount, icon, iconColor, backgroundColor) => (
    <Card sx={{ boxShadow: 3, borderRadius: 2, height: '100%', backgroundColor }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              backgroundColor: iconColor,
              color: '#fff',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 64,
              height: 64,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="subtitle1" color="textSecondary">{title}</Typography>
            <Typography variant="h4" fontWeight="bold" color="textPrimary">{amount}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <BaseServidores>
      <Breadcrumb title="Informações Gerais" itens={['Processo Seletivo Servidores', 'Dashboard']} />
      <Typography variant="h4" gutterBottom mt={3}>Dados Gerais</Typography>
      <Grid container spacing={3} mb={3}>
        {cargos.map((cargo, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {renderCard(cargo.title, data?.[cargo.key] || 0, cargo.icon, cargo.iconColor, cargo.bgColor)}
          </Grid>
        ))}
      </Grid>

      <Typography variant="h4" gutterBottom mt={3}>Gráfico de Cargos</Typography>
      <Card sx={{ boxShadow: 3, borderRadius: 2, mt: 2 }}>
        <CardContent>
          <CargoBarChart data={chartData} />
        </CardContent>
      </Card>
    </BaseServidores>
  );
};

export default HomeServidores;
