import api from '../index'

async function getServidores(unidadeId = 0, regimeId = 0, nome = '', limit = 20, skip = 0, excluido = 0) {
  return await api.get(`/servidor?unidadeId=${unidadeId}&regimeId=${regimeId}&nome=${nome}&skip=${skip}&limit=${limit}&excluido=${excluido}`).then(async (res) => {
    return res.data;
  })
}

async function getServidoresQuadroHorario(unidadeId = 0, regimeId = 0, nome = '', limit = 20, skip = 0) {
  return await api.get(`/servidor/quadroHorario?unidadeId=${unidadeId}&regimeId=${regimeId}&nome=${nome}&skip=${skip}&limit=${limit}`).then(async (res) => {
    return res.data;
  })
}

async function restaurarServidor(data) {
  return await api
    .post('/servidor/restaurar', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function deleteServidor(id) {
  return await api.delete(`/servidor/${id}`).then(async res => {
    return res.data;
  })
}

async function adicionarServidor(data) {
  return await api
    .post('servidor', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function editarServidor(id, data) {
  return await api
    .put(`servidor/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function editarServidorPerfil(id, email, senha, telefone) {
  return await api
    .put(`servidor/perfil/?servId=${id}&email=${email}&senha=${senha}&telefone=${telefone}`).then(async (res) => {
      return res.data;
    });
}

async function listarServidor(id) {
  return await api
    .get(`servidor/${id}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function listarServidorFoto(id, unidadeId) {
  return await api
    .get(`servidor/${id}/unidadeId/${unidadeId}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function postLogin({ login, senha }) {
  return await api
    .post('servidor/login', { login, senha })
    .then(async (res) => {
      const data = res.data;

      if (data === undefined) {
        return res.response.data;
      }


      const token = res.data.data.token
      const unidades = res.data.data.unidadesIds



      const acessos = {
        moduloAdministrativo: res.data.data.moduloAdministrativo,
        moduloDocente: res.data.data.moduloDocente,
        moduloDocenteGrupoAcademico: res.data.data.moduloDocenteGrupoAcademico,
        moduloDocenteGrupoDiario: res.data.data.moduloDocenteGrupoDiario,
        moduloDocenteGrupoGestaoEducacional: res.data.data.moduloDocenteGrupoGestaoEducacional,
        moduloGestao: res.data.data.moduloGestao,
        moduloGestaoGrupoAcademico: res.data.data.moduloGestaoGrupoAcademico,
        moduloGestaoGrupoDiario: res.data.data.moduloGestaoGrupoDiario,
        moduloGestaoGrupoGestaoEducacional: res.data.data.moduloGestaoGrupoGestaoEducacional,
        moduloGestaoUnidades: res.data.data.moduloGestaoUnidades,
        moduloGestaoUnidadesGrupoAcademico: res.data.data.moduloGestaoUnidadesGrupoAcademico,
        moduloGestaoUnidadesGrupoDiario: res.data.data.moduloGestaoUnidadesGrupoDiario,
        moduloGestaoUnidadesGrupoGestaoEducacional: res.data.data.moduloGestaoUnidadesGrupoGestaoEducacional,
        moduloGestaoUnidadesGrupoTransferencias: res.data.data.moduloGestaoUnidadesGrupoTransferencias,
        moduloConfiguracao: res.data.data.moduloConfiguracao,
        moduloGestaoUnidadesGrupoFamiliaConectada: res.data.data.moduloGestaoUnidadesGrupoFamiliaConectada,
        moduloAdministrativoTransporte: res.data.data.moduloAdministrativoTransporte,
        moduloGestaoFrequenciaServidores: res.data.data.moduloGestaoFrequenciaServidores,
        moduloGestaoUnidadesGrupoFrequenciaServidores: res.data.data.moduloGestaoUnidadesFrequenciaServidores,
        moduloAdministrativoPatrimonio: res.data.data.moduloAdministrativoPatrimonio,
        moduloAdministrativoTi: res.data.data.moduloAdministrativoTi,
        moduloAdministrativoFinanceiro: res.data.data.moduloAdministrativoFinanceiro,
        moduloAdministrativoNutricao: res.data.data.moduloAdministrativoNutricao,
        moduloFormularioBilingue: res.data.data.moduloFormularioBilingue,
        moduloAdministrativoEstoque: res.data.data.moduloAdministrativoEstoque,
        moduloGestaoAprovacaoCadastroManual: res.data.data.moduloGestaoAprovacaoCadastroManual,
        moduloGestaoUnidadesEstoque: res.data.data.moduloGestaoUnidadesEstoque,
      }
      console.log(acessos)
      await localStorage.setItem('acessos', JSON.stringify(acessos))
      await localStorage.setItem('access_token', token);
      await localStorage.setItem('unidadeID', unidades[0]);
      return data;

    });
};

async function adicionarUnidadeServidor(data) {
  return await api
    .post(`servidor/unidade`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function deletarUnidadeServidor(unidadeId, servidorId) {
  return await api
    .delete(`servidor/unidade?unidadeId=${unidadeId}&servidorId=${servidorId}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function listarUnidadesServidor(servidorId, skip = 0, limit = 20) {
  return await api
    .get(`servidor/unidade?servidorId=${servidorId}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      return res.data.data;
    });
};

async function getCargos() {
  return await api.get('servidor/cargos').then(async res => {
    return res.data.data
  })
}

async function getRegimes() {
  return await api.get('servidor/regimes').then(async res => {
    return res.data.data
  })
}

async function getAtividades() {
  return await api
    .get('servidor/atividades')
    .then(async (res) => {
      return res.data.data;
    });
};

async function getMotivoLicenca() {
  return await api.get('servidor/motivoslicenca').then(async res => {
    return res.data.data
  });

};

async function getEstados() {
  return await api.get('endereco/estados').then(async res => {
    return res.data.data
  });

};

async function getEstadoCivil() {
  return await api.get('configuracoes/estadocivil').then(async res => {
    return res.data.data
  });

};

async function getEtnias() {
  return await api.get('configuracoes/etnias').then(async res => {
    return res.data.data
  });

};

async function getEscolaridade() {
  return await api.get('configuracoes/grauescolaridade').then(async res => {
    return res.data.data
  });

};

async function getNacionalidades() {
  return await api.get('configuracoes/nacionalidades').then(async res => {
    return res.data
  });

};

async function getGeneros() {
  return await api.get('configuracoes/generos').then(async res => {
    return res.data.data
  });

};

async function getOrgaoEmissor() {
  return await api.get('configuracoes/orgaoemissorrg').then(async res => {
    return res.data.data
  });

};

async function getFuncao() {
  return await api.get('servidor/funcoes').then(async res => {
    return res.data.data
  });

};

async function getAtividade() {
  return await api.get('servidor/atividades').then(async res => {
    return res.data.data
  });

};

async function getSexo() {
  return await api.get('servidor/atividades').then(async res => {
    return res.data.data;
  })
}

async function getTiposDeficiencia() {
  return await api.get('configuracoes/tipoDeficiencias').then(async res => {
    return res.data.data
  })
};



async function getMotivoAfastamento() {
  return await api.get('servidor/motivosAfastamento').then(async res => {
    return res.data.data
  });
};

async function alocarDisciplina(data) {
  return await api.post('/disciplinaquadrohorario', data).then(async res => {
    return res.data;
  })
}

async function editarDisciplinaAlocada(data) {
  console.log(data);
  return await api.put(`/disciplinaquadrohorario/${data.alocationSelected}`, data.data).then(async res => {
    return res.data;
  })
}

async function excluirDisciplinaAlocada(id) {
  return await api.delete(`/disciplinaquadrohorario/${id}`).then(async res => {
    return res.data;
  })
}

async function buscaAlocacoes(id) {
  return await api.get(`/disciplinaquadrohorario/${id}`).then(async res => {
    return res.data.data;
  })
}

async function adicionarDocumento(data) {
  return await api
    .post('/servidor/documento', data)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarDocumentos(alunoId) {
  return await api
    .get(`/servidor/documento/${alunoId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function deletarDocumento(documentoId) {
  return await api
    .delete(`/servidor/documento/${documentoId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function recuperarSenha({ email }) {
  try {
    const res = await api.post('servidor/recuperarSenha', { email });
    if (res.status === 200) {
      return res?.data;
    } else {
      return res?.response?.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

async function recuperarSenhaV2({ email }) {
  try {
    const res = await api.post('servidor/recuperarSenhaV2', { email });
    if (res.status === 200) {
      return res?.data;
    } else {
      return res?.response?.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function resetarSenhaV2(data) {
  try {
    debugger;
    const res = await api.post('servidor/resetarSenhaV2',  data );
    if (res.status === 200) {
      return res?.data;
    } else {
      return res?.response?.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}


async function listarServidoresFrequencia(unidadeId, regimeId, cargoId, mes, ano) {
  return await api.get(`/servidor/frequencia?unidadeId=${unidadeId}&regimeId=${regimeId}&cargoId=${cargoId}&mes=${mes}&ano=${ano}`).then(async (res) => {
    return res.data;
  })
}

async function listarServidoresFrequenciaHe(unidadeId, mes, ano) {
  return await api.get(`/servidor/frequenciaHe?unidadeId=${unidadeId}&mes=${mes}&ano=${ano}`).then(async (res) => {
    return res.data;
  })
}

async function adicionarServidoresFrequencia(data) {
  return await api
    .post('servidor/frequencia/salvar', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function adicionarServidoresFrequenciaHe(data) {
  return await api
    .post('servidor/frequenciaHe/salvar', data)
    .then(async (res) => {
      return res;
    });
}

async function deletarServidoresFrequenciaHe(id) {
  return await api
    .delete(`servidor/frequenciaHe/${id}`)
    .then(async (res) => {
      return res;
    });
}

async function enviarServidoresFrequenciaHe(data) {
  return await api
    .post('servidor/frequenciaHe/enviar', data)
    .then(async (res) => {
      return res;
    });
}

async function adicionarServidoresOcorrencia(data) {
  return await api
    .post('servidor/frequencia/ocorrencia', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function deletarServidoresOcorrencia(ocorrenciaId) {
  return await api
    .delete(`servidor/frequencia/ocorrencia/${ocorrenciaId}`)
    .then(async (res) => {
      return res;
    });
}

async function enviarServidoresFrequencia(data) {
  return await api
    .post('servidor/frequencia/enviar', data)
    .then(async (res) => {
      return res;
    });
}

async function getNotificacoes(status = 2, servidorId = 0, limit = 20, skip = 0) {
  return await api.get(`/servidor/getnotificacoes?status=${status}&servidorId=${servidorId}&skip=${skip}&limit=${limit}`).then(async (res) => {
    return res.data;
  })
}



export {
  postLogin,
  getServidores,
  getServidoresQuadroHorario,
  deleteServidor,
  adicionarServidor,
  editarServidor,
  listarServidor,
  adicionarUnidadeServidor,
  deletarUnidadeServidor,
  listarUnidadesServidor,
  getCargos,
  getRegimes,
  getMotivoLicenca,
  getAtividades,
  getEstados,
  getEstadoCivil,
  getEtnias,
  getEscolaridade,
  getOrgaoEmissor,
  getFuncao,
  getSexo,
  getAtividade,
  getTiposDeficiencia,
  getMotivoAfastamento,
  getGeneros,
  getNacionalidades,
  alocarDisciplina,
  editarDisciplinaAlocada,
  excluirDisciplinaAlocada,
  buscaAlocacoes,
  editarServidorPerfil,
  restaurarServidor,
  listarServidorFoto,
  adicionarDocumento,
  listarDocumentos,
  deletarDocumento,
  recuperarSenha,
  recuperarSenhaV2,
  resetarSenhaV2,
  listarServidoresFrequencia,
  adicionarServidoresFrequencia,
  adicionarServidoresOcorrencia,
  enviarServidoresFrequencia,
  deletarServidoresOcorrencia,
  adicionarServidoresFrequenciaHe,
  listarServidoresFrequenciaHe,
  deletarServidoresFrequenciaHe,
  enviarServidoresFrequenciaHe,
  getNotificacoes
}