import { useForm } from "react-hook-form";
import { store } from "../../../../../store";
import { toast } from "react-toastify";
import { Button, Chip, TextField } from "@mui/material";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { postBaixaEstoque } from "../../../../../services/administrativo/patrimonio-estoque";

export default function BaixaProdutoAdministrativo({
  produto,
  onRefetch,
  onClose,
}) {
  const { mutate } = useMutation(postBaixaEstoque, {
    onSuccess: async () => {
      toast.success("Baixa do produto feita com sucesso.");
      await onRefetch();
    },
    onError: () => {
      toast.error("Erro ao dar baixa no produto. Tente novamente mais tarde.");
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      estoqueId: produto?.id,
      quantidade: produto?.tipoProduto === 1 ? produto?.quantidade : "",
      observacao: "",
    },
  });

  useEffect(() => {
    setValue("estoqueId", produto?.id);
    setValue(
      "quantidade",
      produto?.tipoProduto === 1 ? produto?.quantidade : ""
    );
  }, [produto]);

  const isExpired = () => {
    if (!produto?.dataValidade) return false;
    const validade = new Date(produto.dataValidade);
    const hoje = new Date();
    return validade < hoje;
  };

  const onSubmit = (data) => {
    if (!data.quantidade) {
      toast.error("Obrigatório selecionar a Quantidade.");
      return;
    }

    const request = {
      estoqueId: produto?.id,
      quantidade: parseInt(data.quantidade),
      observacao: data.observacao,
    };
    mutate(request);
    onRefetch();
    onClose();
  };

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row mt-4">
          <Chip
            color="warning"
            label={`QUANTIDADE DISPONÍVEL: ${produto?.quantidade}`}
          />
        </div>
        {produto?.tipoProduto === 2 && produto?.dataValidade ? (
          <div className="row mt-4">
            <Chip
              color={isExpired() ? "error" : "success"}
              label={`VALIDADE: ${new Date(
                produto.dataValidade
              ).toLocaleDateString()}`}
            />
          </div>
        ) : null}
        <div className="row mt-4">
          <div className="col-md-12">
            <TextField
              type="number"
              label="Quantidade"
              variant="outlined"
              fullWidth
              disabled={produto?.tipoProduto === 1}
              value={
                produto?.tipoProduto === 1 ? produto?.quantidade : undefined
              }
              {...register("quantidade", {
                required: "A quantidade é obrigatória.",
                min: {
                  value: 1,
                  message: "A quantidade deve ser maior que zero.",
                },
                max: {
                  value: produto?.quantidade || 0,
                  message: `A quantidade não pode exceder ${produto?.quantidade}.`,
                },
              })}
              error={!!errors.quantidade}
              helperText={errors.quantidade?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <TextField
              label="Observações"
              variant="outlined"
              fullWidth
              {...register("observacao")}
              error={!!errors.observacao}
              helperText={errors.observacao?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="submit"
          color="info"
          variant="contained"
          disabled={!isValid}
        >
          REGISTRAR
        </Button>
      </div>
    </form>
  );
}
