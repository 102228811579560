import Header from "../../../components/header";
import Aside from "../../../components/aside";
import links from './links'
import { useSelector } from 'react-redux';

const Base = ({ children }) => {
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);

  return (
    <div className={
      isMenuOpen ? 'app menu-off-canvas align-content-stretch d-flex flex-wrap menu-off-canvas-show' : 'app menu-off-canvas align-content-stretch d-flex flex-wrap'
    }>
      <Aside links={links} />
      <div className="app-container">
        <div className="search">
          <form>
            <input
              className="form-control"
              type="text"
              placeholder="Pesquisa..."
              aria-label="Search"
            />
          </form>
          <a href="/" className="toggle-search">
            <i className="material-icons md-close"></i>
          </a>
        </div>
        <Header title="Administrativo" />
        <div className="app-content">
          <div className="content-wrapper">
            <div className="container">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Base;
