import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Alert,
  Autocomplete
} from '@mui/material';
import { listarUnidadesChamadaPublica } from '../../../../../../services/processos/chamadaPublica2025';
import { getUnidades } from '../../../../../../services/unidades';
import { getAlunosCpf } from '../../../../../../services/alunos';
import { isValidCPF } from '../../../../../../utils/isValidCpf';

const Step5 = ({ formik }) => {
  const [unidades, setUnidades] = useState([]);
  const [unidadesChamadaPublica, setUnidadesChamadaPublica] = useState([]);

  const escolhaBinaria = [
    { value: 1, text: 'SIM' },
    { value: 2, text: 'NÃO' },
  ];

  const fetchUnidades = async () => {
    const cpf = formik.values.cpfIrmao;
    if (cpf && isValidCPF(cpf)) {
    try {
      const response = await getUnidades();
      const filteredUnidades = response.filter(unidade => !unidade.nome.toUpperCase().includes('SECRETARIA'));
      setUnidades(filteredUnidades);
    } catch (error) {
      console.error('Error fetching unidades:', error);
    }
  }
  };

  useEffect(() => {
    const fetchUnidadesChamadaPublica = async () => {
      try {
        const response = await listarUnidadesChamadaPublica(formik.values.bairro, formik.values.escolaridade);
        setUnidadesChamadaPublica(response);
      } catch (error) {
        console.error('Error fetching unidades chamada publica:', error);
      }
    };

    fetchUnidadesChamadaPublica();
  }, []);

    useEffect(() => {
    if (formik.values.cpfIrmao) {
      const fetchAluno = async () => {
        const cpf = formik.values.cpfIrmao;
        if (cpf && isValidCPF(cpf)) {
        try {
          debugger
          const response = await getAlunosCpf(formik.values.cpfIrmao);
          if (response.statusCode === 200){
            fetchUnidades();
            formik.setFieldValue('unidadeIrmao', response?.data?.[0].unidadeId);
          }
        } catch (error) {
          console.error('Error fetching aluno:', error);
        }
      }
      else{
        formik.setFieldValue('unidadeIrmao', 0);
      }
      };

      fetchAluno();
    }
    }, [formik.values.cpfIrmao]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={unidadesChamadaPublica}
          getOptionLabel={(option) => option.nome}
          isOptionEqualToValue={(option, value) => option.id === value}
          value={unidadesChamadaPublica.find((opt) => opt.id === formik.values.opcao1) || null} // Bind to formik value
          onChange={(event, value) =>
            formik.setFieldValue('opcao1', value ? value.id : 0)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Unidade de Escolha"
              name="opcao1"
              onBlur={formik.handleBlur}
              error={formik.touched.opcao1 && Boolean(formik.errors.opcao1)}
              helperText={formik.touched.opcao1 && formik.errors.opcao1}
              required
            />
          )}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={escolhaBinaria}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.value === value}
          value={escolhaBinaria.find((opt) => opt.value === formik.values.possuiIrmao) || null} // Bind to formik value
          onChange={(event, value) =>
            formik.setFieldValue('possuiIrmao', value ? value.value : 0)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tem Irmão?"
              name="possuiIrmao"
              onBlur={formik.handleBlur}
              error={formik.touched.possuiIrmao && Boolean(formik.errors.possuiIrmao)}
              helperText={formik.touched.possuiIrmao && formik.errors.possuiIrmao}
              required
            />
          )}
          fullWidth
        />
      </Grid>

      {formik.values.possuiIrmao === 1 && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              label="CPF do Irmão"
              name="cpfIrmao"
              value={formik.values.cpfIrmao}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cpfIrmao && Boolean(formik.errors.cpfIrmao)}
              helperText={formik.touched.cpfIrmao && formik.errors.cpfIrmao}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Unidade do Irmão"
              value={
                unidades.find(
                  (unidade) => unidade.id === formik.values.unidadeIrmao
                )?.nome || ''
              }
              InputProps={{ readOnly: false }}
              fullWidth
              required
            />
          </Grid>
         
          {formik.values.cpfIrmao && formik.values.unidadeIrmao === 0 && (
             <Grid item xs={12}>
            <Alert severity="error">
              CPF do irmão não encontrado.
            </Alert>
          </Grid>
          )}
        </>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="termoDeclaracao"
              checked={formik.values.termoDeclaracao}
              onChange={formik.handleChange}
              required
            />
          }
          label="Declaro que estou ciente e concordo com os termos da resolução."
        />
        {formik.touched.termoDeclaracao && formik.errors.termoDeclaracao && (
          <Typography color="error">{formik.errors.termoDeclaracao}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Step5;
