import { useMemo, useState } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getProcessos, deleteProcesso } from '../../../../services/administrativo/financeiro-processos'
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import { listaAnexo } from "../../../../services/processos/remanejamento";

import { toast } from 'react-toastify';

const ProcessosTable = () => {
  const navigate = useNavigate();
  const [newData, setNewData] = useState([])

  const { data: processosData, refetch: refetchData } = useQuery('getProcessos', getProcessos, {
    retry: false,
    enabled: true,
    onSuccess: (data) => {
      setNewData(data.data)
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteProcesso, {
    onSuccess: () => {
      toast.success('Processo excluído com sucesso.');
      refetchData();
    },
    onError: () => {
      toast.error('Algo de errado aconteceu. Tente novamente mais tarde.')
    }
  })

  
const handleDownload = async (arquivoId) => {
  try {
    // Recebe o arquivo da função listaAnexo (agora como Blob)
    const arquivoBlob = await listaAnexo(arquivoId);

    // Gera uma URL para o Blob
    const blobUrl = URL.createObjectURL(arquivoBlob);

    const isOpen = window.open(blobUrl, "_blank");
    if (!isOpen) {
      alert("Permita pop-ups para visualizar/baixar o arquivo.");
    }

    window.open(blobUrl, "_blank");

    // Revogar a URL após abrir o arquivo para liberar memória
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
  }
};

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nomeProcesso' },
      { Header: 'Número', accessor: 'numeroProcesso' },
      {
        Header: 'Vencimento', accessor: 'dataVencimento',
        Cell: ({ row }) => (
          row.original.dataVencimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'Usuário', accessor: 'nomeUsuario' },
      {
        Header: 'Anexo', accessor: 'anexoId',
        Cell: ({ row }) => (
          <button
            className='btn btn-sm'
            style={{ padding: '8px' }}
            onClick={() => {
              handleDownload(row.original.anexoId)
            }}
            disabled={row.original.anexoId === '' || row.original.anexoId === null || !row.original.anexoId}
          >
            Baixar
          </button>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.financeiroProcessosCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                nomeProcesso: row.original.nomeProcesso,
                numeroProcesso: row.original.numeroProcesso,
                dataVencimento: row.original.dataVencimento.split('T')[0],
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Processos'} itens={['Administrativo', 'Financeiro', 'Processos']} 
      actions={{ link: urlsAdministrativo.financeiroProcessosCadastrar, text: "Novo Processo" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={newData} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ProcessosTable;
