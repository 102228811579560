import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, Paper, Box, Grid, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const VisualizacaoProva = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prova } = location.state || {};
  const printRef = useRef();

  // Handle PDF Export
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Prova_${prova.disciplina}`,
    onBeforePrint: () => {
      // Ensure background is white
      document.body.style.backgroundColor = "#fff";
    },
    onAfterPrint: () => {
      document.body.style.backgroundColor = ""; // Reset
    },
  });

  return (
    <Container maxWidth="lg" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      {/* Top Buttons */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          style={{ marginRight: "1rem" }}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PrintIcon />}
          onClick={handlePrint}
        >
          Imprimir
        </Button>
      </Box>

      {/* Printable Section */}
      <Box ref={printRef} style={{ backgroundColor: "#fff", padding: "1rem" }}>
        {/* Header Section */}
        <Paper style={{ padding: "1rem", marginBottom: "2rem" }} elevation={3}>
          <Grid container spacing={2} alignItems="center">
            {/* Logo */}
            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
              <img
                src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
                alt="Logo"
                style={{ maxWidth: "80px", height: "auto" }}
              />
            </Grid>

            {/* Centered Title */}
            <Grid item xs={12} sm={8} style={{ textAlign: "center" }}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                SECRETARIA MUNICIPAL DE EDUCAÇÃO
              </Typography>
              <Typography variant="subtitle1">
                <strong>{prova.escolaridade} - Ensino Fundamental</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={2}></Grid>
          </Grid>

          <Box mt={2}>
            <Typography variant="body1" align="center">
              Unidade Demonstração, ______ de{" "}
              {new Date().toLocaleString("default", { month: "long" })} de{" "}
              {new Date().getFullYear()}.
            </Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Professor (a): ____________________________
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body1">
                  Disciplina: <em>{prova.disciplina}</em>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body1">Turma: ___________</Typography>
              </Grid>
            </Grid>
            <Typography variant="body1" mt={2} marginBottom={2}>
              Nome Completo: ____________________________ Nº: ___________
            </Typography>
          </Box>
        </Paper>

        {/* Combined Questions */}
        <Paper style={{ padding: "1rem" }} elevation={2}>
          {prova.perguntasDissertativas.map((pergunta, index) => (
            <Box key={`dissertativa-${index}`} mb={3}>
              <Typography variant="body1">
                <strong>{index + 1}.</strong> {pergunta}
              </Typography>
              <Paper
                variant="outlined"
                style={{
                  marginTop: "0.5rem",
                  height: "100px",
                  backgroundColor: "#f9f9f9",
                }}
              />
            </Box>
          ))}

          {prova.perguntasMultiplaEscolha.map((pergunta, index) => (
            <Box key={`multipla-${index}`} mb={3}>
              <Typography variant="body1">
                <strong>
                  {index + 1 + prova.perguntasDissertativas.length}.
                </strong>{" "}
                {pergunta.pergunta}
              </Typography>
              <ul
                style={{
                  marginTop: "0.5rem",
                  listStyle: "none",
                  paddingLeft: 0,
                }}
              >
                {pergunta.opcoes.map((opcao, opcaoIndex) => (
                  <li
                    key={`opcao-${index}-${opcaoIndex}`}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Typography variant="body1">( ) {opcao}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Paper>
      </Box>
    </Container>
  );
};

export default VisualizacaoProva;
