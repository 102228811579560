import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../components/breadcrumb'
import Table from '../../../components/table'
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../../gestao/baseGestao';
import BaseDocente from '../../docente/baseDocente';
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import urlsGestao from '../../gestao/urlsGestao';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { useNavigate } from 'react-router-dom'
import { getRelatorioAlunos } from '../../../services/relatorios';
import {getAlunosFiltroEvadidos, restaurarAluno}  from '../../../services/alunos'
import Select from 'react-select';
import api from '../../../services/index'

import { getTurmasUnidadeEscolaridadeTurno } from '../../../services/turmas';

import { getEscolaridades as todasEscolaridades, getEscolaridadesUnidade } from '../../../services/escolaridades';

import { getTurnos as todosTurnos, getTurnosUnidadeEscolaridade } from '../../../services/turnos';

import {getUnidades} from '../../../services/unidades'
import { useQuery } from 'react-query'
import { store } from '../../../store';

const AlunosExcluidosPage = (gestao) => {


  const currentUser = store.getState()['user']['user']

  let BaseComponent;
  let BasePath;
  let editarUrl;
  let adicionarUrl;
  let evadirUrl;
  let aprovacaoUrl;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    editarUrl = urlsGestao.alunosEditar;
    aprovacaoUrl = urlsGestao.alunosAprovacao;
    adicionarUrl = urlsGestao.alunosAdicionar;
    evadirUrl = urlsGestao.alunosEvadir;
  } 
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    editarUrl = urlsGestaoUnidades.alunosEditar;
    aprovacaoUrl = urlsGestaoUnidades.alunosAprovacao;
    adicionarUrl = urlsGestaoUnidades.alunosAdicionar;
    evadirUrl = urlsGestaoUnidades.alunosEvadir;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
  }

  if (gestao.configuracao) {
    BaseComponent = BaseConfiguracoes;
    BasePath = 'Configurações'
  }

  // console.log(aprovacaoUrl)



  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [pesquisarResponsavel, setPesquisarResponsavel] = useState('')
  const [pesquisarAluno, setPesquisaAluno] = useState('')
  const [pesquisaCPF, setpesquisaCPF] = useState('')
  const [data, setData] = useState([])
  const [unidades, setUnidades] = useState([])
  const [openPicker, setOpenPicker] = useState(false);

  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

  // const anoLetivoAtual = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' ? 0 : 2024;

  const filtroAlunosAnoLetivo = localStorage.getItem('filtroAlunosAnoLetivo') || 2025;
  const filtroAlunosEscolaridade = localStorage.getItem('filtroAlunosEscolaridade') || 0;
  const filtroAlunosTurno = localStorage.getItem('filtroAlunosTurno') || 0;


  const filtroAlunosTurma = localStorage.getItem('filtroAlunosTurma') || 0;
  const filtroAlunosRenovacao = localStorage.getItem('filtroAlunosRenovacao') || 0;
  const filtroAlunosMatriculados = localStorage.getItem('filtroAlunosMatriculados') || 0;

  const [escolaridade, setEscolaridade] = useState(filtroAlunosEscolaridade)
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeId)
  const [turno, setTurno] = useState(filtroAlunosTurno)
  const [turma, setTurma] = useState(filtroAlunosTurma)
  const [matriculado, setMatriculado] = useState(filtroAlunosMatriculados)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [renovacao, setRenovacao] = useState(filtroAlunosRenovacao);
  const [anoLetivo, setAnoLetivo] = useState(filtroAlunosAnoLetivo);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);




  const { isError, isLoading, refetch } = useQuery(
    'alunos',
    () => getAlunosFiltroEvadidos(unidade,escolaridade,turno,turma, pesquisarAluno,pesquisaCPF,pesquisarResponsavel,skip,limit),
    {
      retry: 0,
      enabled: false,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

   useEffect(() => {
    refetchUnidades()
  }, [unidade])

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [{value: 0, label: 'TODOS'}];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidades(dados);
    }
  })


  // Filtros abaixo

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades
  } = useQuery(
    'getEscolaridades',
    () => ((unidade !== null && unidade > 0) ? getEscolaridadesUnidade(unidade, anoLetivo) : todasEscolaridades()),
    {
      retry: 0,
      enabled: false
    }
  )

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos
  } = useQuery(
    'getTurnos',
    () =>
      (unidade !== null && unidade != 0) && escolaridade !== 0
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade, anoLetivo)
        : todosTurnos(),
    {
      retry: 0,
      enabled: false
    }
  )

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas
  } = useQuery(
    'getTurmas',
    () =>
      unidade !== null && escolaridade !== null && turno !== null
        ? getTurmasUnidadeEscolaridadeTurno({unidadeId: unidade, escolaridadeId: escolaridade, turnoId: turno, anoLetivo})
        : null,
    {
      retry: 0,
      enabled: false
    }
  )



  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Status', accessor: 'status' },
      // {
      //   Header: 'Nascimento', accessor: 'nascimento',
      //   Cell: ({ row }) => (
      //     row.original.nascimento.split('T')[0].split('-').reverse().join('/')
      //   )
      // },
      // { Header: 'CPF', accessor: 'cpf' },
      // { Header: 'Matrícula', accessor: 'numeroMatricula' },
      { Header: 'Unidade', accessor: 'unidade' },
      {
        Header: 'Motivo', accessor: 'motivo',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.motivo}
          </span>
        )
      },
      // { Header: 'Escolaridade', accessor: 'escolaridade' },
      // {
      //   Header: 'PcD', accessor: 'pcd',
      //   Cell: ({ row }) => (
      //     <span className={`badge bg-${row.original.pne ? 'success' : 'danger'}`}>
      //       {row.original.pne ? 'SIM' : 'NÃO'}
      //     </span>
      //   )
      // },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            
           

            {row.original.status !== 2 && (
            <button
              onClick={() =>
                handleRestaurarAluno(row.original.id)
              }
              className="btn btn-sm btn-success"
            >
              <i className="material-icons md-edit_note"></i> restaurar
            </button>
            )}
          </>
        )
      }
    ],
    [navigate]
  )

  useEffect(() => {
    refetch()
    refetchEscolaridades()
    refetchTurnos()
    refetchTurmas()
  }, [refetch, limit, skip])

  const handleSearch = () => {
    refetch()
  }

  const handleRestaurarAluno = async (alunoId) => {

    let data = {
      alunoId: parseInt(alunoId),
      usuarioId: currentUser?.servidorId
    }
    await restaurarAluno(data)
    refetch()
  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={'Alunos Evadidos'}
        itens={[BasePath, 'Alunos Evadidos', 'Lista Geral']}
        // hasExcelButton 
        // onClickDownload={() => handleDownloadExcel()}
        // actions={{
        //   link: adicionarUrl,
        //   text: 'Novo Aluno'
        // }}
      />

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              isDisabled={gestao.configuracao ? false : true}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                setUnidade(e.value);
                await setEscolaridade(0)
                await setTurno(0)
                await setTurma(0)
                refetch()
                refetchEscolaridades()
              }
              }
            />
          </div>
        </div>

        

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaridadeID" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeID"
              value={escolaridade}
              onChange={async e => {
                await setEscolaridade(e.target.value)
                await setTurno(0)
                await setTurma(0)
                await refetchTurnos(0)
                await refetchTurmas(0)
                await localStorage.setItem('filtroAlunosEscolaridade', e.target.value);
                await localStorage.setItem('filtroAlunosTurno', 0);
                await localStorage.setItem('filtroAlunosTurma', 0);
                
                await refetch()
              }}
            >
              {loadingEscolaridades ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {escolaridades &&
                    escolaridades.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async e => {
                await setTurno(e.target.value)
                localStorage.setItem('filtroAlunosTurno', e.target.value);
                setTurma(0)
                localStorage.setItem('filtroAlunosTurma', e.target.value);
                // cleanFilters('turno')
                refetchTurmas()
                refetch()
              }}
            >
              {loadingTurnos ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turnos &&
                    turnos.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              value={turma}
              onChange={async e => {
                await setTurma(e.target.value)
                localStorage.setItem('filtroAlunosTurma', e.target.value);
                refetch()
              }}
            >
              {loadingTurmas ? (
                <option value=""></option>
              ) : (
                <>
                  <option value=""></option>
                  {turmas?.data &&
                    turmas?.data.map(item => (
                      
                     
                      <option key={item.id} value={item.id}>
                        {item.descricao} {unidade == 0 ? ' - ' + unidades.find(unidade => unidade.value === item.unidadeId)?.label || '' : ''}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        {/* <div className="col-12 col-md-1">
          <div className="form-group">
            <label htmlFor="matriculados" className="form-label">
              Matriculados?
            </label>
            <select
              className="form-select"
              id="matriculados"
              value={matriculado}
              onChange={async e => {
                await setMatriculado(e.target.value)
                localStorage.setItem('filtroAlunosMatriculados', e.target.value);
                refetch()
              }}
            >

                <>
                <option value={0}>TODOS</option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
                 
                </>

            </select>
          </div>
        </div> */}

      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >
        {/* <div
          className="form-group"
          style={{
            marginTop: 6,
            marginRight: 10,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="comMatricula"
            checked={comMatricula}
            onChange={e => setComMatricula(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="comMatricula">
            Com Matrícula
          </label>
        </div> */}
        {/* <div
          className="form-group"
          style={{
            marginTop: 6,
            marginRight: 10,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="inativos"
            checked={inativos}
            onChange={e => setInativos(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="inativos">
            Alunos Inativos
          </label>
        </div> */}

        <div className="form-group" style={{ width: '100%', maxWidth: 390, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            value={pesquisaCPF}
            onChange={e => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={e => setPesquisaAluno(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={e => setPesquisarResponsavel(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={['escolaridade', 'status', gestao.docente ? 'actions' : '']}
            />
          </div>
        </div>
      </div>
    </BaseComponent>
  )
}

export default AlunosExcluidosPage
