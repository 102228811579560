import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseGestao from "../../gestao/baseGestao";
import Breadcrumb from "../../../components/breadcrumb";
import { Controller, Form, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import FormInput from "../../../components/formInput";
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import { LocationApi } from "../../../services/external/cep";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { getEscolaridadesUnidade } from "../../../services/escolaridades";
import { getTurnosUnidadeEscolaridade } from "../../../services/turnos";
import { getTurmasUnidadeEscolaridadeTurno } from "../../../services/turmas";
import { getCidades, getEstados } from "../../../services/endereco";

import {
  getAluno,
  logsAluno,
  createAluno,
  updateAluno,
  putUpdateAluno,
  getAlunosFiltro,
  adicionarDocumento,
  listarDocumentosAluno,
  deletarDocumentosAluno,
} from "../../../services/alunos";
import { useQuery } from "react-query";
import Table from "../../../components/table";
import removeEmpty from "../../../utils/removeEmpty";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import Spinner from "../../../components/spinner";
import api from "../../../services";
import { validate as isCPFValid } from "cpf-check";
import { Alert, Button, Typography } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { getUnidades } from "../../../services/unidades";
import Header from "../../../components/header";
import { listaAnexo } from "../../../services/processos/remanejamento";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import AbaMatriculas from "./tab";
import AlunoUniforme from "./tab/uniforme";
import { cid10 } from "../cid10";

const AlunosAdicionarPage = (gestao) => {
  const [pne, setPne] = useState(null);

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [cpfEdicao, setCpfEdicao] = useState("");

  const validacaoCpf = id ? false : true;

  const [unidade, setUnidade] = useState(parseInt(localStorage.getItem("unidadeID")));
  const [escolaridade, setEscolaridade] = useState(0);
  const [turno, setTurno] = useState(0);
  const [turma, setTurma] = useState(0);
  const [historicoData, setHistoricoData] = useState([]);

  const [loadingEscolaridades, setLoadingEscolaridades] = useState(true);
  const [loadingTurnos, setLoadingTurnos] = useState(true);
  const [loadingTurmas, setLoadingTurmas] = useState(true);

  const [dataEscolaridade, setDataEscolaridade] = useState([]);
  const [dataTurno, setDataTurno] = useState([]);
  const [dataTurma, setDataTurma] = useState([]);

  const [enableAluno, setEnableAluno] = useState(id ? true : false);
  const [enableAlunoLog, setEnableAlunoLog] = useState(true);
  const [ocultaBotaEnviar, setOcultaBotaoEnviar] = useState(false);

  const [segundoResponsavel, setSegundoResponsavel] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);

  const [renovacao, setRenovacao] = useState(false);
  const deficiencias = [
    { value: 0, text: "" },
    { value: 1, text: "DEFICIENCIAS MÚLTIPLAS" },
    { value: 2, text: "DEFICIÊNCIA INTELECTUAL" },
    { value: 3, text: "DEFICIÊNCIA VISUAL" },
    { value: 4, text: "DEFICIÊNCIA AUDITIVA" },
    { value: 5, text: "DEFICIÊNCIA FÍSICA" },
    { value: 6, text: "TRANSTORNO DO ESPECTRO AUTISTA (TEA)" },
    { value: 7, text: "ALTAS HABILIDADES" },
    { value: 8, text: "OUTRA" },
  ];

  const escolhas = [
    { value: "", text: "" },
    { value: 1, text: "SIM" },
    { value: 0, text: "NÃO" },
  ];

  const generos = [
    { value: 0, text: "" },
    { value: 1, text: "MASCULINO" },
    { value: 2, text: "FEMININO" },
  ];

  const etnias = [
    { value: 0, text: "" },
    { value: 1, text: "PRETO" },
    { value: 2, text: "PARDO" },
    { value: 3, text: "BRANCO" },
    { value: 4, text: "AMARELO" },
    { value: 5, text: "INDÍGENA" },
    // { value: 6, text: "QUILOMBOLA" },
  ];

  const [documents, setDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState("");

  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === "ARARUAMA") {
      import("../pickersAraruama").then((mod) => {
        setPne(mod.pne); // Assuming pne is what you want to use
      });
    } else {
      import("../pickersSaoPedro").then((mod) => {
        setPne(mod.pne); // Adjust according to the actual export
      });
    }
  }, []);

  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(
        response?.map((estado) => ({
          label: estado.descricao,
          value: estado.descricao,
          id: estado.id,
        }))
      );
    };
    fetchEstados();
  }, []);

  const tiposSanguineos = [
    { value: 0, text: "" },
    { value: 1, text: "A+" },
    { value: 2, text: "A-" },
    { value: 3, text: "B+" },
    { value: 4, text: "B-" },
    { value: 5, text: "AB+" },
    { value: 6, text: "AB-" },
    { value: 7, text: "O+" },
    { value: 8, text: "O-" },
  ];

  const {
    data2,
    refetch: refetchDocumentos,
    isLoading,
  } = useQuery(
    "getAlunoDocumentos",
    () => id !== undefined && listarDocumentosAluno(id),
    {
      retry: false,
      enabled: true,

      onSuccess: async (data) => {
        console.log(data?.data);
        setDocuments(data?.data);
      },
    }
  );

  const { data, loadingAluno } = useQuery(
    "getAluno",
    () => id !== undefined && getAluno(id),
    {
      retry: false,
      enabled: enableAluno,
      onSuccess: async (data) => {
        setValue("nome", data?.nome);
        setValue("cpf", data?.cpf);
        setCpfEdicao(data?.cpf);
        setValue("rg", data?.rg);
        setValue(
          "numeroCarteiraNascimento",
          formatDate(data?.numeroCarteiraNascimento)
        );
        setValue("numeroCarteiraSus", data?.numeroCarteiraSus);
        setValue("deficiente", data?.pcD_PNE == 1 ? 1 : 0);
        setValue("deficiencia", data?.deficiencia);
        setValue("cid10", data?.cid10);
        setValue("filiacao1", data?.filiacao1);
        setValue("filiacao2", data?.filiacao2);
        setValue("escolaridadeID", data?.escolaridadeID);
        setValue("turnoID", data?.turnoID);
        setValue("turmaID", data?.turmaID);
        setValue("numeroMatricula", data?.numeroMatricula);
        setValue("ultimaAtualizacao", formatDate(data?.ultimaAtualizacao));
        setValue("criacao", formatDate(data?.criacao));

        // ENDEREÇO
        setValue("cep", data?.endereco?.cep);
        setValue("logradouro", data?.endereco?.logradouro);
        setValue("numero", data?.endereco?.numero);
        setValue("complemento", data?.endereco?.complemento);
        setValue("bairro", data?.endereco?.bairro);
        setValue("estado", data?.endereco?.estado);
        setValue("cidade", data?.endereco?.cidade);
        setValue(
          "utilizaTransporteEscolar",
          data?.endereco?.utilizaTransporte == true ? 1 : 0
        );

        // TRANSPORTE ESCOLAR
        setValue("desembarqueNome", data?.transporte?.desembarqueNome);
        setValue(
          "desembarqueParentesco",
          data?.transporte?.desembarqueParentesco
        );
        setValue("desembarqueTelefone", data?.transporte?.desembarqueTelefone);
        setValue("desembarqueCep", data?.transporte?.desembarqueCep);
        setValue(
          "desembarqueLogradouro",
          data?.transporte?.desembarqueLogradouro
        );
        setValue("desembarqueNumero", data?.transporte?.desembarqueNumero);
        setValue("desembarqueBairro", data?.transporte?.desembarqueBairro);
        setValue(
          "desembarqueComplemento",
          data?.transporte?.desembarqueComplemento
        );
        setValue("desembarqueEstado", data?.transporte?.desembarqueEstado);
        setValue("desembarqueCidade", data?.transporte?.desembarqueCidade);

        setValue("desembarqueNome2", data?.transporte?.desembarqueNome2);
        setValue(
          "desembarqueParentesco2",
          data?.transporte?.desembarqueParentesco2
        );
        setValue(
          "desembarqueTelefone2",
          data?.transporte?.desembarqueTelefone2
        );
        setValue("desembarqueCep2", data?.transporte?.desembarqueCep2);
        setValue(
          "desembarqueLogradouro2",
          data?.transporte?.desembarqueLogradouro2
        );
        setValue("desembarqueNumero2", data?.transporte?.desembarqueNumero2);
        setValue("desembarqueBairro2", data?.transporte?.desembarqueBairro2);
        setValue(
          "desembarqueComplemento2",
          data?.transporte?.desembarqueComplemento2
        );
        setValue("desembarqueEstado2", data?.transporte?.desembarqueEstado2);
        setValue("desembarqueCidade2", data?.transporte?.desembarqueCidade2);
        setValue("emergenciaNome", data?.transporte?.emergenciaNome);
        setValue(
          "emergenciaParentesco",
          data?.transporte?.emergenciaParentesco
        );
        setValue("emergenciaTelefone", data?.transporte?.emergenciaTelefone);
        setValue("tipoSanguineo", data?.transporte?.tipoSanguineo);
        setValue(
          "possuiAlergiaMedicamentos",
          data?.transporte?.possuiAlergiaMedicamentos
        );
        setValue("medicamentos", data?.transporte?.medicamentos);
        setValue("rota", data?.transporte?.rota);
        setValue("pontoDeReferencia", data?.transporte?.pontoDeReferencia);

        // RESPONSÁVEL
        setValue("responsavelCpf", data?.responsavel?.cpf);
        setValue(
          "responsavelNascimento",
          formatDate(data?.responsavel?.nascimento)
        );
        setValue("responsavelNome", data?.responsavel?.nome);
        setValue("responsavelTelefone", data?.responsavel?.telefone);

        // SOCIAS
        setValue(
          "beneficiarioProgramaSocial",
          data?.social?.beneficiarioProgramaSocial
        );
        setValue("nis", data?.social?.nis);
        setValue("identificacaoUnica", data?.social?.indentificacaoUnica);
        setValue("naturalidadeEstado", data?.naturalidadeEstado);
        setValue("naturalidadeCidade", data?.naturalidadeCidade);
        setValue("nomeSocial", data?.nomeSocial);
        setValue("sexo", data?.sexo);
        setValue("etnia", data?.etnia);

        setUnidade(data?.unidadeID);
        setValue("unidadeID", data?.unidadeID);
        setEscolaridade(data?.escolaridadeID);
        setTurno(data?.turnoID);
        setTurma(data?.turmaID);

        console.log(turma);

        fetchSelect(data?.unidadeID, data?.escolaridadeID, data?.turnoID);

        setEnableAluno(false);
      },
    }
  );

  useQuery("logsAluno", () => id !== undefined && logsAluno(id), {
    enabled: enableAlunoLog,
    retry: true,
    onSuccess: (data) => {
      setHistoricoData(data);
      setEnableAlunoLog(false);
    },
  });

  const { data: unidadesData } = useQuery("getUnidades", () => getUnidades(), {
    enabled: true,
    retry: true,
  });

  const unidadeDoAluno = unidadesData?.find((x) => x.id == unidade)?.nome;

  function formatDate(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string for null or undefined input
    }

    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function zipcodeMask(value) {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d{3})$/, "$1-$2");
    return value;
  }

  function handleRemoverSegundoResponsavel() {
    setValue("desembarqueNome2", "");
    setValue("desembarqueParentesco2", "");
    setValue("desembarqueTelefone2", "");
    setValue("desembarqueCep2", "");
    setValue("desembarqueLogradouro2", "");
    setValue("desembarqueNumero2", "");
    setValue("desembarqueBairro2", "");
    setValue("desembarqueComplemento2", "");
    setValue("desembarqueEstado2", "");
    setValue("desembarqueCidade2", "");
    setSegundoResponsavel(false);
  }

  const { current: locationApi } = useRef(new LocationApi());
  const { current: controller } = useRef(new AbortController());

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: "",
      nome: "",
      cpf: "",
      rg: "",
      numeroCarteiraNascimento: "",
      numeroCarteiraSus: "",
      deficiente: 0,
      deficiencia: 0,
      cid10: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      utilizaTransporteEscolar: 0,
      responsavelNome: "",
      responsavelCpf: "",
      responsavelNascimento: "",
      responsavelTelefone: "",
      filiacao1: "",
      filiacao2: "",
      escolaridadeID: 0,
      turnoID: 0,
      turmaID: 0,
      unidadeID: unidade,
      identificacaoUnica: "",
      beneficiarioProgramaSocial: 0,
      nis: "",
      desembarqueNome: "",
      desembarqueParentesco: "",
      desembarqueTelefone: "",
      desembarqueCep: "",
      desembarqueLogradouro: "",
      desembarqueNumero: "",
      desembarqueBairro: "",
      desembarqueComplemento: "",
      desembarqueEstado: "",
      desembarqueCidade: "",
      desembarqueNome2: "",
      desembarqueParentesco2: "",
      desembarqueTelefone2: "",
      desembarqueCep2: "",
      desembarqueLogradouro2: "",
      desembarqueNumero2: "",
      desembarqueBairro2: "",
      desembarqueComplemento2: "",
      desembarqueEstado2: "",
      desembarqueCidade2: "",
      emergenciaNome: "",
      emergenciaParentesco: "",
      emergenciaTelefone: "",
      tipoSanguineo: "",
      possuiAlergiaMedicamentos: 0,
      medicamentos: "",
      rota: "",
      pontoDeReferencia: "",
      naturalidadeEstado: "",
      naturalidadeCidade: "",
      nomeSocial: "",
      sexo: "",
      etnia: 0,
    },
  });

  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = cid10
        .filter((item) =>
          item.text.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          value: item.cod,
          label: item.text,
        }));
      resolve(filteredOptions);
    });
  };

  const anoLetivo =
    state && state.aluno && state.aluno.status === 1 ? 2023 : 
    state && state.aluno && state.aluno.status === 2 ? 2024 : 2025;

  async function fetchSelect(unidade = 0, escolaridade = 0, turno = 0) {
    if (id === undefined) {
      await fetchEscolaridade(unidade);
    } else {
      await fetchEscolaridade(unidade);
      if (escolaridade > 0) await fetchTurno(unidade, escolaridade);

      if (escolaridade > 0 && turno > 0)
        await fetchTurma(unidade, escolaridade, turno);
    }
  }

  async function fetchEscolaridade(unidade) {
    if (unidade != 0) {
      var response = await getEscolaridadesUnidade(unidade, anoLetivo);
      setLoadingEscolaridades(false);
      setDataEscolaridade(response || []);
    }
  }

  async function fetchTurno(unidade, escolaridade) {
    if (unidade != 0 && escolaridade != 0) {
      var response = await getTurnosUnidadeEscolaridade(unidade, escolaridade, anoLetivo);
      setLoadingTurnos(false);
      setDataTurno(response || []);
    }
  }

  async function fetchTurma(unidade, escolaridade, turno) {
    if (unidade != 0 && escolaridade != 0 && turno != 0) {
      var response = await getTurmasUnidadeEscolaridadeTurno(
        {unidadeId: unidade,
        escolaridadeId: escolaridade,
        turnoId: turno,
        anoLetivo}
      );
      setLoadingTurmas(false);
      setDataTurma(response?.data || []);
    }
  }

  useEffect(() => {
    if (id === undefined) {
      fetchSelect(unidade);
      setLoadingTurnos(false);
      setLoadingTurmas(false);
    }
  }, []);

  const onSubmit = async (values) => {
    console.log(values);
    const convertedValues = { ...values };
    Object.keys(convertedValues).forEach((key) => {
      if (
        typeof convertedValues[key] === "string" &&
        (convertedValues[key].toLowerCase() === "true" ||
          convertedValues[key].toLowerCase() === "false")
      ) {
        convertedValues[key] = convertedValues[key].toLowerCase() === "true";
      }
    });

    values.deficiente = parseInt(values.deficiente);

    if (parseInt(values.turnoID) === 0) {
      return toast.error("O campo turno é obrigatório.");
    }

    if (parseInt(values.escolaridadeID) === 0) {
      return toast.error("O campo escolaridade é obrigatório.");
    }

    if (parseInt(values.turmaID) === 0) {
      return toast.error("O campo turma é obrigatório.");
    }

    if (id) {
      try {
        await putUpdateAluno({
          id: id,
          data: removeEmpty(upperCaseSimple(values)),
        });

        toast.success("Operação realizada com sucesso.");
        if (sessionStorage.getItem("duplicados")) {
          navigate(urlsConfiguracoes.alunosDuplicado);
        } else {
          navigate(-1);
        }
      } catch (error) {
        // Handle errors if needed
        toast.error("Ocorreu um erro, tente novamente mais tarde.");
      }
    } else {
      try {
        await createAluno(values);

        toast.success("Operação realizada com sucesso.");
        if (sessionStorage.getItem("duplicados")) {
          navigate(urlsConfiguracoes.alunosDuplicado);
        } else {
          navigate(-1);
        }
      } catch (error) {
        toast.error("Ocorreu um erro, tente novamente mais tarde.");
      }
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("arquivo", selectedFile);
      formData.append("alunoId", id);
      formData.append("tipo", documentType);
      await adicionarDocumento(formData);
      // setDocuments([...documents, newDocument]);

      await refetchDocumentos();
      toast.success("Documento enviado com sucesso.");
      document.getElementById("fileUpload").value = "";
      setSelectedFile(null);
      setDocumentType("");
    } else {
      toast.error("Selecione um arquivo para enviar.");
    }
  };

  const handleDelete = async (id) => {
    await deletarDocumentosAluno(id);
    refetchDocumentos();
  };

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
          {habilitaExcluir && (
            <Dropdown.Item
              className="dropdown-item-danger d-flex"
              onClick={() => handleDelete(row.original.arquivoId)}
            >
              <i className="material-icons md-delete pe-2"></i> Excluir
            </Dropdown.Item>
          )}
          {habilitaVisualizar && (
            <Dropdown.Item
              className="dropdown-item-secondary d-flex"
              onClick={() =>
                handleDownload(row.original.arquivoId, row.original.extensao)
              }
            >
              <i className="material-icons md-edit_note pe-2"></i> Visualizar
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleDownload = async (arquivoId, extensao) => {
    try {

      // Recebe o arquivo da função listaAnexo (agora como Blob)
      const arquivoBlob = await listaAnexo(arquivoId);

      // Gera uma URL para o Blob
      const blobUrl = URL.createObjectURL(arquivoBlob);

      // Verificar a extensão e abrir o arquivo em uma nova aba
      if (extensao === "application/pdf") {
        const pdfWindow = window.open(blobUrl, "_blank");
        if (!pdfWindow) {
          alert("Permita pop-ups para visualizar o PDF.");
        }
      } else if (extensao === "image/png") {
        const imgWindow = window.open(blobUrl, "_blank");
        if (!imgWindow) {
          alert("Permita pop-ups para visualizar a imagem.");
        }
      } else {
        // Para outros tipos de arquivos, abre uma nova aba
        const newWindow = window.open(blobUrl, "_blank");
        if (!newWindow) {
          alert("Permita pop-ups para visualizar o arquivo.");
        }
      }

      // Revogar a URL após abrir o arquivo para liberar memória
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  const habilitaExcluir = gestao.gestao ? true : false;
  const habilitaVisualizar = gestao.gestao ? true : true;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const selectedCod = watch("cid10");

  useEffect(() => {
    const cep = zipcodeMask(watch("cep")).replace(/\D/, "");
    const cepData = zipcodeMask(data?.endereco?.cep).replace(/\D/, "");
    if (cep != cepData) {
      handlAutoCompleteForm(cep);
    }
  }, [watch, watch("cep")]);

  useEffect(() => {
    const desembarqueCep = zipcodeMask(watch("desembarqueCep")).replace(
      /\D/,
      ""
    );
    const desembarqueCepData = zipcodeMask(
      data?.transporte.desembarqueCep
    ).replace(/\D/, "");
    if (desembarqueCep !== desembarqueCepData) {
      handlAutoCompleteFormDesembarque(desembarqueCep);
    }
  }, [watch, watch("desembarqueCep")]);

  useEffect(() => {
    const desembarqueCep2 = zipcodeMask(watch("desembarqueCep2")).replace(
      /\D/,
      ""
    );
    const desembarqueCepData2 = zipcodeMask(
      data?.transporte.desembarqueCep2
    ).replace(/\D/, "");

    if (desembarqueCep2 !== desembarqueCepData2) {
      handlAutoCompleteFormDesembarque2(desembarqueCep2);
    }
  }, [watch, watch("desembarqueCep2")]);

  useEffect(() => {
    const checkCpf = async () => {
      setOcultaBotaoEnviar(false);
      const cpf = watch("cpf"); // Assuming 'watch' is correctly defined in your component's context

      if (
        cpf.length === 14 &&
        isCPFValid(cpf) &&
        cpf.replace(/\D/g, "") !== cpfEdicao.replace(/\D/g, "")
      ) {
        try {
          const response = await getAlunosFiltro(
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            cpf.replace(/\D/g, ""),
            "",
            0,
            1,
            0
          );
          const id = response?.data;

          if (id.length > 0) {
            toast.error("CPF já cadatrado anteriormente."); // Assuming 'toast' is correctly defined and imported
            setOcultaBotaoEnviar(true); // Assuming 'setExibeAvisoCpf' is correctly defined
          }
        } catch (error) {
          // Handle any errors here, such as network issues or API failures
          // console.error('Error fetching data: ', error);
        }
      }
      // else if (cpf.length === 14) {
      //   toast.error("CPF informado inválido.");
      // }
    };

    checkCpf();
  }, [watch("cpf")]);

  useEffect(() => {
    const fetchCidades = async () => {
      const estadoId = estados.find(
        (estado) => estado.label === watch("naturalidadeEstado")
      )?.id;
      if (estadoId) {
        const response = await getCidades(estadoId);
        const sortedCidades = response?.sort((a, b) =>
          a.nome.localeCompare(b.nome)
        );
        setCidades(
          sortedCidades?.map((cidade) => ({
            label: cidade.nome,
            value: cidade.nome,
          }))
        );

        // Set the current city value only if it's available in the fetched cities
        const currentCity = watch("naturalidadeCidade");
        if (
          currentCity &&
          sortedCidades.some((cidade) => cidade.nome === currentCity)
        ) {
          setValue("naturalidadeCidade", currentCity);
        } else {
          setValue("naturalidadeCidade", ""); // Clear if not valid
        }
      }
    };

    if (watch("naturalidadeEstado")) {
      fetchCidades();
    }
  }, [watch("naturalidadeEstado")]);

  const handlAutoCompleteForm = useCallback(async (cep) => {
    if (cep.length < 8) return;
    try {
      const data = await locationApi.searchByCep(cep, controller.signal);
      let cidade = data?.data.data.cidade;
      let uf = data?.data.data.uf;
      let bairro = data?.data.data.bairro;
      let logradouro = data?.data.data.logradouro;

      if (data.status === 400) {
        toast.warn(data?.data.message);
      }

      setValue("bairro", bairro, {
        // shouldValidate: true,
      });
      setValue("cidade", cidade, {
        // shouldValidate: true,
      });
      setValue("estado", uf, {
        // shouldValidate: true,
      });
      setValue("logradouro", logradouro, {
        // shouldValidate: true,
      });
    } catch (error) {
      toast.error(
        "Não foi possível carregar o endereço, cep não encontrado na base de dados do correio."
      );
    }
  });

  const handlAutoCompleteFormDesembarque = useCallback(
    async (desembarqueCep) => {
      if (desembarqueCep.length < 8) return;
      try {
        const data = await locationApi.searchByCep(
          desembarqueCep,
          controller.signal
        );
        let desembarqueCidade = data?.data?.data?.cidade;
        let desembarqueUf = data?.data?.data?.uf;
        let desembarqueBairro = data?.data?.data?.bairro;
        let desembarqueLogradouro = data?.data?.data?.logradouro;

        if (data.status === 400) {
          toast.warn(data?.data?.message);
        }

        setValue("desembarqueBairro", desembarqueBairro, {
          // shouldValidate: true,
        });
        setValue("desembarqueCidade", desembarqueCidade, {
          // shouldValidate: true,
        });
        setValue("desembarqueEstado", desembarqueUf, {
          // shouldValidate: true,
        });
        setValue("desembarqueLogradouro", desembarqueLogradouro, {
          // shouldValidate: true,
        });
      } catch (error) {
        toast.error(
          "Não foi possível carregar o endereço, cep não encontrado na base de dados do correio."
        );
      }
    }
  );

  const handlAutoCompleteFormDesembarque2 = useCallback(
    async (desembarqueCep2) => {
      if (desembarqueCep2.length < 8) return;
      try {
        const data = await locationApi.searchByCep(
          desembarqueCep2,
          controller.signal
        );
        let desembarqueCidade2 = data?.data?.data?.cidade;
        let desembarqueUf2 = data?.data?.data?.uf;
        let desembarqueBairro2 = data?.data?.data?.bairro;
        let desembarqueLogradouro2 = data?.data?.data?.logradouro;

        if (data.status === 400) {
          toast.warn(data?.data?.message);
        }

        setValue("desembarqueBairro2", desembarqueBairro2, {
          // shouldValidate: true,
        });
        setValue("desembarqueCidade2", desembarqueCidade2, {
          // shouldValidate: true,
        });
        setValue("desembarqueEstado2", desembarqueUf2, {
          // shouldValidate: true,
        });
        setValue("desembarqueLogradouro2", desembarqueLogradouro2, {
          // shouldValidate: true,
        });
      } catch (error) {
        toast.error(
          "Não foi possível carregar o endereço, cep não encontrado na base de dados do correio."
        );
      }
    }
  );

  useEffect(() => {
    const checkSecondResponsible = () => {
      const desembarqueNome2 = watch("desembarqueNome2");
      const desembarqueParentesco2 = watch("desembarqueParentesco2");
      const desembarqueTelefone2 = watch("desembarqueTelefone2");
      const desembarqueCep2 = watch("desembarqueCep2");
      const desembarqueLogradouro2 = watch("desembarqueLogradouro2");
      const desembarqueNumero2 = watch("desembarqueNumero2");
      const desembarqueBairro2 = watch("desembarqueBairro2");
      const desembarqueComplemento2 = watch("desembarqueComplemento2");
      const desembarqueEstado2 = watch("desembarqueEstado2");
      const desembarqueCidade2 = watch("desembarqueCidade2");

      if (
        desembarqueNome2 ||
        desembarqueParentesco2 ||
        desembarqueTelefone2 ||
        desembarqueCep2 ||
        desembarqueLogradouro2 ||
        desembarqueNumero2 ||
        desembarqueBairro2 ||
        desembarqueComplemento2 ||
        desembarqueEstado2 ||
        desembarqueCidade2
      ) {
        setSegundoResponsavel(true);
      }
    };

    checkSecondResponsible();
  }, [
    watch,
    watch("desembarqueNome2"),
    watch("desembarqueParentesco2"),
    watch("desembarqueTelefone2"),
    watch("desembarqueCep2"),
    watch("desembarqueLogradouro2"),
    watch("desembarqueNumero2"),
    watch("desembarqueBairro2"),
    watch("desembarqueComplemento2"),
    watch("desembarqueEstado2"),
    watch("desembarqueCidade2"),
  ]);

  const collumnsDocumentos = useMemo(() => [
    {
      Header: "ID",
      accessor: "arquivoId",
    },
    {
      Header: "Data de Envio",
      accessor: "dataCriacao",
      Cell: ({ row }) =>
        row.original.dataCriacao.split("T")[0].split("-").reverse().join("/") +
        " às " +
        row.original.dataCriacao.split("T")[1].split(".")[0],
    },
    {
      Header: "Tipo de Documento",
      accessor: "tipo",
    },
    {
      Header: "Usuário",
      accessor: "usuarioLogin",
    },
    {
      Header: "Tamanho",
      accessor: "tamanho",
      Cell: ({ value }) => {
        const sizeInKB = value / 1024;
        if (sizeInKB > 1000) {
          const sizeInMB = sizeInKB / 1024;
          return `${sizeInMB.toFixed(2)} MB`;
        } else {
          return `${sizeInKB.toFixed(2)} KB`;
        }
      },
    },
    {
      Header: "Extensão",
      accessor: "extensao",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            onClick={() =>
              handleDownload(row.original.arquivoId, row.original.extensao)
            }
          >
            Visualizar
          </Button>

          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(row.original.arquivoId)}
            style={{ marginLeft: "10px" }} // Add space between buttons
          >
            Deletar
          </Button>
        </>
      ),
    },
  ]);

  const columnsUnidades = useMemo(
    () => [
      {
        Header: "Data Evento",
        accessor: "criacao",
        Cell: ({ row }) =>
          row.original.criacao.split("T")[0].split("-").reverse().join("/") +
          " às " +
          row.original.criacao.split("T")[1].split(".")[0],
      },
      { Header: "Título", accessor: "titulo" },
      { Header: "Descrição", accessor: "descricao" },
      { Header: "Usuário", accessor: "usuarioLogin" },
    ],
    [navigate]
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={id ? "EDIÇÃO DE ALUNO" : "CADASTRO DE ALUNO"}
        itens={[BasePath, "Alunos", id ? "Edição" : "Cadastro"]}
      />

     

      {loadingAluno ? (
        <Spinner />
      ) : (
        <>
          <form id="form" onSubmit={handleSubmit(onSubmit)}>
        
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mailbox-open-content col-md-12 m-0 p-3">
                      <ul
                        className="nav nav-tabs"
                        id="servidoresTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="dados-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#dados"
                            type="button"
                            role="tab"
                            aria-controls="dados"
                            aria-selected="true"
                          >
                            DADOS GERAIS
                          </button>
                        </li>
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ display: id ? "block" : "none" }}
                        >
                          <button
                            className="nav-link"
                            id="historico-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#historico"
                            type="button"
                            role="tab"
                            aria-controls="historico"
                            aria-selected="true"
                          >
                            HISTÓRICO
                          </button>
                        </li>
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ display: id ? "block" : "none" }}
                        >
                          <button
                            className="nav-link"
                            id="arquivos-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#arquivos"
                            type="button"
                            role="tab"
                            aria-controls="arquivos"
                            aria-selected="true"
                          >
                            DOCUMENTOS
                          </button>
                        </li>

                        {id && data?.renovacao == false && (
                          <>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="matriculas-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#matriculas"
                                type="button"
                                role="tab"
                                aria-controls="matriculas"
                                aria-selected="false"
                              >
                                MATRÍCULAS
                              </button>
                            </li>
                          </>
                        )}
                        {id && (
                          <>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="uniforme-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#uniforme"
                                type="button"
                                role="tab"
                                aria-controls="uniforme"
                                aria-selected="false"
                              >
                                UNIFORME
                              </button>
                            </li>
                          </>
                        )}
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="dados"
                          role="tabpanel"
                          aria-labelledby="dados-tab"
                        >
                          {id && (
                            <div className="row" style={{ marginTop: "20px" }}>
                              <div className="col-md-4">
                                <FormInput
                                  register={register}
                                  errors={errors}
                                  atribute="numeroMatricula"
                                  label="Matrícula"
                                  readOnly={true}
                                  required={false}
                                />
                              </div>
                              <div className="col-md-4">
                                <FormInput
                                  register={register}
                                  errors={errors}
                                  atribute="criacao"
                                  type="date"
                                  label="Data Criação"
                                  readOnly={true}
                                  required={false}
                                />
                              </div>
                              <div className="col-md-4">
                                <FormInput
                                  register={register}
                                  errors={errors}
                                  type="date"
                                  atribute="ultimaAtualizacao"
                                  label="Última Atualização"
                                  readOnly={true}
                                  required={false}
                                />
                              </div>
                            </div>
                          )}
                          <h4
                            style={{ marginTop: "20px", marginBottom: "1px" }}
                          >
                            DADOS PESSOAIS
                          </h4>
                          <hr
                            style={{
                              background: "#000",
                              color: "#000",
                              borderColor: "#00",
                              height: "1px",
                            }}
                          />
                          {!id && (
                            <Alert variant="warning" style={{backgroundColor: 'gray', color: 'white'}}>Alunos cadastrados manualmente serão enviados para aprovação da Secretaria de Educação.</Alert>
                          )}
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="nome"
                                label="Nome"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="nomeSocial"
                                label="Nome Social"
                                required={false}
                              />
                            </div>

                            <div className="col-md-2">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="cpf"
                                mask="999.999.999-99"
                                label="CPF"
                              />
                            </div>

                            <div className="col-md-2">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="rg"
                                label="RG"
                                required={false}
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="numeroCarteiraNascimento"
                                label="Data Nascimento"
                                type="date"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="numeroCarteiraSus"
                                label="Carteira SUS"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  PcD?
                                  <span
                                    style={{ color: "red", marginLeft: "4px" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <select
                                  className="form-select"
                                  id="deficiente"
                                  {...register("deficiente", {
                                    required: false, valueAsNumber: true
                                  })}
                                >
                                  {escolhas?.map((entry) => (
                                    <option key={entry.value} value={entry.value}>
                                      {entry.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: 20 }}>
                            {watch("deficiente") == 1 && (
                              <>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label htmlFor="deficiencia">
                                      Deficiência
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: "4px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="deficiencia"
                                      {...register("deficiencia", {
                                        required:
                                          watch("deficiente") == 1
                                            ? true
                                            : false,
                                        valueAsNumber: true,
                                      })}
                                      defaultValue={0}
                                    >
                                      {deficiencias.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.text}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label htmlFor="cid10">CID-10</label>

                                    <Controller
                                      name="cid10"
                                      control={control}
                                      render={({ field }) => (
                                        <AsyncSelect
                                          {...field}
                                          cacheOptions
                                          loadOptions={loadOptions}
                                          isClearable
                                          noOptionsMessage={() =>
                                            "Digite um código CID"
                                          }
                                          placeholder="Digite o Código do CID"
                                          onChange={(selectedOption) => {
                                            setValue(
                                              "cid10",
                                              selectedOption
                                                ? selectedOption.value
                                                : ""
                                            );
                                          }}
                                          value={
                                            selectedCod
                                              ? {
                                                  value: selectedCod,
                                                  label:
                                                    cid10.find(
                                                      (item) =>
                                                        item.cod === selectedCod
                                                    )?.text || "",
                                                }
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="filiacao1"
                                label="Filiação 1"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="filiacao2"
                                label="Filiação 2"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Unidade</label>
                                <input
                                  className="form-control"
                                  value={unidadeDoAluno}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="escolaridadeID">
                                  Escolaridades
                                </label>
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                                <select
                                  className="form-select"
                                  id="escolaridadeID"
                                  required={true}
                                  value={escolaridade}
                                  onChange={async (e) => {
                                    setEscolaridade(e.target.value);
                                    setTurno(0);
                                    setTurma(0);
                                    setValue("escolaridadeID", parseInt(e.target.value));
                                    setValue("turnoID", 0);
                                    setValue("turmaID", 0);
                                    setLoadingTurnos(true);
                                    await fetchTurno(unidade, e.target.value);
                                    setDataTurma([]);
                                  }}
                                >
                                  {
                                    <>
                                      {loadingEscolaridades ? (
                                        <option value={0}>Carregando...</option>
                                      ) : (
                                        <option value={0}></option>
                                      )}
                                      {dataEscolaridade &&
                                        dataEscolaridade.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.descricao}
                                          </option>
                                        ))}
                                    </>
                                  }
                                </select>
                                {errors.escolaridadeID && (
                                  <span className="text-danger">
                                    Campo obrigatório
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="turnoID">Turnos</label>
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                                <select
                                  className="form-select"
                                  id="turnoID"
                                  required={true}
                                  value={turno}
                                  onChange={async (e) => {
                                    setTurno(e.target.value);
                                    setTurma(0);
                                    setValue("turnoID", parseInt(e.target.value));
                                    setValue("turmaID", 0);
                                    setLoadingTurmas(true);
                                    await fetchTurma(
                                      unidade,
                                      escolaridade,
                                      e.target.value
                                    );
                                  }}
                                >
                                  {
                                    <>
                                      {loadingTurnos ? (
                                        <option value={0}>Carregando...</option>
                                      ) : (
                                        <option value={0}></option>
                                      )}
                                      {dataTurno &&
                                        dataTurno.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.descricao}
                                          </option>
                                        ))}
                                    </>
                                  }
                                </select>
                                {errors.turnoID && (
                                  <span className="text-danger">
                                    Campo obrigatório
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="turmaID">
                                  Turmas {anoLetivo}
                                </label>

                                <select
                                  className="form-select"
                                  id="turmaID"
                                  required={true}
                                  value={turma}
                                  onChange={async (e) => {
                                    setTurma(e.target.value);
                                    await setValue("turmaID", parseInt(e.target.value));
                                  }}
                                >
                                  {
                                    <>
                                      {loadingTurmas ? (
                                        <option value={0}>Carregando...</option>
                                      ) : (
                                        <option value={0}></option>
                                      )}
                                      {dataTurma &&
                                        dataTurma?.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.descricao}
                                          </option>
                                        ))}
                                    </>
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Tipo Sanguineo?</label>
                                <select
                                  className="form-select"
                                  id="tipoSanguineo"
                                  {...register("tipoSanguineo", {
                                    required: false,
                                  })}
                                >
                                  {tiposSanguineos?.map((entry) => (
                                    <option
                                      key={entry.value}
                                      value={entry.text}
                                    >
                                      {entry.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Possui alergia a algum medicamento?
                                </label>
                                <select
                                  className="form-select"
                                  id="possuiAlergiaMedicamentos"
                                  {...register("possuiAlergiaMedicamentos", {
                                    required: false,
                                    valueAsNumber: true,
                                  })}
                                >
                                  {escolhas?.map((entry) => (
                                    <option
                                      key={entry.value}
                                      value={entry.value}
                                    >
                                      {entry.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {watch("possuiAlergiaMedicamentos") == 1 && (
                              <div className="col-md-4">
                                <FormInput
                                  register={register}
                                  errors={errors}
                                  atribute="medicamentos"
                                  label="Quais medicamentos possui alergia?"
                                  required={false}
                                />
                              </div>
                            )}
                          </div>

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Sexo<span style={{ color: "red", marginLeft: "4px" }}>*</span></label>
                                <select
                                  className="form-select"
                                  id="sexo"
                                  {...register("sexo", {
                                    required: false,
                                    valueAsNumber: true,
                                  })}
                                >
                                  {generos?.map((entry) => (
                                    <option
                                      key={entry.value}
                                      value={entry.value}
                                    >
                                      {entry.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="naturalidadeEstado">
                                  Estado Nascimento
                                </label>
                                <select
                                  className="form-select"
                                  id="naturalidadeEstado"
                                  {...register("naturalidadeEstado")}
                                >
                                  <option value={""}></option>
                                  {estados.map((option) => (
                                    <option
                                      key={option.id}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="naturalidadeCidade">
                                  Cidade Nascimento
                                </label>
                                <select
                                  className="form-select"
                                  id="naturalidadeCidade"
                                  value={watch("naturalidadeCidade")}
                                  {...register("naturalidadeCidade", {})}
                                >
                                  <option value={""}></option>
                                  {cidades.map((option) => (
                                    <option
                                      key={option.label}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Etnia</label>
                                <select
                                  className="form-select"
                                  id="etnia"
                                  {...register("etnia", {
                                    required: false,
                                    valueAsNumber: true,
                                  })}
                                >
                                  {etnias?.map((entry) => (
                                    <option
                                      key={entry.value}
                                      value={entry.value}
                                    >
                                      {entry.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div
                            className="row"
                            style={{ marginTop: "20px" }}
                          ></div>
                          <h4
                            style={{ marginTop: "20px", marginBottom: "1px" }}
                          >
                            ENDEREÇO
                          </h4>
                          <hr
                            style={{
                              background: "#000",
                              color: "#000",
                              borderColor: "#00",
                              height: "1px",
                            }}
                          />
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="cep"
                                label="CEP"
                                mask="99999-999"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="logradouro"
                                label="Logradouro"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="numero"
                                label="Número"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="bairro"
                                label="Bairro"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="complemento"
                                label="Complemento"
                                required={false}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="estado"
                                label="Estado"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="cidade"
                                label="Cidade"
                                required={true}
                              />
                            </div>
                          </div>

                          <h4
                            style={{ marginTop: "20px", marginBottom: "1px" }}
                          >
                            DADOS RESPONSÁVEL
                          </h4>
                          <hr
                            style={{
                              background: "#000",
                              color: "#000",
                              borderColor: "#00",
                              height: "1px",
                            }}
                          />
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="responsavelNome"
                                label="Nome"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="responsavelCpf"
                                label="CPF"
                                required={true}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="responsavelNascimento"
                                label="Data Nascimento"
                                type="date"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="responsavelTelefone"
                                label="Telefone"
                                required={true}
                              />
                            </div>
                          </div>
                          <h4
                            style={{ marginTop: "20px", marginBottom: "1px" }}
                          >
                            DADOS SOCIAIS
                          </h4>
                          <hr
                            style={{
                              background: "#000",
                              color: "#000",
                              borderColor: "#00",
                              height: "1px",
                            }}
                          />
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="beneficiarioProgramaSocial">
                                  Beneficiário de Programas Socias
                                </label>
                                <select
                                  className="form-select"
                                  id="beneficiarioProgramaSocial"
                                  {...register("beneficiarioProgramaSocial", {
                                    required: true,
                                    valueAsNumber: true,
                                  })}
                                  defaultValue={0}
                                >
                                  <option value={0}></option>
                                  <option value={1}>BOLSA FAMÍLIA</option>
                                  <option value={2}>CADÚNICO</option>
                                  <option value={3}>
                                    NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="nis"
                                label="NIS"
                                type="text"
                                required={false}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="identificacaoUnica"
                                label="Identificação Única"
                                type="text"
                                required={false}
                              />
                            </div>
                          </div>
                          <h4
                            style={{
                              marginTop: "20px",
                              marginBottom: "1px",
                            }}
                          >
                            TRANSPORTE ESCOLAR
                          </h4>
                          <hr
                            style={{
                              background: "#000",
                              color: "#000",
                              borderColor: "#00",
                              height: "1px",
                            }}
                          />
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Utiliza Transporte Escolar?</label>
                                <select
                                  className="form-select"
                                  id="utilizaTransporteEscolar"
                                  {...register("utilizaTransporteEscolar", {
                                    required: false,
                                  })}
                                >
                                  {pne?.map((entry) => (
                                    <option key={entry.id} value={entry.id}>
                                      {entry.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {watch("utilizaTransporteEscolar") == 1 && (
                              <>
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="rota"
                                    label="Rota"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="pontoDeReferencia"
                                    label="Ponto de Referência"
                                    required={false}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          {watch("utilizaTransporteEscolar") == 1 && (
                            <>
                              <Typography variant="h6" color="red" my={2}>
                                PESSOAS AUTORIZADAS A RECEBER O ALUNO NO
                                DESEMBARQUE:
                              </Typography>

                              <Typography variant="h6" color="green" my={2}>
                                PRIMEIRO RESPONSÁVEL:
                              </Typography>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueNome"
                                    label="Nome"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueParentesco"
                                    label="Grau de parentesco"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueTelefone"
                                    label="Telefone"
                                    mask="(99)99999-9999"
                                    required={false}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueCep"
                                    label="CEP"
                                    mask="99999-999"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueLogradouro"
                                    label="Logradouro"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueNumero"
                                    label="Número"
                                    required={false}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueBairro"
                                    label="Bairro"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueComplemento"
                                    label="Complemento"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueEstado"
                                    label="Estado"
                                    required={false}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="desembarqueCidade"
                                    label="Cidade"
                                    required={false}
                                  />
                                </div>
                              </div>
                              {segundoResponsavel === false && (
                                <Button
                                  sx={{ my: 2 }}
                                  size="large"
                                  variant="contained"
                                  color="success"
                                  onClick={() => setSegundoResponsavel(true)}
                                >
                                  + ADICIONAR PESSOA AUTORIZADA
                                </Button>
                              )}

                              {segundoResponsavel === true && (
                                <>
                                  <div
                                    className="row"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Typography
                                      variant="h6"
                                      color="green"
                                      my={2}
                                    >
                                      SEGUNDO RESPONSÁVEL:
                                    </Typography>
                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueNome2"
                                        label="Nome"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueParentesco2"
                                        label="Grau de parentesco"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueTelefone2"
                                        label="Telefone"
                                        mask="(99)99999-9999"
                                        required={false}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueCep2"
                                        label="CEP"
                                        mask="99999-999"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueLogradouro2"
                                        label="Logradouro"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueNumero2"
                                        label="Número"
                                        required={false}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueBairro2"
                                        label="Bairro"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueComplemento2"
                                        label="Complemento"
                                        required={false}
                                      />
                                    </div>

                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueEstado2"
                                        label="Estado"
                                        required={false}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="col-md-4">
                                      <FormInput
                                        register={register}
                                        errors={errors}
                                        atribute="desembarqueCidade2"
                                        label="Cidade"
                                        required={false}
                                      />
                                    </div>
                                  </div>
                                  <Button
                                    sx={{ my: 2 }}
                                    size="large"
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleRemoverSegundoResponsavel()
                                    }
                                  >
                                    - REMOVER PESSOA AUTORIZADA
                                  </Button>
                                </>
                              )}
                              <Typography variant="h6" my={2} color="red">
                                PESSOA A SER ACIONADA EM CASO DE EMERGÊNCIA:
                              </Typography>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="emergenciaNome"
                                    label="Nome"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="emergenciaParentesco"
                                    label="Grau de parentesco"
                                    required={false}
                                  />
                                </div>

                                <div className="col-md-4">
                                  <FormInput
                                    register={register}
                                    errors={errors}
                                    atribute="emergenciaTelefone"
                                    label="Telefone"
                                    mask="(99)99999-9999"
                                    required={false}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: 0,
                              marginTop: 20,
                            }}
                          >
                            <button
                              style={{
                                marginRight: 20,
                                marginTop: 20,
                                height: 36,
                              }}
                              type="button"
                              className="btn btn-warning"
                              onClick={() => {
                                if (sessionStorage.getItem("duplicados")) {
                                  navigate(urlsConfiguracoes.alunosDuplicado);
                                } else {
                                  navigate(-1);
                                }
                              }}
                            >
                              Voltar
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success"
                              style={{
                                marginTop: "20px",
                                display:
                                  ocultaBotaEnviar === false
                                    ? "initial"
                                    : "none",
                              }}
                              // disabled={ocultaBotaEnviar}
                            >
                              {id ? "Salvar" : "Enviar"}
                            </button>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade show"
                          id="historico"
                          role="tabpanel"
                          aria-labelledby="historico-tab"
                        >
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="col-12">
                              <Table
                                columns={columnsUnidades}
                                data={
                                  historicoData?.data ? historicoData?.data : []
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade show"
                          id="matriculas"
                          role="tabpanel"
                          aria-labelledby="matriculas-tab"
                        >
                          <AbaMatriculas
                            register={register}
                            errors={errors}
                            alunoId={id}
                            renovacaoOut={renovacao}
                          />
                        </div>

                        <div
                          className="tab-pane fade show"
                          id="uniforme"
                          role="tabpanel"
                          aria-labelledby="uniforme-tab"
                        >
                          <AlunoUniforme register={register} alunoId={id} />
                        </div>

                        <div
                          className="tab-pane fade show"
                          id="arquivos"
                          role="tabpanel"
                          aria-labelledby="arquivos-tab"
                        >
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="col-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="fileUpload"
                                  className="form-label"
                                >
                                  UPLOAD DE DOCUMENTO
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="fileUpload"
                                  accept=".pdf,.png"
                                  onChange={handleFileChange}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="documentType"
                                  className="form-label"
                                >
                                  TIPO DE DOCUMENTO
                                </label>
                                <select
                                  className="form-control"
                                  id="documentType"
                                  value={documentType}
                                  onChange={(e) =>
                                    setDocumentType(e.target.value)
                                  }
                                >
                                  <option value="VAZIO"></option>
                                  <option value="ATESTADO">ATESTADO</option>
                                  <option value="CARTEIRA_VACINACAO">
                                    CARTEIRA DE VACINAÇÃO
                                  </option>
                                  <option value="CERTIDAO_NASCIMENTO">
                                    CERTIDÃO DE NASCIMENTO
                                  </option>
                                  <option value="CPF">CPF</option>
                                  <option value="DECLARACAO_ESCOLAR">
                                    DECLARAÇÃO ESCOLAR
                                  </option>
                                  <option value="DECLARACAO_QUILOMBOLA">
                                    DECLARAÇÃO QUILOMBOLA
                                  </option>
                                  <option value="HISTORICO_ESCOLAR">
                                    HISTÓRICO ESCOLAR
                                  </option>
                                  <option value="LAUDO_MEDICO">
                                    LAUDO MÉDICO
                                  </option>
                                  <option value="RG">RG</option>
                                </select>
                              </div>
                              <Button
                                color="success"
                                sx={{ my: 2 }}
                                size="medium"
                                variant="contained"
                                onClick={handleUpload}
                              >
                                Enviar Documento
                              </Button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                {isLoading ? (
                                  <Spinner />
                                ) : (
                                  <Table
                                    columns={collumnsDocumentos}
                                    data={documents ? documents : []}
                                    overflowUnset={true}
                                    hiddenColluns={["arquivoId"]}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </BaseComponent>
  );
};

export default AlunosAdicionarPage;
