import { useMemo, useState } from "react";
import Table from "../../../../../../../../components/table";
import { getHistoricoEstoqueById } from "../../../../../../../../services/administrativo/patrimonio-estoque";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const HistoricoProduto = () => {
  const { id } = useParams();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const { data: historicoData } = useQuery(
    ["getHistoricoEstoqueById", id],
    () => getHistoricoEstoqueById(id),
    {
      enabled: !!id,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "dataEvento",
        Cell: ({ value }) =>
          value ? new Date(value).toLocaleDateString("pt-BR") : "-",
      },
      { Header: "Evento", accessor: "evento" },
      { Header: "Usuário", accessor: "usuario" },
    ],
    [historicoData]
  );

  return (
    <div
      className="tab-pane fade show active"
      id="historico"
      role="tabpanel"
      aria-labelledby="historico-tab"
    >
      <div className="card-body">
        <Table
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          columns={columns}
          data={historicoData ? historicoData?.data : []}
        />
      </div>
    </div>
  );
};
export default HistoricoProduto;
