/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Types as PanelTypes } from "../../store/ducks/panel";
import { useState, useEffect } from "react";
import { getUnidades, getUnidadesById } from "../../services/unidades";
import { quadroHorarioServidorUnidades } from "../../services/quadro-horario";
import { store } from "../../store/index";
import PanicModal from "../panicModal";
import Select, { AriaOnFocus } from "react-select";
import api from "../../services";
import Notifications from "../notificacoes";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditIcon from "@mui/icons-material/Edit";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";
import MessageIcon from "@mui/icons-material/Message";

const Header = ({
  title,
  isGestao,
  showPicker,
  docente,
  loadUnidades = true,
  showPanic = true,
}) => {
  const isMenuOpen = useSelector((state) => state.panel.isMenuOpen);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const [modulosInfo, setModulosInfo] = useState();
  const [panicModalOpen, setPanicModalOpen] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [idAlocation, setIdAlocation] = useState(
    JSON.parse(localStorage.getItem("unidadeID"))
  );
  const [unidadesFiltro, setUnidadesFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [notificationData, setNotificationData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPicker, setAnchorElPicker] = useState(null);
  const [anchorElModules, setAnchorElModules] = useState(null);
  const [anchorElChat, setAnchorElChat] = useState(null);
  const [shouldOpenNotificationOptions, setShouldOpenNotificationOptions] =
    useState(false);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);

  const currentUser = store.getState()["user"]["user"];
  const location = useLocation();

  const modulos =
    JSON.parse(localStorage.getItem("infosModulos"))?.filter(
      (e) => e.status !== 0
    ) || [];

  // const { data: unidades } = useQuery('unidades5', isGestao ? getUnidades : getUnidadesById(currentUser?.servidorId), {
  //   retry: false
  // })

  useEffect(
    () => {
      async function fetchData() {
        try {
          if (loadUnidades) {
            if (isGestao) {
              const data = await getUnidades();
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
            }
            if (docente) {
              const data = await quadroHorarioServidorUnidades(
                currentUser?.servidorId
              );
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
              const unidadeId = JSON.parse(localStorage.getItem("unidadeID"));
              if (dados.find((e) => e.value === unidadeId) === undefined) {
                localStorage.setItem("unidadeID", dados[0].value);
                title = dados[0].label;
              }
            }
            if (!isGestao && !docente) {
              const data = await getUnidadesById(currentUser?.servidorId);
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
            }
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
      setModulosInfo(modulos.filter((e) => e.status !== false));
      localStorage.setItem("unidadeID", idAlocation);
      fetchData();
    },
    [idAlocation],
    []
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const mockNotifications = {
          data: {
            data: {
              avisos: [],
              notificacoes: Array.from({ length: 88 }, (_, index) => ({
                criacao: `2023-12-${String(18 + (index % 10)).padStart(
                  2,
                  "0"
                )}T08:37:16.541`,
                id: 51434 + index,
                lida: index % 2 === 0, // Alterna entre lidas e não lidas
                lidaEm:
                  index % 2 === 0
                    ? `2024-11-05T12:22:${50 + (index % 10)}.803896`
                    : null,
                prioridade: (index % 3) + 1, // Alterna prioridades entre 1, 2 e 3
                tipo: 1,
                titulo: `Notificação ${index + 1}`,
                usuarioId: 3029,
              })),
            },
          },
          totalAvisos: 0,
          totalNotificacoes: 88,
          totalNotificacoesNaoLidas: 44, // Ajuste conforme as lidas/não lidas
          message: "Operação realizada com sucesso.",
          statusCode: 200,
          skip: 0,
          limit: 1000,
          total: 100,
        };
        const notifications = await api.get("/shared/notificacao/perfil");
        setNotificationData(notifications);
        // setDataTab(notifications.data.notificacoes || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
    fetchData();
  }, []);

  const handleOpenNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
  };

  const handleOpenChatNotifications = (event) => {
    setAnchorElChat(event.currentTarget);
  };

  const handleCloseChatNotifications = () => {
    setAnchorElChat(null);
  };

  const handleOpenPicker = (event) => {
    setAnchorElPicker(event.currentTarget);
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setAnchorElPicker(null);
    setOpenPicker(false);
  };

  const handleOpenModules = (event) => {
    setAnchorElModules(event.currentTarget);
  };

  const handleCloseModules = () => {
    setAnchorElModules(null);
  };

  return (
    <div className="app-header">
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="container-fluid">
          {showPanic && (
            <PanicModal
              open={panicModalOpen}
              onClose={() => setPanicModalOpen(false)}
            />
          )}

          <div className="navbar-nav" id="navbarNav">
            <ul className="navbar-nav">
              {/*TOOGLE MENU*/}
              {location.pathname !== "/perfil" &&
              location.pathname !== "/chat" ? (
                <li className="nav-item">
                  <a
                    id="titleUnidade"
                    className="nav-link hide-sidebar-toggle-button"
                    onClick={() =>
                      dispatch({
                        type: PanelTypes.OPEN_MENU,
                        payload: { isMenuOpen: !isMenuOpen },
                      })
                    }
                  >
                    {!location.pathname.includes("administrativo") ? (
                      <>
                        <i className="material-icons md-menu_open"></i> {title}
                      </>
                    ) : (
                      <>
                        <i className="material-icons md-menu_open"></i>
                      </>
                    )}
                  </a>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="d-flex">
            <ul className="navbar-nav">
              <li className="nav-item position-relative">
                <Tooltip title="Notificações">
                  <IconButton
                    onClick={handleOpenNotifications}
                    size="large"
                    color="inherit"
                  >
                    <Badge
                      badgeContent={
                        notificationData?.data?.data
                          ?.totalNotificacoesNaoLidas || 0
                      }
                      color="error"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseNotifications}
                  disableScrollLock
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Notifications notifications={notificationData} />
                </Menu>
              </li>

              <li className="nav-item position-relative">
                <Tooltip title="Chat">
                  <IconButton
                    onClick={handleOpenChatNotifications}
                    size="large"
                    color="inherit"
                  >
                    <Badge
                      badgeContent={
                        notificationData?.data?.data
                          ?.totalNotificacoesChatNaoLidas || 0
                      }
                      color="error"
                    >
                      <MessageIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorElChat}
                  open={Boolean(anchorElChat)}
                  onClose={handleCloseChatNotifications}
                  disableScrollLock
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Notifications isChat notifications={notificationData} />
                </Menu>
              </li>
              {/*UNIDADES*/}

              {showPicker && (
                <li className="nav-item position-relative">
                  <Tooltip title="Selecionar Unidade">
                    <IconButton
                      onClick={handleOpenPicker}
                      size="large"
                      color="inherit"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    anchorEl={anchorElPicker}
                    open={openPicker}
                    onClose={handleClosePicker}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        minWidth: 250,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Select
                        options={unidadesFiltro}
                        value={unidadesFiltro.find(
                          (x) => x.value === idAlocation
                        )}
                        onChange={(e) => {
                          setIdAlocation(e.value);
                          localStorage.setItem("unidadeID", e.value);
                          handleClosePicker();
                          window.location.reload();
                        }}
                        placeholder="Selecione a unidade"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPosition="fixed"
                        menuPlacement="auto"
                      />
                    </Box>
                  </Popover>
                </li>
              )}

              {/*MODULOS*/}
              <li className="nav-item dropdown hidden-on-mobile">
                <Tooltip title="Módulos">
                  <IconButton
                    onClick={handleOpenModules}
                    size="large"
                    color="inherit"
                  >
                    <ViewModuleIcon />
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorElModules}
                  open={Boolean(anchorElModules)}
                  onClose={handleCloseModules}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: "480px",
                      width: "350px",
                      borderRadius: "12px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      overflowY: "auto",
                      overflowX: "hidden",
                    },
                  }}
                >
                  <Box sx={{ padding: "8px 16px" }}>
                    <Typography
                      variant="subtitle1"
                      className="dropdown-header"
                      sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                      Módulos
                    </Typography>
                  </Box>
                  {modulosInfo?.map((e) => (
                    <MenuItem
                      key={e.link}
                      onClick={handleCloseModules}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "12px 16px",
                        "&:hover": {
                          bgcolor: "primary.light",
                          color: "primary.main",
                        },
                      }}
                    >
                      <NavLink
                        to={e.link.includes(".br") ? e.link : `/${e.link}`}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          className="dropdown-item-title"
                          sx={{
                            fontWeight: "500",
                            whiteSpace: "normal", // Permite quebra de linha
                            wordWrap: "break-word", // Quebra palavras longas
                          }}
                        >
                          {e.titulo}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="dropdown-item-description"
                          sx={{
                            fontSize: "0.875rem",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {e.descricao}
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  ))}
                  <Box
                    sx={{
                      padding: "8px 16px",
                      borderTop: "1px solid #e0e0e0",
                      mt: 1,
                    }}
                  >
                    <Link
                      to="/modulos"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          borderRadius: "8px",
                          fontWeight: "bold",
                          textTransform: "none",
                          boxShadow: "0px 4px 10px rgba(0, 123, 255, 0.2)",
                          "&:hover": {
                            backgroundColor: "primary.dark",
                            boxShadow: "0px 6px 14px rgba(0, 123, 255, 0.3)",
                          },
                        }}
                      >
                        Voltar pra tela de módulos
                      </Button>
                    </Link>
                  </Box>
                </Menu>
              </li>

              {/*PÂNICO*/}
              {showPanic && (
                <li className="nav-item">
                  <Tooltip title="Pânico">
                    <IconButton
                      onClick={() => setPanicModalOpen(true)}
                      size="large"
                      color="error"
                    >
                      <WarningIcon />
                    </IconButton>
                  </Tooltip>
                </li>
              )}
              {/*PÂNICO*/}

              <Link
                style={{ color: "#212529" }}
                to="https://r3mais.tomticket.com/kb/modulo"
              >
                <li className="nav-item ">
                  <Tooltip title="Ajuda">
                    <IconButton size="large" color="inherit">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </li>
              </Link>

              {/*LOGOUT*/}
              <li className="nav-item hidden-on-mobile">
                <a className="nav-link text-dark" href="/">
                  <i
                    className="material-icons md-logout"
                    style={{ paddingBottom: "2px", marginRight: "2px" }}
                  ></i>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
