import React, { useState, useEffect } from "react";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  Pagination,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DraftsIcon from "@mui/icons-material/Drafts";
import { store } from "../../store/index";
import api from "../../services/index";

import { useQuery, useMutation } from "react-query";

export default function Notifications({ notifications, isChat = false }) {
  const currentUser = store.getState()["user"]["user"];
  const [activeTab, setActiveTab] = useState(isChat ? "chat" : "notificacao");
  const [filter, setFilter] = useState("nao-lidas");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTab, setDataTab] = useState([]);
  const navigate = useNavigate();

  // Fetch notifications
  const { data, isLoading, refetch } = useQuery(
    "fetchNotifications",
    () => api.get("/shared/notificacao/perfil").then((res) => res.data.data),
    {
      onSuccess: (data) => {
        const filteredData =
          activeTab === "notificacao"
            ? filter === "lidas"
              ? data.notificacoes.filter((e) => e.lida)
              : data.notificacoes.filter((e) => !e.lida)
            : activeTab === "aviso"
              ? filter === "lidas"
                ? data.avisos.filter((e) => e.lida)
                : data.avisos.filter((e) => !e.lida)
              : activeTab === "chat"
                ? filter === "lidas"
                  ? data.notificacoesChat.filter((e) => e.lida)
                  : data.notificacoesChat.filter((e) => !e.lida)
                : [];

        setDataTab(filteredData);
      },
    }
  );

  // Mark notification as read
  const { mutate: confirmarLida } = useMutation(
    (id) => api.put(`/shared/notificacao/${id}/ConfirmarLeitura`),
    {
      onSuccess: () => {
        refetch(); // Refetch notifications after marking as read
      },
    }
  );

  const updateNotificationRead = (id, isRead, title) => {
    if (isChat) {
      navigate("/chat");
      return;
    }

    if (!isRead) {
      confirmarLida(id);
    }
    if (title.indexOf('NOVA TRANSFERÊNCIA INTERNA') > -1)
      navigate('/gestao-unidades/transferencias/recebidas');
  };

  useEffect(() => {
    if (data) {
      let filteredData = [];
      if (activeTab === "notificacao") {
        filteredData =
          filter === "lidas"
            ? data.notificacoes.filter((e) => e.lida)
            : data.notificacoes.filter((e) => !e.lida);
      } else if (activeTab === "aviso") {
        filteredData =
          filter === "lidas"
            ? data.avisos.filter((e) => e.lida)
            : data.avisos.filter((e) => !e.lida);
      } else if (activeTab === "chat") {
        filteredData =
          filter === "lidas"
            ? data.notificacoesChat.filter((e) => e.lida)
            : data.notificacoesChat.filter((e) => !e.lida);
      }


      setDataTab(filteredData); // Update the displayed data
    }
  }, [filter, activeTab, data]); // Ensure it re-runs when these change


  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  return (
    <Box
      sx={{
        p: 1,
        width: 400,
        height: 480,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isChat ? (
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Chat" value="chat" />
        </Tabs>
      ) : (
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Notificação" value="notificacao" />
          <Tab label="Aviso" value="aviso" />
        </Tabs>
      )}

      <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
        <Button
          variant={filter === "nao-lidas" ? "contained" : "outlined"}
          onClick={() => setFilter("nao-lidas")}
          sx={{ mx: 0.5 }}
        >
          Não lidas
        </Button>
        <Button
          variant={filter === "lidas" ? "contained" : "outlined"}
          onClick={() => setFilter("lidas")}
          sx={{ mx: 0.5 }}
        >
          Lidas
        </Button>
      </Box>

      <Divider sx={{ my: 1 }} />

      {isLoading ? (
        <Typography variant="body1" textAlign="center">
          Carregando notificações...
        </Typography>
      ) : dataTab.length === 0 ? (
        <Typography variant="body1" textAlign="center">
          Nenhuma notificação
        </Typography>
      ) : (
        <>
          {dataTab
            .slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
            .map((e) => (
              <MenuItem
                key={e.id}
                onClick={() => updateNotificationRead(e.id, e.lida, e.titulo)}
                sx={{
                  bgcolor: e.lida ? "grey.100" : ".light",
                  "&:hover": { bgcolor: e.lida ? "grey.200" : "primary.main" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {e.titulo}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {`${moment(e.criacao).format(
                      "DD/MM/YYYY"
                    )} às ${moment(e.criacao).format("HH:mm:ss")}`}
                  </Typography>
                </Box>
                <IconButton size="small">
                  {e.lida ? <DraftsIcon /> : <EmailIcon />}
                </IconButton>
              </MenuItem>
            ))}
        </>
      )}

      <Divider sx={{ my: 1 }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: "auto",
          gap: 1,
        }}
      >
        {isChat ? (
          <NavLink to={`/chat`} style={{ textDecoration: "none" }}>
            <Button variant="contained">Abrir Chat</Button>
          </NavLink>
        ) : (
          <NavLink
            to={`/notificacoes`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Ver todas notificações</Button>
          </NavLink>
        )}
        <Pagination
          count={Math.ceil(dataTab.length / 5)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Box>
    </Box>
  );
}
