import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import { Search, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import UrlsProcessoSeletivoServidores from "../UrlsProcessoSeletivoServidores";
import { isValidCPF } from "../../../../utils/isValidCpf";
import { listarPorCpfServidores } from "../../../../services/formulariosService";

function ProcessoSeletivoServidoresHome({ dataAbertura, dataFechamento }) {
  const [cpf, setCpf] = useState("");
  const [isCpfValid, setIsCpfValid] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleCpfChange = (e) => {
    const { value } = e.target;
    const formattedCpf = value.replace(/[^\d]+/g, "");

    setCpf(value);

    if (formattedCpf.length === 11 && isValidCPF(formattedCpf)) {
      setIsCpfValid(true);
    } else {
      setIsCpfValid(false);
    }
  };

  const handleSearchClick = async () => {
    if (!isCpfValid) {
      toast.warn("CPF informado inválido");
      return;
    }

    const response = await listarPorCpfServidores(cpf);

    if (response?.statusCode === 200) {
      navigate(
        `${UrlsProcessoSeletivoServidores.formularioVisualizacao}${response?.data?.id}`
      );
    } else {
      toast.info(
        "Não existem registros em nossa base de dados para o CPF informado."
      );
    }
  };

  const handleCardClick = (url) => {
    navigate(url);
  };

  const renderCountdown = () => {
    const timeDifference = dataAbertura - currentTime;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${hours} hora(s), ${minutes} minuto(s), e ${seconds} segundo(s)`;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        padding: "40px 20px",
      }}
    >
      {/* Logo and Titles */}
      <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
        <img
          src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
          alt="Logo Prefeitura de São Pedro da Aldeia"
          style={{ maxWidth: "150px", marginBottom: "20px" }}
        />
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333" }}>
          PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA
        </Typography>
        <Typography variant="h5" sx={{ color: "#555" }}>
          SECRETARIA DE EDUCAÇÃO
        </Typography>
        <Typography variant="h6" sx={{ color: "#555" }}>
          PROCESSO SELETIVO SIMPLIFICADO 2025 - SEMED <br />{" "}
          <b>CADASTRO RESERVA PARA ANO LETIVO 2025</b>
        </Typography>
      </Box>

      {/* CPF Search in a white box (Always Visible) */}
      <Paper
        elevation={3}
        sx={{
          padding: "10px",
          maxWidth: "500px",
          margin: "0 auto",
          marginBottom: "40px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ flex: 1, marginRight: "10px", textAlign: "center" }}
        >
          CONSULTE SUA INSCRIÇÃO:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={handleCpfChange}
          >
            {() => (
              <TextField
                label="CPF"
                variant="outlined"
                sx={{ flex: 1, marginRight: "10px" }}
              />
            )}
          </InputMask>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Search />}
            onClick={handleSearchClick}
            sx={{ padding: "10px 20px" }}
            disabled={!isCpfValid} // Disable button if CPF is invalid
          >
            PESQUISAR
          </Button>
        </Box>
      </Paper>

      {/* Conditional Rendering Based on Time */}
      {currentTime < dataAbertura && (
        <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
          <Typography variant="h5">
            Faltam {renderCountdown()} para abertura do formulário.
          </Typography>
        </Box>
      )}

      {currentTime >= dataAbertura && currentTime <= dataFechamento && (
        <>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", marginBottom: "20px", color: "red" }}
          >
            INSCRIÇÕES ENCERRADAS
          </Typography>

          {/* Cards for Form Types */}
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.05)" },
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                }}
                onClick={() =>
                  handleCardClick(
                    UrlsProcessoSeletivoServidores.formularioPreenchimento
                  )
                }
              >
                {/* <CardContent sx={{ textAlign: "center" }}>
                  <Edit fontSize="large" sx={{ marginBottom: "10px" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    CLIQUE AQUI PARA INSCRIÇÃO
                  </Typography>
                </CardContent> */}
              </Card>
            </Grid>
          </Grid>
        </>
      )}

      {/* {currentTime > dataFechamento && (
        <Box sx={{ textAlign: "center", marginBottom: "40px", color: "red" }}>
          <Typography variant="h4">Inscrições encerradas.</Typography>
        </Box>
      )} */}
    </Box>
  );
}

export default ProcessoSeletivoServidoresHome;
