import React, { useEffect, useState } from 'react';
import { TextField, Button, Card, Typography, Box, IconButton, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from '../../../../../services';
import { toast } from 'react-toastify';
import { cpf } from 'cpf-cnpj-validator';
import urlsServidores from '../urlsServidores';

export default function ProcessosSeletivos() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [cpfValido, setCpfValido] = useState(false);
  const [exibeFormulario, setExibeFormulario] = useState(1);

  const dataOcultarRecurso = new Date('2025-01-03T23:59:59');

  const { register, watch } = useForm({
    mode: 'onChange',
    defaultValues: { cpf: '' },
  });

  useEffect(() => {
    const cpfWithoutMask = watch('cpf').replace(/\D/g, '');
    setCpfValido(cpf.isValid(cpfWithoutMask));
  }, [watch('cpf')]);

  const handleSearch = async (cpfValue) => {
    try {
      const response = await api.get(`/processoSeletivo/inscricao/filtroCpf?cpf=${cpfValue}`);
      const id = response?.data?.data?.id;
      if (id) {
        navigate(urlsServidores.visualizar + id);
      } else {
        toast.error('Inscrição não encontrada na base de dados.');
      }
    } catch (error) {
      toast.error('Erro ao buscar inscrição.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
      {exibeFormulario === 1 && (
        <Card sx={{ width: isMobile ? '100%' : '80%', padding: 3, marginY: 2 }}>
          <Typography variant="h5" color="primary" textAlign="center">
            PESQUISAR
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, marginTop: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="DIGITE SEU CPF"
              placeholder="000.000.000-00"
              {...register('cpf')}
              error={!cpfValido && watch('cpf') !== ''}
              helperText={!cpfValido && watch('cpf') !== '' ? 'CPF inválido' : ''}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSearch(watch('cpf'))}
              disabled={!cpfValido}
              startIcon={<SearchIcon />}
            >
              PESQUISAR
            </Button>
          </Box>
        </Card>
      )}

     

        <Card sx={{ width: isMobile ? '100%' : '80%', padding: 3, textAlign: 'center', marginY: 2 }}>
          <Typography variant="h5" color="error">
            INSCRIÇÕES ENCERRADAS
          </Typography>
        </Card>

        {new Date() < dataOcultarRecurso && (
           <Card
           sx={{
             width: isMobile ? '100%' : '60%',
             padding: 3,
             textAlign: 'center',
           }}
         >
          
           <Typography variant="h6" color="textPrimary">
             CLIQUE AQUI PARA PREENCHER O RECURSO
           </Typography>
           <Button
             variant="contained"
             color="primary"
             size="large"
             sx={{ marginTop: 2 }}
             onClick={() => navigate(urlsServidores.formularioRecurso)}
           >
             PREENCHER RECURSO
           </Button>
         </Card>
        )}

       
    </Box>
  );
}