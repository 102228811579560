export default function buildQueryParams(filters) {
  const {
    skip,
    limit,
    searchText,
    nome,
    produtoId,
    marca,
    tipoProduto,
    conservacao,
    validadeInicio,
    validadeFim,
    unidadeId,
    categoriaId,
    solicitacaoInicio,
    solicitacaoFim,
    status,
    unidadeDestinoId,
    escolaridadeId,
    turnoId,
    anoLetivo,
    exportar,
    multiserriada,
    vagas,
    preMatricula,
  } = filters;

  const queryParams = {};

  if (skip !== undefined && skip !== null) queryParams.skip = skip;
  if (limit !== undefined && limit !== null) queryParams.limit = limit;
  if (searchText) queryParams.searchText = searchText;
  if (nome) queryParams.nome = nome;
  if (produtoId) queryParams.produtoId = produtoId;
  if (marca) queryParams.marca = marca;
  if (tipoProduto) queryParams.tipoProduto = tipoProduto;
  if (conservacao) queryParams.conservacao = conservacao;
  if (validadeInicio) queryParams.validadeInicio = validadeInicio;
  if (validadeFim) queryParams.validadeFim = validadeFim;
  if (unidadeId) queryParams.unidadeId = unidadeId;
  if (categoriaId) queryParams.categoriaId = categoriaId;
  if (solicitacaoInicio) queryParams.solicitacaoInicio = solicitacaoInicio;
  if (solicitacaoFim) queryParams.solicitacaoFim = solicitacaoFim;
  if (status) queryParams.status = status;
  if (unidadeDestinoId) queryParams.unidadeDestinoId = unidadeDestinoId;
  if (escolaridadeId) queryParams.escolaridadeId = escolaridadeId;
  if (turnoId) queryParams.turnoId = turnoId;
  if (anoLetivo) queryParams.anoLetivo = anoLetivo;
  if (exportar) queryParams.exportar = exportar;
  if (multiserriada) queryParams.multiserriada = multiserriada;
  if (vagas) queryParams.vagas = vagas;
  if (preMatricula) queryParams.preMatricula = preMatricula;

  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : "";
}
