const baseUrl = "/administrativo";

const urlsAdministrativo = {
  dashboard: `${baseUrl}`,
  patrimonio: `${baseUrl}/patrimonio`,
  financeiro: `${baseUrl}/financeiro`,
  financeiroProcessos: `${baseUrl}/financeiro/processos`,
  financeiroProcessosCadastrar: `${baseUrl}/financeiro/processos/cadastrar/`,
  financeiroProcessosCadastrarId: `${baseUrl}/financeiro/processos/cadastrar/:id`,
  financeiroMemorandos: `${baseUrl}/financeiro/memorandos`,
  financeiroMemorandosCadastrar: `${baseUrl}/financeiro/memorandos/cadastrar/`,
  financeiroMemorandosCadastrarId: `${baseUrl}/financeiro/memorandos/cadastrar/:id`,
  financeiroContasAPagar: `${baseUrl}/financeiro/contas-a-pagar`,
  financeiroContasAPagarCadastrar: `${baseUrl}/financeiro/contas-a-pagar/cadastrar/`,
  financeiroContasAPagarCadastrarId: `${baseUrl}/financeiro/contas-a-pagar/cadastrar/:id`,
  manutencao: `${baseUrl}/manutencao`,
  manutencaoCadastrar: `${baseUrl}/manutencao/cadastrar`,
  transporte: `${baseUrl}/transporte`,
  transporteFuncionarios: `${baseUrl}/transporte/funcionarios`,
  transporteVeiculos: `${baseUrl}/transporte/veiculos`,
  transporteVeiculosCadastrar: `${baseUrl}/transporte/veiculos/cadastrar/`,
  transporteVeiculosCadastrarId: `${baseUrl}/transporte/veiculos/cadastrar/:id`,
  transporteVeiculosLancarGastos: `${baseUrl}/transporte/veiculos/lancar-gastos/`,
  transporteVeiculosLancarGastosId: `${baseUrl}/transporte/veiculos/lancar-gastos/:id`,
  transporteQuadroHorario: `${baseUrl}/transporte/quadro-horario`,
  transporteDashboard: `${baseUrl}/transporte/dashboard`,
  transporteAlunos: `${baseUrl}/transporte/alunos`,
  alimentacao: `${baseUrl}/alimentacao`,
  acompanhamentoNutricional: `${baseUrl}/acompanhamento-nutricional`,
  nutricaoCategoriaProduto: `${baseUrl}/nutricao/categoria-produto`,
  nutricaoCategoriaProdutoCadastrar: `${baseUrl}/nutricao/categoria-produto/cadastrar/`,
  nutricaoCategoriaProdutoCadastrarId: `${baseUrl}/nutricao/categoria-produto/cadastrar/:id`,
  nutricaoProduto: `${baseUrl}/nutricao/produto`,
  nutricaoProdutoCadastrar: `${baseUrl}/nutricao/produto/cadastrar/`,
  nutricaoProdutoCadastrarId: `${baseUrl}/nutricao/produto/cadastrar/:id`,
  nutricaoFornecedor: `${baseUrl}/nutricao/fornecedor`,
  nutricaoFornecedorCadastrar: `${baseUrl}/nutricao/fornecedor/cadastrar/`,
  nutricaoFornecedorCadastrarId: `${baseUrl}/nutricao/fornecedor/cadastrar/:id`,
  nutricaoControlePedido: `${baseUrl}/nutricao/controle-pedido`,
  nutricaoControlePedidoCadastrar: `${baseUrl}/nutricao/controle-pedido/cadastrar/`,
  nutricaoControlePedidoCadastrarId: `${baseUrl}/nutricao/controle-pedido/cadastrar/:id`,
  nutricaoMovimentacoes: `${baseUrl}/nutricao/movimentacoes`,
  nutricaoMovimentacoesCadastrar: `${baseUrl}/nutricao/movimentacoes/cadastrar/`,
  nutricaoCardapio: `${baseUrl}/nutricao/cardapio`,
  nutricaoCardapioCadastrar: `${baseUrl}/nutricao/cardapio/cadastrar/`,
  nutricaoCardapioImpressao: `${baseUrl}/nutricao/cardapio/impressao`,
  patrimonioControleFornecedores: `${baseUrl}/patrimonio/controle-fornecedores`,
  patrimonioControleFornecedoresCadastrar: `${baseUrl}/patrimonio/controle-fornecedores/cadastrar/`,
  patrimonioControleFornecedoresCadastrarId: `${baseUrl}/patrimonio/controle-fornecedores/cadastrar/:id`,
  patrimonioControleContratos: `${baseUrl}/patrimonio/controle-contratos`,
  patrimonioControleContratosCadastrar: `${baseUrl}/patrimonio/controle-contratos/cadastrar/`,
  patrimonioControleContratosCadastrarId: `${baseUrl}/patrimonio/controle-contratos/cadastrar/:id`,
  patrimonioCategoriaProdutos: `${baseUrl}/patrimonio/categoria-produtos`,
  patrimonioCategoriaProdutosCadastrar: `${baseUrl}/patrimonio/categoria-produtos/cadastrar/`,
  patrimonioCategoriaProdutosCadastrarId: `${baseUrl}/patrimonio/categoria-produtos/cadastrar/:id`,
  patrimonioControleProdutos: `${baseUrl}/patrimonio/controle-produtos`,
  patrimonioControleProdutosCadastrar: `${baseUrl}/patrimonio/controle-produtos/cadastrar/`,
  patrimonioControleProdutosCadastrarId: `${baseUrl}/patrimonio/controle-produtos/cadastrar/:id`,
  patrimonioControleEstoque: `${baseUrl}/patrimonio/controle-estoque`,
  patrimonioControleEstoqueCadastrar: `${baseUrl}/patrimonio/controle-estoque/cadastrar/`,
  patrimonioControleEstoqueCadastrarId: `${baseUrl}/patrimonio/controle-estoque/cadastrar/:id`,
  patrimonioControleSolicitacao: `${baseUrl}/patrimonio/controle-solicitacao`,
  patrimonioControleSolicitacaoCadastrar: `${baseUrl}/patrimonio/controle-solicitacao/cadastrar/`,
  patrimonioControleSolicitacaoCadastrarId: `${baseUrl}/patrimonio/controle-solicitacao/cadastrar/:id`,
  patrimonioControleImpressoras: `${baseUrl}/patrimonio/impressoras`,
  patrimonioControleImpressorasCadastrar: `${baseUrl}/patrimonio/impressoras/cadastrar/`,
  patrimonioControleImpressorasCadastrarId: `${baseUrl}/patrimonio/impressoras/cadastrar/:id`,
  patrimonioControleImpressorasRelatorios: `${baseUrl}/patrimonio/impressoras/cadastrar/:id`,
  patrimonioControleImpressorasChamado: `${baseUrl}/patrimonio/impressoras/chamado/`,
  patrimonioControleImpressorasChamadoAcompanhamento: `${baseUrl}/patrimonio/impressoras/chamado/acompanhamento`,
  transporteFormulario: `${baseUrl}/transporte/formulario`,
  administrativoManutencao: `${baseUrl}/manutencao`,
  patrimonioAlmoxarifadoEstoque: `${baseUrl}/patrimonio/almoxarifado/estoque`,
  patrimonioAlmoxarifadoEstoqueCadastrar: `${baseUrl}/patrimonio/almoxarifado/estoque/cadastrar/`,
  patrimonioAlmoxarifadoEstoqueCadastrarId: `${baseUrl}/patrimonio/almoxarifado/estoque/cadastrar/:id`,
  patrimonioAlmoxarifadoProdutos: `${baseUrl}/patrimonio/almoxarifado/produtos`,
  patrimonioAlmoxarifadoProdutosCadastrar: `${baseUrl}/patrimonio/almoxarifado/produtos/cadastrar/`,
  patrimonioAlmoxarifadoProdutosCadastrarId: `${baseUrl}/patrimonio/almoxarifado/produtos/cadastrar/:id`,
  patrimonioAlmoxarifadoTransferencias: `${baseUrl}/patrimonio/almoxarifado/transferencias`,
  patrimonioAlmoxarifadoSolicitacoes: `${baseUrl}/patrimonio/almoxarifado/solicitacoes`,
  patrimonioAlmoxarifadoSolicitacoesCadastrar: `${baseUrl}/patrimonio/almoxarifado/solicitacoes/cadastrar/`,
  patrimonioAlmoxarifadoSolicitacoesCadastrarId: `${baseUrl}/patrimonio/almoxarifado/solicitacoes/cadastrar/:id`,
  patrimonioAlmoxarifadoResponderSolicitacoes: `${baseUrl}/patrimonio/almoxarifado/solicitacoes/responder/`,
  patrimonioAlmoxarifadoResponderSolicitacoesId: `${baseUrl}/patrimonio/almoxarifado/solicitacoes/responder/:id`,
  patrimonioAlmoxarifadoBaixas: `${baseUrl}/patrimonio/almoxarifado/baixas`,
};

export default urlsAdministrativo;
