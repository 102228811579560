import React from 'react';
import { Route } from 'react-router-dom';
import urlsGestaoUnidades from '../../../pages/gestao-unidades/urlsGestaoUnidades';
import HomeChamadaPublica from '../../../pages/processos/chamadaPublica2025/homeChamadaPublica2025';
import ChamadaPublicaInscricoesHistoricoPage from '../../../pages/processos/chamadaPublica2025/private/historico';
import ChamadaPublicaMatriculas from '../../../pages/processos/chamadaPublica2025/private/matriculas';
import MatriculasEnturmar from '../../../pages/processos/chamadaPublica2025/private/matriculas/enturmar';
import MatricularIndeferir from '../../../pages/processos/chamadaPublica2025/private/matriculas/indeferir';
import ChamadaPublicaPesquisarPage from '../../../pages/processos/chamadaPublica2025/private/pesquisar';
import ChamadaPublicaHome from '../../../pages/processos/chamadaPublica2025/public';
import ChamadaPublicaFormularioPage from '../../../pages/processos/chamadaPublica2025/public/formulario/novoRede';
import RelatorioInscritosPorUnidadePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porUnidade'
import RelatorioInscritosPorEscolaridadePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porEscolaridade'
import RelatorioInscritosPorPnePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porPne'
import RelatorioInscritosPorNovosDaRedePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porNovosDaRede'
import RelatorioInscritosPorGeralPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porGeral'
import RelatorioConvocacoesPorEscolaridadePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/convocacoes/porEscolaridade'
import RelatorioMatriculadosPorEscolaridadePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/matriculados/porEscolaridade'
import RelatorioMatriculadosPorUnidadePage from '../../../pages/processos/chamadaPublica2025/private/relatorios/matriculados/porUnidade'
import RelatorioMatriculadosPorGeralPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/matriculados/porGeral'
import ListaDeEsperaPage from '../../../pages/processos/chamadaPublica2025/public/listaEspera';
import VisualizacaoFormularioChamadaPublicaPage from '../../../pages/processos/chamadaPublica2025/public/visualizacao';
import urlsChamadaPublica2025 from '../../../pages/processos/chamadaPublica2025/urlsChamadaPublica2025';
import ChamadaPublicInscritosListagemPagePre from '../../../pages/processos/chamadaPublica2025/private/inscricoes-pre';
import ChamadaPublicInscritosListagemPageCreche from '../../../pages/processos/chamadaPublica2025/private/inscricoes-creche';
import ChamadaPublicaInscricoesRemanejamentoPage from '../../../pages/processos/chamadaPublica2025/private/inscricoes-remanejamento';
import ChamadaPublicInscritosListagemPageCrecheRemanejamento from '../../../pages/processos/chamadaPublica2025/private/inscricoes-creche-remanejamento';
import ChamadaPublicInscritosListagemPagePreRemanejamento from '../../../pages/processos/chamadaPublica2025/private/inscricoes-pre-remanejamento';
import RelatorioInscritosPorBairroPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porBairro';
import RelatorioInscritosPorTransExterna from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porTransExterna';
import RelatorioInscritosPorTransInterna from '../../../pages/processos/chamadaPublica2025/private/relatorios/inscritos/porTransInterna';
import ChamadaPublicInscritosListagemPageCrecheConvocacoes from '../../../pages/processos/chamadaPublica2025/private/inscricoes-creche-convocacoes';
import ChamadaPublicInscritosListagemPagePreConvocacoes from '../../../pages/processos/chamadaPublica2025/private/inscricoes-pre-convocacoes';
import RelatorioMatriculadosPorPeriodoNominalPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/matriculados/porPeriodoNominal';
import RelatorioMatriculadosPorPeriodoQuantitativoPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/matriculados/porPeriodoQuantitativo';
import RelatorioConvocadosPorPeriodoNominalPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/convocacoes/porPeriodoNominal';
import RelatorioConvocadosPorPeriodoQuantitativoPage from '../../../pages/processos/chamadaPublica2025/private/relatorios/convocacoes/porPeriodoQuantitativo';
import AlunosManuaisPage from '../../../pages/alunos/manuais';
import AlunosPreMatriculaPage from '../../../pages/alunos/preMatricula';
import AlunosRemanejamentoPage from '../../../pages/alunos/remanejamento';
import ChamadaPublicaFormularioPageTransferencia from '../../../pages/processos/chamadaPublica2025/public/formulario/transferencia';
import ListarAlunoPorNomeChamadaPublica from '../../../pages/processos/chamadaPublica2025/private/alunos';
import VisualizacaoFormularioChamadaPublicaPagev2 from '../../../pages/processos/chamadaPublica2025/public/visualizacao/index2';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEDUC';
const dataAbertura = new Date('2024-01-06T09:00:00')
const dataFechamento = new Date('2025-01-10T23:59:59')
const dataDeCorte = new Date('2025-01-30T09:00:00')
const currentDate = new Date()

const ChamadaPublicaRoutes2025 = () => (
    <React.Fragment>
      {currentDate >= dataDeCorte && currentDate <= dataDeCorte && (
          <Route path={urlsChamadaPublica2025.listaEspera} element={<ListaDeEsperaPage />} />
      )}

      {currentDate >= dataAbertura && currentDate <= dataFechamento && (
        <>
               <Route path={urlsChamadaPublica2025.formularioRede} element={<ChamadaPublicaFormularioPage tipoFormulario={'nao-sou-aluno'}  />} />
               <Route path={urlsChamadaPublica2025.formularioTransferencia} element={<ChamadaPublicaFormularioPage tipoFormulario={'sou-aluno'} />} /> 
               </>
      )}
    {enable && (
    <>
      <Route path={urlsChamadaPublica2025.dashboard} element={<HomeChamadaPublica />} />
      <Route path={urlsChamadaPublica2025.creches} element={<ChamadaPublicInscritosListagemPageCreche parametros={1} bloquearFiltros={false} statusDefault={1}/>} />
      <Route path={urlsChamadaPublica2025.pre} element={<ChamadaPublicInscritosListagemPagePre  parametros={2} bloquearFiltros={false}/>} statusDefault={1} />
      <Route path={urlsChamadaPublica2025.crechesConvocados} element={<ChamadaPublicInscritosListagemPageCrecheConvocacoes parametros={1} bloquearFiltros={true}/>} statusDefault={1} />
      <Route path={urlsChamadaPublica2025.preConvocados} element={<ChamadaPublicInscritosListagemPagePreConvocacoes  parametros={2} bloquearFiltros={true}/>} statusDefault={1}/>
      <Route path={urlsChamadaPublica2025.crechesRemanejamento} element={<ChamadaPublicInscritosListagemPageCrecheRemanejamento parametros={1} bloquearFiltros={true}/>} />
      <Route path={urlsChamadaPublica2025.preRemanejamento} element={<ChamadaPublicInscritosListagemPagePreRemanejamento  parametros={2} bloquearFiltros={true}/>} />
      <Route path={urlsChamadaPublica2025.pesquisar} element={<ChamadaPublicaPesquisarPage />} />
      <Route path={urlsChamadaPublica2025.historicoId} element={<ChamadaPublicaInscricoesHistoricoPage />} />
      <Route path={urlsChamadaPublica2025.homeFormulario} element={<ChamadaPublicaHome dataAbertura={dataAbertura} dataFechamento={dataFechamento} />} />
      <Route path={urlsChamadaPublica2025.visualizacaoId} element={<VisualizacaoFormularioChamadaPublicaPagev2 />} />

    
      

      <Route path={urlsChamadaPublica2025.relatorioInscritosPorUnidade} element={<RelatorioInscritosPorUnidadePage />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosPorEscolaridade} element={<RelatorioInscritosPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritorPorBairro} element={<RelatorioInscritosPorBairroPage />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosTransferenciaExterna} element={<RelatorioInscritosPorTransExterna />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosTransferenciaInterna} element={<RelatorioInscritosPorTransInterna />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosPorPne} element={<RelatorioInscritosPorPnePage />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosPorNovosDaRede} element={<RelatorioInscritosPorNovosDaRedePage />} />
      <Route path={urlsChamadaPublica2025.relatorioInscritosPorGeral} element={<RelatorioInscritosPorGeralPage />} />
      <Route path={urlsChamadaPublica2025.relatorioConvocacoesPorEscolaridade} element={<RelatorioConvocacoesPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica2025.relatorioConvocadosPorPeriodoNominal} element={<RelatorioConvocadosPorPeriodoNominalPage />} />
      <Route path={urlsChamadaPublica2025.relatorioConvocadosPorPeriodoQuantitativo} element={<RelatorioConvocadosPorPeriodoQuantitativoPage />} />
      <Route path={urlsChamadaPublica2025.relatorioMatriculadosPorEscolaridade} element={<RelatorioMatriculadosPorEscolaridadePage />} />
      <Route path={urlsChamadaPublica2025.relatorioMatriculadosPorUnidade} element={<RelatorioMatriculadosPorUnidadePage />} />
      <Route path={urlsChamadaPublica2025.relatorioMatriculadosPorPeriodoNominal} element={<RelatorioMatriculadosPorPeriodoNominalPage />} />
      <Route path={urlsChamadaPublica2025.relatorioMatriculadosPorPeriodoQuantitativo} element={<RelatorioMatriculadosPorPeriodoQuantitativoPage />} />
      <Route path={urlsChamadaPublica2025.relatorioMatriculadosPorGeral} element={<RelatorioMatriculadosPorGeralPage />} />
      <Route path={urlsChamadaPublica2025.remanejamento} element={<ChamadaPublicaInscricoesRemanejamentoPage />} />
      <Route path={urlsChamadaPublica2025.remanejamentoId} element={<ChamadaPublicaInscricoesRemanejamentoPage />} />

      <Route exact path={urlsGestaoUnidades.matriculas} element={ <ChamadaPublicaMatriculas gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculaEnturmarId} element={ <MatriculasEnturmar gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculasIndeferir} element={ <MatricularIndeferir gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculasIndeferir} element={ <MatricularIndeferir gestao={false} />}/>
      <Route exact path={urlsChamadaPublica2025.alunosManuais} element={ <AlunosManuaisPage />}/>
      <Route exact path={urlsChamadaPublica2025.alunosPreMatricula} element={ <AlunosPreMatriculaPage  />}/>
      <Route exact path={urlsChamadaPublica2025.alunosRemanejamento} element={ <AlunosRemanejamentoPage  />}/>
      <Route exact path={urlsChamadaPublica2025.alunosPesquisa} element={ <ListarAlunoPorNomeChamadaPublica />}/>
      {/* <Route exact path={urlsChamadaPublica2025.visualizacaoIdv2} element={ <VisualizacaoFormularioChamadaPublicaPagev2 />}/> */}
    </>
    )}
  </React.Fragment>
);

export default ChamadaPublicaRoutes2025;
