import { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../../../../components/breadcrumb";
import Table from "../../../../../components/table";
import BaseAdministrativo from "../../../baseAdministrativo";
import urlsAdministrativo from "../../../urlsAdministrativo";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteProdutoEstoqueById,
  getAllProdutosEstoque,
  getProdutoByNome,
  getRelatorioEstoque,
} from "../../../../../services/administrativo/patrimonio-estoque";
import Produtos from "./cadastrar/tab/produtos";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ProdutosEmLote from "./cadastrar/tab/lote";
import { conservacao, tipoProduto } from "../data";
import { getUnidades } from "../../../../../services/unidades";
import BaixaProdutoAdministrativo from "../baixa";

const AlmoxarifadoEstoque = () => {
  const estoqueSkip = localStorage.getItem("estoqueSkip");
  const estoqueLimit = localStorage.getItem("estoqueLimit");

  const navigate = useNavigate();
  const [produtos, setProdutos] = useState([]);
  const [newData, setNewData] = useState();
  const [skip, setSkip] = useState(estoqueSkip ? estoqueSkip : 0);
  const [limit, setLimit] = useState(estoqueLimit ? estoqueLimit : 20);
  const [selectedChip, setSelectedChip] = useState("Estoque Geral");
  const [unidadeFilter, setUnidadeFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [produtoIdFilter, setProdutoIdFilter] = useState(0);
  const [marcaFilter, setMarcaFilter] = useState("");
  const [tipoProdutoFilter, setTipoProdutoFilter] = useState(0);
  const [conservacaoFilter, setConservacaoFilter] = useState(0);
  const [validadeInicioFilter, setValidadeInicioFilter] = useState(null);
  const [validadeFimFilter, setValidadeFimFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [produto, setProduto] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openModalLote, setOpenModalLote] = useState(false);
  const [openModalProduto, setOpenModalProduto] = useState(false);
  const [openModalBaixa, setOpenModalBaixa] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModalLote = () => {
    setOpenModalLote(true);
  };

  const handleCloseModalLote = () => {
    setOpenModalLote(false);
  };

  const handleClickOpenModalProduto = () => {
    setOpenModalProduto(true);
  };

  const handleCloseModalProduto = () => {
    setOpenModalProduto(false);
  };

  const handleClickOpenModalBaixa = () => {
    setOpenModalBaixa(true);
  };

  const handleCloseModalBaixa = () => {
    setOpenModalBaixa(false);
  };

  const handleChipSelect = async (chip) => {
    await setSelectedChip(chip);
    await refetchEstoque();
  };

  const { data: unidades } = useQuery("getUnidades", getUnidades, {
    enabled: true,
  });

  const { data: estoqueData, refetch: refetchEstoque } = useQuery({
    queryKey: "getProdutosEstoque",
    queryFn: () =>
      getAllProdutosEstoque({
        skip,
        limit,
        searchText: searchFilter,
        produtoId: produtoIdFilter,
        marca: marcaFilter,
        tipoProduto: tipoProdutoFilter,
        conservacao: conservacaoFilter,
        validadeInicio: validadeInicioFilter,
        validadeFim: validadeFimFilter,
        unidadeId: unidadeFilter,
      }),
    enabled: true,
    onSuccess: (data) => {
      setNewData(data?.data);
    },
  });

  const { data } = useQuery("getProdutos", () => getProdutoByNome(), {
    enabled: true,
    onSuccess: (data) => {
      setProdutos(
        data?.data.map((produto) => ({
          label: produto.nome,
          id: produto.id,
        }))
      );
    },
  });

  const handleDownloadExcel = async () => {
    await getRelatorioEstoque({
      skip,
      limit,
      searchText: searchFilter,
      produtoId: produtoIdFilter,
      marca: marcaFilter,
      tipoProduto: tipoProdutoFilter,
      conservacao: conservacaoFilter,
      validadeInicio: validadeInicioFilter,
      validadeFim: validadeFimFilter,
      unidadeId: unidadeFilter,
    });
  };

  const { mutate } = useMutation((id) => deleteProdutoEstoqueById(id), {
    onSuccess: (data) => {
      toast.success("Produto excluído com sucesso.");
      refetchEstoque();
    },
    onError: (data) => {
      toast.error("Erro ao excluir produto. Tente novamente mais tarde.");
    },
  });

  let BRLFormat = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  };

  const getTipoProdutoLabel = (value) => {
    const tipo = tipoProduto.find((item) => item.value === value);
    return tipo ? tipo.label : "-";
  };

  const getConservacaoLabel = (value) => {
    const conservacaoItem = conservacao.find((item) => item.value === value);
    return conservacaoItem ? conservacaoItem.label : "-";
  };

  const formatDate = (date) => {
    const offsetDate = new Date(date);
    offsetDate.setMinutes(
      offsetDate.getMinutes() + offsetDate.getTimezoneOffset()
    );
    return offsetDate.toLocaleDateString("pt-BR");
  };

  useEffect(() => {
    refetchEstoque();
    localStorage.setItem("estoqueLimit", limit);
    localStorage.setItem("estoqueSkip", skip);
  }, [skip, limit, openModalBaixa]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "produtoImagem",
        Cell: ({ value }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={`data:image/jpeg;base64,${value}`}
              sx={{
                width: 45,
                height: 45,
                border: "1px solid rgba(0, 0, 0, 0.38)",
                padding: 1,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
            />
          </div>
        ),
      },
      { Header: "Produto", accessor: "nomeProduto" },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        Cell: ({ value }) => getTipoProdutoLabel(value),
      },
      {
        Header: "Validade",
        accessor: "dataValidade",
        Cell: ({ value }) =>
          value ? new Date(value).toLocaleDateString("pt-BR") : "-",
      },
      { Header: "Patrimônio", accessor: "numeroPatrimonio" },
      {
        Header: "Valor",
        accessor: "valor",
        Cell: ({ row }) =>
          row.original.valor ? formatValue(row.original.valor) : "-",
      },
      { Header: "Quantidade", accessor: "quantidade" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex" }}>
            <Tooltip
              title={selectedChip === "Estoque Geral" ? "Editar" : "Visualizar"}
            >
              <IconButton
                onClick={() =>
                  navigate(
                    urlsAdministrativo.patrimonioAlmoxarifadoEstoqueCadastrar +
                      row.original.id,
                    {
                      state: {
                        data: row.original,
                        isEdit: selectedChip === "Estoque Geral" ? true : false,
                      },
                    }
                  )
                }
                size="large"
                color="inherit"
              >
                {selectedChip === "Estoque Geral" ? (
                  <EditIcon color="primary" />
                ) : (
                  <VisibilityIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
            {selectedChip === "Estoque Geral" ? (
              <Tooltip title="Excluir">
                <IconButton
                  onClick={() => {
                    const confirm = window.confirm(
                      "Tem certeza que deseja excluir?"
                    );
                    if (confirm) {
                      mutate(row.original.id);
                    }
                  }}
                  size="large"
                  color="inherit"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            ) : null}
            {selectedChip === "Estoque Geral" ? (
              <Tooltip title="Baixa">
                <IconButton
                  onClick={() => {
                    handleClickOpenModalBaixa();
                    setProduto(row.original);
                  }}
                  size="large"
                  color="inherit"
                >
                  <CancelIcon color="warning" />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        ),
      },
    ],
    [newData, selectedChip]
  );

  return (
    <BaseAdministrativo>
      <Breadcrumb
        title={"Estoque"}
        itens={["Administrativo", "Patrimônio", "Estoque"]}
        hasExcelButton
        onClickDownload={() => handleDownloadExcel()}
        // hasModal
        hasModalAlter
        // modalTitulo="Add em Lote"
        modalAlterTitulo="Nova Entrada"
        // onClickModal={handleClickOpenModalLote}
        onClickModalAlter={handleClickOpenModalProduto}
      />
      <div className="card">
        <div className="d-flex gap-2 m-2">
          <Chip
            label="Estoque Geral"
            color={selectedChip === "Estoque Geral" ? "success" : "default"}
            onClick={() => {
              setUnidadeFilter(null);
              handleChipSelect("Estoque Geral");
            }}
          />
          <Chip
            label="Estoque Unidades"
            color={selectedChip === "Estoque Unidades" ? "success" : "default"}
            variant="filled"
            onClick={() => handleChipSelect("Estoque Unidades")}
          />
        </div>
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              <div className="mt-4">
                {selectedChip === "Estoque Unidades" ? (
                  <Autocomplete
                    disablePortal
                    options={unidades}
                    getOptionLabel={(option) => option?.nome || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      unidades?.find(
                        (unidade) => unidade.id === unidadeFilter
                      ) || null
                    }
                    onChange={async (_, selectedOption) => {
                      const unidadeId = selectedOption
                        ? selectedOption.id
                        : localStorage.getItem("unidadeID");
                      await setUnidadeFilter(unidadeId);
                      await refetchEstoque();
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="UNIDADE" />
                    )}
                    isClearable
                  />
                ) : null}
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="NOME"
                    value={searchFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setSearchFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="MARCA"
                    value={marcaFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setMarcaFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="tipoProdutoFilter">TIPO</InputLabel>
                  <Select
                    labelId="tipoProdutoFilter"
                    id="tipoProdutoFilter"
                    value={tipoProdutoFilter}
                    label="Tipo"
                    onChange={async (e) => {
                      await setTipoProdutoFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {tipoProduto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="conservacao">CONSERVAÇÃO</InputLabel>
                  <Select
                    labelId="conservacao"
                    id="conservacao"
                    value={conservacaoFilter}
                    label="Conservação"
                    onChange={async (e) => {
                      await setConservacaoFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {conservacao.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={produtos}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="PRODUTO" />
                    )}
                    onChange={async (_, newValue) => {
                      if (newValue) {
                        await setProdutoIdFilter(newValue.id);
                        await refetchEstoque();
                      }
                    }}
                  />
                </FormControl>
              </div>

              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA VALIDADE INÍCIO"
                    value={validadeInicioFilter}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setValidadeInicioFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA VALIDADE FIM"
                    value={validadeFimFilter}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setValidadeFimFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={async () => {
                    await setSearchFilter("");
                    await setTipoProdutoFilter(0);
                    await setProdutoIdFilter(0);
                    await setMarcaFilter("");
                    await setConservacaoFilter(0);
                    await setValidadeInicioFilter(null);
                    await setValidadeFimFilter(null);
                    await refetchEstoque();
                  }}
                />
              </div>
            </div>
          </Menu>

          {selectedChip === "Estoque Unidades" ? (
            <Chip
              color="info"
              label={`UNIDADE: ${
                unidades?.find((item) => item.id == unidadeFilter)?.nome || "-"
              }`}
              variant="filled"
            />
          ) : null}
          {tipoProdutoFilter > 0 ? (
            <Chip
              color="info"
              label={`TIPO: ${
                tipoProduto.find((item) => item.value === tipoProdutoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setTipoProdutoFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {conservacaoFilter > 0 ? (
            <Chip
              color="info"
              label={`CONSERVAÇÃO: ${
                conservacao.find((item) => item.value === conservacaoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setConservacaoFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {marcaFilter != "" ? (
            <Chip
              color="info"
              label={`MARCA: ${marcaFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setMarcaFilter("");
                await refetchEstoque();
              }}
            />
          ) : null}
          {searchFilter != "" ? (
            <Chip
              color="info"
              label={`NOME: ${searchFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setSearchFilter("");
                await refetchEstoque();
              }}
            />
          ) : null}
          {produtoIdFilter > 0 ? (
            <Chip
              color="info"
              label={`PRODUTO: ${
                produtos.find((produto) => produto.id === produtoIdFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setProdutoIdFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {validadeInicioFilter && validadeFimFilter != null ? (
            <Chip
              color="info"
              label={`VALIDADE: ${formatDate(
                validadeInicioFilter
              )} - ${formatDate(validadeFimFilter)}`}
              variant="outlined"
              onDelete={async () => {
                await setValidadeInicioFilter(null);
                await setValidadeFimFilter(null);
                await refetchEstoque();
              }}
            />
          ) : null}
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={estoqueData?.total}
          columns={columns}
          data={estoqueData?.data ? estoqueData?.data : []}
          paginationKey="estoquePage"
          validatePage
        />
      </div>
      {/* <Dialog
        open={openModalLote}
        onClose={handleCloseModalLote}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          ADICIONAR PRODUTO EM LOTE
        </DialogTitle>
        <DialogContent>
          <ProdutosEmLote onClose={handleCloseModalLote} />
        </DialogContent>
      </Dialog> */}
      <Dialog
        open={openModalProduto}
        onClose={handleCloseModalProduto}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          ADICIONAR PRODUTO
        </DialogTitle>
        <DialogContent>
          <Produtos
            hasBackButton={false}
            onClose={handleCloseModalProduto}
            onRefetch={refetchEstoque}
            canCreate
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModalBaixa}
        onClose={handleCloseModalBaixa}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          BAIXA DO PRODUTO
        </DialogTitle>
        <DialogContent>
          <BaixaProdutoAdministrativo
            produto={produto}
            onRefetch={refetchEstoque}
            onClose={handleCloseModalBaixa}
          />
        </DialogContent>
      </Dialog>
    </BaseAdministrativo>
  );
};
export default AlmoxarifadoEstoque;
