import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getEscolaridades } from "../../../../services/escolaridades";
import { getUnidadesEscolaridades, adicionarUnidadeEscolaridade, deletarUnidadeEscolaridade } from "../../../../services/unidades";
import { store } from "../../../../store";
import { toast } from "react-toastify";
import { getTurmasUnidadeEscolaridadeTurno } from "../../../../services/turmas";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const EscolaridadesTab = ({ unidadeId }) => {
  const [escolaridades, setEscolaridades] = useState([]);
  const [selectedEscolaridade, setSelectedEscolaridade] = useState(null);
  const [unidadesEscolaridades, setUnidadesEscolaridades] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const currentUser = store.getState()["user"]["user"];

  useEffect(() => {
    const fetchUnidadesEscolaridades = async () => {
      try {
        const response = await getUnidadesEscolaridades(unidadeId);
        const descricaoList = response.map((item) => item.descricao);
        setUnidadesEscolaridades(descricaoList);
      } catch (error) {
        console.error("Erro ao buscar escolaridades da unidade", error);
      }
    };

    const fetchEscolaridades = async () => {
      try {
        const response = await getEscolaridades();
        const escolaridadesData = response.sort((a, b) =>
          a.descricao.localeCompare(b.descricao)
        );
        setEscolaridades(escolaridadesData);
      } catch (error) {
        console.error("Erro ao buscar escolaridades", error);
      }
    };

    const fetchTurmasUnidadeEscolaridadeTurno = async () => {
      try {
        const response = await getTurmasUnidadeEscolaridadeTurno({unidadeId, anoLetivo: 2025, limit: 200});
        setTurmas(response.data);
      } catch (error) {
        console.error("Erro ao buscar turmas da unidade", error);
      }
    };

    if (unidadeId) {
      fetchTurmasUnidadeEscolaridadeTurno();
      fetchEscolaridades();
      fetchUnidadesEscolaridades();
    }
  }, [unidadeId]);

  const handleAdicionarClick = async () => {
    if (selectedEscolaridade) {
      try {
        const response = await adicionarUnidadeEscolaridade({
          unidadeId: parseInt(unidadeId),
          escolaridadeId: selectedEscolaridade.id,
          userId: currentUser.servidorId,
        });

        if (response.statusCode === 200 || response.statusCode === 201) {
          toast.success("Escolaridade adicionada com sucesso");
          setUnidadesEscolaridades((prev) => [...prev, selectedEscolaridade.descricao]);
        } else {
          toast.error("Erro ao adicionar escolaridade");
        }
      } catch (error) {
        console.error("Erro ao adicionar escolaridade", error);
        toast.error("Erro ao adicionar escolaridade");
      }
    } else {
      toast.error("Selecione uma escolaridade");
    }
  };

  const filteredEscolaridades = escolaridades.filter(
    (escolaridade) =>
      turmas.some((turma) => turma.escolaridadeDescricao === escolaridade.descricao) &&
      !unidadesEscolaridades.includes(escolaridade.descricao)
  );

  const handleDeleteClick = async (descricao) => {
    try {
      const escolaridadeId = escolaridades.find((item) => item.descricao === descricao).id;
      const response = await deletarUnidadeEscolaridade(unidadeId, escolaridadeId);

      if (response.statusCode === 200 || response.statusCode === 201) {
        toast.success("Escolaridade removida com sucesso");
        setUnidadesEscolaridades((prev) => prev.filter((item) => item !== descricao));
      } else {
        toast.error("Erro ao remover escolaridade");
      }
    } catch (error) {
      console.error("Erro ao remover escolaridade", error);
      toast.error("Erro ao remover escolaridade");
    }
  }

  return (
    <Box padding={3}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Alert severity="warning">Essa aba é reponsável em configurar quais serão as escolaridades da unidade que poderão incluir alunos sem passar pelo processo de aprovação.</Alert>
        </Grid>

        {/* Escolaridade Autocomplete Field */}
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={filteredEscolaridades}
            getOptionLabel={(option) => option.descricao || ""}
            value={selectedEscolaridade}
            onChange={(event, newValue) => setSelectedEscolaridade(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Escolaridade"
                variant="outlined"
                placeholder="Selecione a escolaridade"
              />
            )}
          />
        </Grid>

        {/* Adicionar Button */}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAdicionarClick}
          >
            Adicionar
          </Button>
        </Grid>

        {/* Table for Unidades Escolaridades */}
        {unidadesEscolaridades.length > 0 && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Escolaridades</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unidadesEscolaridades.map((descricao, index) => (
                    <TableRow key={index}>
                      <TableCell>{descricao}</TableCell>
                      <TableCell align="center">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteClick(descricao)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};


export default EscolaridadesTab;