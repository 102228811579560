import React from "react";
import { Grid, TextField, MenuItem, Button, Box } from "@mui/material";
import { createUnidade, updateUnidade } from "../../../../services/unidades";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DadosTab = ({ unidadeData, isEdit }) => {
    const [formData, setFormData] = React.useState({
        nome: "",
        sigla: "",
        endereco: {
            cep: "",
            logradouro: "",
            numero: 0,
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
        },
        contato: {
            telefone: "",
            email: "",
        },
        status: 1,
        unidadeLotacao: 1,
        unidadeLocacao: 1,
        nomeDiretor: "",
        inep: "",
    });

    React.useEffect(() => {
        if (isEdit && unidadeData) {
            setFormData(unidadeData);
        }
    }, [isEdit, unidadeData]);

    const handleChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleNestedChange = (field, nestedField, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                [nestedField]: value,
            },
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {


            if (isEdit) {
                debugger;
                const response = await updateUnidade(formData);
                if (response.statusCode === 200 || response.statusCode === 201) {
                    toast.success("Unidade atualizada com sucesso!");
                }
                // Add update logic here
            } else {
                const response = await createUnidade(formData);
                debugger;
                if (response.statusCode === 200 || response.statusCode === 201) {
                    toast.success("Unidade cadastrada com sucesso!");
                }
                // Add create logic here
            }
        } catch (error) {
            toast.error("Erro ao salvar unidade");
        }
    };

    return (
        <Box padding={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nome"
                        fullWidth
                        value={formData.nome}
                        onChange={(e) => handleChange("nome", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Sigla"
                        fullWidth
                        value={formData.sigla}
                        onChange={(e) => handleChange("sigla", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="CEP"
                        fullWidth
                        value={formData.endereco.cep}
                        onChange={(e) => handleNestedChange("endereco", "cep", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        label="Logradouro"
                        fullWidth
                        value={formData.endereco.logradouro}
                        onChange={(e) => handleNestedChange("endereco", "logradouro", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Número"
                        fullWidth
                        value={formData.endereco.numero}
                        type="number"
                        onChange={(e) => handleNestedChange("endereco", "numero", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Complemento"
                        fullWidth
                        value={formData.endereco.complemento}
                        onChange={(e) => handleNestedChange("endereco", "complemento", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Bairro"
                        fullWidth
                        value={formData.endereco.bairro}
                        onChange={(e) => handleNestedChange("endereco", "bairro", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Cidade"
                        fullWidth
                        value={formData.endereco.cidade}
                        onChange={(e) => handleNestedChange("endereco", "cidade", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Estado"
                        fullWidth
                        value={formData.endereco.estado}
                        onChange={(e) => handleNestedChange("endereco", "estado", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Telefone"
                        fullWidth
                        value={formData.contato.telefone}
                        onChange={(e) => handleNestedChange("contato", "telefone", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email"
                        fullWidth
                        value={formData.contato.email}
                        onChange={(e) => handleNestedChange("contato", "email", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        label="Status"
                        fullWidth
                        value={formData.status}
                        onChange={(e) => handleChange("status", e.target.value)}
                    >
                        <MenuItem value={1}>ATIVO</MenuItem>
                        <MenuItem value={0}>INATIVO</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        label="Unidade de Exercício?"
                        fullWidth
                        value={formData.unidadeLotacao}
                        onChange={(e) => handleChange("unidadeLotacao", e.target.value)}
                    >
                        <MenuItem value={1}>SIM</MenuItem>
                        <MenuItem value={0}>NÃO</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        label="Unidade Locação?"
                        fullWidth
                        value={formData.unidadeLocacao}
                        onChange={(e) => handleChange("unidadeLocacao", e.target.value)}
                    >
                        <MenuItem value={1}>SIM</MenuItem>
                        <MenuItem value={0}>NÃO</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nome Diretor"
                        fullWidth
                        value={formData.nomeDiretor}
                        onChange={(e) => handleChange("nomeDiretor", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="INEP"
                        fullWidth
                        value={formData.inep}
                        onChange={(e) => handleChange("inep", e.target.value)}
                    />
                </Grid>
            </Grid>
            <Box marginTop={3} textAlign="right">
                <Button variant="contained" color="warning" onClick={() => navigate(-1)} style={{ marginRight: 8 }}>
                    Voltar
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {isEdit ? "Atualizar" : "Cadastrar"}
                </Button>
            </Box>
        </Box>
    );
};

export default DadosTab;
