import urlsGestaoUnidades from "./urlsGestaoUnidades";
import { store } from "../../store";

const currentUser = store.getState()["user"]["user"];

const links = (
  moduloGestaoUnidadesGrupoGestaoEducacional,
  moduloGestaoUnidadesGrupoAcademico,
  moduloGestaoUnidadesGrupoDiario,
  moduloGestaoUnidadesGrupoTransferencias,
  moduloGestaoUnidadesGrupoFamiliaConectada,
  moduloGestaoUnidadesFrequenciaServidores,
  moduloGestaoUnidadesEstoque
) => {
  return {
    gestao_educacional: {
      id: "gestao_educacional",
      text: "GESTÃO EDUCACIONAL",
      type: "title",
      visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
      itens: {
        dashboard: {
          id: "dashboard",
          text: "DASHBOARD",
          path: urlsGestaoUnidades.dashboard,
          iconName: "",
          icon: "dashboard",
          visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
        },
        servidores: {
          id: "servidores",
          text: "SERVIDORES",
          path: urlsGestaoUnidades.servidores,
          iconName: "",
          icon: "group",
          visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
        },
        servidores2: {
          id: "colaboradores2",
          text: "FREQUÊNCIA",
          path: urlsGestaoUnidades.servidorFrenquenciaLancamento,
          iconName: "",
          icon: "schedule",
          visible: moduloGestaoUnidadesFrequenciaServidores === 1,
        },
        calendario: {
          id: "calendario",
          text: "CALENDÁRIO",
          path: urlsGestaoUnidades.calendario,
          iconName: "",
          icon: "badge",
          visible: false,
        },
      },
    },
    almoxarifaxo: {
      id: "almoxarifaxo",
      text: "ALMOXARIFADO",
      type: "title",
      visible: moduloGestaoUnidadesEstoque == 1,
      itens: {
        estoque: {
          id: "estoque",
          text: "ESTOQUE",
          path: urlsGestaoUnidades.estoque,
          iconName: "",
          icon: "storefront",
          visible: true,
        },
        baixas: {
          id: "baixas",
          text: "BAIXAS",
          path: urlsGestaoUnidades.baixas,
          iconName: "",
          icon: "recycling",
          visible: true,
        },
        produtos: {
          id: "produtos",
          text: "PRODUTOS",
          path: urlsGestaoUnidades.produtos,
          iconName: "",
          icon: "local_offer",
          visible: true,
        },
        solicitacoes: {
          id: "solicitacoes",
          text: "SOLICITAÇÕES",
          path: urlsGestaoUnidades.solicitacoesEstoque,
          iconName: "",
          icon: "checklist",
          visible: true,
        },
        transferencias: {
          id: "transferencias",
          text: "TRANSFERÊNCIAS",
          path: urlsGestaoUnidades.transferencias,
          iconName: "",
          icon: "swap_horiz",
          visible: true,
        },
      },
    },
    academico: {
      id: "academico",
      text: "ACADÊMICO",
      type: "title",
      visible: moduloGestaoUnidadesGrupoAcademico == 1,
      itens: {
        alunos: {
          id: "alunos",
          text: "ALUNOS",
          path: urlsGestaoUnidades.alunos,
          iconName: "",
          icon: "group",
          visible: moduloGestaoUnidadesGrupoAcademico == 1,
        },
        matricula: {
          id: "matricula",
          text: "MATRÍCULAS",
          path: urlsGestaoUnidades.matriculas,
          iconName: "",
          icon: "group_add",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEMED" &&
            moduloGestaoUnidadesGrupoAcademico == 1,
        },
        matricula2025: {
          id: "matricula",
          text: "MATRÍCULAS",
          path: urlsGestaoUnidades.matriculas,
          iconName: "",
          icon: "group_add",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            moduloGestaoUnidadesGrupoAcademico == 1,
        },
        // matricula2024: {
        //   id: "matricula2024",
        //   text: "MATRÍCULAS 2024",
        //   path: urlsGestaoUnidades.matriculas2024,
        //   iconName: "",
        //   icon: "group_add",
        //   visible:
        //     process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
        //     moduloGestaoUnidadesGrupoAcademico == 1,
        // },
        turmas: {
          id: "turmas",
          text: "TURMAS",
          path: urlsGestaoUnidades.turmas,
          iconName: "",
          icon: "class",
          visible: moduloGestaoUnidadesGrupoAcademico == 1,
        },
      },
    },
    // comunicacao: {
    //   id: 'comunicacao',
    //   text: 'COMUNICAÇÃO',
    //   type: 'title',
    //   visible: true,
    //   itens: {
    //     chat: {
    //       id: 'chat',
    //       text: 'CHAT',
    //       path: urlsGestaoUnidades.chat,
    //       icon: 'chat',
    //       visible: true,
    //     },
    //   }
    // },

    transferencias: {
      id: "transferencias",
      text: "TRANSFERÊNCIAS",
      type: "title",
      visible: moduloGestaoUnidadesGrupoAcademico == 1,
      itens: {
        recebidas: {
          id: "recebidas",
          text: "RECEBIDAS",
          path: urlsGestaoUnidades.transferenciasRecebidas, //alterar nova url de gestao
          iconName: "",
          icon: "groups",
          visible: moduloGestaoUnidadesGrupoAcademico == 1,
        },
        enviadas: {
          id: "enviadas",
          text: "ENVIADAS",
          path: urlsGestaoUnidades.transferenciasEnviadas, //alterar nova url de gestao
          iconName: "",
          icon: "groups",
          visible: moduloGestaoUnidadesGrupoAcademico == 1,
        },
      },
    },

    ti: {
      id: "ti",
      text: "TI",
      type: "title",
      visible: true,
      itens: {
        chamados: {
          id: "chamados",
          text: "CHAMADOS",
          path: urlsGestaoUnidades.chamadosImpressoras,
          iconName: "",
          icon: "support",
          visible: true,
        },
        impressora: {
          id: "impressora",
          text: "IMPRESSORA",
          visible: true,
          iconName: "",
          path: urlsGestaoUnidades.controleImpressoras, //alterar nova url de gestao
          icon: "print",
        },
      },
    },

    diario: {
      id: "diario",
      text: "DIÁRIO",
      type: "title",
      visible: moduloGestaoUnidadesGrupoDiario == 1,
      itens: {
        quadro_horario: {
          id: "quadro_horario",
          text: "QUADRO DE HORÁRIO",
          path: urlsGestaoUnidades.quadroHorario,
          iconName: "",
          icon: "calendar_view_week",
          visible: moduloGestaoUnidadesGrupoDiario == 1,
        },

        // diario: {
        //   id: 'diario',
        //   text: 'DIÁRIO DE CLASSE',
        //   path: urlsGestaoUnidades.diarioClasse,
        //   icon: 'content_paste_go',
        //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && moduloGestaoUnidadesGrupoDiario == 1,
        // },
        faltas: {
          id: 'faltas',
          text: 'FALTAS',
          path: urlsGestaoUnidades.faltas,
          icon: 'spellcheck',
          visible: moduloGestaoUnidadesGrupoDiario == 1,
        },
        // fichaAvaliacao: {
        //   id: 'fichas',
        //   text: 'FICHAS DE AVALIAÇÃO',
        //   path: urlsGestaoUnidades.fichaAvaliacao,
        //   icon: 'collections_bookmark',
        //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEDUC' && moduloGestaoUnidadesGrupoDiario == 1,
        // },
        bnncFundamental: {
          id: "bnnc-fundamentos",
          text: "BNCC FUNDAMENTAL",
          path: urlsGestaoUnidades.bnccFundamental,
          iconName: "",
          icon: "school",
          visible: true,
        },
        bnccInfanitl: {
          id: "bnnc-infantil",
          text: "BNCC INFANTIL",
          path: urlsGestaoUnidades.bnccInfantil,
          iconName: "",
          icon: "child_care",
          visible: true,
        },
      },
    },
    fichaAvaliacao: {
      id: "fichaAvaliacao",
      text: "FICHA AVALIAÇÃO",
      type: "title",
      visible:
        process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
        moduloGestaoUnidadesGrupoDiario == 1,
      itens: {
        fichaAvaliacaoInfantil: {
          id: "fichaAvaliacaoInfantil",
          text: "INFANTIL",
          path: urlsGestaoUnidades.fichaAvaliacaoInfantil,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoPrimeiroAno: {
          id: "fichaAvaliacaoPrimeiroAno",
          text: "1º ANO",
          path: urlsGestaoUnidades.fichaAvaliacaoPrimeiroAno,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoSegundoAno: {
          id: "fichaAvaliacaoSegundoAno",
          text: "2º ANO",
          path: urlsGestaoUnidades.fichaAvaliacaoSegundoAno,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoTerceiroNonoAno: {
          id: "fichaAvaliacaoTerceiroNonoAno",
          text: "3º ANO AO 9º ANO",
          path: urlsGestaoUnidades.fichaAvaliacaoTerceiroNonoAno,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoPrimeiroAnoEja: {
          id: "fichaAvaliacaoPrimeiroAnoEja",
          text: "EJA FASE I",
          path: urlsGestaoUnidades.fichaAvaliacaoPrimeiroAnoEja,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoSegundoAnoEja: {
          id: "fichaAvaliacaoSegundoAnoEja",
          text: "EJA FASE II",
          path: urlsGestaoUnidades.fichaAvaliacaoSegundoAnoEja,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        fichaAvaliacaoTerceiroAnoEja: {
          id: "fichaAvaliacaoTerceiroAnoEja",
          text: "EJA FASE III A FASE VIII",
          path: urlsGestaoUnidades.fichaAvaliacaoTerceiroAnoEja,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
      },
    },
    riap: {
      id: "riap",
      text: "DIÁRIOS DE CLASSE",
      type: "title",
      visible:
        process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
        moduloGestaoUnidadesGrupoDiario == 1,
      // visible: false,
      itens: {
        riapCreche: {
          id: "riapCreche",
          text: "CRECHE | PRÉ",
          path: urlsGestaoUnidades.riapCreche,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        riapInfantil: {
          id: "riapInfantil",
          text: "1 E 2 ANO",
          path: urlsGestaoUnidades.riapInfantil,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        riapFundamental: {
          id: "riapFundamental",
          text: "3 AO 9 ANO",
          path: urlsGestaoUnidades.riapFundamental,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
        riapEja: {
          id: "riapInfantil",
          text: "EJA",
          path: urlsGestaoUnidades.riapEja,
          iconName: "",
          icon: "collections_bookmark",
          visible:
            process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            moduloGestaoUnidadesGrupoDiario == 1,
        },
      },
    },
    familiaConectada: {
      id: "familiaConectada",
      text: "FAMÍLIA CONECTADA",
      type: "title",
      visible: moduloGestaoUnidadesGrupoFamiliaConectada === 1,
      itens: {
        avisos: {
          id: "avisos",
          text: "AVISOS",
          path: urlsGestaoUnidades.familiaConectadaAvisos,
          iconName: "",
          icon: "collections_bookmark",
          visible: moduloGestaoUnidadesGrupoFamiliaConectada === 1,
        },
        atendimento: {
          id: "atendimento",
          text: "ATENDIMENTO",
          path: urlsGestaoUnidades.familiaConectadaAtendimento,
          iconName: "",
          icon: "collections_bookmark",
          visible: moduloGestaoUnidadesGrupoFamiliaConectada === 1,
        },
      },
    },
  };
};

export default links;
