import { useMemo, useState } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getMemorandos, deleteMemorando } from '../../../../services/administrativo/financeiro-memorandos';
import urls from "../../../../utils/urls";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import { listaAnexo } from "../../../../services/processos/remanejamento";

const MemorandosTable = () => {
  const [newData, setNewData] = useState([])
  const navigate = useNavigate();

  const { data: memorandosData, refetch: refetchData } = useQuery('getMemorandos', getMemorandos, {
    retry: false,
    enabled: true,
    onSuccess: (data) => {
      setNewData(data.data)
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteMemorando, {
    onSuccess: () => {
      toast.success('Memorando excluído com sucesso.');
      refetchData();
    },
    onError: () => {
      toast.error('Algo de errado aconteceu. Tente novamente mais tarde.')
    }
  });

  
const handleDownload = async (arquivoId) => {
  try {
    // Recebe o arquivo da função listaAnexo (agora como Blob)
    const arquivoBlob = await listaAnexo(arquivoId);

    // Gera uma URL para o Blob
    const blobUrl = URL.createObjectURL(arquivoBlob);

    const isOpen = window.open(blobUrl, "_blank");
    if (!isOpen) {
      alert("Permita pop-ups para visualizar/baixar o arquivo.");
    }

    window.open(blobUrl, "_blank");

    // Revogar a URL após abrir o arquivo para liberar memória
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
  }
};

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: 'Status', accessor: 'ativo',
        Cell: ({ row }) => (
          row.original.ativo === true ? 'Ativo' : 'Inativo'
        )
      },
      {
        Header: 'Data de envio', accessor: 'dataEnvio',
        Cell: ({ row }) => (
          row.original.dataEnvio.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'Usuário', accessor: 'nomeUsuario' },
      {
        Header: 'Anexo', accessor: 'anexo',
        Cell: ({ row }) => (
          <button
            className='btn btn-sm'
            style={{ padding: '8px' }}
            onClick={() => {
              handleDownload(row.original.anexo)
            }}
            disabled={row.original.anexo === '' || row.original.anexo === null || !row.original.anexo}
          >
            Baixar
          </button>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.financeiroMemorandosCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                descricao: row.original.descricao,
                ativo: row.original.ativo,
                anexo: row.original.anexo
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Memorandos'} itens={['Administrativo', 'Financeiro', 'Memorandos']} actions={{ link: urlsAdministrativo.financeiroMemorandosCadastrar, text: "Novo Memorando" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={newData} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default MemorandosTable;
