import { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Breadcrumb from "../../../../../components/breadcrumb";
import EditIcon from "@mui/icons-material/Edit";
import Table from "../../../../../components/table";
import BaseAdministrativo from "../../../baseAdministrativo";
import BaseGestaoUnidades from "../../../../gestao-unidades/baseGestaoUnidades";
import urlsAdministrativo from "../../../urlsAdministrativo";
import urlsGestaoUnidades from "../../../../gestao-unidades/urlsGestaoUnidades";
import {
  deleteProduto,
  getAllProdutos,
  getProdutoByNome,
  getRelatorioProdutos,
} from "../../../../../services/administrativo/patrimonio-estoque";
import Produto from "./cadastrar/tab/produtos";
import { tipoProduto } from "../data";
import { getGrupoProdutos } from "../../../../../services/administrativo/patrimonio-produto";

const AlmoxarifadoProdutos = ({ gestaoUnidades }) => {
  const produtosSkip = localStorage.getItem("produtosSkip");
  const produtosLimit = localStorage.getItem("produtosLimit");

  const navigate = useNavigate();
  const [produtos, setProdutos] = useState([]);
  const [categoriasAtivas, setCategoriasAtivas] = useState([]);
  const [newData, setNewData] = useState();
  const [skip, setSkip] = useState(produtosSkip ? produtosSkip : 0);
  const [limit, setLimit] = useState(produtosLimit ? produtosLimit : 20);
  const [searchFilter, setSearchFilter] = useState("");
  const [marcaFilter, setMarcaFilter] = useState("");
  const [tipoProdutoFilter, setTipoProdutoFilter] = useState(0);
  const [categoriaFilter, setCategoriaFilter] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openModalProduto, setOpenModalProduto] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const open = Boolean(anchorEl);
  const BaseComponent = gestaoUnidades
    ? BaseGestaoUnidades
    : BaseAdministrativo;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModalProduto = () => {
    setOpenModalProduto(true);
  };

  const handleCloseModalProduto = () => {
    setOpenModalProduto(false);
  };

  const { data: produtosData, refetch: refetchProdutos } = useQuery({
    queryKey: "getAllProdutos",
    queryFn: () =>
      getAllProdutos({
        skip,
        limit,
        nome: searchFilter,
        marca: marcaFilter,
        tipoProduto: tipoProdutoFilter,
        categoriaId: categoriaFilter,
      }),
    enabled: true,
    onSuccess: (data) => {
      setNewData(data?.data);
    },
  });

  const { data } = useQuery("getProdutos", () => getProdutoByNome(), {
    enabled: true,
    onSuccess: (data) => {
      setProdutos(
        data?.data.map((produto) => ({
          label: produto.nome,
          id: produto.id,
        }))
      );
    },
  });

  const { data: groupData } = useQuery("getGrupoProdutos", getGrupoProdutos, {
    retry: false,
    enabled: true,
  });

  useEffect(() => {
    if (groupData) {
      const activeGroups = groupData.filter((group) => group?.status);
      setCategoriasAtivas(activeGroups);
    }
  }, [groupData]);

  const handleDownloadExcel = async () => {
    await getRelatorioProdutos({
      skip,
      limit,
      nome: searchFilter,
      marca: marcaFilter,
      tipoProduto: tipoProdutoFilter,
      categoriaId: categoriaFilter,
    });
  };

  const { mutate } = useMutation((id) => deleteProduto(id), {
    onSuccess: async (data) => {
      toast.success("Produto excluído com sucesso.");
      await refetchProdutos();
    },
    onError: (data) => {
      toast.error("Erro ao excluir produto. Tente novamente mais tarde.");
    },
  });

  const getTipoProdutoLabel = (value) => {
    const tipo = tipoProduto.find((item) => item.value === value);
    return tipo ? tipo?.label : "-";
  };

  const getCategoriaLabel = (value) => {
    const tipo = groupData?.find((item) => item.id === value);
    return tipo ? tipo?.descricao : "-";
  };

  useEffect(() => {
    refetchProdutos();
    localStorage.setItem("produtosLimit", limit);
    localStorage.setItem("produtosSkip", skip);
  }, [skip, limit]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "imagem",
        Cell: ({ value }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={`data:image/jpeg;base64,${value}`}
              sx={{
                width: 45,
                height: 45,
                border: "1px solid rgba(0, 0, 0, 0.38)",
                padding: 1,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
            />
          </div>
        ),
      },
      { Header: "Produto", accessor: "nome" },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        Cell: ({ value }) => getTipoProdutoLabel(value),
      },
      {
        Header: "Categoria",
        accessor: "produtoCategoriaId",
        Cell: ({ value }) => getCategoriaLabel(value),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <Tooltip title="Editar">
              <IconButton
                onClick={() =>
                  navigate(
                    gestaoUnidades
                      ? urlsGestaoUnidades.produtosCadastrar + row.original.id
                      : urlsAdministrativo.patrimonioAlmoxarifadoProdutosCadastrar +
                          row.original.id,
                    {
                      state: {
                        data: row.original,
                      },
                    }
                  )
                }
                size="large"
                color="inherit"
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            {!gestaoUnidades && (
              <Tooltip title="Excluir">
                <IconButton
                  onClick={() => {
                    const confirm = window.confirm(
                      "Tem certeza que deseja excluir?"
                    );
                    if (confirm) {
                      mutate(row.original.id);
                    }
                  }}
                  size="large"
                  color="inherit"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    [newData]
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Produtos"}
        itens={[
          gestaoUnidades ? "Gestão Unidades" : "Administrativo",
          "Patrimônio",
          "Produtos",
        ]}
        hasExcelButton
        onClickDownload={() => handleDownloadExcel()}
        // hasModal
        hasModalAlter
        // modalTitulo="Add em Lote"
        modalAlterTitulo="Novo Produto"
        // onClickModal={handleClickOpenModalLote}
        onClickModalAlter={handleClickOpenModalProduto}
      />
      <div className="card">
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="NOME"
                    value={searchFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setSearchFilter(e.target.value);
                      await refetchProdutos();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="MARCA"
                    value={marcaFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setMarcaFilter(e.target.value);
                      await refetchProdutos();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="tipoProdutoFilter">TIPO</InputLabel>
                  <Select
                    labelId="tipoProdutoFilter"
                    id="tipoProdutoFilter"
                    value={tipoProdutoFilter}
                    label="Tipo"
                    onChange={async (e) => {
                      await setTipoProdutoFilter(e.target.value);
                      await refetchProdutos();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {tipoProduto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="categoriaFilter">CATEGORIA</InputLabel>
                  <Select
                    labelId="categoriaFilter"
                    id="categoriaFilter"
                    value={categoriaFilter}
                    label="Categoria"
                    onChange={async (e) => {
                      await setCategoriaFilter(e.target.value);
                      await refetchProdutos();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {categoriasAtivas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={async () => {
                    await setSearchFilter("");
                    await setTipoProdutoFilter(0);
                    await setMarcaFilter("");
                    await setCategoriaFilter(0);
                    await refetchProdutos();
                  }}
                />
              </div>
            </div>
          </Menu>

          {tipoProdutoFilter > 0 ? (
            <Chip
              color="info"
              label={`TIPO: ${
                tipoProduto.find((item) => item.value === tipoProdutoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setTipoProdutoFilter(0);
                await refetchProdutos();
              }}
            />
          ) : null}

          {categoriaFilter > 0 ? (
            <Chip
              color="info"
              label={`CATEGORIA: ${
                groupData?.find((item) => item.id === categoriaFilter)
                  ?.descricao || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setCategoriaFilter(0);
                await refetchProdutos();
              }}
            />
          ) : null}

          {marcaFilter != "" ? (
            <Chip
              color="info"
              label={`MARCA: ${marcaFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setMarcaFilter("");
                await refetchProdutos();
              }}
            />
          ) : null}
          {searchFilter != "" ? (
            <Chip
              color="info"
              label={`NOME: ${searchFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setSearchFilter("");
                await refetchProdutos();
              }}
            />
          ) : null}
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={produtosData?.total}
          columns={columns}
          data={produtosData?.data ? produtosData?.data : []}
          paginationKey="produtosPage"
          validatePage
        />
      </div>

      <Dialog
        open={openModalProduto}
        onClose={handleCloseModalProduto}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle variant="" id="alert-dialog-title">
          ADICIONAR PRODUTO
        </DialogTitle>
        <DialogContent>
          <Produto
            hasBackButton={false}
            onClose={handleCloseModalProduto}
            onRefetch={refetchProdutos}
          />
        </DialogContent>
      </Dialog>
    </BaseComponent>
  );
};
export default AlmoxarifadoProdutos;
