import api from '..';

async function getEscolaridades() {
  return await api
    .get('escolaridade')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getFaltas(idTurno = 0, idEscolaridade = 0, idturma = 0, idUnidade = 0, idAluno = 0, idDisciplina = 0, dataChamada = '', horarioAula = 0, idTrimestre = 0, skip = 0, limit = 20) {
  return await api
    .get(`Faltas/ListarFaltas?unidadeId=${idUnidade}&escolaridadeId=${idEscolaridade}&turnoId=${idTurno}&turmaId=${idturma}&disciplinaId=${idDisciplina}&dataChamada=${dataChamada}&horarioAula=${horarioAula}&alunoId=${idAluno}&idTrimestre=${idTrimestre}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getDisciplina() {
  return await api
    .get(`gestao-unidades/DiarioClasse/disciplinas`)
    .then(async (res) => {
      return res.data;
    });
};

async function postFalta(data) {
  debugger;
  return await api
    .post(`Faltas`, data)
    .then(async (res) => {
      return res.data;
    });
};

async function putFalta(data) {
  return await api
    .put(`Faltas/atualizaFalta`, data)
    .then(async (res) => {
      return res.data;
    });
};

async function deleteFalta(id) {
  return await api
    .delete(`academico/Faltas/id/${id}`)
    .then(async (res) => {
      return res.data;
    });
};

async function getFaltasImpressao(idturma = 0, idDisciplina = 0, idTrimestre = 0) {
  return await api
    .get(`Faltas/ListarFaltasImprimir?turmaId=${idturma}&disciplinaId=${idDisciplina}&trimestreId=${idTrimestre}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getFaltasAnoLetivo(idturma = 0, idDisciplina = 0, idTrimestre = 0) {
  return await api
    .get(`Faltas/FaltasAnoLetivo?turmaId=${idturma}&disciplinaId=${idDisciplina}&trimestreId=${idTrimestre}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};


export {
  getFaltas,
  getEscolaridades,
  getDisciplina,
  postFalta,
  deleteFalta,
  putFalta,
  getFaltasImpressao,
  getFaltasAnoLetivo
}