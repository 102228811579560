import React from "react";
import moment from "moment";
import { Typography } from "@mui/material";

function ImprimirSolicitacao({ row, formData }) {
  if (!row) {
    return <div>Dados não fornecidos para a solicitação.</div>;
  }

  const dataFormat = moment(row?.dataSolicitacao).format("DD/MM/YYYY");

  return (
    <div>
      <div
        className="header"
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        <img
          src="/assets/images/prefeituras/seduc.png"
          height="70"
          alt="Logo"
          style={{ marginBottom: "10px" }}
        />
        <div
          className="title"
          style={{ fontSize: "18px", fontWeight: "bold", margin: "5px 0" }}
        >
          ESTADO DO RIO DE JANEIRO
        </div>
        <div
          className="title"
          style={{ fontSize: "18px", fontWeight: "bold", margin: "5px 0" }}
        >
          Prefeitura Municipal de Araruama
        </div>
        <div
          className="title"
          style={{ fontSize: "18px", fontWeight: "bold", margin: "5px 0" }}
        >
          {row?.unidade}
        </div>
        <div
          className="sub-title"
          style={{ fontSize: "14px", margin: "5px 0" }}
        >
          Secretaria Municipal de Educação
        </div>
        <h2>Pedido de Material</h2>
      </div>

      <div className="form" style={{ width: "100%", marginTop: "20px" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            margin: "10px 0",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Solicitante
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {row?.solicitadoPor || "__________"}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {dataFormat}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            margin: "10px 0",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Especificação do Material
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Quantidade Pedido
              </th>
            </tr>
          </thead>
          <tbody>
            {row?.produtosSolicitados?.map((produto, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {produto?.nome || "__________"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {produto?.quantidade || "____"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {row?.observacao && (
          <div className="mt-4">OBSERVAÇÕES: {row?.observacao}</div>
        )}
      </div>
      {formData?.produtosEnviados?.length > 0 && (
        <>
          <div className="header mt-4">
            <h2>Produtos Fornecidos</h2>
          </div>
          <table>
            <tr>
              <th>Almoxarife</th>
              <th>Data</th>
            </tr>
            <tr>
              <td>{formData?.atendidoPor}</td>
              <td>
                {formData?.atendidoEm
                  ? new Date(formData?.atendidoEm).toLocaleDateString()
                  : "-"}
              </td>
            </tr>
          </table>
          <table className="mt-4">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Marca</th>
                <th>Número de Série</th>
                <th>Quantidade Fornecida</th>
              </tr>
            </thead>
            <tbody>
              {formData?.produtosEnviados?.map((produto, index) => (
                <tr key={index}>
                  <td>{produto?.nome}</td>
                  <td>{produto?.marca}</td>
                  <td>
                    {produto?.numeroDeSerie ? produto?.numeroDeSerie : "-"}
                  </td>
                  <td>{produto?.quantidade}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <div className="mt-4">
        <Typography textTransform="uppercase" variant="h6">
          Transportado Por: {row?.transportadoPor}
        </Typography>
      </div>
    </div>
  );
}

export default ImprimirSolicitacao;
