import api from "../..";

async function getAlunos(nome = "", idUnidade = 0, status = -1, pcdPne = "", skip = 0, limit = 20) {
  var url = `administrativo/transporte/alunos?idUnidade=${idUnidade}&status=${status}&skip=${skip}&limit=${limit}`;
  if (nome != "") url += `&nome=${nome}`;
  if (pcdPne != "") url += `&pcdPne=${pcdPne}`;
  return await api
    .get(url)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}


async function getFuncionarios(nome = "", matricula = "", cpf = "", skip = 0, limit = 20) {
  var url = `administrativo/transporte?skip=${skip}&limit=${limit}`;
  if (nome != "") url += `&nome=${nome}`;
  if (matricula != "") url += `&matricula=${matricula}`;
  if (cpf != "") url += `&cpf=${cpf}`;
  return await api


    .get(url)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}




async function getVeiculos(modelo = "", fabricante = "", placa = "", ano = "", cor = "", skip = 0, limit = 20) {
  var url = `administrativo/transporte/veiculos?&skip=${skip}&limit=${limit}&modelo=${modelo}&fabricante=${fabricante}&placa=${placa}&ano=${ano}&cor=${cor}`;
 
  return await api
    .get(url)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}


async function createVeiculoGasto(data) {
  return await api
    .post('administrativo/transporte/lancamentogasto', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getTiposGastos() {
  var url = `administrativo/transporte/listagastos`;
  return await api
    .get(url)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getUnidades() {

  var url = `administrativo/transporte/listaunidades`;
  return await api
    .get(url)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function createVeiculo(data) {
  return await api
    .post(`administrativo/transporte/veiculos`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function updateVeiculo({id, data}) {
  return await api
    .put(`administrativo/transporte/veiculos/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}




export {
  getAlunos,
  getFuncionarios,
  getUnidades,
  createVeiculoGasto,
  getTiposGastos,
  getVeiculos,
  createVeiculo,
  updateVeiculo
}
