import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterAltOutlined, VisibilityOutlined } from "@mui/icons-material";
import BaseAdministrativo from "../../../baseAdministrativo";
import BaseGestaoUnidades from "../../../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../../../components/breadcrumb";
import Table from "../../../../../components/table";
import { useQuery } from "react-query";
import {
  getAllTransferencias,
  getRelatorioTransferencias,
  getTransferenciaById,
} from "../../../../../services/administrativo/patrimonio-estoque";
import moment from "moment";
import { getUnidades } from "../../../../../services/unidades";
import EstoqueTransferenciasModal from "./responder-transferencia";
import TransferenciaEntreUnidades from "../../../../almoxarifado-unidades/transferencias/entre-unidades";

export default function EstoqueTransferencias({ gestaoUnidades }) {
  const transferSkip = localStorage.getItem("transferSkip");
  const transferLimit = localStorage.getItem("transferLimit");

  const [anchorEl, setAnchorEl] = useState(null);
  const [newData, setNewData] = useState([]);
  const [skip, setSkip] = useState(transferSkip ? transferSkip : 0);
  const [limit, setLimit] = useState(transferLimit ? transferLimit : 20);
  const [unidadeFilter, setUnidadeFilter] = useState(
    gestaoUnidades ? localStorage.getItem("unidadeID") : null
  );
  const [statusFilter, setStatusFilter] = useState(null);
  const [selectedChip, setSelectedChip] = useState(
    gestaoUnidades ? "Recebidas" : "Enviadas"
  );
  const [transferenciaSelecionada, setTransferenciaSelecionada] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);
  const open = Boolean(anchorEl);

  const BaseComponent = gestaoUnidades
    ? BaseGestaoUnidades
    : BaseAdministrativo;

  const { data: transferenciasData, refetch: refetchTransferencias } = useQuery(
    {
      queryKey: ["getAllTransferencias"],
      queryFn: () =>
        getAllTransferencias({
          skip,
          limit,
          unidadeDestinoId: unidadeFilter,
          status: statusFilter,
        }),
      onSuccess: (data) => {
        setNewData(data?.data);
      },
    }
  );

  const { data: unidades } = useQuery("getUnidades", getUnidades, {
    enabled: true,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const handleChipSelect = (chip) => {
    setSelectedChip(chip);
  };

  const handleCloseModalTransferencia = () => {
    setOpenModalTransferencia(false);
  };

  const handleClickOpenModalTransferencia = () => {
    setOpenModalTransferencia(true);
  };

  const handleDownloadExcel = async () => {
    await getRelatorioTransferencias({
      skip,
      limit,
    });
  };

  const handleOpenModalTransferencia = async (row) => {
    try {
      // 1 = entre unidades | 2 = ADM - unidade
      const tipo = row.unidadeOrigem ? 1 : 2;
      const response = await getTransferenciaById(row.movimentacaoId, tipo);
      setTransferenciaSelecionada(response?.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Erro ao buscar transferência:", error);
    }
  };

  useEffect(() => {
    refetchTransferencias();
    localStorage.setItem("transferLimit", limit);
    localStorage.setItem("transferSkip", skip);
  }, [skip, limit, selectedChip, unidadeFilter, statusFilter]);

  const columns = useMemo(
    () => [
      {
        Header: "Unidade Destino",
        accessor: "unidadeDestino",
        Cell: ({ row }) => {
          return <Chip label={row.original.unidadeDestino} />;
        },
      },
      {
        Header: "Transportador",
        accessor: "transportadoPor",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const statusValue = row.original.status;
          let color = "default";
          switch (statusValue) {
            case "CONCLUÍDO":
              color = "success";
              break;
            case "EM TRANSPORTE":
              color = "warning";
              break;
            default:
              color = "default";
          }

          return <Chip label={statusValue} color={color} />;
        },
      },
      {
        Header: "Data",
        accessor: "criadoEm",
        Cell: ({ row }) => {
          return moment(row.original.criadoEm).format("DD/MM/YYYY");
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            {row.original.status === "EM TRANSPORTE" && gestaoUnidades && (
              <Tooltip title="Visualizar">
                <IconButton
                  onClick={() => handleOpenModalTransferencia(row.original)}
                  size="large"
                  color="inherit"
                >
                  <VisibilityOutlined color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    []
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Transferências"}
        itens={[
          gestaoUnidades ? "Gestão Unidades" : "Administrativo",
          "Estoque",
          "Transferências",
        ]}
        hasExcelButton
        onClickDownload={() => handleDownloadExcel()}
        hasModal={gestaoUnidades}
        modalTitulo="Nova Transferência"
        onClickModal={() => handleClickOpenModalTransferencia()}
      />
      <div className="card">
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          {/* <div className="d-flex gap-2 justify-content-end flex-grow-1">
            <Chip
              label="Enviadas"
              color={selectedChip === "Enviadas" ? "success" : "default"}
              variant="filled"
              onClick={() => handleChipSelect("Enviadas")}
            />
            <Chip
              label="Recebidas"
              color={selectedChip === "Recebidas" ? "success" : "default"}
              onClick={() => handleChipSelect("Recebidas")}
            />
          </div> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              {!gestaoUnidades ? (
                <div className="mt-4">
                  <Autocomplete
                    options={unidades}
                    getOptionLabel={(option) => option?.nome || ""}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      unidades?.find(
                        (unidade) => unidade.id === unidadeFilter
                      ) || null
                    }
                    onChange={(_, selectedOption) => {
                      const unidadeId = selectedOption
                        ? selectedOption.id
                        : localStorage.getItem("unidadeID");
                      setUnidadeFilter(unidadeId);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="UNIDADE" />
                    )}
                  />
                </div>
              ) : null}

              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="status">STATUS</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => {
                      setStatusFilter(e.target.value);
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    <MenuItem value={1}>EM TRANSPORTE</MenuItem>
                    <MenuItem value={2}>CONCLUÍDO</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={() => {
                    setUnidadeFilter(!gestaoUnidades ? null : unidadeFilter);
                    setStatusFilter(null);
                  }}
                />
              </div>
            </div>
          </Menu>
          {unidadeFilter && !gestaoUnidades && (
            <Chip
              color="info"
              label={`UNIDADE: ${
                unidades?.find((item) => item.id == unidadeFilter)?.nome || "-"
              }`}
              variant="outlined"
              onDelete={() => {
                setUnidadeFilter(null);
              }}
            />
          )}
          {statusFilter !== null && (
            <Chip
              color="info"
              label={`STATUS: ${
                statusFilter === 2 ? "CONCLUÍDO" : "EM TRANSPORTE"
              }`}
              variant="outlined"
              onDelete={() => {
                setStatusFilter(null);
              }}
            />
          )}
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={transferenciasData?.total}
          columns={columns}
          data={newData ? newData : []}
          paginationKey="transferPage"
          validatePage
        />
        <EstoqueTransferenciasModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={transferenciaSelecionada}
        />
        <Dialog
          open={openModalTransferencia}
          onClose={handleCloseModalTransferencia}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle variant="" id="alert-dialog-title">
            TRANSFERÊNCIA ENTRE UNIDADES
          </DialogTitle>
          <DialogContent>
            <TransferenciaEntreUnidades
              unidades={unidades}
              unidadeFilter={unidadeFilter}
              setUnidadeFilter={setUnidadeFilter}
              onClose={setOpenModalTransferencia}
            />
          </DialogContent>
        </Dialog>
      </div>
    </BaseComponent>
  );
}
