import React from 'react';
import { Grid, TextField, Alert } from '@mui/material';

const Step3 = ({ formik }) => {
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="CEP"
            name="cep"
            value={formik.values.cep}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
              const maskedValue = value.replace(/(\d{5})(\d{1,3})/, '$1-$2'); // Apply CEP mask
              formik.setFieldValue('cep', maskedValue); // Update Formik value
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.cep && Boolean(formik.errors.cep)}
            helperText={formik.touched.cep && formik.errors.cep}
            required
            fullWidth
            inputProps={{ inputMode: 'numeric', maxLength: 9 }} // Limit input length
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Estado"
            name="estado"
            value={formik.values.estado}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.estado && Boolean(formik.errors.estado)}
            helperText={formik.touched.estado && formik.errors.estado}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Cidade"
            name="cidade"
            value={formik.values.cidade}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.cidade && Boolean(formik.errors.cidade)}
            helperText={formik.touched.cidade && formik.errors.cidade}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Bairro"
            name="bairro"
            value={formik.values.bairro}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bairro && Boolean(formik.errors.bairro)}
            helperText={formik.touched.bairro && formik.errors.bairro}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Logradouro"
            name="logradouro"
            value={formik.values.logradouro}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.logradouro && Boolean(formik.errors.logradouro)}
            helperText={formik.touched.logradouro && formik.errors.logradouro}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número"
            name="numero"
            value={formik.values.numero}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.numero && Boolean(formik.errors.numero)}
            helperText={formik.touched.numero && formik.errors.numero}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Complemento"
            name="complemento"
            value={formik.values.complemento}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.complemento && Boolean(formik.errors.complemento)}
            helperText={formik.touched.complemento && formik.errors.complemento}
            fullWidth
          />
        </Grid>
    {formik.values.cidade && formik.values.cidade !== 'São Pedro da Aldeia' && (
    <Grid item xs={12}>
      <Alert severity="warning">
        Esse formulário é de uso exlusivo para o município de São Pedro da Aldeia.
      </Alert>
    </Grid>
    )}
      </Grid>
    );
  }
export default Step3;
