import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getModules } from '../../services/modulos';
import { useQuery } from 'react-query';
import Spinner from '../../components/spinner';
import Logo from '../../components/logo';
import { NavLink } from 'react-router-dom';
import SuporteRodape from "../../utils/suporteRodape";
import DialogAmbienteNaoProdutivo from "../avisos/dialogAmbienteNaoProdutivo";
import PopUpDialog from "../popup/dialog/popupDialog";

const Modulos = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [modulos, setModulos] = useState();

  const handleLogout = () => {
    navigate('/');
  };

  const token = localStorage.getItem('access_token');

  const { data, isLoading } = useQuery('getModules', () => getModules(token), {
    retry: 3
  });

  useEffect(() => {
    localStorage.setItem('infosModulos', JSON.stringify(data));
    setModulos(data);
  }, [data]);

  return (
    <div className="app menu-off-canvas align-content-stretch d-flex flex-wrap">
      {/* Chama o DialogAmbienteNaoProdutivo */}
      <DialogAmbienteNaoProdutivo />
      <PopUpDialog />

      <div className="app-sidebar">
        <SuporteRodape />
        <Logo />
      </div>
      <div className="app-container">
        <div className="app-header">
          <nav className="navbar navbar-light navbar-expand-lg">
            <div className="container-fluid">
              <div className="navbar-nav" id="navbarNav"></div>
              <div className="d-flex">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <a href='https://r3mais.tomticket.com/kb/modulo' className="nav-link nav-link" target="_blank" rel="noreferrer">
                      <i className="material-icons md-help"></i> Ajuda
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a href='/' className="nav-link nav-link text-danger" onClick={handleLogout}>
                      Sair
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="app-content">
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="page-description">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Home</li>
                        <li className="breadcrumb-item active">Sistema</li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Gestão Educacional
                        </li>
                      </ol>
                    </nav>
                    <h3>Gestão da Secretaria de Educação</h3>
                  </div>
                </div>
              </div>
              {(loading || isLoading) ? (
                <Spinner />
              ) : (
                <div className="row">
                  {
                    modulos?.filter(e => e.status)?.map(e => (
                      <div className="col-sm-12 col-md-6 col-xl-4" key={e.id}>
                        <div className="card" style={{ minHeight: 220 }}>
                          <div className="card-body text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="48"
                              width="48"
                            >
                              <path
                                fill={e.cor}
                                d={e.imagem}
                              />
                            </svg>
                            <h5 className="card-title">{e.titulo}</h5>
                            <p className="card-text">
                              {e.descricao}
                            </p>
                            {
                              e.status === true ? (
                                <NavLink
                                  to={e.link.includes('.br') ? e.link : `/${e.link}`}
                                  className='btn btn-primary'
                                >
                                  Acessar
                                </NavLink>
                              ) : (
                                <button className="btn btn-light">
                                  Inativo
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  {/* Restante do seu código de mapeamento de módulos */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modulos;
