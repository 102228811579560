import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Alert,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Step1 from './novoRede/step1';
import Step2 from './novoRede/step2';
import Step3 from './novoRede/step3';
import Step4 from './novoRede/step4';
import Step5 from './novoRede/step5';
import { cpf } from 'cpf-cnpj-validator';
import { escolaridades } from './data/escolaridades';
import { toast } from 'react-toastify';
import { LocationApi } from '../../../../../services/external/cep';
import { useRef } from 'react';
import { cadastrarInscricaoChamadaPublica } from '../../../../../services/processos/chamadaPublica2025';
import { useNavigate } from 'react-router-dom';
import urlsChamadaPublica2025 from '../../urlsChamadaPublica2025';
import { getAlunoCpfDataNascimento } from '../../../../../services/alunos'
import { validate as isCPFValid } from 'cpf-check';
import { use } from 'react';

const steps = ['ALUNO(A)', 'RESPONSÁVEL LEGAL', 'ENDEREÇO', 'OUTRAS INFORMAÇÕES', 'ESCOLHA'];

const isDateBetween = (date, start, end) => date >= start && date <= end;

const ChamadaPublicaFormularioPage = ({ tipoFormulario }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [error, setError] = useState('');
  const { current: locationApi } = useRef(new LocationApi());
  const navigate = useNavigate();
  const [estudanteNaoLocalizado, setEstudanteNaoLocalizado] = useState(false);
  const [estudanteRede, setEstudanteRede] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpf: '',
      dataNascimento: '',
      escolaridade: null,
      cartaoDoSus: '',
      nis: '',
      deficiente: 0,
      deficiencia: 0,
      estudanteDaRede: tipoFormulario === 'sou-aluno' ? 1 : 2,
      estudanteDaRedeUnidade: 0,
      filiacao1: '',
      filiacao2: '',
      responsavelNomeCompleto: '',
      responsavelDataNascimento: '',
      responsavelCpf: '',
      responsavelRg: '',
      responsavelEmail: '',
      responsavelTelefone: '',
      reponsavelParentesco: '',
      responsavelDeficiente: 0,
      responsavelDeficiencia: 0,
      cep: '',
      estado: '',
      cidade: '',
      bairro: '',
      logradouro: '',
      numero: '',
      complemento: '',
      beneficiarioProgramaSocial: 0,
      tempoTrabalho: 0,
      monoparental: 0,
      opcao1: 0,
      possuiIrmao: 0,
      cpfIrmao: '',
      unidadeIrmao: 0,
      termoDeclaracao: false,
    },
    validationSchema: Yup.object({
      cpf: Yup.string()
        .required('CPF é obrigatório')
        .test('cpf', 'CPF inválido', (value) => cpf.isValid(value)),
      dataNascimento: Yup.string().required('Data de nascimento é obrigatória'),
      nome: Yup.string().required('Nome é obrigatório'),
      escolaridade: Yup.number()
        .required('Escolaridade é obrigatória')
        .moreThan(0, 'Escolaridade é obrigatória')
        .oneOf(escolaridades.map((e) => e.value), 'Escolaridade inválida'),
      deficiente: Yup.number().required('Campo PcD é obrigatório').moreThan(0, 'Campo PcD é obrigatório'),
      deficiencia: Yup.number().when('deficiente', ([deficiente], schema) => {
        if (deficiente === 1)
          return Yup.number().required('Tipo de deficiência é obrigatório').moreThan(0, 'Tipo de deficiência é obrigatório');
        return schema;
      }),
      filiacao1: Yup.string().required('Filiação 1 é obrigatória'),
      responsavelNomeCompleto: Yup.string().required('Nome completo do responsável é obrigatório'),
      responsavelDataNascimento: Yup.string()
        .required('Data de nascimento do responsável é obrigatória'),
      responsavelCpf: Yup.string()
        .required('CPF do responsável é obrigatório')
        .test('cpf', 'CPF inválido', (value) => cpf.isValid(value))
        .test(
          'not-same-as-aluno',
          'O CPF do responsável não pode ser o mesmo do aluno',
          function (value) {
            return value !== this.parent.cpf;
          }
        ),
      // responsavelRg: Yup.string().required('RG do responsável é obrigatório'),
      responsavelEmail: Yup.string()
        .required('Email do responsável é obrigatório')
        .email('Email inválido')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Email inválido'),
      responsavelTelefone: Yup.string()
        .required('Telefone do responsável é obrigatório')
        .matches(/^\(\d{2}\)\s?\d{4,5}-\d{4}$/, 'Telefone inválido'),
      reponsavelParentesco: Yup.string().required('Parentesco do responsável é obrigatório'),
      responsavelDeficiente: Yup.number().required('Campo PcD é obrigatório').moreThan(0, 'Campo PcD é obrigatório'),
      responsavelDeficiencia: Yup.number().when('responsavelDeficiente', ([responsavelDeficiente], schema) => {
        if (responsavelDeficiente === 1)
          return Yup.number().required('Tipo de deficiência é obrigatório').moreThan(0, 'Tipo de deficiência é obrigatório');
        return schema;
      }),
      cep: Yup.string()
        .required('CEP é obrigatório')
        .matches(/^\d{5}-\d{3}$/, 'CEP inválido'), // CEP validation
      estado: Yup.string().required('Estado é obrigatório'),
      cidade: Yup.string().required('Cidade é obrigatória'),
      bairro: Yup.string().required('Bairro é obrigatório'),
      logradouro: Yup.string().required('Logradouro é obrigatório'),
      numero: Yup.string().required('Número é obrigatório'),
      complemento: Yup.string(), // Complemento is optional
      beneficiarioProgramaSocial: Yup.number().required('Beneficiário de programa social?').moreThan(0, 'Beneficiário de programa social?'),
      tempoTrabalho: Yup.number().when('escolaridade', ([escolaridade], schema) => {
        if (escolaridade === 10 || escolaridade === 11 || escolaridade === 12 || escolaridade === 13)
          return Yup.number()
            .required('Responsável Legal Está Empregado?').moreThan(0, 'Responsável Legal Está Empregado?')
        return schema;
      }),
      monoparental: Yup.number().required('MonoParental é obrigatório').moreThan(0, 'MonoParental é obrigatório'),
      opcao1: Yup.number().required('Opção 1 é obrigatória').moreThan(0, 'Opção 1 é obrigatória'),
      possuiIrmao: Yup.number().required('Possui irmão na rede?').moreThan(0, 'Possui irmão na rede?'),
      cpfIrmao: Yup.number().when('possuiIrmao', ([possuiIrmao], schema) => {
        if (possuiIrmao === 1)
          return Yup.string()
            .required('CPF do irmão é obrigatório')
            .test('cpf', 'CPF inválido', (value) => cpf.isValid(value))
        return schema;
      }),
      termoDeclaracao: Yup.boolean().oneOf([true], 'Você deve aceitar os termos para continuar.'),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (formik.values.termoDeclaracao === false) {
        return toast.error('Você deve aceitar os termos para enviar o formulário.');
      }
      try {
        const response = await cadastrarInscricaoChamadaPublica(values);
        if (response?.status === 200 || response?.status === 201) {
          toast.success('Inscrição realizada com sucesso!');
          navigate(urlsChamadaPublica2025.visualizacao + response.data.id);
        } else {
          toast.error('Erro ao realizar inscrição');
        }
      } catch (error) {
        toast.error('Erro ao realizar inscrição');
      }
    },
  });


  useEffect(() => {
    const dataNascimento = formik.values.dataNascimento;
    const escolaridade = formik.values.escolaridade;
    const minDate = new Date('1900-01-01');
    const referenceDate = new Date(dataNascimento);

    setError(''); // Reset error
    setIsNextDisabled(false); // Enable "Próximo" by default

    if (estudanteRede && tipoFormulario === 'nao-sou-aluno') {
      setIsNextDisabled(true);
    }

    if (dataNascimento && referenceDate > minDate) {
      if (escolaridade === 10 && !isDateBetween(referenceDate, new Date('2024-04-01'), new Date('2024-09-30'))) {
        setError('Data Inválida. Permitido Creche I: de 06 (seis) a 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade === 11 && !isDateBetween(referenceDate, new Date('2023-04-01'), new Date('2024-03-31'))) {
        setError('Data Inválida. Creche II: de 1 (um) ano a 1 (um) ano e 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade === 12 && !isDateBetween(referenceDate, new Date('2022-04-01'), new Date('2023-03-31'))) {
        setError('Data Inválida. Creche III: de 2 (dois) anos a 2 (dois) anos e 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade === 13 && !isDateBetween(referenceDate, new Date('2021-04-01'), new Date('2022-03-31'))) {
        setError('Data Inválida. Creche IV: de 3 (três) anos a 3 (três) anos e 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade === 24 && !isDateBetween(referenceDate, new Date('2020-04-01'), new Date('2021-03-31'))) {
        setError('Data Inválida. Pré I: de 4 (quatro) anos a 4 (quatro) anos e 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade === 26 && !isDateBetween(referenceDate, new Date('2019-04-01'), new Date('2020-03-31'))) {
        setError('Data Inválida. Pré II: de 5 (cinco) anos a 5 (cinco) anos e 11 (onze) meses.');
        setIsNextDisabled(true);
      } else if (escolaridade == 1 && !isDateBetween(referenceDate, new Date('2007-04-01'), new Date('2019-03-31'))) {
        setError('Data Inválida. 1 Ano: a partir de 6 anos de idade completos.');
        setIsNextDisabled(true);
      }
    }
  }, [formik.values.dataNascimento, formik.values.escolaridade, estudanteRede]);

  const handleAutoCompleteForm = useCallback(async (cep) => {
    setIsNextDisabled(false);
    if (cep.length < 8) return;

    try {
      const data = await locationApi.searchByCep(cep);

      const { cidade, uf, bairro, logradouro } = data?.data?.data || {};

      if (data.status === 400) {
        toast.warn(data?.data?.message);
        return;
      }
      if (cidade !== 'São Pedro da Aldeia') {
        if (activeStep === 2) {
          setIsNextDisabled(true);
          toast.warn('Endereço fora de São Pedro da Aldeia.');
        }
      }

      formik.setFieldValue('bairro', bairro);
      formik.setFieldValue('cidade', cidade);
      formik.setFieldValue('estado', uf);
      formik.setFieldValue('logradouro', logradouro);
    } catch (error) {
      toast.error('CEP não encontrado na base de dados.');
    }
  }, []);

  useEffect(() => {
    if (formik.values.cep) {
      handleAutoCompleteForm(formik.values.cep.replace(/\D/g, ''));
    }
  }, [formik.values.cep, handleAutoCompleteForm]);

  const handleNext = async () => {
    const errors = await formik.validateForm();
    const currentStepFields = getStepFields(activeStep);

    let fieldIsValid = true;
    const hasErrors = Object.keys(errors).some((field) => currentStepFields.includes(field));
    if (activeStep === 0) {
      if (formik.values.estudanteDaRede === 1) {
        if (estudanteNaoLocalizado) {
          formik.setFieldError('cpf', 'Estudante não localizado');
          fieldIsValid = false;
        }
        if (!cpf.isValid(formik.values.cpf)) {
          formik.setFieldError('cpf', 'CPF inválido');
          fieldIsValid = false;
        }
        if (formik.values.dataNascimento.length !== 10 || !isDateBetween(new Date(formik.values.dataNascimento), new Date('1900-01-01'), new Date())) {
          formik.setFieldError('dataNascimento', 'Data de nascimento inválida');
          fieldIsValid = false;
        }
      }
    }

    if (!fieldIsValid) {
      setIsNextDisabled(true);
    }

    if (!hasErrors && !error && fieldIsValid) {
      setActiveStep((prev) => prev + 1);
    } else {
      formik.setTouched(currentStepFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}));
    }
  };

  useEffect(() => {
    const cidade = formik.values.cidade;
    if (activeStep === 2 && cidade && cidade !== 'São Pedro da Aldeia') {
      setIsNextDisabled(true);
    }
  }, [formik.values.cidade, activeStep]);

  useEffect(() => {
    const checkCpf = async () => {
      setIsNextDisabled(false);
      setEstudanteNaoLocalizado(false);
      const cpf = formik.values.cpf;
      const dataNascimento = formik.values.dataNascimento;

      if (parseInt(dataNascimento.split('-')[0], 10) < 1900) {
        setIsNextDisabled(true);
        return;
      }


      if (cpf.length === 14 && isCPFValid(cpf) && dataNascimento.length === 10 && /^\d{4}$/.test(dataNascimento.split('-')[0]) && parseInt(dataNascimento.split('-')[0], 10) > 1900) {
        try {
          const response = await getAlunoCpfDataNascimento(cpf, dataNascimento);

          if (response) {
            formik.setFieldValue('filiacao1', response?.filiacao1);
            formik.setFieldValue('filiacao2', response?.filiacao2);
            formik.setFieldValue('deficiente', response?.pcD_PNE === 1 ? 1 : 2);
            formik.setFieldValue('deficiencia', response?.pcD_PNE === 1 ? response?.deficiencia : 0);
            formik.setFieldValue('responsavelNomeCompleto', response?.responsavel.nome)
            if (response?.responsavel?.nascimento) {
              const nascimentoDate = new Date(response.responsavel.nascimento).toISOString().split('T')[0];
              formik.setFieldValue('responsavelDataNascimento', nascimentoDate);
            }
            formik.setFieldValue('responsavelCpf', response?.responsavel?.cpf)
            formik.setFieldValue('responsavelTelefone', response?.responsavel?.telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3'))
            formik.setFieldValue('cartaoDoSus', response?.numeroCarteiraSus)
            formik.setFieldValue('nis', response?.social?.nis)
            formik.setFieldValue('nome', response?.nome)
            formik.setFieldValue('cep', response?.endereco?.cep.replace(/^(\d{5})(\d{3})$/, '$1-$2'))
            formik.setFieldValue('estudanteDaRedeUnidade', response?.unidadeID);
            formik.setFieldValue('numero', response?.endereco?.numero)
            formik.setFieldValue('complemento', response?.endereco?.complemento)
            formik.setFieldValue('beneficiarioProgramaSocial', response?.social?.beneficiarioProgramaSocial)
            setEstudanteNaoLocalizado(false);
          }



        } catch (error) {
          formik.setFieldValue('filiacao1', '');
          formik.setFieldValue('filiacao2', '');
          formik.setFieldValue('deficiente', 0);
          formik.setFieldValue('responsavelNomeCompleto', '')

          formik.setFieldValue('responsavelDataNascimento', '');
          formik.setFieldValue('responsavelCpf', '')
          formik.setFieldValue('responsavelTelefone', '')
          formik.setFieldValue('cartaoDoSus', '')
          formik.setFieldValue('nis', '')
          formik.setFieldValue('nome', '')
          formik.setFieldValue('cartaoDoSus', '')
          formik.setFieldValue('cep', '')
          formik.setFieldValue('estudanteDaRedeUnidade', 0);
          // SE QUISER TIRAR A TRAVA E DEIXAR O FORMULÁRIO CORRENDO MESMO SEM ACHAR OS ALUNOS, É SÓ COMENTAR AS DUAS LINHAS DE BAIXO      
          setEstudanteNaoLocalizado(true);
          setIsNextDisabled(true);
        }
      }
    };
    if (tipoFormulario === 'sou-aluno') {
      checkCpf();
    }
  }, [formik.values.cpf, formik.values.dataNascimento, tipoFormulario]);

  useEffect(() => {
    const temIrmaoNaRede = formik.values.temIrmao;
    if (temIrmaoNaRede === 2) {
      formik.setFieldValue('cpfIrmao', "");
      formik.setFieldValue('unidadeIrmao', 0);
    }
  }, [formik.values.temIrmao]);

  useEffect(() => {
    const cpfIrmao = formik.values.cpfIrmao;
    if (!cpf.isValid(cpfIrmao)) {
      formik.setFieldValue('unidadeIrmao', 0);
    }
  }, [formik.values.cpfIrmao]);



  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const getStepFields = (step) => {
    switch (step) {
      case 0:
        return ['cpf', 'dataNascimento', 'escolaridade', 'deficiente', 'deficiencia', 'filiacao1'];
      case 1:
        return ['responsavelNomeCompleto', 'responsavelDataNascimento', 'responsavelCpf', 'responsavelRg', 'responsavelEmail', 'responsavelTelefone', 'reponsavelParentesco', 'responsavelDeficiente', 'responsavelDeficiencia'];
      case 2:
        return ['cep', 'estado', 'cidade', 'bairro', 'logradouro', 'numero'];
      case 3:
        return ['beneficiarioProgramaSocial', 'tempoTrabalho', 'monoparental'];
      case 4:
        return ['opcao1', 'possuiIrmao', 'cpfIrmao', 'unidadeIrmao', 'termoDeclaracao'];
      default:
        return [];
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 formik={formik} tipoFormulario={tipoFormulario} estudanteNaoLocalizado={estudanteNaoLocalizado} setEstudanteRede={setEstudanteRede} estudanteRede={estudanteRede} />;
      case 1:
        return <Step2 formik={formik} />;
      case 2:
        return <Step3 formik={formik} />;
      case 3:
        return <Step4 formik={formik} />;
      case 4:
        return <Step5 formik={formik} />;
      default:
        return <Typography>Formulário enviado com sucesso!</Typography>;
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <img
          src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
          alt="Prefeitura Logo"
          width="200"
          height="150"
          style={{ marginBottom: '15px' }}
        />
        <Typography variant="h5" component="h3" sx={{ marginBottom: '10px' }}>
          SECRETARIA DE EDUCAÇÃO - PREFEITURA MUNICIPAL DE SÃO PEDRO
        </Typography>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
          CHAMADA PÚBLICA | ALUNOS 2025
        </Typography>
      </Box>
      <Box sx={{ overflowX: 'auto', mb: 2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 2 }}>{renderStepContent(activeStep)}</Box>
        {error && (
          <Box sx={{ mt: 2 }}>
            <Alert severity="warning">{error}</Alert>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
            mt: 2,
          }}
        >
          {activeStep > 0 && (
            <Button variant="contained" color="secondary" onClick={handleBack}>
              Voltar
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              Próximo
            </Button>
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Enviar
            </Button>
          )}
        </Box>
      </form>
    </Container>
  );
};

export default ChamadaPublicaFormularioPage;