import { useMemo, useState, useEffect } from 'react';
import Breadcrumb from '../../../../../components/breadcrumb';
import Table from '../../../../../components/table';
import BaseGestaoUnidades from '../../../../gestao-unidades/baseGestaoUnidades';
import urlsGestaoUnidades from '../../../../gestao-unidades/urlsGestaoUnidades';
import { useNavigate } from "react-router-dom";
import { getInscricoesMatricula } from '../../../../../services/processos/preMatricula';
import { useQuery, useMutation } from 'react-query';
import Spinner from '../../../../../components/spinner';
import moment from 'moment';
import urlsPreMatricula from '../../urlsPreMatricula';
import { Dropdown } from 'react-bootstrap';

const PreMatriculaMatriculas = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [nome, setNome] = useState('');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
         
            <Dropdown.Item
              className="dropdown-item-danger d-flex"
              onClick={() => navigate(urlsGestaoUnidades.matriculasIndeferir, {
                state: {
                 id: row.original.id,
                 aluno: {
                   nome: row.original.passo3NomeCompleto,
                   cpf: row.original.passo2CpfAluno,
                   escolaridadeId: row.original.passo2Escolaridade,
                   turnoId: row.original.opcaoPrincipal == 1 ? row.original.passo6TurnoEscolha1 : row.original.passo6TurnoEscolha2
                 }
                }})}
            >
              <i className="material-icons md-delete pe-2"></i> Indeferir
            </Dropdown.Item>
          
            <Dropdown.Item
              className="dropdown-item-secondary d-flex"
              onClick={() =>
                {  window.open(urlsPreMatricula.visualizar + row.original.id, '_blank')}
              }
            >
              <i className="material-icons md-edit_note pe-2"></i> Visualizar
            </Dropdown.Item>
       
      
        
         
            
              <Dropdown.Item
                className="dropdown-item-primary d-flex"
                onClick={() => navigate(urlsGestaoUnidades.matriculaEnturmar + row.original.id, {
                  state: {
                    id: row.original.id,
                    aluno: {
                      nome: row.original.passo3NomeCompleto,
                      cpf: row.original.passo2CpfAluno,
                      escolaridadeId: row.original.passo2Escolaridade,
                      turnoId: row.original.opcaoPrincipal == 1 ? row.original.passo6TurnoEscolha1 : row.original.passo6TurnoEscolha2
                    }
                  }
                })}
              >
                <i className="material-icons md-check pe-2"></i> Enturmar
              </Dropdown.Item>
          
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const turnosEstatico = {
    1: "INTEGRAL",
    2: "MANHÃ",
    3: "NOITE",
    4: "TARDE",
  };

  const escolridadesEstatico = {
    1: "1º ANO",
    2: "2º ANO",
    3: "3º ANO",
    4: "4º ANO",
    5: "5º ANO",
    6: "6º ANO",
    7: "6º ANO ACELERA",
    8: "7º ANO",
    9: "7º ANO ACELERA",
    10: "8º ANO",
    11: "9º ANO",
    12: "CRECHE I (6 MESES A 11 MESES)",
    13: "CRECHE II (1 ANO A 1 ANO E 11 MESES)",
    14: "CRECHE III (2 ANOS A 2 ANOS E 11 MESES)",
    15: "CRECHE IV (3 ANOS A 3 ANOS E 11 MESES)",
    16: "FASE II",
    17: "FASE III",
    18: "FASE IV",
    19: "FASE IX",
    20: "FASE V",
    21: "FASE VI",
    22: "FASE VII",
    23: "FASE VIII",
    24: "PRÉ I (4 ANOS A 4 ANOS E 11 MESES)",
    25: "PRÉ II (5 ANOS A 5 ANOS E 11 MESES)",
    26: "4º E 5º ANOS (MULTISSERIADA)",
    27: "6º E 7º ANOS (MULTISSERIADA)",
  };
  

  function findDescricaoByKey(key) {
    return turnosEstatico[key];
  }

  function findEscolaridadeByKey(key) {
    return escolridadesEstatico[key];
  }
  
  

  const { isError, isLoading, refetch } = useQuery(
    'getInscricoesMatricula',
    () => getInscricoesMatricula(unidadeId, nome, skip, limit),
    {
      retry: 0,
      enabled: true,
      onSuccess: data => {
        setData(data)
      }
    }
  )


  useEffect(() => {
    if (nome.length >= 3) {
      setNome(nome)
      refetch()
    }
    refetch()
    // refetchEscolaridades()
  }, [refetch, limit, skip, nome])
  const getTableData = () => {
    if (isError) {
      return (
        <div className='card-body'>
          <h5 className='card-title'>Erro ao carregar dados</h5>
          <p className='card-text'>Não foi possível carregar os dados. Tente novamente mais tarde.</p>
        </div>
      )
    }
    if (isLoading || !data) {
      return <Spinner />
    }
    return  <Table
    columns={columns}
    data={data?.data ? data.data.inscricoes : []}
    hasPagination
    limit={limit}
    setLimit={setLimit}
    skip={skip}
    setSkip={setSkip}
    totalItems={data?.total}
    hiddenColluns={["escolaridade"]}
    isBoardTable={true}
    overflowUnset={true}

  />
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'passo3NomeCompleto' },
      {
        Header: 'PNE', accessor: 'passo2Deficiente',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.passo2Deficiente ? 'success' : 'danger'}`}>
            {row.original.passo2Deficiente ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      { Header: 'CPF', accessor: 'passo2CpfAluno' },
      {
        Header: 'Nascimento', accessor: 'passo3DataNascimento',
        Cell: ({ row }) => {
          const nascimento = row.original.passo3DataNascimento
          const data = nascimento ? moment(((nascimento).split('T'))[0]).format('DD/MM/YYYY') : ''
          return (
            <>
              {data}
            </>
          )
        }
      },
      {
        Header: 'Escolaridade', accessor: 'passo2Escolaridade',
        Cell: ({ row }) => {

        const escolaridadeDescricao = findEscolaridadeByKey(row.original.passo2Escolaridade)
          return (
            <>
              {escolaridadeDescricao}
            </>
          )
        }
      },
      {
        Header: 'Turno', accessor: 'passo6TurnoEscolha1',
        Cell: ({ row }) => {
          let turnoDescriaco = ''
          const opcaoEscolhida = row.original.opcaoPrincipal
          if(opcaoEscolhida == 1){
            turnoDescriaco = findDescricaoByKey(row.original.passo6TurnoEscolha1)
          }

          if(opcaoEscolhida == 2){
            turnoDescriaco = findDescricaoByKey(row.original.passo6TurnoEscolha2)
          }
          return (
            <>
              {turnoDescriaco}
            </>
          )
        }
      },
      { Header: 'Responsável', accessor: 'passo4NomeResponsavel' },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
      // {
      //   Header: '', accessor: 'actions',
      //   Cell: ({ row }) => (
      //     <>
      //       <button onClick={() => navigate(urlsGestaoUnidades.matriculaEnturmar + row.original.id, {
      //         state: {
      //           id: row.original.id,
      //           aluno: {
      //             nome: row.original.passo3NomeCompleto,
      //             cpf: row.original.passo2CpfAluno,
      //             escolaridadeId: row.original.passo2Escolaridade,
      //             turnoId: row.original.opcaoPrincipal == 1 ? row.original.passo6TurnoEscolha1 : row.original.passo6TurnoEscolha2
      //           }
      //         }
      //       })} className='btn btn-sm btn-primary'>
      //         <i className="material-icons md-add"></i> enturmar
      //       </button>
      //       {` `}
      //       <button onClick={() => navigate(urlsGestaoUnidades.matriculasIndeferir, {
      //          state: {
      //           id: row.original.id,
      //           aluno: {
      //             nome: row.original.passo3NomeCompleto,
      //             cpf: row.original.passo2CpfAluno,
      //             escolaridadeId: row.original.passo2Escolaridade,
      //             turnoId: row.original.opcaoPrincipal == 1 ? row.original.passo6TurnoEscolha1 : row.original.passo6TurnoEscolha2
      //           }
      //         }
      //       })} className='btn btn-sm btn-danger'>
      //         <i className="material-icons md-group_remove"></i> indeferir
      //       </button>
      //       <button
      //         onClick={() =>
      //           {  window.open(urlsPreMatricula.visualizar + row.original.id, '_blank')}
      //           // navigate(urls.processosSeletivosVInscricao + row.original.id)
      //         }
      //         className="btn btn-sm btn-dark"
      //       >
      //         <i className="material-icons md-edit_note"></i> visualizar
      //       </button>
      //     </>
      //   )
      // },
    ],
    [navigate]
  )

  

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Matrículas'} itens={['Gestão de Unidades', 'Matrículas', 'Lista Geral']} />
      <div className="col-12 col-md-12" style={{marginTop : '10px'}}>
        <div className='form-group'>
          <input
            type='text'
            className='form-control' 
            placeholder='DIGITE O NOME DO ALUNO'
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
        </div>
        </div>
      <div className='row' style={{marginTop: '20px'}}>
        <div className='col-12'>
          <div className='card'>
            {getTableData()}
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  )

};

export default PreMatriculaMatriculas;
