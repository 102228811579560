import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseDocente from "../../docente/baseDocente";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import urlsDocente from "../../docente/urlsDocente";
import { store } from "../../../store";
import Breadcrumb from "../../../components/breadcrumb";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { getAlunosFiltro } from "../../../services/alunos";
import { quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioServidorEscolaridade } from "../../../services/quadro-horario";
import { getDisciplinasByTurmaServidor } from "../../../services/disciplinas";
import DisciplinasInfantil from "../tabelas/disciplinas";
import {postQuantidadeAulaRiap, imprimirRelatorioRiap, listarAulasRiapAlunoId} from "../../../services/ficha-avaliacao";
import handleDownloadPDF from "../../../utils/handleDownloadPDF";

const RiapCreche = (gestao) => {

    const currentUser = store.getState()['user']['user'];
    const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
    const [escolaridade, setEscolaridade] = useState(0);
    const [turno, setTurno] = useState(0);
    const [turma, setTurma] = useState(0);
    const [disciplina, setDisciplina] = useState(0);
    const [ciclo, setCiclo] = useState(0);
    const [aluno, setAluno] = useState(0);
    const [nomeAluno, setNomeAluno] = useState('');
    const [observacao, setObservacao] = useState('');
    const [totalDiasLetivos, setTotalDiasLetivos] = useState('');
    const [totalDeFaltas, setTotalDeFaltas] = useState('');
    const [aulasDadas, setAulasDadas] = useState([]);

    const ciclos = [
        { value: 1, label: '1º TRIMESTRE' },
        { value: 2, label: '2 TRIMESTRE' },
        { value: 3, label: '3 TRIMESTRE' },
      ];

      const { data: aulas, isLoading: loadingAulas, refetch: refetchAulas } = useQuery('getAulasRiap', () =>
        aluno !== 0 && disciplina !== 0 ? listarAulasRiapAlunoId(aluno, disciplina) : null,
        {
            retry: 3,
            enabled: aluno !== 0 && disciplina !== 0,
            onSuccess: data => {
              setAulasDadas(data)
              if (data?.find(x => x.trimestreId == ciclo) === undefined){
                setTotalDiasLetivos('')
                setTotalDeFaltas('')
                setObservacao('')
              }
              else{
                setTotalDiasLetivos(data?.find(x => x.trimestreId == ciclo)?.diasLetivos)
                setTotalDeFaltas(data?.find(x => x.trimestreId == ciclo)?.faltas)
                setObservacao(data?.find(x => x.trimestreId == ciclo)?.observacao)
              }
                
            
            }
        }
    )

      const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery('getAlunosFiltro', () =>
        turma !== 0 && disciplina !== 0 ? getAlunosFiltro(unidade, escolaridade, turno, turma, 1, 0, '', '', '',0,100,2025) : null,
        {
            retry: 3,
            enabled: turma !== 0 && disciplina !== 0,
        }
    )

    let BaseComponent;
    let BasePath;
    let servidorId;
    let pageTitle = 'RIAP | Creche - Pré';
    let urlImprir = ''

    if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades';
        servidorId = 0;
        urlImprir = urlsGestaoUnidades.fichaAvaliacaoInfantilImpressao;

    } else if (gestao.docente) {
        BaseComponent = BaseDocente;
        BasePath = 'Docente';
        servidorId = currentUser?.servidorId;
        urlImprir = urlsDocente.fichaAvaliacaoInfantilImpressao;
    }

    async function clickImprimir(){
        if (ciclo == 0 || aluno == 0){
            toast.warn('Selecione um trimestre e um aluno para impressão!')
            return
        }
        try {
          const pdf = await imprimirRelatorioRiap(aluno);
          let nomeArquivo = `RIAP_CRECHE_PRE_${nomeAluno}.pdf`; 
          handleDownloadPDF(pdf, nomeArquivo);
      } catch (error) {
          toast.error('Não foi possível gerar o relatório.');
          console.error('Erro ao gerar o relatório:', error);
      }
    }

    const [turnos, setTurnos] = useState([]);
    const { refetch: refetchTurnos, loadingTurnos } = useQuery(
      'getTurnos',
      () => (unidade !== null ? quadroHorarioServidorTurno(servidorId, unidade, escolaridade) : null),
      {
        retry: 3,
        enabled: unidade !== null && escolaridade !== null,
        onSuccess: (data) => {
          if(data === null){
            return
          }
          var dados = [];
          data.forEach((item) => {
            if (!dados.some((d) => d.value === item.id)) {
              dados.push({ value: item.id, label: item.descricao });
            }
          });
  
          setTurnos(dados);
        },
      }
    );
  
    const [disciplinas, setDisciplinas] = useState([]);

    async function handleSalvarDadosAdicionais(){
        const data = {
            observacao: observacao,
            totalDiasLetivos: totalDiasLetivos,
            totalFaltas: totalDeFaltas,
            alunoId: aluno,
            disciplinaId: disciplina,
            trimestreId: ciclo
        }

        const response = await postQuantidadeAulaRiap(data)

        if (response){
            toast.success('Dados salvos com sucesso!')
        }
        else{
            toast.error('Erro ao salvar os dados!')
        }

    }
  
    const { refetch: refetchDisciplinas, loadingDisciplinas } = useQuery(
      'getDisciplinas',
      () => (turma !== null && turma != 0 ? getDisciplinasByTurmaServidor(turma, servidorId) : null),
      {
        retry: 3,
        enabled: turma !== null && turma != 0,
        onSuccess: (data) => {
          if (data != null){
              var dados = [];
              data.forEach((item) => { {
                  if (!dados.some((d) => d.value === item.id)) {
                    dados.push({ value: item.id, label: item.descricao });
                  }
                }
                setDisciplinas(dados);
              });
          }
          
  
          
        },
      }
    );

    useEffect(() => {
        if (unidade !== null && escolaridade !== null) {
            refetchTurnos();
        }
      }, [escolaridade, refetchTurnos]);
    
      const handleChangeEscolaridade = async (e) => {
        await setEscolaridade(e.target.value);
        await setTurno(0);
        await setCiclo(0)
        await setTurma(0)
        await setDisciplina(0)
        await setCiclo(0)
        await localStorage.removeItem('turmaIdImpressaoRiapInfantil');
      };
    
      const handleChangeTurno = async (e) => {
        await setTurno(e.target.value);
        await setCiclo(0)
        await setTurma(0)
        await setDisciplina(0)
        await setCiclo(0)
        await localStorage.removeItem('turmaIdImpressaoRiapInfantil');
      };
    
      const handleChangeTrimestre = async (e) => {
        await setCiclo(e.target.value);
        await refetchAulas();
        await setDisciplina(0)
      };
    
      const handleChangeDisciplina = async (e) => {
        await setDisciplina(e.target.value);
        await refetchAlunos();
      };

      const handleChangeAluno = async (e) => {
        await setAluno(e.target.value);
        await setNomeAluno(e.target.options[e.target.selectedIndex].label);
        // await refetchAlunos();
      };
    
      const [turmas, setTurmas] = useState([]);
      const { refetch: refetchTurmas, loadingTurmas } = useQuery(
        'getTurmas',
        () => (unidade !== null ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null),
        {
          retry: 3,
          enabled: unidade !== null && escolaridade !== null && turno !== null,
          onSuccess: (data) => {
            if(data === null){
              return
            }
            var dados = [];
            data.forEach((item) => {
              if (!dados.some((d) => d.value === item.id)) {
                dados.push({ value: item.id, label: item.descricao });
              }
            });
    
            setTurmas(dados);
          },
        }
      );
    
      useEffect(() => {
        if (unidade !== null && escolaridade !== null && turno !== null) {
            refetchTurmas();
        }
      }, [turno, refetchTurmas]);

      const handleChangeTurma = async (e) => {
        // await setAluno(0)
        await setTurma(e.target.value);
        await setCiclo(0)
        await setDisciplina(0)
        await localStorage.setItem('turmaIdImpressaoRiapInfantil', e.target.value);
      };

      useEffect(() => {
        setTurno(0)
    }, [escolaridade]);

    useEffect(() => {
    }, [turno]);

    useEffect(() => {
        refetchAlunos(); 
    }, [disciplina]);

    useEffect(() => {
      refetchAulas(); 
  }, [aluno]);



    const [escolaridades, setEscolaridades] = useState([]);
    let tipo = 1
    const tipo1 = [12,13,14,15,24,25]
    const tipo2 = [1]
    const tipo3 = [2]
    const tipo4 = [3,4,5,6,7,8,9]

    const { refetch: refetchEscolaridades, loadingEscolariades } = useQuery(
        'getEscolaridades',
        () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
        {
          retry: 3,
          enabled: unidade !== null,
          onSuccess: (data) => {
            if(data === null){
              return
            }
            var dados = [];
            data.forEach((item) => {
              if (
                (tipo === 1 && tipo1.includes(item.id)) ||
                (tipo === 2 && tipo2.includes(item.id)) ||
                (tipo === 3 && tipo3.includes(item.id)) ||
                (tipo === 4 && tipo4.includes(item.id))
              ) {
                if (!dados.some((d) => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              }
            });
    
            setEscolaridades(dados);
          },
        }
      );
    
    

    
    return (
        <BaseComponent>
          <Breadcrumb 
            title={pageTitle} 
            itens={[BasePath]}  
            imprimir={{ link: urlImprir, text: "Imprimir" }}
          onClickImprimir={clickImprimir}
          />
          <form id='form'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="escolaridadeId" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeId"
              value={escolaridade}
              onChange={async e => {
                await handleChangeEscolaridade(e);
              }}
            >
              {loadingEscolariades ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {escolaridades &&
                    escolaridades.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                     

                                        </div>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async e => {
                await handleChangeTurno(e);
              }}
            >
              {loadingTurnos ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turnos &&
                    turnos.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              value={turma}
              onChange={async e => {
                await handleChangeTurma(e)
              }}
            >
              {loadingTurmas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turmas &&
                    turmas.map(item => (
                      
                     
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                        </div>
                                        {/* Add more form elements here */}
                                    </div>

                                    <div className='row' style={{marginTop: 20}}>
                                    <div className="col-12 col-md-4">
                                            <div className="form-group">
            <label htmlFor="trimestreId" className="form-label">
              Trimestres
            </label>
            <select
              className="form-select"
              id="trimestreId"
              value={ciclo}
              onChange={async e => {
                await handleChangeTrimestre(e);
              }}
            >
              
                <>
                  <option value={0}></option>
                  {ciclos &&
                    ciclos.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
             
            </select>
          </div>
          </div>

          <div className="col-12 col-md-4">
                                            <div className="form-group">
            <label htmlFor="disciplinaId" className="form-label">
              Disciplinas
            </label>
            <select
              className="form-select"
              id="disciplinaId"
              value={disciplina}
              onChange={async e => {
                await handleChangeDisciplina(e);
              }}
            >
              {loadingDisciplinas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {disciplinas &&
                    disciplinas.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
          </div>

          <div className="col-12 col-md-4">
                                            <div className="form-group">
            <label htmlFor="alunoId" className="form-label">
              Alunos
            </label>
            <select
              className="form-select"
              id="alunoId"
              value={aluno}
              onChange={async e => {
                await handleChangeAluno(e);
              }}
            >
              {loadingDisciplinas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {disciplinas &&
                    alunos?.data?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nome}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
          </div>

          {/* <div className="col-12 col-md-4">
                                            <label style={{marginBottom: 10}} htmlFor="aulasDadas" id="aria-label">Horas / Aulas</label>
<input
    className="form-control"
    type="number"
    aria-labelledby="aria-label"
    id="aulasDadas"
    name="aulasDadas"
    defaultValue={aulas?.registro === true ? aulas?.totalAulas : null}
    // placeholder="Digite aqui a quantidade de aulas dadas nesse trimestre para essa disciplina."
    onBlur={(e) => { handleBlurAulasDadas(e) }}
    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
/>

                                                </div> */}

                                            <div className='row' style={{marginTop: 30}}>
                                           
                                            
                                                </div>
                                            
                                            </div>
                                
                                            <div className='row' style={{ marginTop: 20 }}>
                                            {aluno > 0 &&  (
    <DisciplinasInfantil key={aluno} disciplina={parseInt(disciplina)} ciclo={ciclo} turma={turma} aluno={parseInt(aluno)} escolaridade={parseInt(escolaridade)} />
)}
      {aluno > 0 && disciplina > 0 && (
        <div className='col-md-12' style={{marginBottom: 10, marginTop: 10}}>
        <div class="form-group">
            <label for="observacao">Observação</label>
            <textarea onChange={(e) => { setObservacao(e.target.value) }} value={observacao} class="form-control" id="observacao" rows="3"></textarea>
        </div>
    </div>
      )}

{aluno > 0 && disciplina > 0 && (
        <div className='col-md-6' style={{marginBottom: 10, marginTop: 10}}>
        <div class="form-group">
            <label for="totalDiasLetivos">Total Dias Letivos</label>
            <input type="number" onChange={(e) => { setTotalDiasLetivos(e.target.value) }} value={totalDiasLetivos} class="form-control" id="totalDiasLetivos"></input>
        </div>
    </div>
      )}

{aluno > 0 && disciplina > 0 && (
        <div className='col-md-6' style={{marginBottom: 10, marginTop: 10}}>
        <div class="form-group">
            <label for="totalDeFaltas">Total De Faltas</label>
            <input type="number" onChange={(e) => { setTotalDeFaltas(e.target.value) }} value={totalDeFaltas} class="form-control" id="totalDeFaltas"></input>
        </div>
    </div>
      )}

{aluno > 0 && disciplina > 0 && (
        <button type="button" class="btn btn-success" style={{marginTop: 10}} onClick={() => {handleSalvarDadosAdicionais()}}>Salvar</button>
      )}
</div>

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseComponent>
        
    )


}

export default RiapCreche