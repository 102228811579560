import { useMemo, useEffect, useState, React } from 'react'
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseDocente from '../docente/baseDocente';
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../gestao/baseGestao';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getFaltas, deleteFalta, getEscolaridades, getDisciplina } from './../../services/faltas';
import { useNavigate } from "react-router-dom";
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas } from '../../services/quadro-horario';
import { getAlunosFiltro } from './../../services/alunos';
import { getDisciplinasByTurmaServidor } from './../../services/disciplinas';
import Spinner from './../../components/spinner';
import urlsGestao from '../gestao/urlsGestao';
import { store } from '../../store/index'
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades';
import urlsDocente from '../docente/urlsDocente';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { putFalta, getFaltasImpressao, getFaltasAnoLetivo } from './../../services/faltas';

const FaltasPage = (gestao) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [idTurno, setIdTurno] = useState(0);
  const [idEscolaridade, setIdEscolaridade] = useState(0);
  const [idturma, setIdturma] = useState(0);
  const [idUnidade, setIdUnidade] = useState(localStorage.getItem('unidadeID'));
  const [idAluno, setIdAluno] = useState(0);
  const [idDisciplina, setIdDisciplina] = useState(0);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState(false);
  const [dataChamada, setDataChamada] = useState("");
  const currentUser = store.getState()['user']['user']
  const [dataTurma, setDataTurma] = useState([]);
  const [horarioAula, setHorarioAula] = useState(0);
  const [show, setShow] = useState(false);

  const [idChamada, setIdChamada] = useState(0);
  const [alunoId, setAlunoId] = useState(0);
  const [trimestreId, setTrimestreId] = useState(0);
  const [presenca, setPresenca] = useState(false);
  const [reporteConduta, setRepoteConduta] = useState("");
  const [observacao, setObservacao] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const { isLoading: loading, refetch } = useQuery('getFaltas', () => search ? getFaltas(idTurno, idEscolaridade, idturma, idUnidade, idAluno, idDisciplina, dataChamada, horarioAula, trimestreId, skip, limit) : null, {
    onSuccess: (data) => {
      setData(data?.data);
      setTotal(data?.total);
      setLimit(data?.limit);
      setSkip(data?.skip);
    },
    enabled: false,
    retry: true
  })

  let BaseComponent;
  let BasePath;
  let cadastrarFaltaUrl = '';
  let servidorId;

  const ciclos = [
    { value: 1, label: '1º TRIMESTRE' },
    { value: 2, label: '2º TRIMESTRE' },
    { value: 3, label: '3º TRIMESTRE' },
    { value: 4, label: '4º TRIMESTRE' },
  ];

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    cadastrarFaltaUrl = urlsGestao.faltasAdicionar;
    servidorId = 0;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    cadastrarFaltaUrl = urlsGestaoUnidades.faltasAdicionar;
    servidorId = 0;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    cadastrarFaltaUrl = urlsDocente.faltasAdicionar;
    servidorId = currentUser?.servidorId;
  }

  useEffect(() => {
    refetch();
  }, [data, limit, skip, search]);

  const { mutate } = useMutation(deleteFalta, {
    onSuccess: (message) => {
      toast.success(message.message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });



  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'idChamada' },
      { Header: 'Matrícula', accessor: 'matriculaAluno' },
      { Header: 'Aluno', accessor: 'nome' },
      { Header: 'Disciplina', accessor: 'disciplina' },
      {
        Header: 'Data Chamada', accessor: 'dataChamada',
        Cell: ({ row }) => (
          new Date(row.original.dataChamada.split(' ')[0]).toLocaleDateString('pt-br')
        )
      },
      {
        Header: 'Horário da aula', accessor: 'horarioAula'
      },
      {
        Header: 'Presença', accessor: 'presenca',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.presenca ? 'success' : 'danger'}`}>
            {row.original.presenca ? 'Sim' : 'Não'}
          </span>
        )
      },
      { Header: 'Reporte de Conduta', accessor: 'reporteDeConduta' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => {
              handleShow();
              setAlunoId(row.original.idAluno);
              setIdChamada(row.original.idChamada);
              setPresenca(row.original.presenca ? 'SIM' : 'NÃO');
            }} className='btn btn-sm btn-primary' style={{ width: '82px' }}>
              <i className="material-icons md-edit_note"></i> editar
            </button>
          </>
        )
      },
    ],
    [navigate, data]
  )

  const AtualizaFalta = async () => {
    const model = {
      idChamada: idChamada,
      alunoId: alunoId,
      servidorId: currentUser?.servidorId,
      presenca: presenca == "SIM" ? true : false,
      reporteConduta: reporteConduta,
      observacao: observacao,
    };
    var response = await putFalta(model);
    if (response.statusCode)
      toast.success(response.message);
    else
      toast.error(response.message);

    setRepoteConduta("");
    setObservacao("");

    await refetch();
    handleClose();
  }

  const { data: escolaridades } = useQuery('quadroHorarioServidorEscolaridade', () => (quadroHorarioServidorEscolaridade(servidorId, idUnidade)),
    {
      enabled: true
    }
  )

  async function fetchTurma(turno) {
    if (turno != 0) {
      var response = await quadroHorarioServidorTurma(servidorId, parseInt(idUnidade), parseInt(idEscolaridade), turno)
      // setLoadingTurmas(false)
      console.log(response, 'turmas123')
      setDataTurma(response || [])
    }
  }

  const { data: turnos, refetch: refetchTurnos } = useQuery('quadroHorarioServidorTurno', () => quadroHorarioServidorTurno(servidorId, idUnidade, idEscolaridade),
    {
      enabled: true
    }
  )

  const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery('getAlunosByUnidadeEscolaridadeTurnoTurma', () =>
    idUnidade !== '' && idEscolaridade !== '' && idTurno !== '' && idturma !== '' ? getAlunosFiltro(idUnidade, idEscolaridade, idTurno, idturma, 1, 0, '', '', '', 0, 100, 2025) : null,
    {
      retry: 3,
      enabled: idUnidade !== '' && idEscolaridade !== '' && idTurno !== '' && idturma !== ''
    }
  )

  const { data: disciplinas, isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplinasByTurmaServidor', () =>
    idturma !== 0 ? getDisciplinasByTurmaServidor(idturma, servidorId) : null,
    {
      retry: 3
    }
  )

  const { data: horarioAulas, isLoading: loadingHorarioAulas, refetch: refetchHorarioAulas } = useQuery('quadroHorarioAulas', () =>
    idturma !== 0 && idDisciplina !== "" ? quadroHorarioAulas(idturma, servidorId, idDisciplina) : null,
    {
      retry: 3
    }
  )


  const handleSearch = () => {
    setSearch(true);
    setTimeout(() => {
      refetch();
    }, 1000);
  }



  const imprimir = async (id) => {
    if (idEscolaridade > 0 && idTurno > 0 && idturma > 0 && idDisciplina > 0 && trimestreId > 0) {
      if (data?.length == 0) {
        alert("Não é possivel imprimir por não ter gerado nenhum resultado!");
        return;
      }
      var response = await getFaltasImpressao(idturma, idDisciplina, trimestreId);

      var alunosUnicos = [...new Map(response.data.map(item => [item.alunoId, item])).values()];


      var alunos = [];
      alunosUnicos.map((aluno) => {
        var dadosAluno = response.data.filter(x => x.alunoId == aluno.alunoId);
        var faltas = [];
        dadosAluno.map((dado) => {
          if (dado.alunoId == aluno.alunoId)
            faltas.push({
              data: dado.dataFormatada,
              hora: dado.horaAula,
              presenca: dado.presenca,
              faltaCarregada: false
            })
        });
        alunos.push({ aluno: aluno, faltas: faltas });
      });

      var obj = {
        nomeEscola: document.querySelector('#titleUnidade').text,
        nomeEscolaridade: escolaridades.find(x => x.id == idEscolaridade).descricao,
        nomeTurno: turnos.find(x => x.id == idTurno).descricao,
        nomeTurma: dataTurma.find(x => x.id == idturma).descricao,
        nomeDisciplina: disciplinas.find(x => x.id == idDisciplina).descricao,
        nomeTrimestre: ciclos.find(x => x.value == trimestreId).label,
        dias: [...new Set(response?.data.map(item => item.dataFormatada))],
        data: alunos
      };

      localStorage.setItem('faltasImpressao', JSON.stringify(obj));
      window.open(urlsDocente.faltasImpressao, '_blank');
    } else {
      alert("Para realizar a impressão é necessário selecionar:\r\nESCOLARIDADE\r\nTURNO\r\nTURMA\r\nDISCIPLINA\r\nTRIMESTRE")
    }
  }

  const imprimirAnoLetivo = async (id) => {
    if (idEscolaridade > 0 && idTurno > 0 && idturma > 0 && idDisciplina > 0) {
      if (data?.length == 0) {
        alert("Não é possivel imprimir por não ter gerado nenhum resultado!");
        return;
      }
      var response = await getFaltasAnoLetivo(idturma, idDisciplina, trimestreId);

      var obj = {
        nomeEscola: document.querySelector('#titleUnidade').text,
        nomeEscolaridade: escolaridades.find(x => x.id == idEscolaridade).descricao,
        nomeTurno: turnos.find(x => x.id == idTurno).descricao,
        nomeTurma: dataTurma.find(x => x.id == idturma).descricao,
        nomeDisciplina: disciplinas.find(x => x.id == idDisciplina).descricao,
        trimestres: [...new Set(response?.data.map(item => item.trimestre))],
        data: response.data
      };

      localStorage.setItem('faltasImpressaoAnoLetivo', JSON.stringify(obj));
      window.open(urlsDocente.faltasImpressaoAnoLetivo, '_blank');
    } else {
      alert("Para realizar a impressão do Ano Letivo é necessário selecionar:\r\nESCOLARIDADE\r\nTURNO\r\nTURMA\r\nDISCIPLINA")
    }
  }

  return (
    <>

      <BaseComponent>
        <Breadcrumb title={'Buscar Faltas'} itens={[BasePath, 'Faltas', 'Lista Geral']}
          actions={{ link: cadastrarFaltaUrl, text: "Lançar faltas" }}
          imprimir={{ link: urlsDocente.faltasImpressao, text: "Imprimir" }}
          onClickImprimir={imprimir}
          imprimir2={{ link: urlsDocente.faltasImpressao, text: "Imprimir Ano Letivo" }}
          onClickImprimir2={imprimirAnoLetivo} />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar falta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
                <label htmlFor="presenca" className="form-label">PRESENÇA</label>
                {
                  <select className="form-select" id="presenca" value={presenca}
                    onChange={async e => {
                      await setPresenca(e.target.value);
                    }}>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                  </select>
                }
              </div>
            </div>
            <div className='row'>
              <div className="form-group" style={{ width: '100%' }}>
                <label htmlFor="reporteConduta" className="form-label">REPORTE DE CONDUTA</label>
                {
                  <input className="form-control" type='text' id="reporteConduta" value={reporteConduta}
                    onChange={async e => {
                      await setRepoteConduta(e.target.value);
                    }} />
                }
              </div>
            </div>
            <div className='row'>
              <div className="form-group" style={{ width: '100%' }}>
                <label htmlFor="observacao" className="form-label">OBSERVAÇÃO</label>
                {
                  <textarea onChange={(e) => { setObservacao(e.target.value) }} value={observacao} class="form-control" id="observacao" rows="3"></textarea>
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={AtualizaFalta}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 10, marginBottom: 20 }}>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="descricao" className="form-label">ESCOLARIDADE</label>
            {
              <select className="form-select" id="idEscolaridade" value={idEscolaridade}
                onChange={async e => {
                  handleSearch();
                  await setIdEscolaridade(e.target.value)
                  await refetchTurnos();
                }}>
                <option value={0}></option>
                {escolaridades && escolaridades.map(item => (
                  <option key={item.id} value={item.id}>{item.descricao}</option>
                ))}
              </select>
            }
          </div>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="descricao" className="form-label">TURNO</label>
            {
              <select className="form-select" id="idTurno" value={idTurno}
                onChange={async e => {
                  await setIdTurno(e.target.value)
                  handleSearch();
                  await setIdturma(0);
                  await fetchTurma(parseInt(e.target.value));

                }}>
                <option value={0}></option>
                {turnos && turnos.map(item => (
                  <option key={item.id} value={item.id}>{item.descricao}</option>
                ))}
              </select>
            }
          </div>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="descricao" className="form-label">TURMA</label>
            {
              <select className="form-select" id="turmaId" value={idturma}
                onChange={async e => {
                  await setIdturma(e.target.value);
                  refetchAlunos();
                  handleSearch();
                  refetchDisciplina();
                  refetchHorarioAulas();
                }}>
                <option value={0}></option>
                {dataTurma && dataTurma?.map(item => (
                  <option key={item.id} value={item.id}>{item.descricao}</option>
                ))}
              </select>
            }
          </div>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="disciplina" className="form-label">DISCIPLINA</label>
            {
              <select className="form-select" id="disciplina" value={idDisciplina}
                onChange={async e => {
                  await setIdDisciplina(e.target.value);
                  refetchHorarioAulas();
                  handleSearch();
                }}>
                <option value=""></option>
                {disciplinas && disciplinas?.map(item => (
                  <option key={item.id} value={item.id}>{item.descricao}</option>
                ))}
              </select>
            }
          </div>
          <div
            className="form-group"
            style={{
              marginTop: 36,
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleSearch()}
            >
              <i className="material-icons md-search"></i>
            </button>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 10, marginBottom: 20 }}>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="trimestre" className="form-label">TRIMESTRE</label>
            {
              <select className="form-select" id="trimestre" value={trimestreId}
                onChange={async e => {
                  setTrimestreId(e.target.value);
                  handleSearch();
                }}>
                <option value=""></option>
                {ciclos &&
                  ciclos.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </select>
            }
          </div>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="aluno" className="form-label">DATA</label>
            <input onChange={(e) => { setDataChamada(e.target.value); handleSearch(); }} value={dataChamada} type="date" className="form-control"></input>
          </div>
          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="horarioAula" className="form-label">HORÁRIO DA AULA</label>
            <select className="form-select" id="horarioAula" value={horarioAula}
              onChange={async e => {
                await setHorarioAula(e.target.value);
                handleSearch();
              }}>
              <option value=""></option>
              {horarioAulas && horarioAulas?.data.map(item => (
                <option key={item.id} value={item.id}>{item.descricao}</option>
              ))}
            </select>
          </div>

          <div className="form-group" style={{ width: '100%', maxWidth: 250 }}>
            <label htmlFor="aluno" className="form-label">ALUNO</label>
            {
              <select className="form-select" id="aluno" value={idAluno}
                onChange={async e => {
                  await setIdAluno(e.target.value);
                  handleSearch();
                }}>
                <option value="0"></option>
                {alunos && alunos?.data.map(item => (
                  <option key={item.id} value={item.id}>{item.nome}</option>
                ))}
              </select>
            }
          </div>
        </div>





        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 10,
            marginBottom: 20
          }}
        >
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              {loading ? (
                <Spinner />
              ) : (
                <Table
                  columns={columns}
                  data={data == null ? [] : data}
                  hasPagination
                  limit={limit}
                  setLimit={setLimit}
                  skip={skip}
                  setSkip={setSkip}
                  totalItems={total}
                  hiddenColluns={['idChamada']}
                />
              )}
            </div>
          </div>
        </div>
      </BaseComponent>
    </>
  )

};

export default FaltasPage;
