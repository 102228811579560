import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getNotificacoes } from '../../services/servidores';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner';
import { store } from '../../store';
import api from '../../services';

const NotificacoesPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(20);
    const [statusNotificacao, setStatusNotificacao] = useState(0);
    const currentUser = store.getState()["user"]["user"];

    const { isLoading: loading, refetch } = useQuery('getNotificacoes', () => getNotificacoes(statusNotificacao, currentUser.servidorId, limit, skip), {
        onSuccess: (data) => {
            setData(data.data);
            setTotal(data.total);
            setLimit(data.limit);
            setSkip(data.skip);
        },
        enabled: false,
        retry: false
    })

    useEffect(() => {
        refetch();
    }, [limit, skip, statusNotificacao]);

    const columns = useMemo(
        () => [
            { Header: '#', accessor: 'id' },
            { Header: 'Descrição', accessor: 'descricao' },
            {
                Header: 'Lida', accessor: 'lida',
                Cell: ({ row }) => (
                    <span className={`badge bg-${row.original.lida ? 'success' : 'danger'}`}>
                        {row.original.lida ? 'SIM' : 'NÃO'}
                    </span>
                )
            },

            {
                Header: '', accessor: 'actions',
                Cell: ({ row }) => (
                    <>
                        {
                            row.original.lida != true ?
                                <button onClick={() => {
                                    confirmarLida(row.original.id);
                                }} className='btn btn-sm btn-info'>
                                    <i className="material-icons "></i> Marcar como lida
                                </button>
                                :
                                <span className={`badge bg-success`}>MENSAGEM JÁ LIDA</span>
                        }

                    </>
                )
            },
        ],
        [navigate, data]
    )

    // Mark notification as read
  const { mutate: confirmarLida } = useMutation(
    (id) => api.put(`/shared/notificacao/${id}/ConfirmarLeitura`),
    {
      onSuccess: () => {
        toast.success("Notificação marcada como lida");
        refetch(); // Refetch notifications after marking as read
      },
    }
  );

    return (
        <BaseConfiguracoes>
            <Breadcrumb title={'Notificações'} itens={['Perfil', 'Notificações', 'Lista Geral']} />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: 10,
                    marginBottom: 20
                }}
            >
                <div className="form-group" style={{ width: '100%', maxWidth: 300 }}>
                <label className="form-label">Status notificação</label>
                    <select className="form-select"
                        onChange={(e) => {
                            setStatusNotificacao(e.target.value);
                        }}>
                        <option value={0}>Todos</option>
                        <option value={1}>Não lidos</option>
                        <option value={2}>Lidos</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Table
                                columns={columns}
                                data={data}
                                hasPagination
                                limit={limit}
                                setLimit={setLimit}
                                skip={skip}
                                setSkip={setSkip}
                                totalItems={total}
                            />
                        )}
                    </div>
                </div>
            </div>
        </BaseConfiguracoes>
    )

};

export default NotificacoesPage;
