import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BaseAdministrativo from '../../../baseAdministrativo'

import DadosConta from './tab/dadosConta'
import DadosPessoais from './tab/dadosPessoais'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { toast } from 'react-toastify'
import api from '../../../../../services'
import upperCaseSimple from '../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../utils/removeEmpty'
import { store } from '../../../../../store'


const ContasForm = () => {
  const currentUser = store.getState()["user"]["user"];
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()

  const [edit, setEdit] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      descricao: '',
      anexo: null,
    }
  })

  useMemo(() => {
    (async () => {
      if (id && state) {
        setValue('id', id)
        setValue('nome', state?.fornecedor)
        setValue('tipoDeServico', state?.servico)
        setValue('dataPagamento', state?.dataPagamento)
        setValue('valor', new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(state?.valor))
      }
    })()
  }, [id, state, setValue])

  const onSubmit = async (data) => {
    const { tipoDeServico, valor, dataPagamento, anexo } = data;

    console.log({ data })

    if (anexo[0]) {
      const file = anexo[0];

      const formData = new FormData();
      formData.append('arquivo', file);

      let anexoId = '';

      try {
        const sendFile = await api.post('/arquivo/contapagar', formData);
        console.log(sendFile.data.data.id)
        anexoId = sendFile.data.data.id;
      } catch (err) {
        toast.error('Erro no envio do arquivo, tente novamente mais tarde.')
      }

      try {
        let valueUnformatted;
        if (valor.includes('$')) {
          valueUnformatted = Number(valor.split('$')[1].replace('.', '').replace(',', '.'));
        } else {
          valueUnformatted = Number(valor.replace('.', '').replace(',', '.'));
        }
        const dataObject = {
          idFornecedor: data.id,
          idUsuario: currentUser.servidorId,
          tipoServico: tipoDeServico,
          dataPagamento,
          valor: valueUnformatted,
          anexoId
        }
        if (id) {
          await api.put(`/financeiro/contaspagar/${id}`, upperCaseSimple(removeEmpty(dataObject)))
          toast.success("Conta atualizada com sucesso!");
          navigate(urlsAdministrativo.financeiroContasAPagar)
        } else {
          await api.post('/financeiro/contaspagar', upperCaseSimple(removeEmpty(dataObject)))
          toast.success("Conta criada com sucesso!");
          navigate(urlsAdministrativo.financeiroContasAPagar)
        }
      } catch (err) {
        toast.error('Erro no envio da requisição. Verifique sua internet e tente novamente mais tarde.')
      }
    }
  }

  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dadosPessoais-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dadosPessoais"
                        type="button"
                        role="tab"
                        aria-controls="dadosPessoais"
                        aria-selected="true"
                      >
                        Dados Pessoais
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="dadosConta-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dadosConta"
                        type="button"
                        role="tab"
                        aria-controls="dadosConta"
                        aria-selected="false"
                      >
                        Dados Conta
                      </button>
                    </li>
                  </ul>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <DadosPessoais
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        fornecedorData={state ? state?.fornecedor : undefined}
                        readOnly={edit}
                      />
                      <DadosConta
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        servicoData={state ? state?.servico : undefined}
                        readOnly={edit}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(urlsAdministrativo.financeiroContasAPagar)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      {
                        id && !edit && (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => setEdit(true)}
                            disabled={!isValid}
                          >
                            Editar
                          </button>
                        )
                      }

                      {
                        (!id || edit) && (
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={!isValid}
                          >
                            Cadastrar
                          </button>
                        )
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default ContasForm;