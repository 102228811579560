import urls from '../../../utils/urls';

const links = {
  administrativo: {
    id: 'administrativo',
    text: 'ADMINISTRATIVO',
    type: 'title',
    visible: true,
    itens: {
      dashboard: {
        id: 'dashboard',
        text: 'Dashboard',
        path: urls.administrativo,
        iconName:'',
        icon: 'dashboard',
        visible: true,
      },
      // manutencao: {
      //   id: 'manutencao',
      //   text: 'Manutenção',
      //   path: urls.administrativoManutencao,
      //   icon: 'build_circle',
      //   visible: true,
      // },
      // transporte: {
      //   id: 'transporte',
      //   text: 'Transporte',
      //   path: urls.administrativoTransporte,
      //   icon: 'commute',
      //   visible: true,
      // },
      // alimentacao: {
      //   id: 'alimentacao',
      //   text: 'Alimentação',
      //   path: urls.administrativoAlimentacao,
      //   icon: 'restaurant',
      //   visible: true,
      // },
      // acompanhamentoNutricional: {
      //   id: 'acompanhamentoNutricional',
      //   text: 'Acomp Nutricional',
      //   path: urls.administrativoAcompanhamentoNutricional,
      //   icon: 'set_meal',
      //   visible: true,
      // },
    }
  },
  patrimonio: {
    id: 'patrimonio',
    text: 'PATRIMÔNIO',
    type: 'title',
    visible: true,
    itens: {
      fornecedores: {
        id: 'fornecedores',
        text: 'Fornecedores',
        path: urls.administrativoPatrimonioControleFornecedores,
        iconName:'',
        icon: 'group',
        visible: true,
      },
      contratos: {
        id: 'contratos',
        text: 'Contratos',
        path: urls.administrativoPatrimonioControleContratos,
        iconName:'',
        icon: 'assignment',
        visible: true,
      },
      categoriaProdutos: {
        id: 'categoriaProdutos',
        text: 'Categoria de Produtos',
        path: urls.administrativoPatrimonioCategoriaProdutos,
        iconName:'',
        icon: 'category',
        visible: true,
      },
      produtos: {
        id: 'produtos',
        text: 'Produtos',
        path: urls.administrativoPatrimonioControleProdutos,
        iconName:'',
        icon: 'border_color',
        visible: true,
      },
      estoque: {
        id: 'estoque',
        text: 'Controle de estoque',
        path: urls.administrativoPatrimonioControleEstoque,
        iconName:'',
        icon: 'warehouse',
        visible: true,
      },
      solicitacao: {
        id: 'solicitacao',
        text: 'Solicitações',
        path: urls.administrativoPatrimonioControleSolicitacao,
        iconName:'',
        icon: 'content_paste_go',
        visible: true,
      },
    }
  },
  ti: {
    id: 'ti',
    text: 'TI',
    type: 'title',
    visible: true,
    itens: {
      impressoras: {
        id: 'impressoras',
        text: 'Impressoras',
        path: urls.administrativoPatrimonioControleImpressoras,
        iconName:'',
        icon: 'print',
        visible: true,
      },
      // estoque: {
      //   id: 'relatorio',
      //   text: 'Relatório Impressoras',
      //   path: urls.administrativoPatrimonioControleImpressorasRelatorios,
      //   icon: 'report',
      //   visible: true,
      // },
    }
  },
  financeiro: {
    id: 'financeiro',
    text: 'FINANCEIRO',
    type: 'title',
    visible: true,
    itens: {
      processos: {
        id: 'processos',
        text: 'Processos',
        path: urls.administrativoFinanceiroProcessos,
        iconName:'',
        icon: 'folder_special',
        visible: true,
      },
      contasPagar: {
        id: 'contasPagar',
        text: 'Contas a pagar',
        path: urls.administrativoFinanceiroContasAPagar,
        iconName:'',
        icon: 'request_quote',
        visible: true,
      },
      memorando: {
        id: 'memorando',
        text: 'Memorandos',
        path: urls.administrativoFinanceiroMemorandos,
        iconName:'',
        icon: 'class',
        visible: true,
      },
    }
  },  

  nutricao: {
    id: 'nutricao',
    text: 'NUTRIÇÃO',
    type: 'title',
    visible: true,
    itens: {
      fornecedores: {
        id: 'fornecedores',
        text: 'Fornecedores',
        path: urls.administrativoNutricaoFornecedor,
        iconName:'',
        icon: 'group',
        visible: true,
      },
      categoriaProdutos: {
        id: 'categoriaProdutos',
        text: 'Categoria Produtos',
        path: urls.administrativoNutricaoCategoriaProduto,
        iconName:'',
        icon: 'category',
        visible: true,
      },
      produtos: {
        id: 'produtos',
        text: 'Produtos',
        path: urls.administrativoNutricaoProduto,
        iconName:'',
        icon: 'border_color',
        visible: true,
      },
      controlePedido: {
        id: 'controlePedido',
        text: 'Controle de Pedidos ',
        path: urls.administrativoNutricaoControlePedido,
        iconName:'',
        icon: 'assignment',
        visible: true,
      },
      movimentacoes: {
        id: 'movimentacoes',
        text: 'Movimentações',
        path: urls.administrativoNutricaoMovimentacoes,
        iconName:'',
        icon: 'warehouse',
        visible: true,
      },
      cardapio: {
        id: 'cardapio',
        text: 'Cardápio',
        path: urls.administrativoNutricaoCardapio,
        iconName:'',
        icon: 'content_paste_go',
        visible: true,
      },
    }
  },
  transporte: {
    id: 'transporte',
    text: 'TRANSPORTE',
    type: 'title',
    visible: true,
    itens: {
      funcionariosDashboard: {
      id: 'funcionariosDashboard',
      text: 'Dashboard',
      path: urls.administrativoTransporteDashboard,
      iconName:'',
      icon: 'group',
      visible: true,
    },
    alunos: {
      id: 'alunos',
      text: 'Alunos',
      path: urls.administrativoTransporteAlunos,
      iconName:'',
      icon: 'group',
      visible: true,
    },
    funcionarios: {
      id: 'funcionarios',
      text: 'Funcionários',
      path: urls.administrativoTransporteFuncionarios,
      iconName:'',
      icon: 'group',
      visible: true,
    },
    veiculos: {
      id: 'veiculos',
      text: 'Veículos',
      path: urls.administrativoTransporteVeiculos,
      iconName:'',
      icon: 'group',
      visible: true,
    },
    quadroHorario: {
      id: 'quadroHorario',
      text: 'Quadro de Horários',
      path: urls.administrativoTransporteQuadroHorario,
      iconName:'',
      icon: 'group',
      visible: true,
    },
    }
  },
};

export default links;