import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "./../../../components/breadcrumb";
import BaseGestao from './../../gestao/baseGestao';
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseDocente from "../../docente/baseDocente";
import { useMutation, useQuery } from 'react-query';
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas } from '../../../services/quadro-horario';
import { getAlunosFiltro } from './../../../services/alunos';
import { getDisciplina, postFalta, getFaltas } from './../../../services/faltas';
import { getDisciplinasByTurmaServidor } from './../../../services/disciplinas';
import { getBnccByDisciplinaAnoFaixa } from './../../../services/bncc-fundamental';
import urlsGestao from './../../gestao/urlsGestao';
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import urlsDocente from "../../docente/urlsDocente";
import ErrorIcon from '@mui/icons-material/Error';
import { store } from '../../../store/index';
import Select, { AriaOnFocus } from 'react-select';
import './estilo.css';

const FaltasAdicionarPage = (gestao) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
    const [escolaridade, setEscolaridade] = useState(0);
    const [turno, setTurno] = useState(0);
    const [turma, setTurma] = useState(0);
    const [trimestre, setTrimestre] = useState(0);
    const [disciplina, setDisciplina] = useState(0);
    const [dataChamada, setDataChamada] = useState("");
    const [horarioAula, setHorarioAula] = useState("");
    const [planoEnsino, setPlanoEnsino] = useState("");
    const [bncc, setBncc] = useState("");
    const currentUser = store.getState()['user']['user'];
    const [listaBncc, setListaBncc] = useState([]);
    const [listaAlunos, setListaAlunos] = useState([]);
    const onMenuOpen = () => setIsOpen(true);
    const onMenuClose = () => setIsOpen(false);
    const [habilitaDataChamada, setHabilitaDataChamada] = useState(false);

    let BaseComponent;
    let BasePath;
    let servidorId = 0;

    const ciclos = [
        { value: 1, label: '1º TRIMESTRE' },
        { value: 2, label: '2º TRIMESTRE' },
        { value: 3, label: '3º TRIMESTRE' },
        // { value: 4, label: '4º TRIMESTRE' },
    ];

    

    // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
    if (gestao.gestao) {
        BaseComponent = BaseGestao;
        BasePath = 'Gestão'
        servidorId = 0;

    }
    if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades'
        servidorId = 0;

    }

    // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
    if (gestao.docente) {
        BaseComponent = BaseDocente;
        BasePath = 'Docente'
        servidorId = currentUser?.servidorId;

    }

    const { mutate, isLoading } = useMutation(postFalta, {
        onSuccess: (message) => {
            toast.success(message);
            navigate(urlsGestao.faltas);
        },
        onError: (error) => {
            if (error.response) {
                const { message } = error.response.data;
                toast.error(message);
            } else if (error.request) {
                toast.error('Erro ao tentar cadastrar uma nacionalidade, tente novamente mais tarde.');
            } else {
                toast.error(error.message);
            }
        }
    });

    const { data: escolaridades } = useQuery('getEscolaridades', () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
        {
            retry: 3,
            enabled: unidade !== null
        }
    )

    const { data: turmas, isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmasUnidadeEscolaridadeTurno', () =>
        unidade !== '' && escolaridade !== '' && turno !== '' ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null,
        {
            retry: 3,
            enabled: unidade !== '' && escolaridade !== '' && turno !== ''
        }
    )

    const { data: turnos, isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery('getTurnos', () => quadroHorarioServidorTurno(servidorId, unidade, escolaridade),
        {
            enabled: unidade !== 0 && escolaridade !== 0,
            retry: 3
        }
    )

    const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery('getAlunosByUnidadeEscolaridadeTurnoTurma', () =>
        unidade !== 0 && escolaridade !== 0 && turno !== 0 && turma !== 0 ? getAlunosFiltro(unidade, escolaridade, turno, turma, 1, 0, '', '', '', 0, 100, 2025) : null,
        {
            retry: 3,
            enabled: unidade !== 0 && escolaridade !== 0 && turno !== 0 && turma !== 0
        }
    )

    const { data: disciplinas, isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplinasByTurmaServidor', () =>
        turma !== 0 ? getDisciplinasByTurmaServidor(turmas, servidorId) : null,
        {
            retry: 3
        }
    )

    const { data: horarioAulas, isLoading: loadingHorarioAulas, refetch: refetchHorarioAulas } = useQuery('quadroHorarioAulas', () =>
        turma !== 0 && disciplina !== "" ? quadroHorarioAulas(turma, servidorId, disciplina) : null,
        {
            retry: 3
        }
    )
    console.log(disciplinas);

    const escolaridadeDescricao = escolaridade > 0 ? escolaridades?.find(x => x.id === parseInt(escolaridade))?.descricao : "";
    const disciplinaDescricao = disciplina > 0 ? disciplinas?.find(x => x.id === parseInt(disciplina))?.descricao : "";

    console.log(disciplinaDescricao);

    

    const { data: bnccs, isLoading: loadingBncc, refetch: refetchBncc } = useQuery('getBnccByDisciplinaAnoFaixa', () =>
        escolaridade !== 0 && disciplina !== 0 ?
            getBnccByDisciplinaAnoFaixa(disciplinaDescricao, escolaridadeDescricao) : null,
        {
            onSuccess: (data) => {
                var dados = [];
                if (data != null && (escolaridadeDescricao.includes("CRECHE") || escolaridadeDescricao.includes("PRÉ"))) {
                    
                    data.forEach(item => {
                        dados.push({ value: item.id, label: item.habilidades })
                    });
                    setListaBncc(dados);
                }
                else if (data != null && (!escolaridadeDescricao.includes("CRECHE") || !escolaridadeDescricao.includes("PRÉ"))) {
                    data.data.forEach(item => {
                        dados.push({ value: item.id, label: item.habilidades })
                    });
                    setListaBncc(dados);
                }
            },
            retry: 3
        }
    )

    useEffect(() => {
        if (disciplinaDescricao != "" && escolaridadeDescricao != "") {
            refetchBncc();
        }
    }, [disciplinaDescricao, escolaridadeDescricao]);

    const { data: faltas, isLoading: loading, refetch } = useQuery('getFaltas', () => getFaltas(turno, escolaridade, turma, unidade, 0, disciplina, 0, 9999),
        {
            retry: 2
        }
    )

    const SalvarLancamentoFaltas = async () => {

        if (disciplina == "") {
            toast.error('SELECIONE A DISCIPLINA');
            return;
        }
        if (dataChamada == "") {
            toast.error('INFORME A DATA DA CHAMADA');
            return;
        }
        if (planoEnsino == "") {
            toast.error('INFORME O PLANO DE ENSINO');
            return;
        }
        if (horarioAula == "") {
            toast.error('INFORME O HORÁRIO DA AULA');
            return;
        }
        if (trimestre == "") {
            toast.error('INFORME O TRIMESTRE');
            return;
        }

        if (planoEnsino == "") {
            toast.error('SELECIONE O PLANO DE ENSINO');
            return;
        }
        var alunosPost = [];
        for (let i = 0; i < listaAlunos.length; i++) {
            const aluno = listaAlunos[i];
            alunosPost.push({
                idAluno: aluno.id,
                presenca: aluno.presenca,
                reporteDeConduta: document.getElementById("reporteConduta" + aluno.id).value
            });
        }

        // if(anoFaixa.indexOf("CRECHE") > -1)
        //     url = `BnccInfantil/filtro?escolaridade=CRECHE&anoFaixa=&componente=`;
        //   else if(anoFaixa.indexOf("PRÉ") > -1)


        var model = {
            turmaId: parseInt(turma),
            disciplinaId: parseInt(disciplina),
            trimestreId: parseInt(trimestre),
            dataChamada: dataChamada,
            usuarioCriacao: servidorId,
            planoDeEnsino: planoEnsino,
            quadroHorarioAulaId: parseInt(horarioAula),
            bnccId: bncc == "" ? null : bncc,
            tipoBncc: escolaridades.find(x => x.id == parseInt(escolaridade)).descricao.indexOf("CRECHE") > -1 || escolaridades.find(x => x.id == parseInt(escolaridade)).descricao.indexOf("PRÉ") ? 1 : 2,
            listaPresenca: alunosPost
        }
        var response = await postFalta(model);
        if (response.statusCode == 200) {
            navigate(-1);
            toast.success("Lançamento de falta realizado com sucesso!");
        }
        else
            toast.error("Houve um problema ao realizar o Lancamento de falta!");
    }

    // useEffect(() => {
    //     faltas?.data?.map((row) => {
    //         document.getElementById("falta" + row.idAluno).value = row.qtdFaltas;
    //     });
    // }, [disciplina]);

    useEffect(() => {
        var items = [];
        alunos?.data.forEach(aluno => {
            items.push({
                id: aluno.id,
                numeroMatricula: aluno.numeroMatricula,
                nome: aluno.nome,
                presenca: true,
                reporteDeConduta: ''
            });
        });

        setListaAlunos(items);
        // alunos?.data.map((row) => {
        //     document.getElementById("chk" + row.id).checked = true;
        // });
    }, [alunos]);

    const clickFaltaPresenca = (item) => {
        listaAlunos.find(x => x.numeroMatricula == item.numeroMatricula && x.id == item.id).presenca = !item.presenca;
        setListaAlunos([...listaAlunos]);
    }

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const dayOfWeek = selectedDate.getDay(); // 0 - segunda, 1 - terça, 2 - quarta, 3 - quinta, 4 - sexta

        var dia = horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 1 ? 6 : horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 7 ? 5 : 0;
        if (horarioAulas?.data.find(x => x.id == horarioAula).diaSemana >= 2) {
            dia = horarioAulas?.data.find(x => x.id == horarioAula).diaSemana - 2;
        } 
        if (dayOfWeek === dia) {
            setDataChamada(e.target.value);
        } else {
            alert('Selecione o dia correto para o lançamento.\r\n' +
                `${horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 1 ? 'Domingo' :
                    horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 2 ? 'Segunda-feira' :
                        horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 3 ? 'Terça-feira' :
                            horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 4 ? 'Quarta-feira' :
                                horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 5 ? 'Quinta-feira' :
                                    horarioAulas?.data.find(x => x.id == horarioAula).diaSemana == 6 ? 'Sexta-feira' : 'Sabado'
                }`
            );
            e.target.value = '';
        }
    };

    const handleKeyDown = (e) => {
        e.preventDefault();
    };

    return (
        <BaseComponent>
            <Breadcrumb title={'Formulário Faltas'} itens={[BasePath, 'Faltas', 'Formulário']} />
            <form id='form'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">ESCOLARIDADE</label>
                                            {
                                                <select className="form-select" id="idEscolaridade" value={escolaridade}
                                                    onChange={async e => {
                                                        await setEscolaridade(e.target.value)
                                                        await setTurma(0);
                                                        await refetchTurnos();
                                                        await refetchBncc();
                                                    }}>
                                                    <option value={0}></option>
                                                    {escolaridades && escolaridades.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">TURNO</label>
                                            {
                                                <select className="form-select" id="idTurno" value={turno}
                                                    onChange={async e => {
                                                        await setTurno(e.target.value)
                                                        await setTurma(0);
                                                        refetchTurmas();
                                                    }}>
                                                    <option value={0}></option>
                                                    {turnos && turnos.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">TURMA</label>
                                            {
                                                <select className="form-select" id="turmaId" value={turma}
                                                    onChange={async e => {
                                                        await setTurma(e.target.value);
                                                        refetchAlunos();
                                                        refetchDisciplina();
                                                        refetchHorarioAulas();
                                                    }}>
                                                    <option value={0}></option>
                                                    {turmas && turmas?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                        <div className='col-3'>
                                            <label htmlFor="disciplina" className="form-label">DISCIPLINA</label>
                                            {
                                                <select className="form-select" id="disciplina" value={disciplina}
                                                    onChange={async e => {
                                                        await setDisciplina(e.target.value);
                                                        refetchHorarioAulas();
                                                        alunos?.data?.map((row) => {
                                                            document.getElementById("reporteConduta" + row.id).value = "";
                                                        });
                                                        await refetchBncc();
                                                    }}>
                                                    <option value=""></option>
                                                    {disciplinas?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    <div className='row' style={{ marginTop: 20 }}>
                                        <div className='col-12'>
                                            {alunos != null && alunos?.data.length > 0 ?
                                                <>
                                                    <div className='row' style={{ marginBottom: '20px' }}>
                                                        <div className='col-3'>
                                                            <label htmlFor="trimestre" className="form-label">TRIMESTRE</label>
                                                            {
                                                                <select className="form-select" id="trimestre" value={trimestre}
                                                                    onChange={async e => {
                                                                        setTrimestre(e.target.value);
                                                                    }}>
                                                                    <option value=""></option>
                                                                    {ciclos &&
                                                                        ciclos.map(item => (
                                                                            <option key={item.value} value={item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            }
                                                        </div>
                                                        <div className='col-3'>
                                                            <label htmlFor="horarioAula" className="form-label">HORÁRIO DA AULA</label>
                                                            <select className="form-select" id="horarioAula" value={horarioAula}
                                                                onChange={async e => {
                                                                    await setHorarioAula(e.target.value);
                                                                    if (e.target.value == "") {
                                                                        setHabilitaDataChamada(false);
                                                                        setDataChamada('');
                                                                    } else {
                                                                        setHabilitaDataChamada(true);
                                                                    }
                                                                }}>
                                                                <option value=""></option>
                                                                {horarioAulas && horarioAulas?.data.map(item => (
                                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='col-3'>
                                                            <label htmlFor="dataChamada" className="form-label">DATA CHAMADA</label>
                                                            <input id="dataChamada" value={dataChamada} readOnly={!habilitaDataChamada} onChange={(e) => { handleDateChange(e) }} type="date" onKeyDown={handleKeyDown} className="form-control"></input>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-hover table-responsive">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th style={{ display: 'none' }} className="text-center">ID</th>
                                                                    <th className="text-center">MATRÍCULA</th>
                                                                    <th className="text-center">NOME</th>
                                                                    <th className="text-center">PRESENÇA<i style={{ marginLeft: '10px', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="'P' representa PRESENÇA. 'F' representa FALTA"><ErrorIcon /></i></th>
                                                                    <th className="text-center">REPORTE DE CONDUTA<i style={{ marginLeft: '10px', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Campo utilizado para informar caso tenha alguma informação para enviar ao pai referente ao aluno."><ErrorIcon /></i></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    listaAlunos.map((row) => {
                                                                        return (<tr className="text-center">
                                                                            <td style={{ display: 'none' }}>{row.id}</td>
                                                                            <td>{row.numeroMatricula}</td>
                                                                            <td>{row.nome}</td>
                                                                            <td>
                                                                                {
                                                                                    <div style={{ cursor: 'pointer', marginLeft: '60px' }} onClick={() => clickFaltaPresenca(row)} className={row.presenca == true ? 'presenca btn-presenca' : 'falta btn-presenca'}>
                                                                                        <h3>{row.presenca == true ? 'P' : 'F'}</h3>
                                                                                    </div>
                                                                                }
                                                                                {/* <div className="form-check form-switch">
                                                                                    <input style={{ width: '2.5em', height: '1.3em' }} className="form-check-input" type="checkbox" role="switch" id={'chk' + row.id} />
                                                                                </div> */}
                                                                            </td>
                                                                            <td>
                                                                                <input className="form-control" type='text' id={'reporteConduta' + row.id} />
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : alunos != null && alunos?.data.length == 0 ?
                                                    <p className="text-center">Nenhum registro encontrado</p> : <p className="text-center">Selecione os filtros</p>}

                                        </div>
                                    </div>
                                </>
                                {
                                    alunos != null && alunos?.data.length ?
                                        <div className='row' style={{ marginTop: 30 }}>
                                            <div className='col-md-12'>
                                                <label htmlFor="bncc" className="form-label">BNCC</label>
                                                <Select
                                                    placeholder="Selecione o BNCC"
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    onMenuOpen={onMenuOpen}
                                                    onMenuClose={onMenuClose}
                                                    options={listaBncc}
                                                    value={listaBncc.find(x => x.value == bncc)}
                                                    onChange={e => {
                                                        setBncc(e.value);
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className='col-md-12' style={{ marginBottom: 10, marginTop: 10 }}>
                                                <div class="form-group">
                                                    <label for="planoEnsino">PLANO DE ENSINO*</label>
                                                    <textarea onChange={(e) => { setPlanoEnsino(e.target.value) }} value={planoEnsino} class="form-control" id="planoEnsino" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                        </>
                                }


                            </div>
                            <div className='card-footer text-end'>
                                <button
                                    style={{ marginRight: 20 }}
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={() => navigate(-1)}
                                >
                                    Voltar
                                </button>
                                <button disabled={alunos?.data.length > 0 ? false : true}
                                    style={{ marginRight: 20 }}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={async () => await SalvarLancamentoFaltas()}>
                                    Salvar
                                </button>
                                {/* <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                                    {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseComponent>
    )

};

export default FaltasAdicionarPage;