import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Zoom from "@mui/material/Zoom";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  Avatar,
  Tooltip,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  getProdutoById,
  postProduto,
  putProduto,
} from "../../../../../../../../services/administrativo/patrimonio-estoque";
import { getGrupoProdutos } from "../../../../../../../../services/administrativo/patrimonio";
import { store } from "../../../../../../../../store";
import { tipoProduto } from "../../../../data";

export default function Produto({ onClose, hasBackButton = true, onRefetch }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [imagePreview, setImagePreview] = useState(
    id ? `data:image/jpeg;base64,${data?.imagem}` : null
  );
  const [base64Image, setBase64Image] = useState(null);

  const currentUser = store.getState()["user"]["user"];

  const { data: produtoData } = useQuery(
    "getProdutoById",
    () => id && getProdutoById(id),
    {
      enabled: id ? true : false,
      onSuccess: (data) => {
        setData(data?.data);
      },
    }
  );

  const { data: groupData } = useQuery("getGrupoProdutos", getGrupoProdutos, {
    retry: false,
    enabled: true,
  });

  const { mutate } = useMutation(id ? putProduto : postProduto, {
    onSuccess: (data) => {
      if (id) {
        toast.success("Produto atualizado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/produtos/");
        onRefetch();
      } else {
        toast.success("Produto criado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/produtos/");
        onRefetch();
      }
    },
    onError: (data) => {
      if (id) {
        toast.error("Erro ao atualizar produto. Tente novamente mais tarde.");
      } else {
        toast.error("Erro ao criar produto. Tente novamente mais tarde.");
      }
    },
  });

  useEffect(() => {
    if (id && data) {
      setImagePreview(`data:image/jpeg;base64,${data?.imagem}`);
    }
  }, [id, data]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = null;
  };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      id: null,
      imagem: null,
      nome: "",
      marca: "",
      produtoCategoriaId: null,
      tipoProduto: null,
      criadoPor: currentUser?.usuarioLogin || "",
    },
  });

  useEffect(() => {
    if (id && data) {
      setValue("id", data?.id);
      setValue("nome", data?.nome);
      setValue("marca", data?.marca);
      setValue("produtoCategoriaId", data?.produtoCategoriaId);
      setValue("tipoProduto", data?.tipoProduto);
      setValue("criadoPor", data?.criadoPor);
    }
  }, [id, data, setValue]);

  const onSubmit = (data) => {
    if (!data.nome) {
      toast.error("O Nome do Produto é obrigatório.");
      return;
    }

    if (!data.marca) {
      toast.error("A Marca do Produto é obrigatória.");
      return;
    }

    if (data?.produtoCategoriaId == 0) {
      toast.error("A Categoria do Produto é obrigatória.");
      return;
    }

    if (data.tipoProduto == 0) {
      toast.error("O Tipo do Produto é obrigatório.");
      return;
    }

    const request = {
      ...data,
      imagem: base64Image,
    };

    console.log(request);

    if (id) {
      mutate(request);
    } else {
      mutate(request);
      onClose();
    }
  };

  useEffect(() => {
    if (groupData) {
      const activeGroups = groupData.filter((group) => group?.status);
      setFilteredGroups(activeGroups);
    }
  }, [groupData]);

  const produtoCategoriaIdWatch = watch("produtoCategoriaId");
  const tipoWatch = watch("tipoProduto");

  return (
    <div
      className="tab-pane fade show active"
      id="produtos"
      role="tabpanel"
      aria-labelledby="produtos-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-2">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="image-upload"
              />
              <label htmlFor="image-upload">
                <Tooltip
                  title={id ? "Editar Imagem" : "Adicionar Imagem"}
                  arrow
                  slots={{
                    transition: Zoom,
                  }}
                >
                  <Avatar
                    sx={{
                      width: 130,
                      height: 130,
                      border: "1px solid rgba(0, 0, 0, 0.38)",
                      padding: 1,
                      backgroundColor: imagePreview
                        ? "transparent"
                        : "rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                    }}
                    src={imagePreview}
                  >
                    {!imagePreview && (
                      <AddPhotoAlternateIcon sx={{ fontSize: 48 }} />
                    )}
                  </Avatar>
                </Tooltip>
              </label>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <TextField
                label="Nome do Produto"
                variant="outlined"
                fullWidth
                {...register("nome")}
                error={!!errors.nome}
                helperText={errors.nome?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-6">
              <TextField
                label="Marca"
                variant="outlined"
                fullWidth
                {...register("marca")}
                error={!!errors.marca}
                helperText={errors.marca?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <FormControl fullWidth error={!!errors.produtoCategoriaId}>
                <InputLabel>Categoria</InputLabel>
                <Select
                  {...register("produtoCategoriaId")}
                  label="Categoria"
                  value={produtoCategoriaIdWatch || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {filteredGroups?.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.descricao}
                    </MenuItem>
                  ))}
                </Select>
                {errors.produtoCategoriaId && (
                  <FormHelperText>
                    {errors.produtoCategoriaId.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl fullWidth error={!!errors.tipoProduto}>
                <InputLabel>Tipo de Produto</InputLabel>
                <Select
                  {...register("tipoProduto")}
                  label="Tipo de Produto"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={tipoWatch || ""}
                >
                  <MenuItem value="" disabled>
                    SELECIONE
                  </MenuItem>
                  {tipoProduto?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tipoProduto && (
                  <FormHelperText>Campo obrigatório!</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <TextField
                label="Usuário do Sistema"
                variant="outlined"
                fullWidth
                {...register("criadoPor")}
                error={!!errors.criadoPor}
                helperText={errors.criadoPor?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {hasBackButton && (
            <Button
              type="button"
              color="warning"
              variant="contained"
              onClick={() => navigate(-1)}
              style={{ marginRight: "8px" }}
            >
              Voltar
            </Button>
          )}
          <Button
            type="submit"
            color="success"
            variant="contained"
            disabled={!isValid}
            // onClick={onClose}
          >
            {id ? "Atualizar" : "Cadastrar"}
          </Button>
        </div>
      </form>
    </div>
  );
}
