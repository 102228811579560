export const tipoProduto = [
  { value: 1, label: "DURÁVEL" },
  { value: 2, label: "NÃO DURÁVEL" },
];

export const conservacao = [
  { value: 1, label: "ÓTIMO" },
  { value: 2, label: "BOM" },
  { value: 3, label: "REGULAR" },
  { value: 4, label: "RUIM" },
  { value: 5, label: "PESSIMO" },
];

export const status = [
  { value: 1, label: "CONCLUÍDO" },
  { value: 2, label: "EM ANÁLISE" },
  { value: 3, label: "EM TRANSPORTE" },
  { value: 4, label: "REPROVADO" },
];
