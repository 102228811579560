import { useQuery } from "react-query";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  getRegistros,
  postTransferencia,
} from "../../../../services/administrativo/patrimonio-estoque";
import { toast } from "react-toastify";

function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

function TransferenciaEntreUnidades({ unidades, onClose }) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [unidadeDestino, setUnidadeDestino] = useState(null);
  const [registro, setRegistro] = useState({});

  const debouncedSearch = React.useRef(
    debounce((nextValue) => setSearchText(nextValue), 300)
  ).current;

  const unidadeOrigemId = localStorage.getItem("unidadeID");

  const { control, handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues: {
      produtos: [],
      observacoes: "",
      unidadeOrigemId: unidadeOrigemId || "",
      transportadoPor: "",
    },
  });

  const { data: registrosData, isFetching } = useQuery(
    ["getRegistros", searchText],
    () =>
      getRegistros({
        unidadeId: unidadeOrigemId,
        searchText,
      }),
    {
      enabled: !!searchText,
    }
  );

  const handleInputChange = (event, value) => {
    debouncedSearch(value);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "produtos",
  });

  const onSubmit = async (data) => {
    if (!data.produtos || data.produtos.length === 0) {
      toast.error("Você precisa adicionar ao menos um produto.");
      return;
    }

    const produtosFormatados = data?.produtos?.map((produto) => ({
      estoqueId: produto.id,
      quantidade: parseInt(produto.quantidadeFornecida),
    }));

    const payload = {
      observacao: data.observacoes,
      transportadoPor: data.transportadoPor,
      unidadeOrigemId: parseInt(data.unidadeOrigemId),
      unidadeDestinoId: unidadeDestino,
      produtosEnviados: produtosFormatados,
    };

    try {
      await postTransferencia(payload);
      const successMessage = "Transferência efetivada com sucesso!";
      toast.success(successMessage);
      reset();
      onClose();
    } catch {
      const errorMessage =
        "Erro ao efetivar transferência. Tente de novo mais tarde!";
      toast.error(errorMessage);
    }
  };

  return (
    <div className="card">
      <Box m={4} component="form" onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        <Grid container spacing={2} marginTop={4} alignItems="center">
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Unidade Origem"
              disabled
              defaultValue={unidadeOrigemId || ""}
              {...register("unidadeOrigemId", {
                required: "A unidade de origem é obrigatória",
              })}
            >
              {unidades?.map((unidade) => (
                <MenuItem key={unidade.id} value={unidade.id}>
                  {unidade.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={unidades}
              getOptionLabel={(option) => option?.nome || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              value={
                unidades?.find((unidade) => unidade.id === unidadeDestino) ||
                null
              }
              onChange={(_, selectedOption) => {
                setUnidadeDestino(selectedOption?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Unidade Destino" />
              )}
            />
          </Grid>

          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item xs={5}>
                <Autocomplete
                  id={`produtoId-${index}`}
                  disableClearable
                  options={
                    registrosData?.data?.filter(
                      (registro) =>
                        !watch("produtos").some(
                          (produto) => produto.id === registro.id
                        )
                    ) || []
                  }
                  filterOptions={(x) => x}
                  getOptionLabel={(option) =>
                    `${option.nomeProduto} - ${option.marca} - ${
                      option.numeroPatrimonio ? option.numeroPatrimonio : "N/A"
                    }` || ""
                  }
                  onInputChange={handleInputChange}
                  loading={isFetching}
                  onChange={(_, selectedOption) => {
                    setRegistro(selectedOption);
                    const {
                      id,
                      tipoProduto,
                      numeroDeSerie,
                      dataValidade,
                      quantidade,
                    } = selectedOption;
                    setValue(`produtos.${index}`, {
                      id,
                      tipoProduto,
                      numeroDeSerie,
                      dataValidade,
                      quantidadeDisponivel: quantidade,
                      quantidadeFornecida:
                        tipoProduto === "DURÁVEL" ? 1 : undefined,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nome do Produto"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {watch(`produtos.${index}.tipoProduto`) === "DURÁVEL" && (
                <>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Quantidade"
                      value={1}
                      disabled
                    />
                  </Grid>
                  {watch(`produtos.${index}.numeroDeSerie`) && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        label="Número de Série"
                        value={watch(`produtos.${index}.numeroDeSerie`)}
                        disabled
                      />
                    </Grid>
                  )}
                </>
              )}

              {watch(`produtos.${index}.tipoProduto`) === "NÃO DURÁVEL" && (
                <>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Quantidade"
                      {...register(`produtos.${index}.quantidadeFornecida`, {
                        required: "Quantidade é obrigatória",
                        validate: (value) =>
                          (value > 0 &&
                            value <=
                              watch(
                                `produtos.${index}.quantidadeDisponivel`
                              )) ||
                          `Quantidade deve ser menor ou igual a ${watch(
                            `produtos.${index}.quantidadeDisponivel`
                          )}`,
                      })}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Data de Validade"
                      value={
                        watch(`produtos.${index}.dataValidade`)
                          ? new Date(watch(`produtos.${index}.dataValidade`))
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => remove(index)}
                >
                  Remover
                </Button>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() =>
                append({
                  nomeProduto: "",
                  tipoProduto: "",
                  quantidadeFornecida: 1,
                  conservacao: "",
                })
              }
            >
              Adicionar Produto
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Transportado Por"
              {...register("transportadoPor")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Observações"
              {...register("observacoes")}
              placeholder="Adicione quaisquer detalhes ou comentários adicionais sobre esta solicitação."
            />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Efetivar Transferência
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default TransferenciaEntreUnidades;
