import ProcessoSeletivoServidoresRecurso from './tab/index'


const FormularioProcessoSeletivoServidorRecurso = () => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 48px',
    }}>
      <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}  width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
      <h6 style={{ margin: '15px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <h2 style={{ margin: '15px', alignSelf: 'center' }}>PROCESSO SELETIVO SIMPLIFICADO N 23/2024</h2>
      {/* <h2 style={{ margin: '15px', alignSelf: 'center' }}>SOMENTE PARA ALUNOS DAS UNIDADES DE ENSINO QUE NÃO ATENDEM O SEGMENTO NECESSÁRIO À MATRÍCULA 2025.</h2> */}
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                    <div className="tab-content" id="myTabContent">
                    <ProcessoSeletivoServidoresRecurso />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormularioProcessoSeletivoServidorRecurso
