import { useMemo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Card, CardContent, CardActions, Grid, Typography, TextField, MenuItem } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import {
  criarDistrito,
  editarDistrito,
  alocarBairros,
  getBairrosDistrito,
  deleteBairroDistrito,
  alocarDistrito,
  getUnidadesDistritos,
  deleteDistritoUnidade,
} from "../../../services/unidades";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import Table from "../../../components/table";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import { getUnidades } from "../../../services/unidades";

const DistritoPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [unidadeData, setUnidadeData] = useState([]);
  const [selectedBairros, setSelectedBairros] = useState([]);
  const [selectedUnidades, setSelectedUnidades] = useState([]);
  const [unidadesOptions, setUnidadesOptions] = useState([]);
  const [bairroOptions] = useState([
    "Alecrim",
    "Baixo Grande",
    "Baleia",
    "Balneário São Pedro",
    "Balneário das Conchas",
    "Base Aero Naval",
    "Bela Vista",
    "Boa Vista",
    "Boqueirão",
    "Botafogo",
    "Campo Redondo",
    "Centro",
    "Colina",
    "Cruz",
    "Estação",
    "Flexeira",
    "Flexeira II",
    "Fluminense",
    "Itaí",
    "Jardim Arco Íris",
    "Jardim Morada da Aldeia",
    "Jardim Primavera",
    "Jardim Soledade",
    "Jardim das Acácias",
    "Morro do Milagre",
    "Nova São Pedro",
    "Parque Arruda",
    "Parque Dois Meninos",
    "Parque Estoril",
    "Pau Rachado",
    "Pinheiros",
    "Ponta do Ambrósio",
    "Porto da Aldeia",
    "Porto do Carro",
    "Poço Fundo",
    "Praia Linda",
    "Praia do Sudoeste",
    "Recanto das Orquídeas",
    "Recanto do Sol",
    "Retiro",
    "Rua do Fogo",
    "Santo Antônio",
    "Sergeira",
    "São José",
    "São João",
    "São Mateus",
    "Três Vendas",
    "Vinhateiro",
    "Área Rural de São Pedro da Aldeia",
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      descricao: "",
      cidade: "",
      estado: "",
    },
  });

  const { refetch } = useQuery(
    "getBairrosDistrito",
    () => getBairrosDistrito(id),
    {
      onSuccess: (response) => {
        setData(response?.data);
      },
      enabled: !!id,
    }
  );

  const { refetch: refetchUnidades } = useQuery(
    "getUnidadesDistrito",
    () => getUnidadesDistritos(id, 0,100),
    {
      onSuccess: (response) => {
        setUnidadeData(response?.data);
      },
      enabled: !!id,
    }
  );

  const { mutate: saveDistrito, isLoading: savingDistrito } = useMutation(
    id ? editarDistrito : criarDistrito,
    {
      onSuccess: () => {
        toast.success(`Distrito ${id ? "atualizado" : "criado"} com sucesso!`);
        navigate("/configuracoes/distritos");
      },
      onError: () => {
        toast.error("Erro ao salvar o distrito. Tente novamente mais tarde.");
      },
    }
  );

  const { mutate: allocateBairros, isLoading: allocatingBairros } = useMutation(
    alocarBairros,
    {
      onSuccess: () => {
        toast.success("Bairros alocados com sucesso.");
        refetch();
      },
      onError: () => {
        toast.error("Erro ao alocar os bairros. Tente novamente mais tarde.");
      },
    }
  );

  const { mutate: allocateUnidades, isLoading: allocatingUnidades } = useMutation(
    alocarDistrito,
    {
      onSuccess: () => {
        toast.success("Unidades alocadas com sucesso.");
        refetchUnidades();
      },
      onError: () => {
        toast.error("Erro ao alocar as unidades. Tente novamente mais tarde.");
      },
    }
  );

  useQuery(
    "getUnidades", 
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        setUnidadesOptions(data);
      },
    }
  );

  const deleteBairroFunction = async (bairro) => {
    try {
      await deleteBairroDistrito(id, bairro);
      toast.success("Bairro removido do distrito com sucesso.");
      refetch();
    } catch {
      toast.error("Erro ao remover o bairro do distrito.");
    }
  };

  const deleteUnidadeFunction = async (unidade) => {
    try {
      await deleteDistritoUnidade(id, unidade);
      toast.success("Unidade removida do distrito com sucesso.");
      refetchUnidades();
    } catch {
      toast.error("Erro ao remover a unidade do distrito.");
    }
  };

  useEffect(() => {
    if (state && state.distrito) {
      const { descricao, cidade, estado } = state.distrito;
      setValue("descricao", descricao);
      setValue("cidade", cidade);
      setValue("estado", estado);
    }
  }, [state, setValue]);

  const onSubmitDistrito = (data) => {
    const payload = upperCaseSimple(removeEmpty(data));
    if (id) {
      saveDistrito({ id, ...payload });
    } else {
      saveDistrito(payload);
    }
  };

  const onSubmitBairros = () => {
    if (selectedBairros.length > 0) {
      allocateBairros({ distritoId: parseInt(id), bairros: selectedBairros });
    } else {
      toast.error("Selecione pelo menos um bairro antes de alocar.");
    }
  };

  const onSubmitUnidades = () => {
    if (selectedUnidades.length > 0) {
      allocateUnidades({ distritoId: parseInt(id), unidades: selectedUnidades });
    } else {
      toast.error("Selecione pelo menos uma unidade antes de alocar.");
    }
  };

  const bairroColumns = useMemo(
    () => [
      { Header: "Bairro", accessor: (row) => row, id: "bairro" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              if (window.confirm("Tem certeza que deseja excluir esse bairro?")) {
                deleteBairroFunction(row.original);
              }
            }}
          >
            Excluir
          </Button>
        ),
      },
    ],
    []
  );

  const unidadeColumns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Unidade", accessor: "nome" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              if (window.confirm("Tem certeza que deseja excluir essa unidade?")) {
                deleteUnidadeFunction(row.original.id);
              }
            }}
          >
            Excluir
          </Button>
        ),
      },
    ],
    []
  );

  const availableBairros = bairroOptions.filter((bairro) => !data.includes(bairro));

const availableUnidades = unidadesOptions.filter(
  (unidade) => !unidadeData.some((allocated) => allocated.id === unidade.id)
);

  return (
    <BaseConfiguracoes>
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Gerenciar Distrito
      </Typography>

      {/* District Form */}
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Informações do Distrito
          </Typography>
          <form onSubmit={handleSubmit(onSubmitDistrito)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Descrição"
                  {...register("descricao", { required: "Campo obrigatório" })}
                  error={!!errors.descricao}
                  helperText={errors.descricao?.message}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Cidade"
                  {...register("cidade")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Estado"
                  {...register("estado")}
                />
              </Grid>
            </Grid>
            <CardActions>
              <Button type="submit" variant="contained" color="primary">
                {savingDistrito ? "Salvando..." : id ? "Atualizar" : "Cadastrar"}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>

     {/* Bairro and Unidade Allocation Side by Side */}
<Grid container spacing={2} sx={{ marginTop: 2 }}>
  {/* Bairro Allocation */}
  <Grid item xs={12} md={6}>
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alocar Bairros
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Selecione Bairros"
              SelectProps={{
                multiple: true,
                value: selectedBairros,
                onChange: (e) => setSelectedBairros(e.target.value),
              }}
            >
              {availableBairros.map((bairro) => (
                <MenuItem key={bairro} value={bairro}>
                  {bairro}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSubmitBairros();
                setSelectedBairros([]);
              }}
              disabled={allocatingBairros}
            >
              {allocatingBairros ? "Alocando..." : "Alocar Bairros"}
            </Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Table columns={bairroColumns} data={data} hasPagination={false} />
        </Box>
      </CardContent>
    </Card>
  </Grid>

  {/* Unidade Allocation */}
  <Grid item xs={12} md={6}>
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alocar Unidades
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Selecione Unidades"
              SelectProps={{
                multiple: true,
                value: selectedUnidades,
                onChange: (e) => setSelectedUnidades(e.target.value),
              }}
            >
              {availableUnidades.map((unidade) => (
                <MenuItem key={unidade.id} value={unidade.id}>
                  {unidade.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={()=> {
                onSubmitUnidades();
                setSelectedUnidades([]);
              }}
              disabled={allocatingUnidades}
            >
              {allocatingUnidades ? "Alocando..." : "Alocar Unidades"}
            </Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Table
            columns={unidadeColumns}
            data={unidadeData}
            hasPagination={false}
            hiddenColluns={["id"]}
          />
        </Box>
      </CardContent>
    </Card>
  </Grid>
</Grid>

    </Box>
    </BaseConfiguracoes>
  );
};

export default DistritoPage;
