import { useEffect, useState } from 'react';
import './style.css';
import { store } from '../../../../store';
import { getUnidades } from '../../../../services/unidades';



const ImpressaoFaltasAnoLetivo = () => {
    const [data, setData] = useState();
    const [alunosUnicos, setAlunosUnicos] = useState([]);
    const currentUser = store.getState()['user']['user']

    useEffect(() => {
        var dados = JSON.parse(localStorage.getItem("faltasImpressaoAnoLetivo"));
        setData(dados);
        setTimeout(() => {
            window.print();
        }, 2000);
    }, []);

    const footer = () => {
        var date = new Date();

        return `Emitido por ${currentUser.usuarioLogin} em ${date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })} às ${date.toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit', })}`;

    }

    return (
        <div className='class-print' style={{ marginLeft: '10px', marginRight: '13px' }}>
            <div className="row remove-border-bottom">
                <div className="logo-container">
                    <img
                        className='p-3 logo-impressao'c
                        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
                        alt='Logo'
                    />
                </div>
                <div className='text-container'>
                    ESTADO DO RIO DE JANEIRO <br />
                    {process.env.REACT_APP_PREFEITURA !== 'PREFEITURA MUNICIPAL DE ARARUAMA' ? 'PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA' : process.env.REACT_APP_PREFEITURA} <br/>
                    SECRETARIA MUNICIPAL DE EDUCAÇÃO
                </div>
            </div>
            <div className='row'>
                <h5 className='text-center'>{data?.nomeEscola}</h5>
                <p className='text-center'>RELATÓRIO DE FALTAS</p>
            </div>
            <div className='row border-right border-bottom border-left bg-bege'>
                <h5 className='text-center dados-unidade mt-2'>ESCOLARIDADE: {data?.nomeEscolaridade} | TURNO: {data?.nomeTurno} | TURMA: {data?.nomeTurma} | DISCIPLINA: {data?.nomeDisciplina} | {data?.nomeTrimestre}</h5>
            </div>
            <div className='class-print' style={{ marginLeft: '10px', marginRight: '13px' }}>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Aluno</th>
                                <th><i>1º Trimestre</i></th>
                                <th><i>2º Trimestre</i></th>
                                <th><i>3º Trimestre</i></th>
                                <th><i>Professor 1</i></th>
                                <th><i>Professor 2</i></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map((item) => (
                                <tr key={item.id}>
                                    <td className='td-class'>{item.nomeAluno}</td>
                                    <td className='td-class'>{item.trimestre1}</td>
                                    <td className='td-class'>{item.trimestre2}</td>
                                    <td className='td-class'>{item.trimestre3}</td>
                                    <td className='td-class'>{item.servidor1}</td>
                                    <td className='td-class'>{item.servidor2 == undefined ? "-" : item.servidor2}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="footer-class">
                    <p>{footer()}</p>
                </div>
            </div>

        </div>
    );
};

export default ImpressaoFaltasAnoLetivo;
