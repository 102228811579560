import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput'

export default function Processo({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()


  return (
    <div
      className="tab-pane fade show active"
      id="processo"
      role="tabpanel"
      aria-labelledby="processo-tab"
    >
      <div className="card-body">
        <div className="row">
          {id && (
            <div className="col-md-1">
              <FormInput
                register={register}
                errors={errors}
                atribute="id"
                label="ID"
                readOnly={true}
                required={false}
              />
            </div>
          )}

          <div className={id ? "col-md-6" : "col-md-7"}>
            <FormInput
              register={register}
              errors={errors}
              atribute="nomeProcesso"
              label="Nome do processo"
            />
          </div>

          <div className="col-md-5">
            <FormInput
              register={register}
              errors={errors}
              atribute="numeroProcesso"
              label="Número do processo"
            />
          </div>

        </div>

        <div className="row" style={{ marginTop: '30px' }}>

          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="dataVencimento"
              label="Data Vencimento"
            />
          </div>

          <div className="col-md-6">
            <FormInput
            required={id ? false : true}
              register={register}
              errors={errors}
              type="file"
              atribute="anexo"
              label="Anexo"
            />
          </div>

        </div>
      </div>
    </div>
  )
}
