import api from "..";
import buildQueryParams from "../../utils/buildQueryParams";

async function getTurmas(id) {
  return await api.get(`turmas/${id}`).then(async (res) => {
    const { data } = res.data;
    return data;
  });
}

async function deleteTurma(id) {
  return await api.delete(`temporario/turmas/${id}`).then(async (res) => {
    const { message } = res.data;
    return message;
  });
}

async function createTurma(data) {
  return await api.post("turma", data).then(async (res) => {
    const { message } = res.data;
    return message;
  });
}

async function updateTurma({ id, data }) {
  return await api.put(`turma/${id}`, data).then(async (res) => {
    const { message } = res.data;
    return message;
  });
}

async function getTurma(id) {
  return await api.get(`temporario/turmas/${id}`).then(async (res) => {
    const { data } = res.data;
    return data;
  });
}

async function getAlunos(id) {
  return await api.get(`temporario/turmas/${id}/alunos`).then(async (res) => {
    const { data } = res.data;
    return data;
  });
}

async function getVagas(id) {
  return await api.get(`temporario/turmas/${id}/vagas`).then(async (res) => {
    const { data } = res.data;
    return data;
  });
}

async function getEducadores(id) {
  return await api
    .get(`temporario/turmas/${id}/educadores`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function postEnturmacao(values) {
  return await api.post("temporario/enturmarcao", values).then(async (res) => {
    const { message } = res.data;
    return message;
  });
}

async function getComponenteCurricular(id) {
  return await api
    .get(`temporario/turmas/${id}/componente-curricular`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getTurmasUnidadeEscolaridadeTurno(filters) {
  const queryString = buildQueryParams(filters);
  let config = {};

  if (filters.exportar === 1) {
    config = {
      responseType: "arraybuffer",
      headers: { "Content-Type": "blob" },
    };
  }

  return await api.get(`turma${queryString}`, config).then(async (res) => {
    const { data } = res;
    return data;
  });
}

export {
  getTurmas,
  deleteTurma,
  createTurma,
  updateTurma,
  getTurma,
  getAlunos,
  postEnturmacao,
  getVagas,
  getEducadores,
  getComponenteCurricular,
  getTurmasUnidadeEscolaridadeTurno,
};
