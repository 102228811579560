import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RedefinirSenha from '../../pages/redefinir-senha';


const RedefinirSenhaRoutes = () => (
    <React.Fragment>
        <Route path="/redefinir-senha/" element={<RedefinirSenha />} /> 
        <Route path="/redefinir-senha/:id" element={<RedefinirSenha />} /> 
    </React.Fragment>
);

export default RedefinirSenhaRoutes;
