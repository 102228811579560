import Breadcrumb from '../../../../../../components/breadcrumb'
import BasePreMatricula from '../../../basePreMatricula';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import { getEscolaridades } from '../../../../../../services/escolaridades';
import { getInscricoesRelatorioQuantitativos } from '../../../../../../services/processos/preMatricula';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';
import { toast } from 'react-toastify';
import FormInput from '../../../../../../components/formInput';
import { useForm } from 'react-hook-form';


const RelatorioMatriculadosPorPeriodoQuantitativo = () => {

  const [escolaridade, setEscolaridade] = useState(0)
  const [skip, setSkip] = useState(0);
  const [status, setStatus] = useState(3);
  const [exportar, setExportar] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0)
  const nomeArquivo = 'relatotio-pre-matricula-2025-matriculados-por-periodo-nominal.xlsx'


  const titulo = total === 0 ? 'POR PERÍODO - QUANTITATIVO' : 'POR PERÍODO - QUANTITATIVO - ' + total + ' INCRIÇÕES'



  const { register, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      dataInicial: "",
      dataFinal: ""
    }
  })

  function abrirInscricao(cpf){
    localStorage.setItem('CPF', cpf)
    window.open('https://app.educacaoararuama.rj.gov.br/pre-matricula/formulario/visualizacao/' + cpf, '_blank')

  }

async function fetchInscricoes(){

    const dataInicial = watch('dataInicial')
    const dataFinal = watch('dataFinal')

    if (dataInicial !== '' && dataFinal !== ''){

    if(dataInicial > dataFinal){
        return toast.warning('Data inicial não pode ser maior que a data final.')
    }

    if(dataFinal < dataInicial){
        return toast.warning('Data final não pode ser menor que a data inicial.')
    }
}

    if (dataInicial != '' && dataFinal  != ''){
        var response = await getInscricoesRelatorioQuantitativos(dataInicial, dataFinal, skip, limit, exportar, status)
        

        setTotal(response?.data.totalInscricoes)
        setData(response   || []) 
    }
    
}

const exportarDados = async () => {

    const dataInicial = watch('dataInicial')
    const dataFinal = watch('dataFinal')

    if (dataInicial !== '' && dataFinal !== ''){

    if(dataInicial > dataFinal){
        return toast.warning('Data inicial não pode ser maior que a data final.')
    }

    if(dataFinal < dataInicial){
        return toast.warning('Data final não pode ser menor que a data inicial.')
    }
}
    
  if(data.length === 0 || data?.total === 0){
    return toast.warning('Não há dados para exportar.')
  }

  try {

  const relatorioData = await getInscricoesRelatorioQuantitativos(dataInicial, dataFinal, skip, 10000, 1, status)

  if (relatorioData) {
    return handleDownloadExcel(relatorioData, nomeArquivo);
  } else {
      return toast.error('Falha ao obter o relatório');
      // Tratar falha na obtenção do relatório
  }
  } catch (error) {
    return toast.error('Erro ao buscar relatório');
    // Tratar erro na chamada da função
  }
 
  // return await handleDownloadExcel(relatorioData, titulo);
}

useEffect(() => {
  fetchInscricoes()
}, [watch('dataInicial'), watch('dataFinal'), limit, skip])


  const columns = useMemo(
      () => [
      { Header: 'Unidade', accessor: 'unidadeDescricao' },
      { Header: 'Escolaridade', accessor: 'escolaridadeDescricao' },
      { Header: 'Turno', accessor: 'turnoDescricao' },
      { Header: 'Quantidade', accessor: 'quantidade' },
      ],
      []
  )
    


return (
    <BasePreMatricula>
      <Breadcrumb
        title={titulo}
        itens={['PRÉ-MATRÍCULA 2025', 'RELATÓRIOS', 'MATRICULADOS', 'POR PERÍODO - QUANTITATIVO']}
        hasExcelButton 
        onClickDownload={() => exportarDados()} 
      />

{/* <Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert> */}
<div className="row" style={{ marginBottom: 20 }}>
<div className="col-md-6">
                    <FormInput
                    type='date'
                    atribute="dataInicial"
                    label="Data Inicial"
                    // defaultValue={dataInicial}
                    // onChange={handleDataInicialChange}
                    register={register}
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                    type='date'
                    atribute="dataFinal"
                    label="Data Final"
                    // defaultValue={dataInicial}
                    // onChange={handleDataFinalChange}
                    register={register}
                    />
                   
                </div>
        </div>

        <div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data?.data?.inscricoes ? data?.data?.inscricoes : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>

      </BasePreMatricula>

      
)
}

export default RelatorioMatriculadosPorPeriodoQuantitativo