import api from '../index'

const token = localStorage.getItem('access_token');

async function getUnidades() {
  return await api
    .get('unidade', {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getUnidadesById(id) {
  return await api
    .get(`unidade/servidor/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function deleteUnidade(id) {
  return await api
    .delete(`unidade/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createUnidade(data) {
  return await api
    .post('unidade', data)
    .then(async (res) => {
      return res.data;
    });
}

async function updateUnidade(data) {
  return await api
    .put(`unidade/${data.id}`, data)
    .then(async (res) => {
      return res.data;
    });
}

async function getUnidade(id) {
  return await api
    .get(`unidade/${id}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getUnidadeUsuarioLogado(usuarioId) {
  return await api
    .get(`unidade/usuarioLogado/${usuarioId}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    }
    )
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getUnidadesEscolaridade2024(escolaridade) {
  return await api
    .get(`unidade/2024/escolaridade/${escolaridade}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    }
    )
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getUnidadesEscolaridade2025(escolaridade) {
  return await api
    .get(`unidade/2025/escolaridade/${escolaridade}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    }
    )
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getUnidadesFiltro(descricao = '') {
  return await api
    .get(`unidade?descricao=${descricao}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getUnidadesFiltroExportar(descricao = '', exportar = 1) {

  let config = {};

  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }
  
    return await api
    .get(`unidade?descricao=${descricao}&exportar=${exportar}`, config)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

async function getDistritos(skip, limit) {
  return await api
    .get(`unidade/distrito?skip=${skip}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function criarDistrito(data) {
  return await api
    .post('unidade/distrito', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function editarDistrito(data) {
  return await api
    .put(`unidade/distrito/${data.id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function alocarDistrito(data) {
  return await api
    .post('unidade/distrito/alocacao', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getUnidadesDistritos(distritoId, skip, limit) {
  return await api
    .get(`unidade/distrito/alocacao?distritoId=${distritoId}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function deleteDistritoUnidade(distritoId, unidadeId) {
  return await api
    .delete(`unidade/distrito/${distritoId}/unidade/${unidadeId}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getBairrosDistrito(id) {
  return await api
    .get(`unidade/distrito/${id}/bairro`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function alocarBairros(data) {
  return await api
    .post('unidade/distrito/bairro/alocacao', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function deleteBairroDistrito(distritoId, bairroId) {
  return await api
    .delete(`unidade/distrito/${distritoId}/bairro/${bairroId}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getUnidadesEscolaridades(unidadeId) {
  return await api
    .get(`unidade/${unidadeId}/escolaridade`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function adicionarUnidadeEscolaridade(body) {
  return await api
    .post('unidade/escolaridade', body)
    .then(async (res) => {
      return res.data;
    });
}

async function deletarUnidadeEscolaridade(unidadeId, escolaridadeId) {
  return await api
    .delete(`unidade/${unidadeId}/escolaridade/${escolaridadeId}`)
    .then(async (res) => {
      return res.data;
    });
}




export {
  getUnidades,
  getUnidadesById,
  deleteUnidade,
  createUnidade,
  updateUnidade,
  getUnidade,
  getUnidadeUsuarioLogado,
  getUnidadesEscolaridade2024,
  getUnidadesFiltro,
  getDistritos,
  criarDistrito,
  editarDistrito,
  alocarDistrito,
  getUnidadesDistritos,
  deleteDistritoUnidade,
  getUnidadesFiltroExportar,
  getUnidadesEscolaridade2025,
  getBairrosDistrito,
  alocarBairros,
  deleteBairroDistrito,
  getUnidadesEscolaridades,
  adicionarUnidadeEscolaridade,
  deletarUnidadeEscolaridade
}