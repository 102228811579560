import api from "../..";
import buildQueryParams from "../../../utils/buildQueryParams";

async function postMovimentacao(data) {
  return await api
    .post("patrimonio/controleestoque", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getMovimentacoes() {
  return await api
    .get(`patrimonio/controleestoque/skip=${0}&limit=${20}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getProdutoByNome(nome) {
  return await api.get(`/administrativo/produtos`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function getAllProdutosEstoque(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(`administrativo/estoque${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getRelatorioEstoque(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(
      `administrativo/estoque/relatorio${queryString}`,
      { responseType: "arraybuffer" },
      { headers: { "Content-Type": "blob" } }
    )
    .then((response) => {
      let fileName = `relatorio-estoque.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: "application/pdf",
            encoding: "UTF-8",
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            encoding: "UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
}

async function getProdutoEstoqueById(id) {
  return await api.get(`administrativo/estoque/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function postProdutoEstoque(data) {
  return await api.post("administrativo/estoque", data).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function putProdutoEstoque(data) {
  return await api.put(`/administrativo/estoque`, data).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function deleteProdutoEstoqueById(id) {
  return await api.delete(`administrativo/estoque/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function getAllProdutos(filters) {
  const queryString = buildQueryParams(filters);
  return await api
    .get(`administrativo/produtos${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getProdutoById(id) {
  return await api.get(`administrativo/produtos/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function postProduto(data) {
  return await api.post(`administrativo/produtos`, data).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function putProduto(data) {
  return await api.put(`administrativo/produtos`, data).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function deleteProduto(id) {
  return await api.delete(`administrativo/produtos/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function getRelatorioProdutos(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(
      `administrativo/produtos/relatorio${queryString}`,
      { responseType: "arraybuffer" },
      { headers: { "Content-Type": "blob" } }
    )
    .then((response) => {
      let fileName = `relatorio-produtos.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: "application/pdf",
            encoding: "UTF-8",
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            encoding: "UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
}

async function getHistoricoEstoqueById(id) {
  return await api
    .get(`administrativo/estoque/historico/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getHistoricoProdutoById(id) {
  return await api
    .get(`administrativo/produtos/historico/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function postBaixaEstoque(data) {
  return await api
    .post("administrativo/estoque/baixas", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getAllBaixas(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(`administrativo/estoque/baixas${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function postRetornoBaixa(data) {
  return await api
    .post("administrativo/estoque/baixas/restaurar", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getAllSolicitacoes(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(`administrativo/estoque/solicitacoes${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getSolicitacaoById(id) {
  return await api
    .get(`administrativo/estoque/solicitacoes/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function postSolicitacao(data) {
  return await api
    .post("administrativo/estoque/solicitacoes", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function putSolicitacao(data) {
  return await api
    .put("administrativo/estoque/solicitacoes", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getRelatorioSolicitacoes(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(
      `administrativo/estoque/solicitacoes/relatorio${queryString}`,
      { responseType: "arraybuffer" },
      { headers: { "Content-Type": "blob" } }
    )
    .then((response) => {
      let fileName = `relatorio-solicitacoes.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: "application/pdf",
            encoding: "UTF-8",
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            encoding: "UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
}

async function getRegistros(filters) {
  const queryString = buildQueryParams(filters);
  return await api
    .get(`/administrativo/estoque/listar-registros${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getAllTransferencias(filters) {
  const queryString = buildQueryParams(filters);
  return await api
    .get(`/administrativo/estoque/transferencias${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getTransferenciaById(id, tipo) {
  return await api
    .get(`administrativo/estoque/transferencias/${id}?tipo=${tipo}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function putTransferencia(data) {
  return await api
    .put("administrativo/estoque/transferencias", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function postTransferencia(data) {
  return await api
    .post("administrativo/estoque/transferencias", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getRelatorioTransferencias(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(
      `administrativo/estoque/transferencias/relatorio${queryString}`,
      { responseType: "arraybuffer" },
      { headers: { "Content-Type": "blob" } }
    )
    .then((response) => {
      let fileName = `relatorio-transferencias.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: "application/pdf",
            encoding: "UTF-8",
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            encoding: "UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
}

export {
  postMovimentacao,
  getMovimentacoes,
  getAllProdutosEstoque,
  postProdutoEstoque,
  getProdutoEstoqueById,
  deleteProdutoEstoqueById,
  getProdutoByNome,
  getRelatorioEstoque,
  getAllProdutos,
  postProduto,
  getProdutoById,
  putProduto,
  deleteProduto,
  getRelatorioProdutos,
  putProdutoEstoque,
  getHistoricoEstoqueById,
  getHistoricoProdutoById,
  postBaixaEstoque,
  getAllBaixas,
  postRetornoBaixa,
  postSolicitacao,
  getAllSolicitacoes,
  getSolicitacaoById,
  putSolicitacao,
  getRegistros,
  getAllTransferencias,
  postTransferencia,
  getRelatorioSolicitacoes,
  getRelatorioTransferencias,
  getTransferenciaById,
  putTransferencia,
};
