import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import FormInput from '../../../../../../../components/formInput'
import { getProduto, postProduto, putProduto, getGrupoProdutos, getUnidadeMedida } from '../../../../../../../services/administrativo/patrimonio-produto'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import upperCaseSimple from '../../../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../../../utils/removeEmpty'
import urlsAdministrativo from '../../../../../urlsAdministrativo'

export default function Produtos() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation();
  const [data, setData] = useState(null)
  const [grupos, setGrupos] = useState([])

  const { data: produtoData } = useQuery('getProduto', () => id && getProduto(id), {
    enabled: id ? true : false,
    onSuccess: data => {
      setData(data);
    }
  })

  const { data: gruposData, isFetched: gruposFetched } = useQuery('getGrupoProdutos', () => getGrupoProdutos(), {
    enabled: true,
  })

  const { data: unidadeMedidaData, isFetched: unidadeMedidaFetched } = useQuery('getUnidadeMedida', () => getUnidadeMedida(), {
    enabled: true,
  })

  const { mutate, isLoading } = useMutation(id ? putProduto : postProduto, {
    onSuccess: data => {
      if (id) {
        toast.success('Produto atualizado com sucesso.')
        navigate(urlsAdministrativo.patrimonioControleProdutos)
      } else {
        toast.success('Produto criado com sucesso.')
        navigate(urlsAdministrativo.patrimonioControleProdutos)
      }
    },
    onError: data => {
      if (id) {
        toast.error('Erro ao atualizar produto. Tente novamente mais tarde.')
      } else {
        toast.error('Erro ao criar produto. Tente novamente mais tarde.')
      }
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      nomeProduto: '',
      categoriaProduto: 0,
      descricao: '',
      fabricante: '',
      // numeroSerie: '',
      valor: 0,
      quantidade: 0
    }
  })

  useEffect(() => {
    if (state && gruposFetched && id && unidadeMedidaFetched) {
      setValue('id', id)
      setValue('nomeProduto', state?.data?.nome);
      setValue('categoriaProduto', parseInt(state?.data?.categoria));
      setValue('descricao', state?.data?.descricao);
      setValue('fabricante', state?.data?.fabricante);
      // setValue('numeroSerie', state?.data?.numeroSerie);
      setValue('valor', state?.data?.valor);
      setValue('quantidade', state?.data?.quantidade);
      setValue('codigo', state?.data?.codigo);
      setValue('dataValidade', state?.data?.dataValidade.split('T')[0]);
      setValue('unidadeMedida', parseInt(state?.data?.unidadeMedida));
    }
  }, [state, gruposData, gruposFetched, unidadeMedidaData, unidadeMedidaFetched])

  const onSubmit = (data) => {

    const request = {
      nomeProduto: data.nomeProduto,
      categoriaProduto: parseInt(data.categoriaProduto),
      descricao: data.descricao,
      fabricante: data.fabricante,
      // numeroSerie: data.numeroSerie,
      valor: parseInt(data.valor),
      quantidade: parseInt(data.quantidade),
      codigo: data.codigo,
      dataValidade: data.dataValidade,
      unidadeMedida: parseInt(data.unidadeMedida),
    }

    if (id) {
      mutate({ id: id, data: upperCaseSimple(removeEmpty(request)) })
    } else {
      mutate(upperCaseSimple(removeEmpty(request)))
    }
  }

  return (
    <div
      className="tab-pane fade show active"
      id="produtos"
      role="tabpanel"
      aria-labelledby="produtos-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            {id && (
              <div className="col-md-1">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="id"
                  label="ID"
                  readOnly={true}
                  required={false}
                />
              </div>
            )}

            <div className={id ? "col-md-8" : "col-md-9"}>
              <FormInput
                register={register}
                errors={errors}
                atribute="nomeProduto"
                label="Nome do Produto"
              />
            </div>

            <div className="col-md-3">
              <label htmlFor="categoriaProduto">Categoria do Produto <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="categoriaProduto" {...register("categoriaProduto", { required: true })}>
                <option>SELECIONE</option>
                {gruposData?.map(grupo => (
                  <option key={grupo.id} value={grupo.id}>
                    {grupo.descricao}
                  </option>
                ))}
              </select>
              {errors.categoriaProduto?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>

            <div className="col-md-12">
              <FormInput
                register={register}
                errors={errors}
                atribute="descricao"
                label="Descrição"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>

            <div className="col-md-5">
              <FormInput
                register={register}
                errors={errors}
                atribute="fabricante"
                label="Fabricante"
              />
            </div>
            {/* <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                atribute="numeroSerie"
                label="Número de série"
              />
            </div> */}
            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="valor"
                label="Valor"
              />
            </div>
            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                type='number'
                atribute="quantidade"
                label="Quantidade"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="codigo"
                label="Código"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                type='date'
                atribute="dataValidade"
                label="Data de validade"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="unidadeMedida">Unidade de medida <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="unidadeMedida" {...register("unidadeMedida", { required: true })}>
                <option>SELECIONE</option>
                {unidadeMedidaData?.map(unidadeMedida => (
                  <option key={unidadeMedida.id} value={unidadeMedida.id}>
                    {unidadeMedida.descricao} - {unidadeMedida.sigla}
                  </option>
                ))}
              </select>
              {errors.unidadeMedida?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>
          </div>

        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate(-1)}
            style={{ marginRight: '8px' }}
          >
            Voltar
          </button>

          <button
            type="submit"
            className="btn btn-success"
            disabled={!isValid}
          >
            {id ? 'Atualizar' : 'Cadastrar'}
          </button>

        </div>
      </form>
    </div>
  )
}
