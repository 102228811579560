import { useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery, useMutation } from "react-query"
import { toast } from 'react-toastify';
import { getContas, deleteConta } from '../../../../services/administrativo/financeiro-contas';
import { listaAnexo } from "../../../../services/processos/remanejamento";

const dataTable = [ //mock de contas
  {
    id: 1,
    nomeFornecedor: 'Nome Servidor',
    servico: 'Serviço',
    valor: 'R$ 1000',
    dataPagamento: '19-05-2023',
    anexo: 'Anexo'
  },
  {
    id: 2,
    nome: 'Nome Servidor',
    servico: 'Serviço',
    valor: 'R$ 1000',
    dataPagamento: '19-05-2023',
    anexo: 'Anexo'
  },
  {
    id: 3,
    nome: 'Nome Servidor',
    servico: 'Serviço',
    valor: 'R$ 1000',
    dataPagamento: '19-05-2023',
    anexo: 'Anexo'
  },
  {
    id: 4,
    nome: 'Nome Servidor',
    servico: 'Serviço',
    valor: 'R$ 1000',
    dataPagamento: '19-05-2023',
    anexo: 'Anexo'
  },
  {
    id: 5,
    nome: 'Nome Servidor',
    servico: 'Serviço',
    valor: 'R$ 1000',
    dataPagamento: '19-05-2023',
    anexo: 'Anexo'
  },
]

const handleDownload = async (arquivoId) => {
  try {
    // Recebe o arquivo da função listaAnexo (agora como Blob)
    const arquivoBlob = await listaAnexo(arquivoId);

    // Gera uma URL para o Blob
    const blobUrl = URL.createObjectURL(arquivoBlob);

    const isOpen = window.open(blobUrl, "_blank");
    if (!isOpen) {
      alert("Permita pop-ups para visualizar/baixar o arquivo.");
    }

    window.open(blobUrl, "_blank");

    // Revogar a URL após abrir o arquivo para liberar memória
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
  }
};

const ContasTable = () => {
  const navigate = useNavigate();

  const [newData, setNewData] = useState([])

  const { refetch: refetchContas } = useQuery('getContas', getContas, {
    enabled: true,
    retry: false,
    onSuccess: (data) => {
      setNewData(data.data)
    }
  })

  const { mutate: mutateDelete } = useMutation(deleteConta, {
    onSuccess: () => {
      toast.success("Conta excluída com sucesso.")
      refetchContas();
    },
    onError: () => {
      toast.error("Alguma coisa deu errada. Tente novamente mais tarde.")
    }
  })

  let BRLFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Fornecedor', accessor: 'nomeFornecedor' },
      { Header: 'Tipo de Serviço', accessor: 'tipoServico' },
      {
        Header: 'Valor', accessor: 'valor',
        Cell: ({ row }) => (
          formatValue(row.original.valor)
        )
      },
      {
        Header: 'Data Pagamento', accessor: 'dataPagamento',
        Cell: ({ row }) => (
          row.original.dataPagamento.split("T")[0].split("-").reverse().join("/")
        )
      },
      {
        Header: 'Anexo', accessor: 'anexoId',
        Cell: ({ row }) => (
          <button
            className='btn btn-sm'
            style={{ padding: '6px' }}
            onClick={() => {
              handleDownload(row.original.anexoId)
            }}
          >
            Baixar
          </button>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.financeiroContasAPagarCadastrarId + row.original.id, {
              state: {
                id: row.original.id,
                fornecedor: row.original.nomeFornecedor,
                servico: row.original.tipoServico,
                valor: row.original.valor,
                dataPagamento: row.original.dataPagamento.split('T')[0],
                anexo: row.original.attachment
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  mutateDelete(row.original.id)
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Contas a Pagar'} itens={['Administrativo', 'Financeiro', 'Contas a pagar']} actions={{ link: urlsAdministrativo.financeiroContasAPagarCadastrar, text: "Nova Conta" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={newData} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ContasTable;
