import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Autocomplete,
  Box,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import NProgress from "nprogress"; // Import NProgress
import BaseDocente from "../docente/baseDocente";
import BaseGestao from "../gestao/baseGestao";
import Breadcrumb from "../../components/breadcrumb";
import { iaProvaGet, iaProvaReponse } from "../../services/iaService";
import { getEscolaridades } from "../../services/escolaridades";
import { getDisciplinas } from "../../services/disciplinas";

const MAX_QUESTIONS = 10;

const CreateProvas = (gestao) => {
  const [formData, setFormData] = useState({
    escolaridade: "",
    disciplina: "",
    conteudo: "",
    quantidadePerguntasMultiplaEscolha: "",
    quantidadePerguntasDissertativas: "",
    servidorId: 1,
  });

  const [provas, setProvas] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const navigate = useNavigate();

  const escolaridades = [
    "1º ANO",
    "2º ANO",
    "3º ANO",
    "4º ANO",
    "5º ANO",
    "6º ANO",
    "7º ANO",
    "8º ANO",
    "9º ANO",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const disciplinaData = await getDisciplinas();
        setDisciplinas(disciplinaData || []);
        await fetchProvas();
      } catch (error) {
        toast.error("Erro ao carregar dados iniciais!");
      }
    };
    fetchData();
  }, []);

  const fetchProvas = async () => {
    try {
      const response = await iaProvaGet();
      if (response.statusCode === 200 && response.data) {
        setProvas(response.data);
      } else {
        toast.error("Erro ao buscar provas!");
      }
    } catch (error) {
      toast.error("Erro ao buscar provas!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const upperCaseValue = value.toUpperCase();

    if (
      (name === "quantidadePerguntasMultiplaEscolha" ||
        name === "quantidadePerguntasDissertativas") &&
      parseInt(upperCaseValue) +
        parseInt(
          formData[
            name === "quantidadePerguntasMultiplaEscolha"
              ? "quantidadePerguntasDissertativas"
              : "quantidadePerguntasMultiplaEscolha"
          ] || 0
        ) >
        MAX_QUESTIONS
    ) {
      toast.error(`O total de questões não pode exceder ${MAX_QUESTIONS}.`);
      return;
    }

    setFormData((prev) => ({ ...prev, [name]: upperCaseValue }));
  };

  const handleCreateProva = async () => {
    if (
      !formData.escolaridade ||
      !formData.disciplina ||
      !formData.conteudo ||
      !formData.quantidadePerguntasMultiplaEscolha ||
      !formData.quantidadePerguntasDissertativas
    ) {
      toast.error("Por favor, preencha todos os campos!");
      return;
    }

    // Start progress bar
    NProgress.start();

    try {
      const response = await iaProvaReponse(formData);
      if (response.statusCode === 200 || response.statusCode === 201) {
        toast.success("Prova criada com sucesso!");
        setFormData({
          escolaridade: "",
          disciplina: "",
          conteudo: "",
          quantidadePerguntasMultiplaEscolha: "",
          quantidadePerguntasDissertativas: "",
        });
        await fetchProvas();
      } else {
        toast.error("Erro ao criar prova!");
      }
    } catch (error) {
      toast.error("Erro ao criar prova!");
    } finally {
      // Stop progress bar
      NProgress.done();
    }
  };

  const BaseComponent = gestao ? BaseGestao : BaseDocente;
  const title = gestao ? "Gestão" : "Docente";

  return (
    <BaseComponent>
      <Breadcrumb title={"Provas"} itens={[title, "Provas", "Lista Geral"]} />
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Typography variant="h5" gutterBottom>
          Criar Provas
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Autocomplete
            options={escolaridades}
            renderInput={(params) => (
              <TextField {...params} label="Escolaridade" margin="normal" />
            )}
            onChange={(e, value) =>
              setFormData((prev) => ({
                ...prev,
                escolaridade: value || "",
              }))
            }
          />
          <Autocomplete
            options={disciplinas}
            getOptionLabel={(option) => option.descricao || ""}
            renderInput={(params) => (
              <TextField {...params} label="Disciplina" margin="normal" />
            )}
            onChange={(e, value) =>
              setFormData((prev) => ({
                ...prev,
                disciplina: value ? value.descricao : "",
              }))
            }
          />
          <TextField
            fullWidth
            label="Conteúdo"
            name="conteudo"
            value={formData.conteudo}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Perguntas Múltipla Escolha"
            name="quantidadePerguntasMultiplaEscolha"
            type="number"
            value={formData.quantidadePerguntasMultiplaEscolha}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Perguntas Dissertativas"
            name="quantidadePerguntasDissertativas"
            type="number"
            value={formData.quantidadePerguntasDissertativas}
            onChange={handleChange}
          />
          <Button variant="contained" color="primary" onClick={handleCreateProva}>
            Criar Prova
          </Button>
        </Box>

        {/* Table of Created Provas */}
        <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Escolaridade</TableCell>
                <TableCell>Disciplina</TableCell>
                <TableCell>Conteúdo</TableCell>
                <TableCell>Múltipla Escolha</TableCell>
                <TableCell>Dissertativas</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {provas.map((prova, index) => (
                <TableRow key={index}>
                  <TableCell>{prova.escolaridade}</TableCell>
                  <TableCell>{prova.disciplina}</TableCell>
                  <TableCell>{prova.conteudo}</TableCell>
                  <TableCell>{prova.perguntasMultiplaEscolha.length}</TableCell>
                  <TableCell>{prova.perguntasDissertativas.length}</TableCell>
                  <TableCell>
                    <Tooltip title="Visualizar Prova">
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityIcon />}
                        onClick={() =>
                          navigate("/docente/provas/visualizacao", { state: { prova } })
                        }
                      >
                        Visualizar
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </BaseComponent>
  );
};

export default CreateProvas;
