import React, { useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import FormInput from '../../../../../../../components/formInput'
import { getUnidades } from '../../../../../../../services/unidades'

export default function Categoria({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()
  const { state } = useLocation();

  
  const { data: unidades } = useQuery('unidades', () => getUnidades(), {
    retry: true
  })


  // const getSelectedUnidade = (item) => {
  //     console.log(state.unidadeId)
  //     return item.id === state.unidades
  //   }

  useEffect(() => {
    if (id) {
      console.log(state)
      setValue('id', id)
      setValue('marca', state?.marca)
      setValue('modelo', state?.modelo)
      setValue('numeroSerie', state?.numeroSerie)
      setValue('unidadeId', state?.unidadeId)
    }
  }, [state, setValue])


  return (
    <div
      className="tab-pane fade show active"
      id="categoria"
      role="tabpanel"
      aria-labelledby="categoria-tab"
    >
      <div className="card-body">
        <div className="row">
          {id && (
            <div className="col-md-1">
              <FormInput
                register={register}
                errors={errors}
                atribute="id"
                label="ID"
                readOnly={true}
              />
            </div>
          )}

          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              atribute="marca"
              label="Marca"
            />
          </div>

          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              atribute="modelo"
              label="Modelo"
            />
          </div>

          <div className={id ? "col-md-3" : "col-md-4"}>
            <FormInput
              register={register}
              errors={errors}
              atribute="numeroSerie"
              label="Número de Série"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-12">
              <label htmlFor="unidades" >
                Unidade
              </label>
              <select
                className="form-select"
                id="unidadeId"
                {...register('unidadeId', { required: true })}
              >
                <option value={''}>SELECIONE</option>
                {unidades?.map(unidade => (
                  <option key={unidade.id} value={unidade.id} 
                  // selected={getSelectedUnidade(unidade)}
                  >
                    {unidade.nome}
                  </option>
                ))}
              </select>
              {errors.unidadeLotacao && (
                <div role="alert" className="invalid-feedback d-block">
                  Campo obrigatório!
                </div>
              )}

            </div>
          </div>
      </div>
    </div>
  )
}
