import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import upperCaseSimple from "../../../../../../../../utils/upperCaseSimple";
import removeEmpty from "../../../../../../../../utils/removeEmpty";
import {
  getProdutoById,
  getProdutoByNome,
  getProdutoEstoqueById,
  postProdutoEstoque,
  putProdutoEstoque,
} from "../../../../../../../../services/administrativo/patrimonio-estoque";
import { getGrupoProdutos } from "../../../../../../../../services/administrativo/patrimonio";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Button,
  Avatar,
} from "@mui/material";
import { store } from "../../../../../../../../store";
import { CurrencyInput } from "react-currency-mask";
import { conservacao, tipoProduto } from "../../../../data";
import { getUnidades } from "../../../../../../../../services/unidades";

export default function Produtos({ onClose, hasBackButton = true, onRefetch, canCreate }) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState();

  const currentUser = store.getState()["user"]["user"];
  const unidadeId = data?.unidadeId;
  const isEdit = location?.state?.isEdit;

  const { data: produtoData } = useQuery(
    "getProdutoEstoqueById",
    () => id && getProdutoEstoqueById(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        setData(data?.data);
      },
    }
  );

  const produtoId = data?.produtoId;

  const { data: produtosData } = useQuery(
    "getProdutoByNome",
    () => getProdutoByNome(),
    {
      enabled: true,
      onSuccess: (data) => {
        setProdutos(data?.data);
      },
    }
  );

  const { data: produtoById } = useQuery(
    ["getProdutoById", produtoId],
    () => produtoId && getProdutoById(produtoId),
    {
      enabled: !!produtoId,
      onSuccess: (data) => {
        setProduto(data?.data);
      },
    }
  );

  const { data: groupData } = useQuery("getGrupoProdutos", getGrupoProdutos, {
    retry: false,
    enabled: true,
  });

  const { data: unidadesData } = useQuery("getUnidades", getUnidades, {
    enabled: true,
  });
  const unidadeNome = unidadesData?.find((x) => x.id == unidadeId)?.nome;

  const { mutate } = useMutation(id ? putProdutoEstoque : postProdutoEstoque, {
    onSuccess: (data) => {
      if (id) {
        toast.success("Produto atualizado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/estoque/");
      } else {
        toast.success("Produto criado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/estoque/");
      }
    },
    onError: (data) => {
      if (id) {
        toast.error("Erro ao atualizar produto. Tente novamente mais tarde.");
      } else {
        toast.error("Erro ao criar produto. Tente novamente mais tarde.");
      }
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      id: null,
      numeroNotaFiscal: null,
      chaveDanfe: null,
      dataCompra: null,
      valorDaNota: null,
      produtoId: null,
      quantidade: null,
      valor: null,
      numeroPatrimonio: null,
      numeroDeSerie: null,
      conservacao: null,
      avarias: null,
      dataValidade: null,
      observacao: "",
      criadoPor: currentUser?.usuarioLogin || "",
    },
  });

  useEffect(() => {
    if (id && data && produto) {
      const formattedDate = data?.dataCompra
        ? new Date(data?.dataCompra).toISOString().split("T")[0]
        : null;

      const formattedDateValidade = data?.dataValidade
        ? new Date(data?.dataValidade).toISOString().split("T")[0]
        : null;

      setValue("unidadeId", data?.unidadeId);
      setValue("id", data?.id);
      setValue("produtoId", produto?.id);
      setValue("marca", produto?.marca);
      setValue("produtoCategoriaId", produto?.produtoCategoriaId);
      setValue("tipoProduto", produto?.tipoProduto);
      setValue("numeroNotaFiscal", data?.numeroNotaFiscal);
      setValue("chaveDanfe", data?.chaveDanfe);
      setValue("dataCompra", formattedDate);
      setValue("valorDaNota", data?.valorDaNota);
      setValue("quantidade", data?.quantidade);
      setValue("valor", data?.valor);
      setValue("numeroPatrimonio", data?.numeroPatrimonio);
      setValue("numeroDeSerie", data?.numeroDeSerie);
      setValue("conservacao", data?.conservacao);
      setValue("avarias", data?.avarias);
      setValue("dataValidade", formattedDateValidade);
      setValue("observacao", data?.observacao);
      setValue("criadoPor", data?.criadoPor);
    }
  }, [id, data, setValue, produtos, produto]);

  const onSubmit = (data) => {
    if (!data.produtoId) {
      toast.error("Obrigatório selecionar um Produto.");
      return;
    }

    if (!data.quantidade) {
      toast.error("A Quantidade é obrigatória.");
      return;
    }

    if (!data.valor) {
      toast.error("O Valor do Produto é obrigatório.");
      return;
    }

    if (data.tipoProduto == 1) {
      if (!data?.numeroPatrimonio) {
        toast.error("O Número de Patrimônio é obrigatório.");
        return;
      }

      if (!data.conservacao) {
        toast.error("A Conservação do Produto é obrigatória.");
        return;
      }
    } else if (data.tipoProduto == 2) {
      if (!data.dataValidade) {
        toast.error("A Data de Validade do Produto é obrigatória.");
        return;
      }
    }

    const request = {
      id: data.id,
      produtoId: data.produtoId,
      numeroNotaFiscal: data.numeroNotaFiscal,
      chaveDanfe: data.chaveDanfe,
      dataCompra: data.dataCompra,
      valorDaNota: data.valorDaNota,
      valor: data.valor,
      quantidade: parseInt(data.quantidade),
      numeroPatrimonio: data.numeroPatrimonio,
      numeroDeSerie: data.numeroDeSerie,
      conservacao: data.conservacao,
      avarias: data.avarias,
      dataValidade: data.dataValidade,
      observacao: data.observacao,
      criadoPor: currentUser.usuarioLogin,
    };

    if (id) {
      mutate(upperCaseSimple(removeEmpty(request)));
    } else {
      mutate(upperCaseSimple(removeEmpty(request)));
      onClose();
      onRefetch();
    }
  };

  const tipoProdutoWatch = watch("tipoProduto");
  const conservacaoWatch = watch("conservacao");
  const produtoCategoriaIdWatch = watch("produtoCategoriaId");
  const marcaWatch = watch("marca");

  useEffect(() => {
    if (tipoProdutoWatch == 1) {
      setValue("quantidade", 1);
    }
  }, [tipoProdutoWatch]);

  return (
    <div
      className="tab-pane fade show active"
      id="produtos"
      role="tabpanel"
      aria-labelledby="produtos-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-2">
              <div style={{ display: "flex" }}>
                <Avatar
                  src={`data:image/jpeg;base64,${produto?.imagem}`}
                  sx={{
                    width: 130,
                    height: 130,
                    border: "1px solid rgba(0, 0, 0, 0.38)",
                    padding: 1,
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <TextField
                label="Número da Nota Fiscal"
                variant="outlined"
                fullWidth
                disabled={!isEdit && !canCreate}
                {...register("numeroNotaFiscal")}
                error={!!errors.numeroNotaFiscal}
                helperText={errors.numeroNotaFiscal?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-md-2 mt-4">
              <TextField
                label="Chave DANFE"
                variant="outlined"
                fullWidth
                disabled={!isEdit && !canCreate}
                {...register("chaveDanfe")}
                error={!!errors.chaveDanfe}
                helperText={errors.chaveDanfe?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-md-2 mt-4">
              <TextField
                type="date"
                label="Data da Compra"
                variant="outlined"
                fullWidth
                disabled={!isEdit && !canCreate}
                {...register("dataCompra")}
                error={!!errors.dataCompra}
                helperText={errors.dataCompra?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-md-2 mt-4">
              <Controller
                name="valorDaNota"
                control={control}
                defaultValue=""
                rules={{
                  required: "O valor da nota é obrigatório",
                }}
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    onChangeValue={(event, originalValue, maskedValue) => {
                      field.onChange(originalValue);
                    }}
                    InputElement={
                      <TextField
                        label="Valor total da Nota Fiscal"
                        variant="outlined"
                        fullWidth
                        disabled={!isEdit && !canCreate}
                        error={!!errors.valorDaNota}
                        helperText={errors.valorDaNota?.message}
                        inputProps={{
                          inputMode: "numeric",
                          placeholder: "R$ 0,00",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    }
                  />
                )}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <Autocomplete
                id="produtoId"
                disableClearable
                options={produtos}
                getOptionLabel={(option) =>
                  `${option.nome} - ${option.marca}` || ""
                }
                getOptionSelected={(option, value) => option.id === value}
                value={produtos.find((p) => p.id == watch("produtoId")) || null}
                disabled={!!id}
                onChange={(_, selectedOption) => {
                  setValue(
                    "produtoId",
                    selectedOption ? selectedOption.id : null
                  );
                  setValue("marca", selectedOption ? selectedOption.marca : "");
                  setValue(
                    "produtoCategoriaId",
                    selectedOption ? selectedOption.produtoCategoriaId : null
                  );
                  setValue(
                    "tipoProduto",
                    selectedOption ? selectedOption?.tipoProduto : null
                  );
                  setProduto(selectedOption);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nome do Produto"
                    variant="outlined"
                    error={!!errors.produtoId}
                    helperText={errors.produtoId?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <TextField
                id="marca"
                label="Marca"
                value={id ? produto?.marca : marcaWatch}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-3">
              <FormControl fullWidth>
                <InputLabel>Categoria</InputLabel>
                <Select
                  id="produtoCategoriaId"
                  label="Categoria"
                  value={produtoCategoriaIdWatch || ""}
                  disabled
                >
                  {groupData?.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-2">
              <FormControl fullWidth>
                <InputLabel>Tipo de Produto</InputLabel>
                <Select
                  id="tipoProduto"
                  label="Tipo de Produto"
                  value={tipoProdutoWatch || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                >
                  {tipoProduto?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {tipoProdutoWatch == 1 && (
              <>
                <div className="col-md-2">
                  <TextField
                    type="number"
                    label="Número do Patrimônio"
                    variant="outlined"
                    fullWidth
                    disabled={!isEdit && !canCreate}
                    {...register("numeroPatrimonio")}
                    error={!!errors.numeroPatrimonio}
                    helperText={errors.numeroPatrimonio?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <TextField
                    type="number"
                    label="Número de Série"
                    variant="outlined"
                    fullWidth
                    disabled={!isEdit && !canCreate}
                    {...register("numeroDeSerie")}
                    error={!!errors.numeroDeSerie}
                    helperText={errors.numeroDeSerie?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <FormControl fullWidth error={!!errors.conservacao}>
                    <InputLabel>Conservação</InputLabel>
                    <Select
                      {...register("conservacao")}
                      label="Conservação"
                      disabled={!isEdit && !canCreate}
                      value={conservacaoWatch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        SELECIONE
                      </MenuItem>
                      {conservacao?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.conservacao && (
                      <FormHelperText>Campo obrigatório!</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="row">
                  {conservacaoWatch > 3 && (
                    <div className="col-md-12 mt-4">
                      <TextField
                        label="Descrição das Avarias"
                        variant="outlined"
                        fullWidth
                        disabled={!isEdit && !canCreate}
                        {...register("avarias")}
                        error={!!errors.avarias}
                        helperText={errors.avarias?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {tipoProdutoWatch == 2 && (
              <div className="col-md-2">
                <TextField
                  type="date"
                  label="Data de validade"
                  variant="outlined"
                  fullWidth
                  disabled={!isEdit && !canCreate}
                  {...register("dataValidade")}
                  error={!!errors.dataValidade}
                  helperText={errors.dataValidade?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
          </div>

          <div className="row mt-4">
            <div className="col-md-2">
              <TextField
                type="number"
                label="Quantidade Unitária"
                variant="outlined"
                fullWidth
                {...register("quantidade")}
                error={!!errors.quantidade}
                helperText={errors.quantidade?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={tipoProdutoWatch == 1}
              />
            </div>
            <div className="col-md-2">
              <Controller
                name="valor"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    onChangeValue={(event, originalValue, maskedValue) => {
                      field.onChange(originalValue);
                    }}
                    InputElement={
                      <TextField
                        label="Valor Unitário"
                        variant="outlined"
                        fullWidth
                        disabled={!isEdit && !canCreate}
                        error={!!errors.valor}
                        helperText={errors.valor?.message}
                        inputProps={{
                          inputMode: "numeric",
                          placeholder: "R$ 0,00",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    }
                  />
                )}
              />
            </div>
            <div className="col-md-8">
              <TextField
                label="Usuário do Sistema"
                variant="outlined"
                fullWidth
                {...register("criadoPor")}
                error={!!errors.criadoPor}
                helperText={errors.criadoPor?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <TextField
                label="Observações"
                variant="outlined"
                fullWidth
                disabled={!isEdit && !canCreate}
                {...register("observacao")}
                error={!!errors.observacao}
                helperText={errors.marca?.observacao}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>

          {!isEdit ? (
            <div className="row mt-4">
              <div className="col-md-12">
                <TextField
                  label="Unidade"
                  id="unidadeId"
                  variant="outlined"
                  value={unidadeNome}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </div>
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {hasBackButton && (
            <Button
              type="button"
              color="warning"
              variant="contained"
              onClick={() => navigate(-1)}
              style={{ marginRight: "8px" }}
            >
              Voltar
            </Button>
          )}
          {isEdit || canCreate ? (
            <Button
              type="submit"
              color="success"
              variant="contained"
              disabled={!isValid}
              // onClick={onClose}
            >
              {id ? "Atualizar" : "Cadastrar"}
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
}
