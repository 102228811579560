import React, { useEffect } from 'react';
import { postNotaAluno } from '../../../services/ficha-avaliacao';
import { toast } from 'react-toastify';

const TabelaInfantil = ({ alunos, disciplina, ciclo, turma }) => {

    useEffect(() => {
        if (!alunos || alunos.length === 0) {
            return; // If alunos is falsy or empty, don't do anything
        }

        console.log("Alunos changed");

    }, [alunos]); // Run this effect whenever alunos changes

    async function handleBlur(row, e) {
        let data = {
            alunoId: row.id,
            disciplinaId: disciplina,
            trimestreId: ciclo,
            turmaId: turma,
            tipoLancamento: 4,
            valorLancamento: 0
        }

        const response = await postNotaAluno(data);

        if (response?.statusCode === undefined) {
            return
        }
        if (response.statusCode === 200) {
            toast.success('Faltas lançadas com sucesso!');
        }
        else {
            toast.error('Erro ao lançar faltas!');
        }
    }

    if (!alunos || alunos.length === 0) {
        return null; // If alunos is falsy or empty, don't render anything
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover w-100">
                <thead>
                    <tr className="text-center">
                        <th style={{ display: 'none' }} className="text-center">ID</th>
                        <th className="text-center">MATRÍCULA</th>
                        <th className="text-center">NOME</th>
                        <th className="text-center">FALTAS</th>
                    </tr>
                </thead>
                <tbody>
                    {alunos.map((row) => (
                        <tr key={row.id} className="text-center">
                            <td style={{ display: 'none' }}>{row.id}</td>
                            <td>{row.matricula}</td>
                            <td>{row.nome}</td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'faltas' + row.id}
                                    name={'faltas' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true ? row.faltas : null}
                                    onBlur={(e) => { handleBlur(row, e) }}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TabelaInfantil;
